import React, { Component } from "react";

import EarningReportComponent from "../../components/ManageLedger/EarningReport";
export class EarningReport extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return <EarningReportComponent />;
  }
}

export default EarningReport;
