import React, { Component } from "react";

import NumberList from "../../components/MatkaNumber/List";
import { connect } from "react-redux";

class List extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return <NumberList {...this.props} />;
  }
}
const mapstatetop = (state) => ({
  ...state,
});

export const MatkaNumber = connect(mapstatetop)(List);
