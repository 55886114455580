import { createBrowserRouter, Navigate } from "react-router-dom";
import { routerList, PublicRoute, ProtectRoutes } from "./router";
import { getStorage } from "utils";

const loggedIn = getStorage("userLogin", "object")

let list = routerList.map((route) => ({
    ...route, element:
        route.private ? <ProtectRoutes showSidebar={route?.sidebar ?? 1}><route.element /></ProtectRoutes> :
            <PublicRoute><route.element /></PublicRoute>
}))
export const routers = createBrowserRouter([
    {
        path: "/",
        element: (
            <Navigate to={loggedIn ? '/dashboard' : "/login"} />
        ),
    },
    ...list, // render public and private route
    {
        path: "*",
        element: (
            <Navigate to={loggedIn ? '/dashboard' : "/login"} />
        ),
    },
]);
export default routers;
