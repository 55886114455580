import React, { Component } from "react";

import { connect } from "react-redux";
import CompanyReportComponent from "../../components/CompanyReport/CompanyReportComponent";

class CompanyReport extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      
        <CompanyReportComponent {...this.props} />
      
    );
  }
}
const mapstatetop = (state) => ({
  ...state,
});

export default connect(mapstatetop)(CompanyReport);
