import React, { useEffect, useState } from "react";
import Progress from "../common/Progress";
import Navigation from "../common/Navigation";
import Footer from "../common/Footer";
import TopHeader from "../common/TopHeader";
import { correctHeight, detectBody } from "./Helpers";
import $ from "jquery";
import Banner from "components/common/Banner";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { domainDataRequest } from "_actions";

export const BannerContext = React.createContext();
export const BannerProvider = BannerContext.Provider;
export const BannerConsumer = BannerContext.Consumer;

const Main = ({ children }) => {
  const dispatch = useDispatch()
  const { domainData } = useSelector(state => state.auth)
  const [state, setState] = useState({
    toggleBanner: true,
  });

  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    // Run correctHeight function on load and resize window event
    $(window).bind("load resize", function () {
      correctHeight();
      detectBody();
    });

    // Correct height of wrapper after metisMenu animation.
    $(".metismenu a").click(() => {
      setTimeout(() => {
        correctHeight();
      }, 300);
    });
  }, []);

  const handleToggle = () => {
    setState((prevState) => ({
      toggleBanner: !prevState.toggleBanner,
    }));
  };

  useEffect(() => {
    let param = { host: window.location.hostname }
    if (domainData == null) {
      dispatch(domainDataRequest(param, (res) => {
        document.title = res.data.website_name;
        let link = document.querySelector("link[rel~='icon']");
        if (!link) {
          link = document.createElement('link');
          link.rel = 'icon';
          document.getElementsByTagName('head')[0].appendChild(link);
        }
        link.href = res.data.fav_icon;
      }))
    }

  }, [])

  let wrapperClass = "gray-bg ";

  return (
    <BannerProvider
      value={{
        toggleBanner: state.toggleBanner,
        setToggleBaner: handleToggle,
      }}
    >
      <div id="wrapper">
        <Progress />
        <Navigation location={location} navigate={navigate} />

        <div id="page-wrapper" className={wrapperClass}>
          <TopHeader />
          <Banner toggleBanner={state.toggleBanner} />
          {children}

          <Footer />
        </div>
      </div>
    </BannerProvider>
  );
};
export default Main;
