import React, { Component } from 'react'

import ManagePasswordComponent from 'components/ManagePassword/ManagePassword';
export class ManagePassword extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }
    render() {
        return (
            
                <ManagePasswordComponent />
            

        )
    }
}

export default ManagePassword