import React, { Component } from "react";

import AnnouncementComponent from "components/Announcement/Announcement";
export class Announcement extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return <AnnouncementComponent />;
  }
}

export default Announcement;
