import { createRequest } from '_actions/agent/agent.actions';
import { profileDetails } from '_services';
import InnerPageHeader from 'components/common/InnerPageHeader'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

const AddNewUser = ({ role, title, navigationUrl, parentType, action, breadcrumbTitle, navigate, isEdit, params, showFixSharing }) => {
    let userRole = localStorage.getItem("role") || null;
    const dispatch = useDispatch()
    const urlparam = useParams()

    const [focusedField, setFocusedField] = useState(null);

    const [formData, setFormData] = useState({
        first_name: '',
        last_name: '',
        username: '',
        password: '',
        confirm_password: '',
        fixed_limit: '',
        cricket_commission: 0.0,
        session_commission: 0.0,
        match_commission: 0.0,
        football_commission: 0.0,
        tennis_commission: 0.0,
        matka_commission: 0.0,
        other_commission: 0.0,
        my_agent_share: 0.0,
        my_share: 0.0,
        my_agent_share: 0.0,
        game_share: 0.0,
        agent_game_share: 0.0,
        matka_agent_share: 0.0,
        matka_share: 0.0,
        theme: {}
    });

    const [errors, setErrors] = useState({});
    const [profileData, setProfileData] = useState({});

    const validateForm = () => {
        const newErrors = {};

        if (!formData.first_name) {
            newErrors.first_name = "Name is required";
        } else if (formData.first_name.length < 4) {
            newErrors.first_name = "Name must be at least 4 characters long";
        }

        if (!formData.username) {
            newErrors.username = "Username is required";
        } else if (formData.username.length < 4) {
            newErrors.username = "Username must be at least 4 characters long";
        }

        if (!formData.password) {
            newErrors.password = "Password is required";
        }

        if (!formData.confirm_password) {
            newErrors.confirm_password = "Confirm Password is required";
        } else if (formData.password !== formData.confirm_password) {
            newErrors.confirm_password = "Passwords do not match";
        }

        if (!formData.fixed_limit) {
            newErrors.fixed_limit = "fixed limit field is required";
        } else if (isNaN(formData.fixed_limit) || Number(formData.fixed_limit) < 0) {
            newErrors.fixed_limit = "fixed limit must be a positive number";
        }

        return newErrors;
    }

    useEffect(() => {
        getProfileDetail()
    }, [urlparam.user_id])

    const getProfileDetail = () => {
        const postData = {
            user_id: urlparam.user_id
        }
        profileDetails(postData)
            .then((response) => {
                let data = response?.data?.data
                if (data) {
                    setProfileData(data)
                    setFormData({
                        ...formData,
                        // my_agent_share: data.my_agent_share,
                        my_share: data.my_agent_share,
                        game_share: data.agent_game_share,
                    });
                }
            }).catch((error) => {

            })
    }

    const handleChange = (e) => {
        const { name, value } = e.target;

        // Ensure the value is not greater than profileData.my_share
        if (name === 'cricket_commission' && ((Number(value) > (urlparam?.user_id ? profileData.cricket_commission : userRole == 1 ? 100 : (Number(profileData.cricket_commission)))) || Number(value) < 0)) {
            setErrors(prevState => ({ ...prevState, [name]: 'Value cannot exceed your total commission.' }));
            return; // Prevent further state updates
        }
        // Ensure the value is not greater than profileData.my_share
        // if (name === 'session_commission' && ((Number(value) > (userRole == 1 ? 100 : (Number(profileData.session_commission)))) || Number(value) < 0)) {
        if (name === 'session_commission' && ((Number(value) > (urlparam?.user_id ? profileData.session_commission : userRole == 1 ? 100 : (Number(profileData.session_commission)))) || Number(value) < 0)) {
            setErrors(prevState => ({ ...prevState, [name]: 'Value cannot exceed your total commission.' }));
            return; // Prevent further state updates
        }
        if (name === 'my_agent_share' && Number(value) > Number(profileData.my_agent_share)) {
            setErrors(prevState => ({ ...prevState, [name]: 'Value cannot exceed your total share.' }));
            return; // Prevent further state updates
        }
        if (name === 'agent_game_share' && Number(value) > Number(profileData.agent_game_share)) {
            setErrors(prevState => ({ ...prevState, [name]: 'Value cannot exceed your total share.' }));
            return; // Prevent further state updates
        }
        // Ensure the value is not greater than profileData.my_share
        if (name === 'game_share' && Number(value) > Number(profileData.agent_game_share)) {
            setErrors(prevState => ({ ...prevState, [name]: 'Value cannot exceed your total share.' }));
            return; // Prevent further state updates
        }
        if (name === 'fixed_limit' && Number(value) > Number(profileData.balance)) {
            setErrors(prevState => ({ ...prevState, [name]: 'Value cannot exceed your total fix limit.' }));
            return; // Prevent further state updates
        }

        setFormData(prevState => ({ ...prevState, [name]: value }));
        setErrors(prevState => ({ ...prevState, [name]: '' })); // Clear error when input changes
        updateSharing(name, value);
    }

    const updateSharing = (name, value) => {
        if (name === 'my_agent_share') {
            setFormData(prevState => {
                const new_my_share = Number(profileData?.my_agent_share) - Number(prevState.my_agent_share);
                return { ...prevState, my_share: new_my_share };
            });
        } else if (name === 'agent_game_share') {
            setFormData(prevState => {
                // const new_my_share = Number(prevState?.my_share) - Number(value);
                const new_my_share = Number(profileData?.agent_game_share) - Number(prevState.agent_game_share);
                return { ...prevState, game_share: new_my_share };
            });
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        const newErrors = validateForm();

        if (Object.keys(newErrors).length > 0) {
            setErrors(newErrors);  // If there are errors, set them in the state
        } else {
            // Submit form (e.g., make API call)
            if (urlparam.user_id) {
                formData.user_id = urlparam.user_id;
            }
            createUser(formData)

        }
    }

    const createUser = (sendObj) => {
        sendObj.role = role;
        dispatch(
            createRequest(sendObj, (res) => {
                if (res) {
                    navigate(navigationUrl);
                }
            })
        );
    };


    return (
        <React.Fragment>
            <InnerPageHeader
                pageTitle={params?.id ? this.state.formObj.username : breadcrumbTitle}
                breadcrumbs={[
                    { title: "Dashboard", url: "/" },
                    { title: parentType, url: navigationUrl },
                ]}
                currenPageTitle={action}
            />

            <div className="wrappe wrapper-conten animated fadeInRight">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="ibox float-e-margins">
                            <div className="ibox-title">
                                <h5>{action}</h5>
                            </div>

                            <div className="ibox-content">
                                <form onSubmit={handleSubmit} className="form-horizontal">

                                    <div className="new-box">
                                        <div className="heading col-sm-12"><p>Personal Details</p></div>
                                        <div className="col-sm-12">
                                            <div className="form-group col-md-6 col-sm-12" style={{ minHeight: '90px', marginBottom: 0 }}>
                                                <label className={`control-label ${focusedField === 'first_name' ? 'label-focused' : ''}`}>
                                                    Name
                                                </label>
                                                <input
                                                    type="text"
                                                    className={`form-control ${errors.first_name ? 'is-invalid' : ''}`}
                                                    name="first_name"
                                                    id="first_name"
                                                    value={formData.first_name}
                                                    onChange={handleChange}
                                                    onFocus={() => setFocusedField('first_name')}
                                                    onBlur={() => setFocusedField(null)}
                                                />
                                                {errors.first_name && <div className="text-danger">{errors.first_name}</div>}
                                            </div>

                                            <div className="form-group col-md-6 col-sm-12 " style={{ minHeight: '90px', marginBottom: 0 }}>
                                                <label className={`control-label ${focusedField === 'username' ? 'label-focused' : ''}`}>
                                                    Username
                                                </label>
                                                <input
                                                    type="text"
                                                    className={`form-control ${errors.username ? 'is-invalid' : ''}`}
                                                    name="username"
                                                    id="username"
                                                    value={formData.username}
                                                    onChange={handleChange}
                                                    onFocus={() => setFocusedField('username')}
                                                    onBlur={() => setFocusedField(null)}
                                                    onKeyDown={(event) => {
                                                        if (event.code === 'Space') event.preventDefault();
                                                    }}
                                                />
                                                {errors.username && <div className="text-danger">{errors.username}</div>}
                                            </div>

                                            <div className="form-group col-md-6 col-sm-12" style={{ minHeight: '90px', marginBottom: 0 }}>
                                                <label className={`control-label ${focusedField === 'password' ? 'label-focused' : ''}`}>
                                                    Password
                                                </label>
                                                <input
                                                    type="password"
                                                    className={`form-control ${errors.password ? 'is-invalid' : ''}`}
                                                    name="password"
                                                    id="password"
                                                    value={formData.password}
                                                    onChange={handleChange}
                                                    onFocus={() => setFocusedField('password')}
                                                    onBlur={() => setFocusedField(null)}
                                                />
                                                {errors.password && <div className="text-danger">{errors.password}</div>}
                                            </div>

                                            <div className="form-group col-md-6 col-sm-12" style={{ minHeight: '90px', marginBottom: 0 }}>
                                                <label className={`control-label ${focusedField === 'confirm_password' ? 'label-focused' : ''}`}>
                                                    Confirm Password
                                                </label>
                                                <input
                                                    type="password"
                                                    className={`form-control ${errors.confirm_password ? 'is-invalid' : ''}`}
                                                    name="confirm_password"
                                                    id="confirm_password"
                                                    value={formData.confirm_password}
                                                    onChange={handleChange}
                                                    onFocus={() => setFocusedField('confirm_password')}
                                                    onBlur={() => setFocusedField(null)}
                                                />
                                                {errors.confirm_password && <div className="text-danger">{errors.confirm_password}</div>}
                                            </div>

                                            <div className="form-group col-md-6 col-sm-12" style={{ minHeight: '90px', marginBottom: 0 }}>
                                                <label className={`control-label ${focusedField === 'fixed_limit' ? 'label-focused' : ''}`}>
                                                    Free Chip
                                                </label>
                                                <input
                                                    type="number"
                                                    className={`form-control ${errors.fixed_limit ? 'is-invalid' : ''}`}
                                                    name="fixed_limit"
                                                    id="fixed_limit"
                                                    value={formData.fixed_limit}
                                                    onChange={handleChange}
                                                    onFocus={() => setFocusedField('fixed_limit')}
                                                    onBlur={() => setFocusedField(null)}
                                                />
                                                <p>Available Balance: {Number(profileData.balance) - Number(formData.fixed_limit)}</p>
                                                {errors.fixed_limit && <div className="text-danger">{errors.fixed_limit}</div>}
                                            </div>

                                            <div className="form-group col-md-6 col-sm-12" style={{ minHeight: '90px', marginBottom: 0 }}>
                                                <label className={`control-label ${focusedField === 'session_commission' ? 'label-focused' : ''}`}>
                                                    Session Commission
                                                </label>
                                                <input
                                                    type="number"
                                                    className={`form-control ${errors.session_commission ? 'is-invalid' : ''}`}
                                                    name="session_commission"
                                                    id="session_commission"
                                                    value={formData.session_commission}
                                                    onChange={handleChange}
                                                    min="0" // Ensure the value is at least 1
                                                    step="0" // Ensure it only allows whole numbers
                                                    onFocus={() => setFocusedField('session_commission')}
                                                    onBlur={() => setFocusedField(null)}
                                                />
                                                <p>Session Commission set from 0 to {urlparam?.user_id ? profileData.session_commission : userRole == 1 ? 100 : profileData.session_commission}</p>
                                                {errors.session_commission && <div className="text-danger">{errors.session_commission}</div>}
                                            </div>
                                            <div className="form-group col-md-6 col-sm-12" style={{ minHeight: '90px', marginBottom: 0 }}>
                                                <label className={`control-label ${focusedField === 'cricket_commission' ? 'label-focused' : ''}`}>
                                                    Cricket Match Commission
                                                </label>
                                                <input
                                                    type="number"
                                                    className={`form-control ${errors.cricket_commission ? 'is-invalid' : ''}`}
                                                    name="cricket_commission"
                                                    id="cricket_commission"
                                                    value={formData.cricket_commission}
                                                    onChange={handleChange}
                                                    min="0" // Ensure the value is at least 1
                                                    step="0" // Ensure it only allows whole numbers
                                                    onFocus={() => setFocusedField('cricket_commission')}
                                                    onBlur={() => setFocusedField(null)}
                                                />
                                                <p>Cricket Commission set from 0 to {urlparam?.user_id ? profileData.cricket_commission : userRole == 1 ? 100 : profileData.cricket_commission}</p>
                                                {errors.cricket_commission && <div className="text-danger">{errors.cricket_commission}</div>}
                                            </div>
                                        </div>
                                    </div>

                                    {role != 9 && <div className="row mt-3">
                                        <div className='col s12'>
                                            <table class="table col s12 m6 l4 no-padding partnership-table">
                                                <tbody>
                                                    <tr>
                                                        <th class="center-align">Position</th>
                                                        <th class="center-align">Sports Share</th>
                                                    </tr>
                                                    <tr>
                                                        <td class="center-align">Available Partnership</td>
                                                        <td class="center-align">
                                                            <label id="my_agent_share">{profileData?.my_agent_share}</label>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td class="center-align">Down-line</td>
                                                        <td>
                                                            <div class="input-field col s12 m12 no-margin">
                                                                <input
                                                                    id="my_agent_share"
                                                                    name="my_agent_share"
                                                                    type="number"
                                                                    class="validate"
                                                                    value={formData.my_agent_share}
                                                                    disabled={role == 9}
                                                                    onChange={handleChange} />
                                                                {errors.my_agent_share && <div className="text-danger">{errors.my_agent_share}</div>}

                                                                <label for="my_agent_share">Client
                                                                    Part.</label>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td class="center-align">My-Share</td>
                                                        <td class="center-align">
                                                            <label id="our_cricket_partnership">{formData.my_share}</label>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>

                                            <table class="table col s12 m6 l4 no-padding partnership-table">
                                                <tbody>
                                                    <tr>
                                                        <th class="center-align">Position</th>
                                                        <th class="center-align">Casino Share</th>
                                                    </tr>
                                                    <tr>
                                                        <td class="center-align">Available Partnership</td>
                                                        <td class="center-align">
                                                            <label id="agent_game_share">{profileData?.agent_game_share}</label>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td class="center-align">Down-line</td>
                                                        <td>
                                                            <div class="input-field col s12 m12 no-margin">
                                                                <input
                                                                    id="agent_game_share"
                                                                    name="agent_game_share"
                                                                    type="number"
                                                                    class="validate"
                                                                    value={formData.agent_game_share}
                                                                    disabled={role == 9}
                                                                    onChange={handleChange}
                                                                />
                                                                {errors.agent_game_share && <div className="text-danger">{errors.agent_game_share}</div>}

                                                                <label for="game_share">Client
                                                                    Part.</label>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td class="center-align">Game-Share</td>
                                                        <td class="center-align">
                                                            <label id="our_cricket_partnership">{formData.game_share}</label>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>

                                        </div>
                                    </div>}
                                    <br />
                                    <div className="form-group text-center">
                                        <div className="col-sm-12">
                                            <button type="submit" className="btn btn-primary create-user-btn full-width-btn">{action}</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default AddNewUser;
