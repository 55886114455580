import React, { Component, Fragment } from "react";
import Select from "react-select";
import "./ManageLedger.css";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";
import moment from "moment";
import Pagination from "../common/Pagination";
import { getSportList } from "_services/sports.services";
import InnerPageHeader from "components/common/InnerPageHeader";
export class EarningReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      from_date: null,
      to_date: null,
      selectedSport: "",
      selectedSportOptions: [],
      totalRecords: 10,
      sportsData: [],
    };
  }
  handleCallback = (from_date, to_date) => {
    this.setState({ from_date, to_date });
  };

  handleChange = (selectedSport) => {
    this.setState({ selectedSport });
  };

  componentDidMount() {
    this.fetchAllSports();
  }

  fetchAllSports = () => {
    getSportList()
      .then((response) => {
        if (
          response &&
          response.data &&
          response.data.data &&
          response.data.data.length
        ) {
          let allSports = [];
          this.setState({ sportsData: response.data.data }, () => {
            this.state.sportsData.map((x) => {
              allSports.push({ label: x.sports_name, value: x.sport_id });
            });
            this.setState({ selectedSportOptions: allSports });
          });
        } else {
          this.setState({ sportsData: [] });
        }
      })
      .catch((error) => {
        this.setState({ sportsData: [] });
      });
  };

  render() {
    const {
      selectedSport,
      selectedSportOptions,
      to_date,
      from_date,
      totalRecords,
    } = this.state;
    return (
      <Fragment>
        <InnerPageHeader
          pageTitle="COLLECTION REPORT"
          breadcrumbs={[{ title: "Dashboard", url: "/" }]}
          currenPageTitle="Games Daily Report"
        />
        <div className="row">
          <div className="col-lg-3 col-md-4 col-sm-12">
            <Select
              options={selectedSportOptions}
              onChange={this.handleChange}
              isMulti
              value={selectedSport ? selectedSport : ""}
              className="reactSelect"
              placeholder=""
            />
          </div>
          <DateRangePicker
            initialSettings={{
              // startDate: from_date.toDate(),
              // endDate: to_date.toDate(),
              startDate: from_date
                ? from_date.toDate()
                : moment().subtract(6, "days"),
              endDate: to_date ? to_date.toDate() : moment(),
              ranges: {
                Today: [moment().toDate(), moment().toDate()],
                Yesterday: [
                  moment().subtract(1, "days").toDate(),
                  moment().subtract(1, "days").toDate(),
                ],
                "Last 7 Days": [
                  moment().subtract(6, "days").toDate(),
                  moment().toDate(),
                ],
                "This Week": [
                  moment().startOf("isoWeek").toDate(),
                  moment().toDate(),
                ],
                "Last Week": [
                  moment().subtract(1, "week").startOf("isoWeek").toDate(),
                  moment().subtract(1, "week").endOf("isoWeek").toDate(),
                ],
                "Last 30 Days": [
                  moment().subtract(29, "days").toDate(),
                  moment().toDate(),
                ],
                "This Month": [
                  moment().startOf("month").toDate(),
                  moment().toDate(),
                ],
                "Last Month": [
                  moment().subtract(1, "month").startOf("month").toDate(),
                  moment().subtract(1, "month").endOf("month").toDate(),
                ],
              },
            }}
            onCallback={this.handleCallback}
          >
            <div
              id="reportrange"
              className="col-lg-3 col-md-3 col-sm-9 mlDatePicker"
            >
              <span style={{ float: "right" }}>
                <i
                  className={"glyphicon glyphicon-calendar  fa fa-calendar"}
                ></i>
                &nbsp;&nbsp;
                <span className="text-right">
                  {" "}
                  {from_date && to_date
                    ? `${from_date.format("DD MMM, YYYY")} - ${to_date.format(
                        "DD MMM, YYYY"
                      )}`
                    : "-"}
                </span>
                &nbsp;&nbsp;
                <i class="fa fa-caret-down" aria-hidden="true"></i>
              </span>
            </div>
          </DateRangePicker>
          <div
            className="col-lg-3 col-md-4 col-sm-6 col-sm-3"
            style={{ margin: "11px 0px" }}
          >
            <a className="btn btn-primary btn-xs search_date_btn">Search</a>
          </div>
        </div>
        <div className="wrapper wrapper-content animated fadeInRight">
          <div className="row">
            <div className="col-12">
              <div className="ibox">
                <div className="ibox-title">
                  <h5>Games Daily Report</h5>
                </div>
                <div className="ibox-content">
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="table-responsive">
                        <table className="table table-striped table-bordered table-hover dataTables-example">
                          <thead>
                            <tr>
                              <th>Date</th>
                              <th>Client Name</th>
                              <th>Ledger Title</th>
                              <th>Event Type</th>
                              <th>Debit</th>
                              <th>Credit</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>11-04-2022</td>
                              <td>123</td>
                              <td>TeenPatti-T-20 - 20 Jun</td>
                              <td>TeenPatti</td>
                              <td>-</td>
                              <td>7245.22</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div className="col-xs-12 col-sm-6">
                      <Pagination
                        totalRecords={totalRecords}
                        postion={"left"}
                      />
                    </div>

                    <div className="col-lg-12">
                      <div className="table-responsive">
                        <table className="table table-bordered dataTables-example">
                          <thead>
                            <tr>
                              <th>Date</th>
                              <th>Client Name</th>
                              <th>Ledger Title</th>
                              <th>Event Type</th>
                              <th>Debit Total</th>
                              <th>Credit Total</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <th colSpan="4" className="text-center">
                                All Page Total
                              </th>
                              <th>-840728.25</th>
                              <th>702.0</th>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default EarningReport;
