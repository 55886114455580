import React, { useEffect, useState } from "react";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";
import moment from "moment";

function DateRangePickerComponent(props) {

  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);


  useEffect(() => {
    // console.log('props',props);
    const aDate = props.fDate;
    const bDate = props.tDate;
    if (aDate) {
      setFromDate(aDate)
      setToDate(bDate)
    }
  }, [props.fDate])



  const handleCallback = (from_date, to_date) => {
    props.handleEvent(from_date, to_date);
    // this.setState({ from_date, to_date,currentlyPage:1 });

  }


  return (
    <div className="">
      <DateRangePicker
        initialSettings={{

          ranges: {
            Today: [moment().toDate(), moment().toDate()],
            Yesterday: [
              moment().subtract(1, "days").toDate(),
              moment().subtract(1, "days").toDate(),
            ],
            "Last 7 Days": [
              moment().subtract(6, "days").toDate(),
              moment().toDate(),
            ],
            "This Week": [
              moment().startOf("isoWeek").toDate(),
              moment().toDate(),
            ],
            "Last Week": [
              moment().subtract(1, "week").startOf("isoWeek").toDate(),
              moment().subtract(1, "week").endOf("isoWeek").toDate(),
            ],
            "Last 30 Days": [
              moment().subtract(29, "days").toDate(),
              moment().toDate(),
            ],
            "This Month": [
              moment().startOf("month").toDate(),
              moment().toDate(),
            ],
            "Last Month": [
              moment().subtract(1, "month").startOf("month").toDate(),
              moment().subtract(1, "month").endOf("month").toDate(),
            ],
          },
        }}
        onCallback={handleCallback}
      >
        <div
          id="reportrange"
          style={{ padding: 10, }}
          className="col-lg-3 col-md-3 col-sm-9 mlDatePicker"
        >
          <span style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', padding: 0, }}>
            <i
              className={"glyphicon glyphicon-calendar  fa fa-calendar"}
            ></i>
            &nbsp;&nbsp;
            <span className="text-right">
              {fromDate && moment(fromDate).format("DD MMM, YYYY")}
              {toDate && moment(fromDate).format("DD MMM, YYYY") != moment(toDate).format("DD MMM, YYYY") && ' - ' + moment(toDate).format("DD MMM, YYYY")}
              {/* {(fromDate && toDate)
                    ? `${((fromDate).format("DD MMM, YYYY"))} - ${((toDate).format("DD MMM, YYYY"))}`
                    : "-"} */}
            </span>
            &nbsp;&nbsp;
            <i className="fa " aria-hidden="true"></i>
          </span>
        </div>
      </DateRangePicker>
      {/* <DateRangePicker
        initialSettings={{
          ranges: range,
        }}
        alwaysShowCalendars={false}
        onEvent={(from_date, to_date)=>props.handleEvent(from_date, to_date)}
      >
        <button className="my_ledgers_dated_picker col-lg-3 col-md-4 col-sm-9 col-xs-11 pull-right">
          <i className="glyphicon glyphicon-calendar fa fa-calendar"></i>
          &nbsp;
          <span>
            {props.fromDate && props.toDate
              ? `${moment(props.fromDate).format("DD MMM, YYYY")} to ${moment(props.toDate).format(
                  "DD MMM, YYYY"
                )}`
              : "-"}
          </span>
          <b className="caret"></b>
        </button>
      </DateRangePicker> */}
    </div>
  );
}

export default DateRangePickerComponent;
