import React, { Component } from "react";
import ReactPaginate from "react-paginate";
import "react-datepicker/dist/react-datepicker.css";
import ConfrimModal from "components/ConfirmModal";
import InnerPageHeader from "../common/InnerPageHeader";
import {
  activateFastMatkaCategoryReq,
  activateMatkaCategoryReq,
  deleteFastMatkaCategory,
  deleteMatkaCategory,
  getFastMatkaCategory,
  getMatkaCategory,
} from "_services/matkaservice";
import Loader from "components/common/Loader";
import { Link } from "react-router-dom";
class List extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dataList: [],
      isLoading: false,
      delLoader: false,
      totalRecords: "",
      currentPage: 1,
      perPage: 10,
      pageCount: 0,
    };
    this.isFast = this.props?.type === "fast_matka";
  }

  componentDidMount() {
    this.fetchListData();
  }

  fetchListData = async () => {
    const res = this.isFast
      ? await getFastMatkaCategory({
          current_page: this.state.currentPage,
        })
      : await getMatkaCategory({
          current_page: this.state.currentPage,
        });
    if (res && res?.data) {
      this.setState({
        dataList: res.data.records,
        totalRecords: res?.data?.total_records,
        pageCount: Math.ceil(res.data?.total_records / this.state.perPage),
      });
    } else {
      this.setState({
        dataList: [],
        totalRecords: 0,
      });
    }
    this.setState({ isLoading: false });
  };

  getRecordDetails = () => {
    const { currentPage, totalRecords, perPage } = this.state;
    let startingCount = currentPage === 1 ? 1 : currentPage * perPage - 9;
    let endingCount =
      perPage < totalRecords
        ? currentPage === 1
          ? perPage
          : currentPage * 10
        : totalRecords;
    return `Showing ${startingCount} to ${endingCount} of ${totalRecords} entries`;
  };

  handlePageClick = (e) => {
    const selectedPage = e.selected;
    this.setState({ currentPage: selectedPage + 1 }, () => {
      this.fetchListData();
    });
  };

  handleDelete = async (data) => {
    this.setState({ delLoader: data });
    const payload = {
      id: data,
    };
    const resp = this.isFast
      ? await deleteFastMatkaCategory(payload)
      : await deleteMatkaCategory(payload);
    if (resp?.data?.error === "") {
      this.fetchListData();
      this.setState({ delLoader: false });
    }
  };

  actviateUser = async (data) => {
    this.setState({ isLoading: data?._id?.$oid });
    const payload = {
      id: data?._id?.$oid,
      status: data?.is_active === 1 ? 0 : 1,
    };
    const resp = this.isFast
      ? await activateFastMatkaCategoryReq(payload)
      : await activateMatkaCategoryReq(payload);
    if (resp?.data?.error === "") {
      this.fetchListData();
    }
  };

  submit = () => [this.setState({ isShowModal: false })];

  render() {
    const {
      dataList,
      totalRecords,
      currentPage,
      perPage,
      pageCount,
      isShowModal,
      isLoading,
      delLoader,
    } = this.state;
    
    const pathname = this.isFast ? "fast-matka" : "matka"

    return (
      <>
        <InnerPageHeader
          pageTitle={this.isFast ? "Fast Matka Category" : "Matka Category"}
          breadcrumbs={[{ title: "Dashboard", url: "/" }]}
          currenPageTitle="List"
        />
        <div className="wrapper wrapper-content animated fadeInRight">
          <div className="row">
            <div className="col-lg-12">
              <div className="ibox float-e-margins">
                <div className="ibox-title">
                  <h5 style={{ fontWeight: 700 }}>All Category</h5>
                  <div className="ibox-tools">
                    <Link
                      to={`/add-${pathname}-category`}
                      className="btn btn-primary btn-xs"
                      style={{ cursor: "pointer" }}
                    >
                      Add Category
                    </Link>
                  </div>
                </div>
                <div className="ibox-content">
                  <div className="table-responsive">
                    <div
                      id="DataTables_Table_0_wrapper"
                      className="dataTables_wrapper form-inline dt-bootstrap"
                    >
                      <table className="table table-striped table-bordered table-hover dataTables-example">
                        <thead>
                          <tr>
                            <th>S.No</th>
                            <th>Title</th>
                            <th>Open Time</th>
                            <th>Close Time</th>
                            <th>Week Days</th>
                            <th>Type</th>
                            <th>Status</th>
                            <th>Actions</th>
                          </tr>
                        </thead>
                        <tbody>
                          {dataList?.length ? (
                            dataList.map((user, index) => (
                              <tr className="gradeX" key={index}>
                                <td>
                                  {index + 1 + (currentPage - 1) * perPage}
                                </td>
                                <td>{user?.title}</td>
                                <td>{user.open_time}</td>
                                <td className="center">{user.close_time}</td>
                                <td>{user.week_days}</td>
                                <td>
                                  {+user.category_type === 3
                                    ? "Open to Close"
                                    : +user.category_type === 2
                                    ? "Close"
                                    : "Open"}
                                </td>
                                <td className="center">
                                  <button
                                    className={
                                      user?.is_active === 1
                                        ? "matka_actBtn"
                                        : "matka_inActBtn"
                                    }
                                    disabled={isLoading === user?._id?.$oid}
                                    onClick={() => this.actviateUser(user)}
                                  >
                                    {isLoading === user?._id?.$oid ? (
                                      <Loader boxClass="active" />
                                    ) : user?.is_active === 1 ? (
                                      "Active"
                                    ) : (
                                      "In Active"
                                    )}
                                  </button>
                                </td>
                                <td className="">
                                  <Link
                                    className="btn btn-white btn-sm"
                                    to={ `/edit-${pathname}-category/${user?._id?.$oid}`}
                                    state={{ ...user, id: user?._id?.$oid }}
                                  >
                                    <i className="fa fa-pencil-square"></i> Edit
                                  </Link>
                                  &nbsp;&nbsp; &nbsp;&nbsp;
                                  <a
                                    className="btn btn-white btn-sm anvDel_spins"
                                    disabled={delLoader === user?._id?.$oid}
                                    onClick={() =>
                                      this.handleDelete(user?._id?.$oid)
                                    }
                                  >
                                    {/* {delLoader ? ( */}
                                    {delLoader === user?._id?.$oid ? (
                                      <Loader />
                                    ) : (
                                      <i className="fa fa-trash"></i>
                                    )}
                                    Delete
                                  </a>
                                </td>
                              </tr>
                            ))
                          ) : (
                            <tr className="gradeX">
                              <td colSpan={7}>No data available in table</td>
                            </tr>
                          )}
                        </tbody>
                      </table>

                      <div className="row" style={{ margin: "15px 0px" }}>
                        <div className="col-xs-12 col-sm-6">
                          <div
                            className="dataTables_info"
                            id="DataTables_Table_0_info"
                            role="status"
                            aria-live="polite"
                          >
                            {totalRecords
                              ? this.getRecordDetails()
                              : "Showing 0 to 0 of 0 entries"}
                          </div>
                        </div>
                        <div className="col-xs-12 col-sm-6">
                          <div className="paginate_class">
                            <ReactPaginate
                              previousLabel={"prev"}
                              nextLabel={"next"}
                              breakLabel={"..."}
                              breakClassName={"break-me"}
                              pageCount={pageCount}
                              marginPagesDisplayed={2}
                              pageRangeDisplayed={5}
                              onPageChange={this.handlePageClick}
                              containerClassName={"pagination"}
                              subContainerClassName={"pages pagination"}
                              activeClassName={"active"}
                            />
                          </div>
                        </div>
                        <div className="clearfix visible-xs"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {isShowModal && (
              <ConfrimModal
                isShow={isShowModal}
                message={
                  <label>
                    Are you sure you want to delete{" "}
                    {/* <span style={{ color: "red" }}>{event_name}</span> match? */}
                  </label>
                }
                onCancle={() => this.setState({ isShowModal: false })}
                onSubmit={() => this.submit()}
              />
            )}
          </div>
        </div>
      </>
    );
  }
}
export default List;
