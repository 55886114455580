import { chipSummery, profileDetails } from '_services';
import InnerPageHeader from 'components/common/InnerPageHeader';
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';

const ChipSummery = () => {
    const { user_id } = useParams();
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to top when component mounts
    }, []);

    useEffect(() => {
        fetchChipSummery();
    }, [user_id]);

    const fetchChipSummery = () => {
        let sendObj = {
            user_id: user_id || '',
        };

        setLoading(true);

        chipSummery(sendObj)
            .then((response) => {
                if (response && response.data && response.data.data) {
                    setData(response.data.data);
                }
                setLoading(false);
            })
            .catch((error) => {
                console.log(error, "error");
                setError("Failed to fetch data.");
                setLoading(false);
            });
    };

    return (
        <div>
            {/* <InnerPageHeader
                pageTitle={'Chip Summery'}
                breadcrumbs={[
                    { title: "Dashboard", url: "/" },
                    // { title: parentType, url: navigationUrl },
                ]}
                currenPageTitle={'Chip Summery'}
            /> */}

            <div className="wrapper wrapper-content animated fadeInRight">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="ibox float-e-margins">
                            {/* <div className="ibox-title">
                                <h5>Chip Summery</h5>

                            </div> */}
                            <div className="ibox-content">

                                {loading ? (
                                    <p>Loading...</p>
                                ) : error ? (
                                    <p>{error}</p>
                                ) : (
                                    <div className="card-content">
                                        <div className="row">
                                            <div className="col s12">
                                                <ul className="collection with-header" style={{ margin: 0 }}>
                                                    <li className="collection-header">
                                                        <h5>Chip Summery</h5></li>
                                                    <li className="collection-item" style={{ paddingLeft: 10 }}>
                                                        <div>User : {data?.username}</div>
                                                    </li>
                                                    <li className="collection-item" style={{ paddingLeft: 10 }}>
                                                        <div>Current Balance : {data?.current_balance}</div>
                                                    </li>
                                                    <li className="collection-item" style={{ paddingLeft: 10 }}>
                                                        <div>Children Free Chip : {data?.cl_free_chip} </div>
                                                    </li>
                                                    <li className="collection-item" style={{ paddingLeft: 10 }}>
                                                        <div>Client Total Wallet : {data?.cl_tot_wallet} </div>
                                                    </li>
                                                    <li className="collection-item" style={{ paddingLeft: 10 }}>
                                                        <div>Up-line PL : {data?.upline_pl}</div>
                                                    </li>
                                                    <li className="collection-item" style={{ paddingLeft: 10 }}>
                                                        <div>Sub Total : {data?.sub_total}</div>
                                                    </li>
                                                    <li className="collection-item" style={{ paddingLeft: 10 }}>
                                                        <div>Used Free Chip : {data?.used_chip}</div>
                                                    </li>
                                                    <li className="collection-item" style={{ paddingLeft: 10 }}>
                                                        <div>Total : {data?.total}</div>
                                                    </li>
                                                    <li className="collection-item" style={{ paddingLeft: 10 }}>
                                                        <div>Total Clients : {data?.total_clients}</div>
                                                    </li>
                                                </ul>

                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ChipSummery