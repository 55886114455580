import React, { Fragment, Component } from "react";
import { location, withRouter } from "react-router-dom";

import CreditCoinsComponent from "../../components/CreditCoins/CreditCoins";
//import withAuth from "../../../_helpers/withAuth";
import { connect } from "react-redux";

class CreditCoins extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      
        <CreditCoinsComponent {...this.props} />
      
    );
  }
}
const mapstatetop = (state) => ({
  ...state,
});

export default connect(mapstatetop)(CreditCoins);
