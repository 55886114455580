import React from 'react';
import moment from 'moment';

const LiveMatchesList = ({ match, redirectToFixture }) => {
    function getDateAndTime(dateFormat, date) {
        const date_time = moment.utc(date).local().format(dateFormat);
        return date_time;
    }
    return (
        <div
            className="ro"
            style={{ margin: "0 !important" }}
            key={match.event_id}
        >
            <div className="match-index-row header-top-spacing">
                <a onClick={() => redirectToFixture(match)}>
                    <div className="row" style={{ margin: "auto auto 0px" }}>
                        <div className="col-xs-12 top-time-dtl in-play-right-box-color">
                            16&nbsp;&nbsp;June&nbsp;&nbsp;07:30&nbsp;PM
                        </div>
                        <div
                            className="col-md-9 col-lg-9 col-sm-9 col-xs-9 in-play-row-left live-report-in-play-row-left"
                            style={{ padding: "0px 0px 0px 0px" }}
                        >
                            <div
                                className="col-lg-1 col-sm-2 col-xs-1 flags-container"
                                style={{ padding: "18px 0px 18px 0px" }}
                            ></div>
                            <div
                                className="col-lg-11 col-sm-10 col-xs-8 match-detail-container"
                                style={{ padding: `${match.event_text ? '5px' : '15px'} 0px 15px 15px` }}
                            >
                                <div className="match-title">
                                    <p className="team-name-font-color">
                                        {match.event_name}
                                    </p>
                                    {match.event_text &&
                                        <div className="match-info">
                                            <p className="match-summary-text">
                                                {match.event_text}
                                            </p>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className="col-md-3 col-lg-3 col-sm-3 col-xs-3 in-play-row-right in-play-right-box-color head_style">
                                <div className="match-time-dtl d-flex" style={{ justifyContent: 'center' }}>
                                    {/* <p className="match-time-dtl-date live-report-match-time">
                                        {match?.open_date && getDateAndTime("DD", match.open_date)}
                                    </p>
                                    <p className="match-time-dtl-mounth live-report-match-month">
                                        {match?.open_date && getDateAndTime("MMMM", match.open_date)}
                                    </p>
                                    <p className="match-time-dtl-mounth live-report-match-date">
                                        {match?.open_date && getDateAndTime("hh:mm A", match.open_date)}
                                    </p> */}
                                    {match?.open_date && getDateAndTime("DD", match.open_date)}{" "} {match?.open_date && getDateAndTime("MMMM", match.open_date)}{" "}{match?.open_date && getDateAndTime("hh:mm A", match.open_date)}
                                    <p></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </a>
                <div
                    className="row"
                    style={{ paddingBottom: "0px", margin: "auto auto" }}
                ></div>
            </div>
        </div>
    )
}
export default LiveMatchesList