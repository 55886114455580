import React, { Component } from 'react'

import GamesDailyReportComponent from '../../components/ManageLedger/GamesDailyReport';
export class GamesDailyReport extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }
    render() {
        return (
            
                <GamesDailyReportComponent />
            

        )
    }
}

export default GamesDailyReport