import { performRequest } from "_apiConfig/apiHandler";
import { default as apiEndPoints } from "_apiConfig/apiEndPoints";
import config from "../lib/app-config";

export const profileDetails = (data = {}) => {
  return performRequest(
    apiEndPoints.methodType.POST,
    `${config.api_baseurl}users/profile`,
    data
  );
};

export const chipSummery = (data = {}) => {
  return performRequest(
    apiEndPoints.methodType.POST,
    `${config.api_baseurl}users/chip_summery`,
    data
  );
};

export const settlementNew = (data = {}) => {
  return performRequest(
    apiEndPoints.methodType.POST,
    `${config.api_baseurl}users/settlement_new`,
    data
  );
};


export const editUser = (data = {}) => {
  return performRequest(
    apiEndPoints.methodType.POST,
    `${config.api_baseurl}users/edit_user`,
    data
  );
};

//////////////////////////////////////////////////////////////

export const getColorsAPI = (data = {}) => {
  return performRequest(
    apiEndPoints.methodType.POST,
    `${config.api_baseurl}get_colors`,
    data
  );
};

export const resetUserPassword = (data = {}) => {
  return performRequest(
    apiEndPoints.methodType.POST,
    `${config.api_baseurl}users/reset_user_password`,
    data
  );
};
export const resetCommission = (data) => {
  return performRequest(
    apiEndPoints.methodType.POST,
    `${config.api_baseurl}reset_commission`,
    data
  );
};
