import React from 'react'
import { useSelector } from 'react-redux'

const Footer = () => {
    const { domainData } = useSelector(state => state.auth)
    return (
        <div className="footer">
            {/* <div className="pull-right">
        10GB of <strong>250GB</strong> Free.
    </div> */}
            <div>

                <strong>{domainData && domainData.website_name}</strong> | Powered By {domainData && domainData.website_name} | &copy; 2024
            </div>
        </div>
    )
}

export default Footer