import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import {
  DeleteBanners,
  getBannersList
} from "../../_services/banners.services";

import locked from "../../images/locked.png";
import unlocked from "../../images/unlocked.png";

import ReactPaginate from "react-paginate";
import InnerPageHeader from "components/common/InnerPageHeader";
import { useScreenDetector } from "hooks/useScreenDetector";
import TagAndChips from "components/common/TagAndChips";

export default function List() {
  const { isMobile } = useScreenDetector()
  const [bannersList, setBannersList] = useState([]);
  const [filterType, setFilter] = useState('Casino');

  useEffect(() => {
    BannersList();
  }, [filterType]);

  const BannersList = () => {
    let param = {
      "type": filterType
    }
    getBannersList(param)
      .then((response) => {
        if (response?.data?.data) {
          setBannersList(response?.data?.data?.list);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };


  const deleteFunction = (id) => {
    let sendObj = { id };
    let result = window.confirm('Are you sure you want to delete this banner ?')
    if (result) {
      DeleteBanners(sendObj)
        .then((response) => {
          if (response) {
            BannersList()
              .then((response) => {
                if (response?.data?.data) {
                  setBannersList(response?.data?.data);
                }
              })
              .catch((error) => {
                console.log(error);
              });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  return (
    <>
      <InnerPageHeader
        pageTitle="BANNERS LIST"
        breadcrumbs={[{ title: "Dashboard", url: "/" }]}
        currenPageTitle="BANNERS LIST"
      />
      <div className="wrapper wrapper-content animated fadeInRight">
        <div className="row">
          <div className="col-12">
            <div className="ibox">
              <div className="ibox-title">
                <h5>Banners List</h5>
                <div className="ibox-tools">
                  <Link to="/add_banners" className="btn btn-primary btn-xs">
                    Create Banners
                  </Link>
                </div>
              </div>
              <div className="ibox-title">
                <div className={isMobile ? "" : "d-flex"}>
                  <div className="d-flex justify-start gap-2 overflow-x-scroll">
                    <TagAndChips className={filterType == 'Casino' ? "btn btn-primary2" : "btn btn-primary4"} onClick={() => setFilter('Casino')}>
                      Casino
                    </TagAndChips>
                    <TagAndChips className={filterType == 'Exchange' ? "btn btn-primary2" : "btn btn-primary4"} onClick={() => setFilter('Exchange')}>
                      Exchange
                    </TagAndChips>
                  </div>

                </div>
              </div>
              <div className="ibox-content">
                <div className="table-responsive">
                  <table className="table table-bordered table-responsive  table-striped dataTables-example">
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>Type</th>
                        <th>Desktop</th>
                        <th>Mobile</th>
                        <th className="text-center" style={{ width: "14%" }}>
                          Action
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {bannersList?.map((element, ind) => {
                        return (
                          <tr key={ind}>
                            <td>{element.name}</td>
                            <td>{element.type}</td>
                            <td><img src={element.desktop_banner} alt="" width={isMobile ? 100 : 300} height={isMobile ? 50 : 150} /></td>
                            <td><img src={element.mobile_banner} alt="" width={isMobile ? 100 : 300} height={isMobile ? 50 : 150} /></td>
                            <td>
                              {/* <Link
                                className="btn btn-white btn-sm"
                                to={`/edit_banner/${element._id.$oid}`}
                                state={{ edit: element }}
                              >
                                <i className="fa fa-pencil-square"></i> Edit
                              </Link> */}
                              &nbsp;&nbsp;&nbsp;
                              <a
                                className="btn btn-white btn-sm"
                                onClick={(e) =>
                                  deleteFunction(element._id.$oid)
                                }
                              >
                                <i className="fa fa-trash"></i> Delete
                              </a>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>

                  <div className="paginate_class">
                    <ReactPaginate
                      previousLabel={"prev"}
                      nextLabel={"next"}
                      breakLabel={"..."}
                      breakClassName={"break-me"}
                      // pageCount={pageCount}
                      marginPagesDisplayed={2}
                      pageRangeDisplayed={5}
                      // onPageChange={handlePageClick}
                      containerClassName={"pagination"}
                      subContainerClassName={"pages pagination"}
                      activeClassName={"active"}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
