import axios from 'axios'
import React from 'react'
import { useState } from 'react'
import { useEffect } from 'react'
import { casinoGamelist, casinoLiveReport, showHideCasino } from "../../_services/casino.services"
import ReactPaginate from 'react-paginate';
import { Link } from 'react-router-dom'
import moment from "moment";
import { formatDateAndTime } from "../../utils/index"
import InnerPageHeader from 'components/common/InnerPageHeader'
import { toast } from 'react-toastify'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import { userProfileRequest } from '_actions/userprofile/userprofile.actions'
import ClipLoader from "react-spinners/ClipLoader";

export default function GameComp() {
    const role = localStorage.getItem("role") || null;
    const userProfile = useSelector(state => state.userProfile)
    const dispatch = useDispatch()

    const [offset, setOffset] = useState(1);
    const [allCasino, setAllCasino] = useState([]);
    const [perPage] = useState(20);
    const [pageCount, setPageCount] = useState(0)
    const [isLoading, setIsLoading] = useState(false)
    const [isHideCasino, setToggleHideCasino] = useState(false);
    const [isLoadingCasino, setLoadingCasino] = useState(false);

    useEffect(() => {
        getUserProfile()
    }, [])

    useEffect(() => {
        fatchCasinoList();
    }, [offset])

    useEffect(() => {
        console.log(userProfile?.data?.hide_casino)
        setToggleHideCasino(!!+userProfile?.data?.hide_casino)
    }, [userProfile])

    const fatchCasinoList = () => {
        let sendObj = {}
        sendObj.sport_id = 6;
        sendObj.current_page = offset;
        sendObj.items_perpage = perPage;
        sendObj.to_date = "2022-09-20 01:56:55";
        sendObj.from_date = "2022-06-01 18:30:00";

        casinoGamelist(sendObj)
            .then((response) => {

                if (response && response?.data?.data?.records) {

                    setAllCasino(response.data.data.records)

                    setPageCount(Math.ceil(response.data.data.total_records / perPage))

                }

            }).catch((error) => {
                console.log(error)
            })
    }

    const handlePageClick = (e) => {
        const selectedPage = e.selected;
        setOffset(selectedPage + 1)
    };

    const handleShowHideCasino = async (e) => {
        const postData = {
            hide_casino: +e.target.checked
        }
        setIsLoading(true)
        setToggleHideCasino(e.target.checked)
        let res = await showHideCasino(postData)
        try {
            toast(res?.message);
            setIsLoading(false)

        } catch (error) {
            toast.error("Request failed");
            setIsLoading(false)
            setToggleHideCasino((e) => !e)
        }
    }

    const getUserProfile = () => {
        dispatch(userProfileRequest({ role }))
    }

    const getCasinoLiveReport = () => {
        setLoadingCasino(true)
        casinoLiveReport().then(res => {
            if (res?.data?.data) {
                window.open(res?.data?.data?.casino_url)
                setLoadingCasino(false)
            } else {
                setLoadingCasino(false)
            }
        })
    }

    return (
        <div>
            <InnerPageHeader
                pageTitle="Casino Game List"
                breadcrumbs={[
                    { title: "Dashboard", url: "/" },
                ]}
                currenPageTitle="Game List"
            />
            <div className="">
                <div className="px-2 mx-0 mt-3 row">
                    <div className="col-lg-12">
                        <div className="ibox">
                            <div className="ibox-title">
                                <div className='d-flex justify-between'>
                                    <div >
                                        {role == 1 &&
                                            <button
                                                type="button"
                                                className="btn btn-primary btn-sm"
                                                onClick={() => getCasinoLiveReport()}
                                                disabled={isLoadingCasino}
                                            >
                                                {isLoadingCasino ? <ClipLoader color={"#FFFFFF"} loading={isLoadingCasino} size={16} /> : ""} Live Report
                                            </button>
                                        }
                                    </div>
                                    <div className='d-flex flex-center'>
                                        <span className='pr-1'>
                                            <strong>on/off casino for all my clients</strong>
                                        </span>
                                        <label className="switch">
                                            <input
                                                disabled={isLoading}
                                                type="checkbox"
                                                checked={isHideCasino}
                                                onChange={(e) => handleShowHideCasino(e)
                                                }
                                            />
                                            <span className="slider round">
                                            </span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div className="ibox-content">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="table-responsive">
                                            <table className="table table-striped table-bordered table-hover dataTables-example">
                                                <thead>
                                                    <tr>
                                                        <th>S.No</th>
                                                        <th>PID</th>
                                                        <th>Title</th>
                                                        <th>Sport</th>
                                                        <th>Date</th>
                                                        <th>Type</th>
                                                        <th>Declared</th>
                                                        <th>Won By</th>
                                                        <th>Profit/Loss</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        allCasino?.map((element, index) => {
                                                            return (
                                                                <tr key={index}>
                                                                    <td> {(offset - 1) * perPage + index + 1} </td>
                                                                    <td> {element.casino_round_id} </td>

                                                                    <td className="anchor">
                                                                        <Link
                                                                            // to={{
                                                                            //     pathname: `/games_list/${element.event_id}`,
                                                                            //     state: { matchData: element },

                                                                            // }}
                                                                            to={`/games_list/${element.event_id}`}
                                                                            state={{ matchData: element }}
                                                                        >
                                                                            <strong>{element.event_name} - {element.open_date}</strong>
                                                                        </Link>
                                                                    </td>

                                                                    <td> {element.game_code} </td>
                                                                    <td>
                                                                        {element.open_date ?
                                                                            formatDateAndTime('MMM DD, hh:mm:ss A', element.open_date)
                                                                            :
                                                                            "-"}
                                                                    </td>
                                                                    <td> - </td>
                                                                    <td> {element.settlement_status === 1 ? "YES" : "NO"}</td>
                                                                    <td> {element.won_by ? element.won_by : "-"}</td>

                                                                    {
                                                                        element.Pl === 0 ?
                                                                            (
                                                                                <td>{element.Pl}</td>
                                                                            )
                                                                            :
                                                                            (null)
                                                                    }

                                                                    {
                                                                        element.Pl > 0 ?
                                                                            (
                                                                                <td style={{ color: element.Pl > 0 ? "#23c6c8" : "red", fontWeight: 'bolder' }}>
                                                                                    {element.Pl} &nbsp;
                                                                                    <i className="fa fa-level-up"></i>
                                                                                </td>
                                                                            )
                                                                            :
                                                                            (null)
                                                                    }

                                                                    {
                                                                        element.Pl < 0 ?
                                                                            (
                                                                                <td style={{ color: element.Pl < 0 ? "red" : "green", fontWeight: 'bolder' }}>
                                                                                    {element.Pl} &nbsp;
                                                                                    <i className="fa fa-level-down"></i>
                                                                                </td>
                                                                            )
                                                                            :
                                                                            (null)
                                                                    }

                                                                </tr>
                                                            )
                                                        })
                                                    }
                                                    <tr>
                                                        <th colSpan={8}>Total</th>
                                                        <th>
                                                            {Math.round(
                                                                allCasino?.reduce(
                                                                    (a, v) => (a = a + v.Pl),
                                                                    0
                                                                ) * 100
                                                            ) / 100}
                                                        </th>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>

                                <div className="paginate_class">
                                    <ReactPaginate
                                        previousLabel={"prev"}
                                        nextLabel={"next"}
                                        breakLabel={"..."}
                                        breakClassName={"break-me"}
                                        pageCount={pageCount}
                                        marginPagesDisplayed={2}
                                        pageRangeDisplayed={5}
                                        onPageChange={handlePageClick}
                                        containerClassName={"pagination"}
                                        subContainerClassName={"pages pagination"}
                                        activeClassName={"active"}
                                    />
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
