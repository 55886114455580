import { usersPayCash, usersReceiveCash } from '_services/userDetails.services';
import { useScreenDetector } from 'hooks/useScreenDetector';
import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { toast } from 'react-toastify'; // Ensure toast is imported

const SettlementModal = ({ isOpen, onClose, userData, type, fetchData }) => {
    const { isMobile } = useScreenDetector()
    const [amount, setAmount] = useState(0);
    const [remark, setRemark] = useState('');
    const [posting, setPosting] = useState(false); // New state for managing button state

    const [isChecked, setIsChecked] = useState(false);

    const handleCheckboxChange = (event) => {
        setIsChecked(event.target.checked);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setPosting(true); // Set posting to true when submitting

        if (amount > 0) {
            const sendObj = {
                user_guid: userData?.user_guid,
                amount,
                remark,
                type,
                deposit_chips: isChecked ? 1 : 0
            };

            try {
                const res = type == 2
                    ? await usersReceiveCash(sendObj)
                    : await usersPayCash(sendObj);

                toast.success(
                    <div>
                        <i className="fa fa-check" style={{ fontWeight: "bold", marginRight: "1rem" }} aria-hidden="true"></i>
                        <span>Balance Successfully updated</span>
                    </div>,
                    {
                        position: "top-center",
                        autoClose: 3000,
                        hideProgressBar: true,
                        closeOnClick: false,
                        pauseOnHover: true,
                        draggable: true,
                    }
                );

                if (res?.data?.data?.ledger_balance) {
                    // Assuming you have a state setter for new ledger balance
                    // setNewLedgerBal(res.data.data.ledger_balance);
                    setAmount(""); // Resetting the amount
                    setRemark(""); // Resetting the remark
                }
            } catch (error) {
                toast.error(error.response?.data?.error || "An error occurred.");
            } finally {
                setPosting(false); // Reset posting state
                fetchData()
            }
        } else {
            toast.error("Please enter an amount greater than 0.");
            setTimeout(() => setPosting(false), 1500); // Reset button state after timeout
        }
    };

    return (
        <Modal show={isOpen} onHide={onClose} className="settlement-modal" style={{ opacity: 1 }}
            backdrop={true}>
            <Modal.Header closeButton>
                <Modal.Title>
                    {/* <div className='settlement-title'> */}
                    Settlement for {isMobile && <br />} {userData?.username}
                    {/* </div> */}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {/* <form className="settlement-form" onSubmit={handleSubmit}>
                    <div style={{ display: "none" }}>
                        <input name="utf8" type="hidden" value="✓" />
                    </div>
                    <div className="form-group settlement-form-group d-flex justify-content-between">
                        <div className="settlement-form-item">
                            <label className="settlement-form-label">Agent Name: <span>{userData?.username}</span></label>

                        </div>
                        <div className="settlement-form-item">
                            <label className="settlement-form-label">Ledger Balance: <span>{userData?.ledger_balance}</span></label>

                        </div>
                    </div>
                    <div className="form-group settlement-form-group">
                        <label className="settlement-form-label">Update Coins</label>
                        <input
                            type="number"
                            name="amount"
                            className="form-control settlement-input-coin"
                            value={amount}
                            onChange={(e) => setAmount(e.target.value)}
                            min={1}
                        />
                    </div>
                    <div className="form-group settlement-form-group">
                        <label className="settlement-form-label">Note</label>
                        <textarea
                            name="remark"
                            className="form-control settlement-input-note"
                            value={remark}
                            onChange={(e) => setRemark(e.target.value)}
                        ></textarea>
                    </div>
                    <div className="form-group settlement-form-group">
                        <div className="settlement-form-actions">
                            <Button variant="primary" type="submit" disabled={posting}>
                                {posting ? 'Saving...' : 'Save Changes'}
                            </Button>
                            <Button style={{ marginLeft: '20px' }} variant="secondary" onClick={onClose}>
                                Close
                            </Button>
                        </div>
                    </div>
                </form> */}

                <form className="settlement-form" onSubmit={handleSubmit}>
                    <div style={{ display: "none" }}>
                        <input name="utf8" type="hidden" value="✓" />
                    </div>
                    <div className="form-group settlement-form-group d-flex justify-content-between">
                        <p style={{ color: '#504f4f' }}>Total Outstanding Amount : {userData?.ledger_balance}</p>
                    </div>
                    <div className="form-group settlement-form-group">
                        <label className="settlement-form-label">Amount</label>
                        <input
                            type="number"
                            name="amount"
                            className="form-control settlement-input-coin"
                            value={amount}
                            onChange={(e) => setAmount(e.target.value)}
                        // min={1}
                        />
                    </div>
                    <div className="form-group settlement-form-group">
                        <label className="settlement-form-label">Note</label>
                        <input
                            name="remark"
                            className="form-control settlement-input-coin"
                            value={remark}
                            onChange={(e) => setRemark(e.target.value)}
                        ></input>
                    </div>

                    {(type == 2 && userData.role == 9) && (
                        <>
                            <div className='d-flex justify-content-center ' style={{ alignItems: 'center' }}>
                                <label>
                                    <input
                                        style={{ transform: "scale(1.5)" }}
                                        type="checkbox"
                                        checked={isChecked}
                                        onChange={handleCheckboxChange}
                                    />
                                </label>
                                <p style={{ fontSize: '12px', marginLeft: '10px', color: 'red' }}>Do you want to Deposit Chips (With Chips) ?</p>
                            </div>
                            <div className="form-group settlement-form-group d-flex justify-content-between">
                                <p style={{ color: '#504f4f' }}>Available Chips : {userData?.parent_balance || 0}</p>
                            </div>
                        </>
                    )}



                    <div className="form-group settlement-form-group">
                        <div className="settlement-form-actions">
                            <Button variant="primary" type="submit" disabled={posting}>
                                {posting ? 'Saving...' : 'Save'}
                            </Button>
                            <Button style={{ marginTop: '10px' }} variant="secondary" onClick={onClose}>
                                Close
                            </Button>
                        </div>
                    </div>
                </form>
            </Modal.Body>
            {/* <Modal.Footer>
                
            </Modal.Footer> */}
        </Modal>
    );
};

export default SettlementModal;
