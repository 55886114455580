import React from "react";
import MasterComp from "../../components/master/List";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";

function List(props) {
  const role = 3;
  const navigationUrl = "/sub-admin";
  const title = "Sub Admin";

  const navigate = useNavigate();
  return (
    <MasterComp
      {...props}
      navigate={navigate}
      title={title}
      role={role}
      navigationUrl={navigationUrl}
    />
  );
}

const mapstatetop = (state) => ({
  ...state,
});

export const SubAdminList = connect(mapstatetop)(List);
