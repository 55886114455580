import React, { useState } from "react";
import "./ScoreWidget.css";

export const ViewScoreWidget = (props) => {
  const { updateData } = props;
  const [open, setOpen] = useState(false);

  return (
    <div className="container-fluid" style={{ width: '100%' }}>
      <div className="row content-top-padding">
        <div className="content_matches_wrapper mt-zero" id="content_matches_wrapper">
          <div className="bet_status_box match-score content_in_play_wrapper match-status-table match-score-box-color anvWidget score_widget">
            <div
              className="inplay-show-tbl-td match-status-text match-score-box-color "
              id="score_table_383067"
            >
              {updateData?.teams?.length && updateData
                ? updateData?.teams?.map((i) => (
                  <span
                    key={i.team_name}
                    className="match-score-span-1 font-sans-serif"
                  >
                    {i.team_name} - {i?.score}
                  </span>
                ))
                : ""}
              <span className="match-score-span-2 font-sans-serif">
                {updateData?.msg}{" "}
                {updateData?.requireRunRate && (
                  <span>{`RRR: ${updateData?.requireRunRate}`}</span>
                )}
              </span>
              <span className="match-score-span-2 font-sans-serif">
                <div className="scoreBall_list anvFlex">
                  <ul>
                    <li className="LastBall">Last 6 Ball</li>
                    {updateData ? (
                      updateData?.last24balls?.reverse().map((i) => (
                        <li>
                          <span>{i?.score_card ? i?.score_card : 0}</span>
                        </li>
                      ))
                    ) : (
                      <>
                        <li>
                          <span>0</span>
                        </li>
                        <li>
                          <span>0</span>
                        </li>
                        <li>
                          <span>0</span>
                        </li>
                        <li>
                          <span>0</span>
                        </li>
                        <li>
                          <span>0</span>
                        </li>
                        <li>
                          <span>0</span>
                        </li>
                      </>
                    )}
                  </ul>
                </div>
              </span>
              <span className="match-score-span-2 font-sans-serif anvBottom_arrow">
                {open ? (
                  <div
                    className="refresh_img"
                    onClick={() => {
                      setOpen(!open);
                    }}
                  >
                    Close
                  </div>
                ) : (
                  <div
                    className="refresh_img"
                    onClick={() => {
                      setOpen(!open);
                    }}
                  >
                    More
                  </div>
                )}
              </span>
              {open && (
                <span className="match-score-span-2 font-sans-serif anvTab_score">
                  {/* <div className='row'> */}
                  <div
                    show={open}
                    onHide={() => setOpen(false)}
                    className="table_show"
                  >
                    <table className="score2_table">
                      <thead>
                        <tr className="tableHeadof_score">
                          <th>Batsman</th>
                          <th>R</th>
                          <th>B</th>
                          <th>4s</th>
                          <th>6s</th>
                          <th>SR</th>
                        </tr>
                      </thead>
                      {updateData ? (
                        updateData?.currentPlayersScore?.Batsman?.map((i) => (
                          <tbody style={{ backgroundColor: "#00669d" }}>
                            <tr>
                              <td>{i?.on_play ? i?.on_play : ""}</td>
                              <td>{i?.runs ? i?.runs : ""}</td>
                              <td>{i?.balls ? i?.balls : ""}</td>
                              <td>{i?.fours ? i?.fours : ""}</td>
                              <td>{i?.sixes ? i?.sixes : ""}</td>
                              <td>
                                {i?.strike_rate === "NaN" ? "" : i?.strike_rate}
                              </td>
                            </tr>
                          </tbody>
                        ))
                      ) : (
                        <>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                        </>
                      )}
                    </table>
                    {updateData?.currentPlayersScore?.bowler?.player_name && (
                      <div className="bowler_name">
                        {` Bowler -
                                        ${updateData?.currentPlayersScore?.bowler?.player_name} `}
                      </div>
                    )}
                  </div>
                </span>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
