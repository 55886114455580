// export const userRoleKeys = {
//   sub_company: 2,
//   admin: 3,
//   master: 4,
//   super_stockist: 5,
//   stockist: 6,
//   agent: 7,
//   client: 8,
// };

export const userRoleKeys = {
  company: 1,
  sub_company: 2,
  sub_admin: 3,
  admin: 4,
  super_senior: 5,
  super: 6,
  dealer: 7,
  agent: 8,
  client: 9,
  staff: 10,
};

export const userRoleDetails = {
  1: { type: "company", rootUrl: "/", title: "Company", abbreviation: "C" },
  2: { type: "sub_company", rootUrl: "/sub_company", title: "Sub company", abbreviation: "SC" },
  3: { type: "sub_admin", rootUrl: "/sub_admin", title: "Sub Admin", abbreviation: "SA" },
  4: { type: "admin", rootUrl: "/admin", title: "Admin", abbreviation: "AD" },
  5: { type: "super_senior", rootUrl: "/super_senior", title: "Super Senior", abbreviation: "SS" },
  6: { type: "super", rootUrl: "/super", title: "Super", abbreviation: "SU" },
  7: { type: "dealer", rootUrl: "/dealer", title: "Dealer", abbreviation: "DL" },
  8: { type: "agent", rootUrl: "/agent", title: "Agent", abbreviation: "AG" },
  9: { type: "client", rootUrl: "/my-clients", title: "Client", abbreviation: "CL" },
  10: { type: "staff", rootUrl: "/staff", title: "Staff", abbreviation: "S" },
};