import React from "react";
import { useState } from "react";
import { useEffect } from "react";

import moment from "moment";
import InnerPageHeader from "components/common/InnerPageHeader";
import { Link, useParams } from "react-router-dom";
import { profitLossMarket } from "_services";

const ProfitLossDetail = () => {
    const { event_id, event_name } = useParams();

    const [profitLossData, setData] = useState([]);

    useEffect(() => {
        getProfitLossReport()
    }, [event_id])


    const getProfitLossReport = () => {
        let sendObj = {
            event_id,
        };

        profitLossMarket(sendObj)
            .then((response) => {
                if (response && response?.data?.data) {
                    setData(response?.data?.data);

                }
            })
            .catch((error) => {
                console.log(error, "error");
            });
    };

    let currentUser = JSON.parse(localStorage.getItem("userLogin"))

    return (
        <>

            <div className="wrapper wrapper-content animated fadeInRight">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="ibox float-e-margins">
                            <div className="ibox-title">
                                <h5 style={{ textTransform: 'uppercase' }}> Pl of {event_name} </h5>
                            </div>
                            <div className="ibox-content">

                                <br />


                                <div className="table-responsive">
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th style={{ textAlign: 'left' }}>No</th>
                                                <th style={{ textAlign: 'left' }}>Market</th>
                                                <th>Comm In</th>
                                                <th>Comm Out</th>
                                                <th> Amount </th>
                                                <th> Total </th>
                                                <th> Info </th>

                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <th style={{ textAlign: 'left' }}>#</th>
                                                <th style={{ textAlign: 'left' }}>Total</th>
                                                <th></th>
                                                <th></th>
                                                <th>  </th>
                                                <th style={{ color: profitLossData?.reduce((a, v) => a + v.final_total, 0) >= 0 ? 'green' : 'red' }}>
                                                    {Math.round(
                                                        profitLossData?.reduce(
                                                            (a, v) => a + v.final_total,
                                                            0
                                                        ) * 100
                                                    ) / 100}
                                                </th>
                                                <th>  </th>
                                            </tr>
                                            {profitLossData &&
                                                profitLossData.map((element, index) => {
                                                    return (
                                                        <tr key={index}>
                                                            <td> {index + 1} </td>
                                                            <td style={{ whiteSpace: 'initial' }}>{element.market_name}</td>
                                                            <td> {element.comm_in} </td>
                                                            <td>0</td>
                                                            <td>{Number(element.pl_tot).toFixed(2)} </td>
                                                            <td style={{ color: element.final_total > 0 ? 'green' : 'red' }}> {Number(element.final_total).toFixed(2)} </td>
                                                            <td className="center" style={{ overflow: 'visible' }} >
                                                                <div className="user-options" style={{ width: 'initial' }}>
                                                                    <React.Fragment>
                                                                        {/* <Link to={`/profit-loss-summery-market/${element.market_id}/${event_id}`}>
                                                                            <div className="opt-items" data-tooltip="Profit Loss Summery">
                                                                                <span>S</span>
                                                                            </div>
                                                                        </Link> */}
                                                                        <Link to={`/bet_history_by_market/${currentUser?.user_id}/${currentUser?.role}/1/${element.market_id}`}>
                                                                            <div className="opt-items" data-tooltip="Bet History">
                                                                                <span>B</span>
                                                                            </div>
                                                                        </Link>
                                                                    </React.Fragment>
                                                                </div>
                                                            </td>

                                                        </tr>
                                                    );
                                                })}
                                        </tbody>
                                    </table>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ProfitLossDetail