import React, { Component } from "react";
import InnerPageHeader from "../common/InnerPageHeader";
import { editCloseTimeManualMarket, getLeagueMarketsList } from "_services";
import {
  editMarket,
  deleteFancy,
  fancyType,
} from "../../_services/tournament.services";
import { toast } from "react-toastify";
import Modal from "react-modal";
import { firstLetterCapital } from "utils";
import ConfrimModal from "components/ConfirmModal";
import { detectMobView } from "components/layouts/Helpers";
import { Button } from "react-bootstrap";
import Loader from "components/common/Loader";
import { RotatingLines } from "react-loader-spinner";
import axios from "axios";
import config from "../../lib/app-config";

import DatePicker from "react-datepicker";
import moment from 'moment'

const customStyles = {
  content: {
    top: "25%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    overflowY: "hidden",
    overflowX: "hidden",
    maxHeight: "80vh",
    minWidth: "400px",
    padding: 40,
    border: 0,
    background: "transparent",
  },
};

const additionalStyles = {
  overflow: 'initial !important'
};

const combinedStyles = {
  ...customStyles,
  ...additionalStyles
};

class FixtureMarketComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      marketList: [],
      leagueEventDetails: "",
      isAdd: true,
      valid: false,
      inputSelect: [],
      selectValue: "",
      toggleSelectError: false,
      showCloseTimePopup: false,
      isLoading: false,
      reFetch: false,
      formObj: {
        market_type: "",
        market_id: ""
      },
    };
  };

  componentDidMount() {
    this.selectFunction();
    this.checkURLAndHandle();
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.checkURLAndHandle();
    }
  }

  checkURLAndHandle() {
    if (
      this.props.location &&
      this.props.location.state &&
      this.props.location.state.detail &&
      this.props.location.state.detail.event_name
    ) {
      this.handleLeagueMarketList();
      this.setState({
        keyword: "",
        isMarketLoading: true,
        leagueEventDetails: this.props.location.state.detail,
      });
    } else {
      this.props.history.push("/");
    }
  }

  selectFunction = () => {
    fancyType()
      .then((response) => {
        if (response?.data?.data) {
          this.setState({ inputSelect: response?.data?.data });
        }
      })
      .catch((error) => {
        console.log(error, "error");
      });
  };

  handleRefreshEvent = (type) => {
    const { params } = this.props;
    let url = type ? "infotechfeed/fetch_manual_fancy/" : "infotechfeed/fetch_fancy/"

    axios
      // .get(config.baseurl + "infotechfeed/fetch_fancy/" + params?.event_id)
      .get(config.baseurl + url + params?.event_id)
      .then((response) => {
        if (response) {
          this.handleLeagueMarketList();
        }
        setTimeout(() => {
          window.scrollTo({
            top: document,
            behavior: "smooth",
          });
        }, 1000);
      })
      .catch((error) => {
        toast("Refresh again after that Event Updated successfully");
      });
  };

  handleLeagueMarketList = () => {
    const { params } = this.props;

    let sendData = {};
    sendData.event_id = params.event_id;
    sendData.type = params.type;

    getLeagueMarketsList(sendData)
      .then((response) => {
        if (response && response.data && response.data.data) {
          this.setState({ isLoading: false });

          if (this.state.reFetch) {
            toast.success(
              <div>
                <i
                  className="fa fa-check"
                  style={{ fontWeight: "bold", marginRight: "1rem" }}
                  aria-hidden="true"
                ></i>
                <span>Refresh successfully</span>
              </div>,
              {
                position: "top-center",
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
              }
            );
          }

          this.setState({ reFetch: false });
          let skyData = [],
            diamondData = [],
            manualData = [];
          response.data.data.map((item) => {
            switch (true) {
              case +item.sky_fancy === 1:
                skyData.push(item);

                break;
              case +item.diamond_fancy === 1:
                diamondData.push(item);

                break;
              case !item?.diamond_fancy && !item?.sky_fancy:
                manualData.push(item);

                break;
            }
          });
          const createMarketList = [];
          if (skyData.length > 0) {
            createMarketList.push({ title: "Sky", data: skyData });
          }
          if (diamondData.length > 0) {
            createMarketList.push({ title: "Diamond", data: diamondData });
          }
          if (manualData.length > 0) {
            createMarketList.push({ title: "Manual", data: manualData });
          }

          this.setState({ marketList: createMarketList });
        } else {
          this.setState({ marketList: [] });
        }
      })
      .catch((error) => {
        this.setState({ marketList: [] });
      });
  };

  inputhandleChange = (event) => {
    this.setState({
      selectValue: event.target.value,
      toggleSelectError: event.target.value ? false : true,
    });
  };

  handleMarket = (market = this.state.isShowModal) => {
    this.setState({ isAdd: false });
    const { params } = this.props;
    this.state.inputSelect.map((el) => {
      if (el.session_type === this.state.selectValue) {
        let sendData = {};
        sendData.market_id = market.session_id
          ? market.session_id
          : market.market_id;
        sendData.event_id = params.event_id;
        sendData.session_type = el.id;
        sendData.type = params.type;
        sendData.status = market?.active == 1 ? 0 : 1;
        // this.setState((preState) => {
        //   let newArr = [...preState.marketList];
        //   newArr.splice(rootInd,1,{...newArr[rootInd], data:})
        //   newArr[index].active = sendData.status;
        //   return newArr;
        // }, () => {
        editMarket(sendData)
          .then(() => {
            this.handleLeagueMarketList();
            // this.onCancleModal()
            this.setState({ toggleSelectError: false, isShowModal: false });
          })
          .catch(() => this.onCancleModal());

        // });
      }
    });

    if (market.market_type > "0") {
      let sendData = {};
      sendData.market_id = market.market_id;
      sendData.event_id = params.event_id;
      sendData.type = params.type;
      sendData.session_type = market.market_type;
      sendData.status = market.active === 1 ? 0 : 1;

      // this.setState((preState) => {

      //   let newArr = [...preState.marketList];
      //   newArr[index].active = sendData.status;
      //   return newArr;
      // }, () => {
      editMarket(sendData).then(() => this.handleLeagueMarketList());

      // });
    }
  };

  newHandleMarket = (market, type) => {
    this.setState({ isAdd: false });
    const { params } = this.props;
    const activeMarketList = this.state.marketList.filter(
      (item) => item?.title === type
    );
    const index = activeMarketList[0].data.findIndex(
      (item) => item?.session_id === market?.session_id
    );

    let sendData = {};
    sendData.market_id = market.session_id;
    sendData.event_id = params.event_id;
    sendData.session_type = market.market_type;
    sendData.type = params.type;
    sendData.status = activeMarketList[0]?.data[index]?.active === 1 ? 0 : 1;

    // this.setState((preState) => {

    //   let newArr = [...preState.marketList];
    //   newArr[index].active = sendData.status;
    //   return newArr;
    // }, () => {
    editMarket(sendData).then(() => this.handleLeagueMarketList());

    // });
  };

  handleDelete = (sessionId) => {
    this.setState({ valid: true });

    const { params } = this.props;

    let sendObj = {};
    sendObj.event_id = params.event_id;
    sendObj.session_id = sessionId;

    deleteFancy(sendObj)
      .then((response) => {
        this.setState({ valid: false });

        if (response) {
          setTimeout(() => {
            window.location.reload();
          }, 1500);
        }
      })
      .catch((error) => {
        toast.error(error.response.data.error);

        setTimeout(() => {
          this.setState({ valid: false });
        }, 3000);
      });
  };

  onCancleModal = () => {
    this.setState({
      selectValue: "",
      toggleSelectError: false,
      isShowModal: false,
    });
  };

  onSubmit = async (e) => {
    e.preventDefault();
    let { formObj } = this.state;

    const { params } = this.props
    const payload = formObj
    payload["event_id"] = params.event_id
    if (formObj?.market_close_time) {
      payload["market_close_time"] = moment(new Date(formObj?.market_close_time)).format("YYYY-MM-DD hh:mm A")
    }

    editCloseTimeManualMarket(payload)
      .then((response) => {
        this.setState({ valid: false });
        if (response) {
          this.setState((state) => ({
            showCloseTimePopup: false,
            formObj: {
              ...state.formObj,
              market_id: "",
              event_id: "",
              market_close_time: new Date()
            },
          }))
          if (response.data.message) {
            toast.success(response.data.message);
          }
          this.selectFunction();
          this.checkURLAndHandle();

        }
      })
      .catch((error) => {
        toast.error(error.response.data.error);

        setTimeout(() => {
          this.setState({ valid: false });
        }, 3000);
      });


  };

  closeTime = (market) => {
    const marketCloseTimeUTC = new Date(market.market_close_time);

    // Convert the market close time to IST
    const marketCloseTimeIST = new Date(
      marketCloseTimeUTC.getTime() + (5.5 * 60 * 60 * 1000) // Adding 5 hours and 30 minutes
    );

    this.setState((state) => ({
      cMarketData: market,
      formObj: {
        ...state.formObj,
        "market_type": market.market_type,
        "market_id": market.market_id,
        // "market_close_time": new Date(market.market_close_time)
        "market_close_time": marketCloseTimeIST
      },
      showCloseTimePopup: true,
    }))
  }

  render() {
    const {
      marketList,
      leagueEventDetails,
      valid,
      inputSelect,
      selectValue,
      toggleSelectError,
      isShowModal,
    } = this.state;

    const { params } = this.props

    return (
      <>
        <InnerPageHeader
          pageTitle={leagueEventDetails.event_name}
          breadcrumbs={[
            { title: "Dashboard", url: "/" },
            { title: "Tournaments", url: "/tournament" },
          ]}
          currenPageTitle={`${leagueEventDetails.league_name} / ${leagueEventDetails.event_name}`}
        />
        <div className="wrapper wrapper-content animated fadeInRight">
          <div className="row">
            <div className="col-lg-12">
              <div className="ibox float-e-margins">
                <div
                  className="ibox-title"
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <h5 style={{ fontWeight: 700 }}>
                      {params.type === "main"
                        ? "Exchange Market"
                        : "Fancy Market"}
                    </h5>
                    <div className="h4">{leagueEventDetails.event_name}</div>
                  </div>
                  <div
                    className="ibox-tools "
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Button
                      className="anvInfoBtn m-2 mb-2 "
                      style={{ marginRight: "15px" }}
                      onClick={() => {
                        this.setState({ marketList: [] });
                        this.props.navigate(
                          `/fixture-market/${params.event_id}/${params.type === "main" ? "fancy" : "main"
                          }`,
                          {
                            state: this.props.location.state,
                          }
                        );
                      }}
                    >
                      {params.type === "main" ? "Fancy Session" : "Market"}
                    </Button>
                    {params.type === "fancy" && (
                      <Button
                        size="sm"
                        style={{
                          display: "flex",
                          gap: 5,
                          alignItems: "center",
                          marginRight: "15px"
                        }}
                        disabled={this.state.isLoading}
                        className="crt_admin mt-2 mb-2 "
                        color="primary"
                        onClick={() => {
                          this.setState({ isLoading: true, reFetch: true });
                          this.handleRefreshEvent();
                        }}
                      >
                        {" "}
                        Fetch Fancy{" "}
                        {this.state.isLoading && (
                          <RotatingLines
                            strokeColor="white"
                            strokeWidth="5"
                            animationDuration="0.75"
                            width="20"
                            visible={true}
                          />
                        )}
                      </Button>
                    )}
                    {params.type === "fancy" && (
                      <Button
                        size="sm"
                        style={{
                          display: "flex",
                          gap: 5,
                          alignItems: "center",
                        }}
                        disabled={this.state.isLoading}
                        className="crt_admin ml-2 mt-2 mb-2 "
                        color="primary"
                        onClick={() => {
                          this.setState({ isLoading: true, reFetch: true });
                          this.handleRefreshEvent(true);
                        }}
                      >
                        {" "}
                        Fetch Manual Fancy{" "}
                        {this.state.isLoading && (
                          <RotatingLines
                            strokeColor="white"
                            strokeWidth="5"
                            animationDuration="0.75"
                            width="20"
                            visible={true}
                          />
                        )}
                      </Button>
                    )}
                  </div>
                </div>
                <div className="ibox-content">
                  <div className="table-responsive">
                    <div
                      id="DataTables_Table_0_wrapper"
                      className={
                        "dataTables_wrapper form-inline dt-bootstrap " +
                        (detectMobView() ? " " : " multiple_table_container")
                      }
                    >
                      {marketList?.map((list, ind) => (
                        <div
                          style={{
                            width: "100%",
                            marginLeft: ind === 0 ? "10px" : "",
                          }}
                          key={ind}
                        >
                          {params.type !== "main" && <h3> {list?.title}</h3>}
                          <table className="table table-striped table-bordered table-hover dataTables-example">
                            <thead>
                              <tr>
                                <th>S.NO</th>
                                <th>RUNNER NAME</th>
                                <th>Feed</th>
                                <th> ACTION </th>
                              </tr>
                            </thead>
                            <tbody>
                              {list?.data && list?.data.length ? (
                                list?.data.map((market, index) => (
                                  <tr className="gradeX" key={index}>
                                    <td>{index + 1}</td>
                                    <td>
                                      {params.type === "main"
                                        ? `${market.market_name} ${market.manual_market == 1 ? " (Manual)" : ""}`
                                        : market.runner_name}
                                    </td>
                                    <td>
                                      {market?.diamond_fancy == 1
                                        ? "Diamond"
                                        : market?.sky_fancy == 1
                                          ? "Sky"
                                          : "-"}
                                    </td>

                                    {market.session_id ? (
                                      <td className="d-flex">
                                        {market.active != "1" ? (
                                          <div>
                                            <button
                                              type="button"
                                              className="bt btn-w- btn-primary newbtn"
                                              style={{ background: "#228C22" }}
                                              onClick={() =>
                                                this.setState({
                                                  isShowModal: market,
                                                })
                                              }
                                            >
                                              ADD
                                            </button>
                                          </div>
                                        ) : (
                                          <div>
                                            <button
                                              type="button"
                                              className="bt btn-w- btn-primary newbtn"
                                              onClick={this.newHandleMarket.bind(
                                                this,
                                                market,
                                                list.title
                                              )}
                                              style={{ background: "#FF0000" }}
                                            >
                                              ADDED
                                            </button>
                                          </div>
                                        )}
                                        &nbsp; &nbsp;
                                        {market.session_id ? (
                                          <div>
                                            <button
                                              type="button"
                                              className="bt btn-w- btn-primary newbtn"
                                              style={{ color: '#000' }}
                                              // data-toggle="modal" data-target="#exampleModal"
                                              onClick={() =>
                                                this.setState({
                                                  cMarketData: market,
                                                  showDeleteConfirmPopup: true,
                                                })
                                              }
                                            >
                                              DELETE
                                            </button>
                                          </div>
                                        ) : null}
                                      </td>
                                    ) : null}

                                    {market.market_type > "0" ? (
                                      <td className="d-flex justify-content-center align-items-center">
                                        {market.active == "1" ? (
                                          <a
                                            href="/"
                                            onClick={(e) => {
                                              e.preventDefault();
                                            }}
                                          >
                                            <button
                                              type="button"
                                              className="btn btn-w-m btn-primary"
                                              onClick={() =>
                                                this.handleMarket(market)
                                              }
                                              style={{ background: "#FF0000" }}
                                            >
                                              ADDED
                                            </button>
                                          </a>
                                        ) : (
                                          <a
                                            href="/"
                                            onClick={(e) => {
                                              e.preventDefault();
                                            }}
                                          >
                                            <button
                                              type="button"
                                              className="btn btn-w-m btn-primary"
                                              onClick={() =>
                                                this.handleMarket(market)
                                              }
                                              style={{ background: "#228C22" }}
                                            >
                                              ADD
                                            </button>
                                          </a>
                                        )}

                                        &nbsp; &nbsp;
                                        {Number(market.market_type) == 5 && Number(market.manual_market) == 1 ? (
                                          <div>
                                            <button
                                              type="button"
                                              className="bt btn-w- btn-primary newbtn"
                                              style={{ color: '#000' }}
                                              // data-toggle="modal" data-target="#exampleModal"
                                              // onClick={() =>
                                              //   this.setState((state) => ({
                                              //     cMarketData: market,
                                              //     showCloseTimePopup: true,
                                              //     formObj: {
                                              //       ...state.formObj,
                                              //       "market_type": market.market_type,
                                              //       "market_id": market.market_id
                                              //     },
                                              //   }))
                                              // }
                                              onClick={() => this.closeTime(market)

                                              }

                                            >
                                              EDIT CLOSE TIME
                                            </button>
                                          </div>
                                        ) : null}

                                      </td>
                                    ) : null}






                                  </tr>
                                ))
                              ) : (
                                <tr className="gradeX">
                                  <td colSpan={3}>
                                    No data available in table
                                  </td>
                                </tr>
                              )}
                            </tbody>
                          </table>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {
            <Modal
              isOpen={this.state.showDeleteConfirmPopup}
              onRequestClose={() =>
                this.setState({ showDeleteConfirmPopup: false })
              }
              contentLabel="Confirm Modal"
              style={customStyles}
            >
              <div className="modal-dialog">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLabel">
                      {" "}
                      &nbsp; Delete Fancy{" "}
                    </h5>
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div className="modal-body">
                    <h3> Are you sure, you want to delete fancy ? </h3>
                  </div>
                  <div className="modal-footer">
                    <button
                      type="button"
                      className="btn btn-secondary"
                      data-dismiss="modal"
                      onClick={() =>
                        this.setState({ showDeleteConfirmPopup: false })
                      }
                    >
                      Close
                    </button>
                    <button
                      type="button"
                      className="btn btn-primary"
                      disabled={valid}
                      onClick={() =>
                        this.handleDelete(this.state.cMarketData?.session_id)
                      }
                      data-dismiss="modal"
                    >
                      Confirm Delete
                    </button>
                  </div>
                </div>
              </div>
            </Modal>
          }



          {
            <Modal
              isOpen={this.state.showCloseTimePopup}
              onRequestClose={() =>
                this.setState({ showCloseTimePopup: false })
              }
              contentLabel="Confirm Modal"
              style={additionalStyles}
            >
              <form onSubmit={this.onSubmit} className="form-horizontal">
                <div className="modal-dialog">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title" id="exampleModalLabel">
                        {" "}
                        &nbsp; Edit Close Time{" "}
                      </h5>
                      <button
                        type="button"
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                        onClick={() =>
                          this.setState({ showCloseTimePopup: false })
                        }
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                    <div className="modal-body">

                      {+this.state.formObj.market_type === 5 &&
                        <>
                          <div className="hr-line-dashed"></div>
                          <div className="form-group">
                            <label className="col-sm-3 control-label">
                              Close Time
                            </label>
                            <div className="col-sm-9">
                              <DatePicker
                                autoComplete="off"
                                showTimeSelect
                                id="datetime"
                                // minDate={new Date()}
                                // dateFormat="yyyy-MM-dd hh:mm aa"
                                dateFormat="dd-MM-yyyy hh:mm aa"
                                // dateFormat="MMMM d, yyyy h:mm aa"
                                timeFormat="HH:mm"
                                className="form-control "
                                name="date"
                                selected={this.state?.formObj?.market_close_time}
                                onChange={(e) => {
                                  const selectedDateUTC = new Date(e.getTime() - (5.5 * 60 * 60 * 1000)); // Subtracting 5 hours and 30 minutes
                                  this.setState((state) => ({
                                    formObj: {
                                      ...state.formObj,
                                      "market_close_time": e,
                                      // "market_close_time": selectedDateUTC,
                                    },
                                  }))
                                }
                                }
                                required
                              />
                            </div>
                          </div>
                        </>
                      }
                    </div>
                    <div className="modal-footer">
                      <button
                        type="button"
                        className="btn btn-secondary"
                        data-dismiss="modal"
                        onClick={() =>
                          this.setState({ showCloseTimePopup: false })
                        }
                      >
                        Close
                      </button>
                      <button
                        className="btn btn-primary"
                        disabled={valid}
                        type='submit'
                        data-dismiss="modal"
                      >
                        Confirm
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </Modal>
          }




          {Boolean(isShowModal?.session_id) && (
            <ConfrimModal
              title="Please select fancy user type "
              isShow={Boolean(isShowModal?.session_id)}
              message={
                <>
                  <select
                    className="selectClass"
                    value={this.state.selectValue}
                    onChange={this.inputhandleChange}
                  >
                    <option value="">Select session type</option>
                    {inputSelect?.map((el, i) => {
                      return (
                        <option key={i} value={el.session_type}>
                          {firstLetterCapital(el.session_type)}
                        </option>
                      );
                    })}
                  </select>
                  {toggleSelectError && (
                    <p className="text_danger">
                      Please select valid session type
                    </p>
                  )}
                </>
              }
              footerButton={
                <>
                  <button
                    type="button"
                    className="btn btn-secondary"
                    data-dismiss="modal"
                    onClick={() => this.onCancleModal()}
                  >
                    Close
                  </button>
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={() =>
                      selectValue
                        ? this.handleMarket()
                        : this.setState({ toggleSelectError: true })
                    }
                  >
                    Confirm
                  </button>
                </>
              }
              onCancle={() => this.onCancleModal()}
            />
          )}
        </div>
      </>
    );
  }
}
export default FixtureMarketComponent;
