import React from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import Agents from "../../components/Agent/List";

function List(props) {
  const navigate = useNavigate();
  return <Agents {...props} navigate={navigate} />;
}

const mapstatetop = (state) => ({
  ...state,
});

export default List = connect(mapstatetop)(List);
