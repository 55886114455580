import InnerPageHeader from "components/common/InnerPageHeader";
import SectionTitle from "components/common/SectionTitle";
import TagAndChips from "components/common/TagAndChips";
import { Button } from "react-bootstrap";
import { useLocation, Link, useNavigate } from "react-router-dom";

import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { getAgentReport } from "_services";
import { getUserReport } from "_services";
import { getSubCompanyReport } from "_services/sports.services"
import { convertParseFloat } from "_helpers";
import PageTabs from "components/common/pageTabs";

const PlusMinusType = () => {

    const navigate = useNavigate();
    const location = useLocation();

    const userLogin = JSON.parse(localStorage.getItem("userLogin"));

    const [subCompanyPage, setSubCompanyPage] = useState(userLogin)
    const [selectedAgent, setSelectedAgent] = useState(null);
    const [historyStack, setHistoryStack] = useState([]);

    const { event_id } = useParams();

    // ------------- sub company report -------------

    const [subCompanyReportList, setSubCompanyReportList] = useState([]);

    useEffect(() => {
        if (event_id) {
            fetchSubCompanyReport();
        }
    }, []);

    const fetchSubCompanyReport = () => {
        let sendData = {};
        sendData.current_page = 1;
        sendData.items_perpage = 100;
        sendData.event_id = event_id;
        getSubCompanyReport(sendData)
            .then((response) => {
                if (
                    response &&
                    response.data &&
                    response.data.data &&
                    response.data.data.sc_report &&
                    response.data.data.sc_report.length
                ) {
                    setSubCompanyReportList(response.data.data.sc_report);
                } else {
                    setSubCompanyReportList([]);
                }
            })
            .catch((error) => {
                setSubCompanyReportList([]);
            });
    };

    // -------------- user report --------------

    const [userReportList, setUserReportList] = useState([]);

    // useEffect(() => {
    //     if (event_id) {
    //         fetchUserReport();
    //     }
    // }, []);

    const fetchUserReport = (user_id) => {
        let sendData = {};
        sendData.current_page = 1;
        sendData.items_perpage = 100;
        sendData.event_id = event_id;
        sendData.user_id = user_id;
        getUserReport(sendData)
            .then((response) => {
                if (
                    response &&
                    response.data &&
                    response.data.data &&
                    response.data.data.user_report &&
                    response.data.data.user_report.length
                ) {
                    // setUserReportList(response.data.data.user_report);
                    setAgentReportList(response.data.data.user_report);
                } else {
                    // setUserReportList([]);
                    setAgentReportList([]);
                }
            })
            .catch((error) => {
                // setUserReportList([]);
                setAgentReportList([]);
            });
    };

    // --------------- agent report ---------------

    const [agentReportList, setAgentReportList] = useState([]);

    useEffect(() => {
        if (event_id) {
            fetchAgentReport(0);
        }
    }, []);

    const fetchAgentReport = (user_id) => {
        let sendData = {};
        sendData.current_page = 1;
        sendData.items_perpage = 100;
        sendData.event_id = event_id;
        sendData.user_id = user_id;
        getAgentReport(sendData)
            .then((response) => {
                if (
                    response &&
                    response.data &&
                    response.data.data &&
                    response.data.data.agent_report &&
                    response.data.data.agent_report.length
                ) {
                    setAgentReportList(response.data.data.agent_report);
                } else {
                    setAgentReportList([]);
                }
            })
            .catch((error) => {
                setAgentReportList([]);
            });
    };

    const handleAgentClick = (el) => {
        if (el.role === 8) {
            fetchUserReport(el.user_id);
        } else if (el.role !== 9) {
            fetchAgentReport(el.user_id);
        }

        // Save the current selected agent to history before updating
        if (selectedAgent) {
            setHistoryStack([...historyStack, selectedAgent]);
        }
        setSelectedAgent(el);
    };

    const handleBackButtonClick = () => {
        // Check if there is any previous agent in the stack
        if (historyStack.length > 0) {
            // Pop from the stack to get the previous agent
            const previousAgent = historyStack.pop();
            if (previousAgent) {
                if (previousAgent.role === 8) {
                    fetchUserReport(previousAgent.parent_id);
                } else if (previousAgent.role !== 9) {
                    fetchAgentReport(previousAgent.parent_id);
                }
                setSelectedAgent(previousAgent);
            }

            // Update the history stack
            setHistoryStack([...historyStack]);
        }
    };

    return (
        <div>
            <InnerPageHeader
                pageTitle={`Agent Company Report`}
                breadcrumbs={[
                    { title: "Dashboard", url: "/" },
                    { title: "Agent Company Report", url: '' },
                ]}
                currenPageTitle="List"
            />

            <div className="wrapper wrapper-content animated fadeInRight">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="ibox float-e-margins">
                            <div className="ibox-title">
                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <h5>Agent Report</h5>
                                    {historyStack.length > 0 && (
                                        <button onClick={handleBackButtonClick} >Back</button>)
                                    }
                                </div>
                            </div>
                            <div className="ibox-content table-responsive">
                                <table className="table table-bordered table-striped">
                                    <thead>
                                        <tr className="custom-table-header ">
                                            <th>User Name</th>
                                            <th>MatchOdds Plus Minus</th>
                                            <th>Other Market Plus Minus</th>
                                            <th>Bookmaker Plus Minus</th>
                                            <th>Session Plus Minus</th>
                                            <th>Total</th>
                                            <th>Match Commission</th>
                                            <th>Session Commission</th>
                                            <th>Total Commission</th>
                                            <th>Net</th>
                                            <th>Agent Share</th>
                                            <th>OTHERS</th>
                                            <th>Final</th>
                                        </tr>
                                    </thead>
                                    <tbody>

                                    </tbody>
                                    {agentReportList && agentReportList.length > 0 ?
                                        <tfoot>
                                            {agentReportList.map((el, ind) => {
                                                return (
                                                    <tr key={ind}>
                                                        <th
                                                            style={{
                                                                cursor: el.role != 9 ? 'pointer' : 'default',
                                                                color: el.role != 9 ? 'dodgerblue' : 'black'
                                                            }}
                                                            onClick={() => el.role != 9 && handleAgentClick(el)} >
                                                            {el.username} ({(el.first_name)}) </th>
                                                        <th>{convertParseFloat(Math.round((el.match_odds_pm) * 100) / 100)}</th>
                                                        <th>{convertParseFloat(Math.round((el.other_pm) * 100) / 100)}</th>
                                                        <th>{convertParseFloat(Math.round((el.bookmaker_pm) * 100) / 100)}</th>
                                                        <th>{convertParseFloat(Math.round((el.session_pm) * 100) / 100)}</th>
                                                        <th>{convertParseFloat(Math.round((el.match_pm + el.session_pm) * 100) / 100)}</th>
                                                        <th>{convertParseFloat(Math.round((el.match_commission) * 100) / 100)}</th>
                                                        <th>{convertParseFloat(Math.round((el.session_commission) * 100) / 100)}</th>
                                                        <th>{convertParseFloat(Math.round((el.match_commission + el.session_commission) * 100) / 100)}</th>
                                                        <th>{convertParseFloat(Math.round(((el.match_pm + el.session_pm) - (el.match_commission + el.session_commission)) * 100) / 100)}</th>
                                                        <th>{convertParseFloat(Math.round((el.agent_share) * 100) / 100)}</th>
                                                        <th>{convertParseFloat(Math.round((el.other_charges) * 100) / 100)}</th>
                                                        <th>{convertParseFloat(Math.round((el.final_total) * 100) / 100)}</th>
                                                    </tr>
                                                )
                                            })
                                            }

                                            <tr>
                                                <th>Total</th>
                                                <th>{convertParseFloat(agentReportList.reduce((a, v) => a = a + v.match_odds_pm, 0))}</th>
                                                <th>{convertParseFloat(agentReportList.reduce((a, v) => a = a + v.other_pm, 0))}</th>
                                                <th>{convertParseFloat(agentReportList.reduce((a, v) => a = a + v.bookmaker_pm, 0))}</th>
                                                <th>{convertParseFloat(agentReportList.reduce((a, v) => a = a + v.session_pm, 0))}</th>
                                                <th>{convertParseFloat(Math.round(((agentReportList.reduce((a, v) => a = a + v.match_odds_pm, 0)) + (agentReportList.reduce((a, v) => a = a + v.other_pm, 0)) + (agentReportList.reduce((a, v) => a = a + v.session_pm, 0))) * 100) / 100)}
                                                </th>
                                                <th>{convertParseFloat(Math.round(((agentReportList.reduce((a, v) => a = a + v.match_commission, 0))) * 100) / 100)}</th>
                                                <th>{convertParseFloat(Math.round(((agentReportList.reduce((a, v) => a = a + v.session_commission, 0))) * 100) / 100)}</th>
                                                <th>
                                                    {convertParseFloat(Math.round(((agentReportList.reduce((a, v) => a = a + v.match_commission, 0)) + (agentReportList.reduce((a, v) => a = a + v.session_commission, 0))) * 100) / 100)}
                                                </th>
                                                <th>
                                                    {convertParseFloat(((agentReportList.reduce((a, v) => a = a + v.match_pm, 0)) + (agentReportList.reduce((a, v) => a = a + v.session_pm, 0))) - ((agentReportList.reduce((a, v) => a = a + v.match_commission, 0)) + (agentReportList.reduce((a, v) => a = a + v.session_commission, 0))))}
                                                </th>
                                                <th>{convertParseFloat(Math.round(((agentReportList.reduce((a, v) => a = a + v.agent_share, 0))) * 100) / 100)}</th>
                                                <th>{convertParseFloat(Math.round(((agentReportList.reduce((a, v) => a = a + v.other_charges, 0))) * 100) / 100)}</th>
                                                <th>{convertParseFloat(Math.round(((agentReportList.reduce((a, v) => a = a + v.final_total, 0))) * 100) / 100)}</th>
                                            </tr>
                                        </tfoot>
                                        : <tfoot>
                                            <tr>
                                                <th colSpan={13}>No record found</th></tr></tfoot>}
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default PlusMinusType