import _ from 'lodash';

const DevHost = ["localhost"];
const LiveHost = ["admin.future9club.com", "future9club.com"];
const StagHost = ["top2020.pro", "top2020.in", "www.top2020.pro", "www.top2020.in"];

const hostname = window.location.hostname;

const live = {
  api_baseurl: "https://adminapi.future9club.com/api/",
  baseurl: "https://adminapi.future9club.com/",
  socketurl: "https://node.future9club.com/",
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
    "Accept-Language": "en",
  },
};

const dev = {
  api_baseurl: "https://adminapi.future9club.com/api/",
  baseurl: "https://adminapi.future9club.com/",
  socketurl: "https://node.future9club.com/",
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
    "Accept-Language": "en",
  },
};


const stag = {
  api_baseurl: "https://adminapi.future9club.com/api/",
  baseurl: "https://adminapi.future9club.com/",
  socketurl: "https://node.future9club.com/",
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
    "Accept-Language": "en",
  },
};



const AppConfig = _.includes(DevHost, hostname) ? dev : (_.includes(StagHost, hostname) ? stag : live);

// const AppConfig =  _.includes(DevHost, hostname)? dev: live;

export default AppConfig;