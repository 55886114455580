import React from "react";
import { Accordion, Button, Card, Modal } from "react-bootstrap";

function DeclaredOddsFancyView(props) {
  const {
    showDeclared,
    setDeclaredColumn,
    declaredFancyData,
    userRole,
    getDeclaredSatatusSum,
    showConfirmPoppup,
    declaredMarketData,
    isLoading,
    getWinner
  } = props;
  return (
    <Accordion activeKey={showDeclared}>
      <Card>
        <Card.Header>
          <div
            className="bet-place-tbl-t session-th-strip-new pointer justify-between d-flex align-center"
            onClick={() =>
              setDeclaredColumn(showDeclared === "session" ? "" : "session")
            }
            style={{ padding: "0px 6px", height: '30px' }}
          >
            <p className="align-left m-0">DECLARED SESSIONS</p>
            <span
              className={
                (showDeclared === "session" ? " active " : "") + " fa arrow"
              }
            ></span>
          </div>
        </Card.Header>
        <Accordion.Collapse eventKey="session">
          {declaredFancyData && declaredFancyData.length ? (
            <div className="table-aside declare-session-table">

              <table className="table table-bordere ">
                <thead>
                  {/* <tr>
                    <th colSpan={4}>
                      Declare session
                    </th>
                  </tr> */}
                  <tr className="new-header">
                    <th
                      className="bet-place-tbl-t"
                      style={{ backgroundColor: "#49494A" }}
                    >
                      SESSION
                    </th>
                    <th className="bet-odds-typ bet-place-tbl-t">
                      Settled at
                    </th>
                    <th className="bet-odds-typ bet-place-tbl-t">Status</th>
                    {userRole?.role == 1 && (
                      <th className="bet-odds-typ bet-place-tbl-t">Action</th>
                    )}
                  </tr>
                </thead>
                <tbody>
                  {declaredFancyData.map((declared, index) => (
                    <tr key={index}>
                      <td
                        data-column="session_titl"
                        className="bet-place-tbl-t"
                        style={{ textAlign: "left" }}
                      >
                        {declared.runner_name}{" "}
                      </td>
                      <td className="bet-place-tbl-t bet-val-cen">
                        {declared.settlement_result}
                      </td>
                      <td
                        className="bet-place-tbl-t bet-val-cen"
                        style={{
                          color: declared.pl >= 0 ? "#228C22" : "#FF0000",
                        }}
                      >
                        {declared.pl}
                      </td>
                      {userRole?.role == 1 && (
                        <td className="bet-place-tbl-t bet-val-cen">
                          <a
                            className="bt  rollback_btn"
                            data-method="get"
                            data-remote="true"
                            onClick={() => showConfirmPoppup("session", declared)}
                          >
                            Rollback
                          </a>
                        </td>
                      )}
                    </tr>
                  ))}

                  <tr>
                    <td className="bet-place-tbl-t bet-val-cen">
                      Declare Session Total
                    </td>
                    <td className="bet-place-tbl-t bet-val-cen"></td>
                    <td
                      className="bet-place-tbl-t bet-val-cen"
                      style={{
                        color:
                          getDeclaredSatatusSum(declaredFancyData) >= 0
                            ? "#228C22"
                            : "#FF0000",
                      }}
                    >
                      {getDeclaredSatatusSum(declaredFancyData)}
                    </td>
                    {userRole?.role == 1 && (
                      <td className="bet-place-tbl-t bet-val-cen"></td>
                    )}
                  </tr>
                </tbody>
              </table>
            </div>

          ) : isLoading ? (
            <p>Loading...</p>
          ) : (
            <p> No Data Found</p>
          )}
        </Accordion.Collapse>
      </Card>
      <Card style={{ marginTop: '10px' }}>
        <Card.Header>
          <div
            className="bet-place-tbl-t session-th-strip-new pointer justify-between d-flex align-center"
            onClick={() =>
              setDeclaredColumn(showDeclared === "market" ? "" : "market")
            }
            style={{ padding: "0px 6px", height: '30px' }}
          >
            <p className="align-left m-0">DECLARED MARKET</p>
            <span
              className={
                (showDeclared === "market" ? " active " : "") + " fa arrow"
              }
            ></span>
          </div>
        </Card.Header>
        <Accordion.Collapse eventKey="market">
          {declaredMarketData && declaredMarketData.length ? (
            <table className="table table-bordere ">
              <thead>
                <tr className="new-header">
                  <th
                    className="bet-place-tbl-t"
                    style={{ backgroundColor: "#49494A" }}
                  >
                    MARKET
                  </th>
                  <th className="bet-odds-typ bet-place-tbl-t">WINNER</th>
                  <th className="bet-odds-typ bet-place-tbl-t">Status</th>
                  {userRole?.role == 1 && (
                    <th className="bet-odds-typ bet-place-tbl-t">Action</th>
                  )}
                </tr>
              </thead>
              <tbody>
                {declaredMarketData.map((declared, index) => (
                  <tr key={index}>
                    <td
                      data-column="session_titl"
                      className="bet-place-tbl-t"
                      style={{ textAlign: "left" }}
                    >
                      {declared.market_name}{" "}
                    </td>
                    <td className="bet-place-tbl-t bet-val-cen">
                      {getWinner(declared)}
                    </td>
                    <td
                      className="bet-place-tbl-t bet-val-cen"
                      style={{
                        color: declared.pl >= 0 ? "#228C22" : "#FF0000",
                      }}
                    >
                      {declared.pl}
                    </td>
                    {userRole?.role == 1 && (
                      <td className="bet-place-tbl-t bet-val-cen">
                        {/* <a
                          className="btn btn-info"
                          data-method="get"
                          data-remote="true"
                          onClick={() => showConfirmPoppup("market", declared)}
                        >
                          Rollback
                        </a> */}
                        <a
                          className="bt  rollback_btn"
                          data-method="get"
                          data-remote="true"
                          onClick={() => showConfirmPoppup("market", declared)}
                        >
                          Rollback
                        </a>
                      </td>
                    )}
                  </tr>
                ))}

                <tr>
                  <td className="bet-place-tbl-t bet-val-cen">
                    Declare Market Total
                  </td>
                  <td className="bet-place-tbl-t bet-val-cen"></td>
                  <td
                    className="bet-place-tbl-t bet-val-cen"
                    style={{
                      color:
                        getDeclaredSatatusSum(declaredMarketData) >= 0
                          ? "#228C22"
                          : "#FF0000",
                    }}
                  >
                    {getDeclaredSatatusSum(declaredMarketData)}
                  </td>
                  {userRole?.role == 1 && (
                    <td className="bet-place-tbl-t bet-val-cen"></td>
                  )}
                </tr>
              </tbody>
            </table>
          ) : isLoading ? (
            <p>Loading...</p>
          ) : (
            <p> No Data Found</p>
          )}
        </Accordion.Collapse>
      </Card>
    </Accordion>
  );
}

export default DeclaredOddsFancyView;
