import React from "react";

import Settlement from "components/settlements/List";
import { connect } from "react-redux";
import { useLocation } from "react-router-dom";

function FancySession(props) {
  const location = useLocation();
  return <Settlement {...props} location={location} />;
}
const mapstatetop = (state) => ({
  ...state,
});

export default connect(mapstatetop)(FancySession);
