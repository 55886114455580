import React, { Component } from 'react'

import MyLedgerCmp from '../../components/ManageLedger/MyLedgerCmp';
export class MyLedger extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }
    render() {
        return (
            
                <MyLedgerCmp />
            
        )
    }
}

export default MyLedger