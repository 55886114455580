import { getByDisplayValue } from "@testing-library/react";
import React, { useEffect, useState } from "react";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import {
    getBetSlip,
    getMatchPosition,
    getBetListCount,
    deleteBetSlip,
    getEventMarket
} from "_services";
import ReactPaginate from "react-paginate";
import { userListByKeywordRequest } from "../../_actions/userlistbykeyword/userlistbykeyword.actions";

import { ReactSearchAutocomplete } from "react-search-autocomplete";
import { formatDateAndTime } from "../../utils/index";
import ConfrimModal from "components/ConfirmModal";
import InnerPageHeader from "components/common/InnerPageHeader";
import { addBetTrClass, stackList } from "components/layouts/Helpers";

const BetSlip = () => {

    const location = useLocation();
    const navigate = useNavigate();
    const { matchData, bookmaker_type = 2 } = location.state;

    const { event_id } = useParams();

    const [betSlipCounts, setBetSlipCounts] = useState("");
    const [betSlipList, setBetSlipList] = useState([]);
    const [initialData, setInitialData] = useState([]);
    const [matchPositionList, setMatchPositionList] = useState([]);

    const [totalRecords, setTotolRecords] = useState("");

    const userLogin = JSON.parse(localStorage.getItem("userLogin"));

    const [currentPage, setCurrentPage] = useState(1);
    const [perPage] = useState(50);
    const [pageCount, setPageCount] = useState(0);
    const [keyword, updateKeyword] = useState('');
    const [userListByKeyword, updateList] = useState([]);
    const [allMarket, updateAllMarket] = useState([]);
    const [selectedMarket, updateSelectedMarket] = useState(null);
    const [showDeleteModal, setShowDeleteModal] = useState({});
    const [selectedUserId, setSelectedUserId] = useState('')
    const [selectedStackId, setSelectedStackId] = useState('')


    useEffect(() => {
        if (event_id) {
            fetchBetSlipCount();
            fetchMatchList();
        }
    }, []);

    useEffect(() => {
        if (event_id) {
            let sendData = {};
            sendData.event_id = event_id;
            getEventMarket(sendData)
                .then((response) => {
                    if (response && response.data && response.data.data) {
                        const allMarketList = response.data.data.market;
                        updateAllMarket(allMarketList);
                        if (!selectedMarket?.market_id) {
                            updateSelectedMarket(allMarketList[0]);
                        }
                        fetchBetSlip(selectedMarket?.market_id ? selectedMarket?.market_id : allMarketList[0]?.market_id);
                    }
                })
                .catch((error) => {
                });

        }
    }, [currentPage]);

    useEffect(() => {
        fetchBetSlip()
    }, [selectedUserId, selectedStackId])

    const fetchBetSlip = (market_id = selectedMarket?.market_id ? selectedMarket?.market_id : "") => {
        let sendData = {};
        sendData.current_page = currentPage;
        sendData.items_perpage = perPage;
        sendData.bookmaker_type = bookmaker_type;
        sendData.event_id = event_id;
        sendData.market_id = market_id;
        sendData.user_id = selectedUserId;
        sendData.stake_type = selectedStackId
        getBetSlip(sendData)
            .then((response) => {
                if (
                    response?.data?.data?.bet_data?.length
                ) {
                    setInitialData(response.data.data.bet_data);
                    setTotolRecords(response.data.data.total_records);
                    let dat = JSON.parse(JSON.stringify(response.data.data.bet_data));
                    dat.forEach((element) => {
                        element.extra_name = getRunnersName(
                            element.selection_id,
                            element.market_runners
                        );
                        const extraValue = getExtraValue(element);
                        for (const iterator of extraValue) {
                            for (const key in iterator) {
                                if (Object.hasOwnProperty.call(iterator, key)) {
                                    element[key] = iterator[key]
                                }
                            }
                        }

                        element.extra_my_share = getMyShare(element.upline_data);
                        element.extra_plus_minus = getPlusMinus(
                            element.selection_id,
                            element
                        );
                        element.extra_plus_minus = (+element.extra_plus_minus).toFixed(2) * -1;

                    });

                    setBetSlipList(dat);

                    setPageCount(Math.ceil(response.data.data.total_records / perPage));
                } else {
                    setBetSlipList([]);
                    setInitialData([]);
                    setPageCount(Math.ceil(response.data.data.total_records / perPage));

                }
            })
            .catch((error) => {
                setBetSlipList([]);
                setInitialData([]);
            });
    };

    const handlePageClick = (e) => {
        const selectedPage = e.selected;
        setCurrentPage(selectedPage + 1);
    };

    const fetchBetSlipCount = () => {
        let sendData = {};
        sendData.bookmaker_type = bookmaker_type;
        sendData.event_id = event_id;
        getBetListCount(sendData)
            .then((response) => {
                if (response && response.data && response.data.data) {
                    setBetSlipCounts(response.data.data);
                } else {
                    setBetSlipCounts("");
                }
            })
            .catch((error) => {
                setBetSlipCounts("");
            });
    };

    const fetchMatchList = () => {
        let sendData = {};
        sendData.event_id = event_id;
        getMatchPosition(sendData)
            .then((response) => {
                if (
                    response &&
                    response.data &&
                    response.data.data &&
                    response.data.data.length
                ) {
                    setMatchPositionList(response.data.data);
                } else {
                    setMatchPositionList([]);
                }
            })
            .catch((error) => {
                setMatchPositionList([]);
            });
    };

    const getRunnersName = (selection_id, market_runners) => {
        try {
            if (selection_id && market_runners && market_runners.length) {
                let dat = market_runners.find((val) => val.selectionId == selection_id);
                if (dat && dat.runnerName) {
                    return dat.runnerName;
                } else {
                    return "-";
                }
            } else {
                return "-";
            }
        } catch (error) { }
    };

    const getValue = (dat, position) => {
        try {
            if (dat.market_runners && dat.market_runners.length) {
                let id =
                    dat.market_runners[position] &&
                        dat.market_runners[position].selectionId
                        ? dat.market_runners[position].selectionId
                        : "";
                if (id && dat.runner_pl && dat.runner_pl[id]) {
                    return dat.runner_pl[id];
                } else {
                    return "";
                }
            }
        } catch (error) { }
    };

    const getExtraValue = (dat) => {
        const share = getMyShare(dat?.upline_data);
        if (dat?.market_runners.length) {
            return dat.market_runners.map((mrt, ind) => {
                let id = mrt.selectionId ? mrt.selectionId : ""
                if (id && dat?.runner_pl[id]) {
                    const value = (+dat.runner_pl[id])?.toFixed(2) * -1
                    let percentageValue = (share * value) / 100;
                    return { ['extra_val_' + (ind + 1)]: value, ['extra_after_val_' + (ind + 1)]: (+percentageValue).toFixed(2) };
                }
            });
        }
    };

    const getMyShare = (uplineData) => {
        try {
            if (uplineData && uplineData.length) {
                let dat = uplineData.find((val) => val.parent_id == userLogin.user_id);
                if (dat) {
                    return dat.my_share;
                } else {
                    return 0;
                }
            } else {
                return 0;
            }
        } catch (error) {
            return 0;
        }
    };

    const getMyShareAfterData = (dat, position, uplineData) => {
        try {
            let value = getValue(dat, position);
            let share = getMyShare(uplineData);
            let percentageValue = (share * value) / 100;
            return percentageValue;
        } catch (error) {
            return 0;
        }
    };

    const getPlusMinus = (selection_id, dat) => {
        try {
            if (dat && dat.runner_pl) {
                let keys = Object.keys(dat.runner_pl);
                let indexVal = keys.findIndex((val) => val == selection_id);
                if (indexVal !== -1) {
                    if (indexVal == 0) {
                        return getMyShareAfterData(dat, 0, dat.upline_data);
                    } else {
                        return getMyShareAfterData(dat, 1, dat.upline_data);
                    }
                } else {
                    return 0;
                }
            }
        } catch (error) {
            return 0;
        }
    };

    const getSum = (key) => {
        try {
            let val = betSlipList.reduce((total, value) => {
                if (key === "extra_after_val_3") {
                }
                return total + (value[key] ? parseFloat(value[key]) : 0);
            }, 0);
            return val.toFixed(2);
        } catch (error) {
            return 0;
        }
    };

    const handleDeleteFn = (event) => {
        if (event) {
            let sendData = {
                betslip_uid: event.betslip_uid,
                user_id: event.user_id
            };
            setShowDeleteModal(sendData)
        } else if (showDeleteModal.user_id) {
            setShowDeleteModal(state => ({ ...state, loading: true }))
            deleteBetSlip(showDeleteModal)
                .then((response) => {
                    if (response) {
                        fetchBetSlip(selectedMarket.market_id);
                        setShowDeleteModal({})
                    }
                })
                .catch((error) => {
                    setShowDeleteModal(state => ({ ...state, loading: false }))
                });
        }
    };

    const handleChange = (event) => {
        const index = event.target.value;
        updateSelectedMarket(allMarket[index]);
        setCurrentPage(1);
        fetchBetSlip(allMarket[index]?.market_id);
    };



    return (
        <div>
            <InnerPageHeader
                pageTitle={`Match Slip`}
                breadcrumbs={[
                    { title: "Dashboard", url: "/" },
                    { title: "Match Slip", url: '' },
                ]}
                currenPageTitle="List"
            />

            <div className="wrapper wrapper-content animated fadeInRight">
                <div
                    className="row"
                    style={{
                        display: "flex",
                        alignItems: "center",
                        marginBottom: 20,
                    }}
                >

                    {matchData?.sport_id !== 6 && (
                        <div className="col-sm-3 m-b-xs">
                            <select
                                className="input-sm form-control input-s-sm inline"
                                id="market_ids"
                                name="model[field]"
                                style={{ height: "34px" }}
                                onChange={handleChange}
                            >
                                {allMarket.map((val, indexVal) => (
                                    <option key={indexVal} value={indexVal}>
                                        {val.market_name}
                                    </option>
                                ))}
                            </select>
                        </div>
                    )}


                </div>

                <div className="row">
                    <div className="col-lg-12">




                        <div className="ibox float-e-margins">
                            <div className="ibox-title">
                                <h5>Match Slips</h5>
                                <div className="ibox-tools"></div>
                            </div>

                            <div className="ibox-content table-responsive ">
                                <table className="table table-bordered table-striped">
                                    <thead>
                                        <tr>
                                            <th colSpan={(betSlipList[0]?.market_runners?.length ? betSlipList[0]?.market_runners?.length : 0) + 8}>
                                                Bet
                                            </th>
                                            <th colSpan={(betSlipList[0]?.market_runners?.length ? betSlipList[0]?.market_runners?.length : 0) + 1}>
                                                My Share / Position
                                            </th>
                                            <th colSpan="3">Settlement</th>
                                        </tr>
                                    </thead>

                                    <thead>
                                        <tr className="table-inner-header">
                                            <th>Bet ID</th>
                                            <th>DATE</th>
                                            <th>Market Title</th>
                                            <th>RATE</th>
                                            <th>AMOUNT</th>
                                            <th>MODE</th>
                                            <th>Runner Name</th>
                                            <th>User</th>
                                            {betSlipList[0]?.market_runners?.length ?
                                                betSlipList[0]?.market_runners.map((item) => (
                                                    <th>{item.runnerName}</th>
                                                )) : <></>}
                                            <th>My Share</th>
                                            {betSlipList[0]?.market_runners?.length ?
                                                betSlipList[0]?.market_runners.map((item) => (
                                                    <th>{item.runnerName}</th>
                                                )) : <></>}
                                            <th>Status</th>
                                            <th>Plus / Minus</th>
                                            <th> Action </th>
                                        </tr>
                                    </thead>
                                    {betSlipList && betSlipList.length ? (
                                        <>
                                            <tbody>
                                                {betSlipList.map((bets, index) => (
                                                    <tr key={index}
                                                        // className={addBetTrClass(bets.stake)}
                                                        className={bets.betting_type == 1 ? "lay" : "back"}
                                                    >
                                                        <td>{bets.betslip_uid}</td>
                                                        <td>
                                                            <i className="fa fa-clock-o"></i>&nbsp;
                                                            {formatDateAndTime(
                                                                "MMM DD, hh:mm:ss A",
                                                                bets.created_at
                                                            )}
                                                        </td>
                                                        <td>{bets.market_name ? bets.market_name : "-"}</td>

                                                        <td>
                                                            {/* {bets.price ? bets.display_price : "-"} */}
                                                            {bets.price ? (parseFloat(bets.display_price)).toFixed(2) : "-"}
                                                        </td>

                                                        <td>{bets.stake ? bets.stake : "-"}</td>

                                                        <td>
                                                            {bets.betting_type == "1" && "Lagai"}
                                                            {bets.betting_type == "2" && "KHAI"}
                                                        </td>
                                                        <td>
                                                            {bets.extra_name}
                                                        </td>
                                                        <td>
                                                            {/* {bets.username ? bets.username : "-"} */}
                                                            <a
                                                                href="/"
                                                                onClick={(e) => {
                                                                    e.preventDefault();
                                                                    navigate(
                                                                        `/userview/client/${bets.user_guid}/${bets.username}`
                                                                    );
                                                                }}
                                                            >
                                                                {bets.username} ({bets.name})
                                                            </a>
                                                        </td>

                                                        <th>
                                                            {bets.extra_val_1}
                                                        </th>
                                                        <th>
                                                            {bets.extra_val_2}
                                                        </th>
                                                        {bets.extra_val_3 && <th>
                                                            {bets.extra_val_3}
                                                        </th>}

                                                        <td>
                                                            {/* {getMyShare(bets.upline_data)} */}
                                                            {bets.extra_my_share}
                                                        </td>
                                                        <th className="runner-sum">
                                                            {bets.extra_after_val_1}
                                                        </th>
                                                        <th className="runner-sum">
                                                            {bets.extra_after_val_2}
                                                        </th>
                                                        {bets?.extra_after_val_3 !== undefined ?
                                                            <th className="runner-sum">
                                                                {bets.extra_after_val_3}
                                                            </th>
                                                            : <></>}

                                                        <td>
                                                            {bets.winning_status == "0" && (
                                                                <span className="label label-info">
                                                                    Pending
                                                                </span>
                                                            )}

                                                            {bets.winning_status == "3" && (
                                                                <span className="label label-danger">
                                                                    Cancelled
                                                                </span>
                                                            )}

                                                            {(bets.winning_status == "1" ||
                                                                bets.winning_status == "2") && (
                                                                    <span className="label label-primary">
                                                                        Settle
                                                                    </span>
                                                                )}
                                                        </td>
                                                        <td>{bets.extra_plus_minus}</td>

                                                        <td>
                                                            {userLogin.role == 1 && bets.winning_status == "0" && (
                                                                <button
                                                                    type="button"
                                                                    className="btn btn-primary"
                                                                    onClick={() => handleDeleteFn(bets)}
                                                                >

                                                                    DELETE
                                                                </button>
                                                            )}

                                                            {/* {
                                bets.winning_status == "0" ?
                                (
                                  <button 
                                    type="button" 
                                    className="btn btn-primary"
                                    onClick={() => handleDeleteFn(bets)}
                                    >
                                    DELETE
                                  </button>
                                )
                                :
                                (null)
                              } */}
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                            <tfoot>
                                                <tr>
                                                    <th></th>
                                                    <th></th>
                                                    <th></th>
                                                    <th></th>
                                                    <th></th>
                                                    <th></th>
                                                    <th></th>
                                                    <th>Total</th>
                                                    <th>{getSum("extra_val_1")}</th>
                                                    <th>{getSum("extra_val_2")} </th>
                                                    {betSlipList?.length && betSlipList[0].extra_after_val_3 !== undefined ? <th>{getSum("extra_val_3")} </th> : <></>}
                                                    <th> </th>
                                                    <th>{getSum("extra_after_val_1")}</th>
                                                    <th>{getSum("extra_after_val_2")}</th>
                                                    {betSlipList?.length && betSlipList[0].extra_after_val_3 !== undefined ? <th>{getSum("extra_after_val_3")}</th> : <></>}
                                                    <th></th>
                                                    <th>{getSum("extra_plus_minus")}</th>
                                                    <th></th>
                                                </tr>
                                            </tfoot>
                                        </>
                                    ) : (
                                        <tbody>
                                            <tr>
                                                <td colSpan={15}>No Data Found</td>
                                            </tr>
                                        </tbody>
                                    )}
                                </table>

                                <div className="row">
                                    <div className="float-right"></div>
                                </div>

                                <div className="paginate_class">
                                    <ReactPaginate
                                        previousLabel={"prev"}
                                        nextLabel={"next"}
                                        breakLabel={"..."}
                                        breakClassName={"break-me"}
                                        pageCount={pageCount}
                                        marginPagesDisplayed={2}
                                        pageRangeDisplayed={5}
                                        onPageChange={handlePageClick}
                                        containerClassName={"pagination"}
                                        subContainerClassName={"pages pagination"}
                                        activeClassName={"active"}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>

    )
}

export default BetSlip