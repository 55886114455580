import React, { Component } from "react";
import InnerPageHeader from "./InnerPageHeader";
import { Link, useLocation } from "react-router-dom";
import Pagination from "./Pagination";
import { profileDetails } from "_services";

class UserViewPage extends Component {
  constructor(props) {
    super(props);
  }
  state = {
    masterList: [],
    totalRecords: 0,
    role: 3,
    currentPage: 1,
    userData: null,
  };

  componentDidMount() {
    const { id } = this.props?.params;
    let sendData = {};
    sendData.user_guid = id;
    profileDetails(sendData)
      .then((response) => {
        if (response?.data?.data) {
          this.setState({ userData: response?.data?.data });
        } else {
          this.setState({ userData: null });
        }
      })
      .catch((error) => {});

      // let items = localStorage.getItem('username');
      
  }

  render() {
    const { masterList, totalRecords, userData } = this.state;
    const { type, id, username } = this.props?.params;
    const  lastPath  = this.props?.location?.state?.lastPath;
    let userfirstname = userData?userData.first_name:'';
    let user_role = userData?userData.role:'';

    return (
      <>
        <InnerPageHeader
          pageTitle={type.charAt(0).toUpperCase() + type.slice(1) + " List"}
          breadcrumbs={[
            { title: "Dashboard", url: "/" },
            // { title: type, url: "/" + type.charAt(0).toUpperCase() + type.slice(1),},
            {
              title: type.charAt(0).toUpperCase() + type.slice(1),
              url: lastPath ? lastPath : "/" + type.charAt(0).toUpperCase() + type.slice(1),
            },
          ]}
          currenPageTitle={username+'('+userfirstname+')'}
          // currenPageTitle="UserName"{username}
        />

        <div className="wrapper wrapper-content animated fadeInRight">
          <div className="row">
            <div className="col-lg-12">
              <div className="ibox float-e-margins">
                <div className="ibox-title">
                  <h1>
                    
                    {user_role? (user_role==7?'User Dashboard':'Agent Dashborad'):''}
                    
                  </h1>
                </div>
                <div className="ibox-content">
                  <div className="table-responsive">
                    <p style={{ textAlign: "center" }}>
                      <a
                        href={`/userdetails/${type}/receivecash/${id}/${username}`}
                        onClick={(e) => {
                          e.preventDefault();
                          this.props.navigate(
                            `/userdetails/${type}/receivecash/${id}/${username}`
                          );
                        }}
                        style={{ marginRight: "0.25rem" }}
                      >
                        <button
                          type="button"
                          className="btn btn-primary btn-lg"
                        >
                          Receive Cash
                        </button>
                      </a>
                      <a
                        href={`/userdetails/${type}/paycash/${id}/${username}`}
                        onClick={(e) => {
                          e.preventDefault();
                          this.props.navigate(
                            `/userdetails/${type}/paycash/${id}/${username}`
                          );
                        }}
                        style={{ marginRight: "0.25rem" }}
                      >
                        <button
                          type="button"
                          className="btn btn-primary btn-lg"
                        >
                          Pay Cash
                        </button>
                      </a>
                      <a
                        href={`/userdetails/${type}/ledger/${id}/${username}`}
                        style={{ marginRight: "0.25rem" }}
                        onClick={(e) => {
                          e.preventDefault();
                          this.props.navigate(
                            `/userdetails/${type}/ledger/${id}/${username}`
                          );
                        }}
                      >
                        <button
                          type="button"
                          className="btn btn-primary btn-lg"
                        >
                          Ledger
                        </button>
                      </a>
                      <a
                        href={`/userdetails/${type}/cashledger/${id}/${username}`}
                        style={{ marginRight: "0.25rem" }}
                        onClick={(e) => {
                          e.preventDefault();
                          this.props.navigate(
                            `/userdetails/${type}/cashledger/${id}/${username}`
                          );
                        }}
                      >
                        <button
                          type="button"
                          className="btn btn-primary btn-lg"
                        >
                          Cash Ledger
                        </button>
                      </a>
                      {userData && userData.role!=7?
                      <a
                        href={`/userdetails/${type}/matchledger/${id}/${username}`}
                        style={{ marginRight: "0.25rem" }}
                        onClick={(e) => {
                          e.preventDefault();
                          this.props.navigate(
                            `/userdetails/${type}/matchledger/${id}/${username}`
                          );
                        }}
                      >
                        <button
                          type="button"
                          className="btn btn-primary btn-lg"
                        >
                          Match Ledger
                        </button>
                      </a>:''}

                      {userData && userData.role!=7?
                      <a
                        href={`/userdetails/${type}/directagents/${id}/${username}`}
                        style={{ marginRight: "0.25rem" }}
                        onClick={(e) => {
                          e.preventDefault();
                          this.props.navigate(
                            `/userdetails/${type}/directagents/${id}/${username}`
                          );
                        }}
                      >
                        <button
                          type="button"
                          className="btn btn-primary btn-lg"
                        >
                          Direct Agents
                        </button>
                      </a>:''}

                       {userData && userData.role!=7?
                      <a
                        href={`/userdetails/${type}/directclients/${id}/${username}`}
                        style={{ marginRight: "0.25rem" }}
                        onClick={(e) => {
                          e.preventDefault();
                          this.props.navigate(
                            `/userdetails/${type}/directclients/${id}/${username}`
                          );
                        }}
                      >
                        <button
                          type="button"
                          className="btn btn-primary btn-lg"
                        >
                          Direct Clients
                        </button>
                      </a>
                      :''}
                      <a
                        href={`/userdetails/${type}/coinhistory/${id}/${username}`}
                        style={{ marginRight: "0.25rem" }}
                        onClick={(e) => {
                          e.preventDefault();
                          this.props.navigate(
                            `/userdetails/${type}/coinhistory/${id}/${username}`
                          );
                        }}
                      >
                        <button
                          type="button"
                          className="btn btn-primary btn-lg"
                        >
                          Coin History
                        </button>
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-4">
            <div className="ibox float-e-margins">
              <div className="ibox-title">
                <h5>Coins</h5>
              </div>
              <div className="ibox-content">
                <h1 className="no-margins">
                  {userData && userData.balance ? userData?.balance : "0.00"}
                </h1>
              </div>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="ibox float-e-margins">
              <div className="ibox-title">
                <h5>Ledger Balance</h5>
              </div>
              <div className="ibox-content">
                <h1 className="no-margins">{userData?.ledger_balance}</h1>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
export default UserViewPage;
