import InnerPageHeader from "components/common/InnerPageHeader";
import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
export class Edit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      stateData: props.stateData,
      name: "",
      userName: "",
      password: "",
      groupName: "",
    };
  }
  handleChange = (e) => {
    let label = e.target.name;
    let value = e.target.value;
    if (label === "name") this.setState({ name: value });
    else if (label === "userName") this.setState({ userName: value });
    else if (label === "password") this.setState({ password: value });
    else this.setState({ groupName: value });
  };

  onSubmit(e) {
    e.preventDefault();
    alert("submit from");
    window.history.back();
  }

  render() {
    // console.log(this.props , "props")

    const { name, userName, password, stateData } = this.state;

    return (
      <Fragment>
        <InnerPageHeader
          pageTitle={`${stateData.edit ? "Edit" : "Create"} Staff`}
          breadcrumbs={[
            { title: "Dashboard", url: "/" },
            { title: "Staff List", url: "/staff_list" },
          ]}
          currenPageTitle={`${stateData.edit ? "Edit" : "Create"} Staff`}
        />
        <div className="wrapper wrapper-content animated fadeInRight">
          <div className="row">
            <div className="col-12">
              <div className="ibox">
                <div className="ibox-title">
                  <h5>{`${stateData.edit ? "Edit" : "New"} Staff`}</h5>
                </div>
                <div className="ibox-content">
                  <div className="row">
                    <div className="col-lg-12">
                      <form autoComplete="off">
                        <div className="form-group row">
                          <label className="col-sm-2 col-form-label">
                            User_guid
                          </label>
                          <div className="col-sm-10">
                            <input
                              type="text"
                              name="userName"
                              className="form-control"
                              placeholder="User_guid"
                              value=""
                              autoComplete="off"
                              onChange={(e) => this.handleChange(e)}
                            />
                          </div>
                        </div>
                        <hr />

                        <div className="form-group row">
                          <label className="col-sm-2 col-form-label">
                            First Name
                          </label>
                          <div className="col-sm-10">
                            <input
                              type="text"
                              className="form-control"
                              value={name}
                              autoComplete="off"
                              name="name"
                              placeholder="First NAME"
                              onChange={(e) => this.handleChange(e)}
                            />
                          </div>
                        </div>
                        <hr />

                        <div className="form-group row">
                          <label className="col-sm-2 col-form-label">
                            Password
                          </label>
                          <div className="col-sm-10">
                            <input
                              type="password"
                              className="form-control"
                              value={password}
                              autoComplete="off"
                              name="password"
                              placeholder="PASSWORD"
                              onChange={(e) => this.handleChange(e)}
                            />
                          </div>
                        </div>
                        <hr />
                      </form>
                      <div className="form-group row">
                        <div className="col-sm-4 col-sm-offset-2">
                          <Link
                            className="btn btn-white mr-3"
                            type="button"
                            style={{ marginRight: "1rem" }}
                            to="/staff_list"
                          >
                            Cancel
                          </Link>
                          <button
                            type="button"
                            className="btn btn-primary"
                            onClick={(e) => this.onSubmit(e)}
                          >
                            Save Changes
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default Edit;
