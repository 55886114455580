import React, { useState, useEffect } from "react";
import { useLocation, useParams, Link } from "react-router-dom";
import InnerPageHeader from "components/common/InnerPageHeader";
import { getCommentaryButtons, setEventCommentary } from "_services";
import { useSelector } from "react-redux";
import "./style.css";
import { toast } from "react-toastify";

function Commentary() {
  const location = useLocation();

  const { matchData } = location && location.state ? location.state : {};

  const { event_id } = useParams();

  const [buttonData, setButtonData] = useState([]);

  const userLogin = JSON.parse(localStorage.getItem("userLogin"));

  useEffect(() => {
    getCommentaryButtonData();
  }, []);

  const getCommentaryButtonData = () => {
    try {
      getCommentaryButtons()
        .then((response) => {
          if (response?.data?.data) {
            setButtonData(response.data.data);
          } else {
            setButtonData([]);
          }
        })
        .catch((error) => {
          setButtonData([]);
        });
    } catch (err) { }
  };

  const handleBtnClick = (data) => {
    try {
      if (data) {
        let sendData = {};
        sendData.event_id = event_id;
        sendData.btn_click = data.button_id;
        sendData.btn_color = data.button_color_name;
        sendData.button_label = data.button_label;
        sendData.bet_close_time = data.bet_close_time;
        setEventCommentary(sendData)
          .then((response) => {
            // toast("Success");
            getCommentaryButtonData();
          })
          .catch((error) => { });
      }
    } catch (err) { }
  };

  return (
    <>
      <InnerPageHeader
        pageTitle="Commentary"
        breadcrumbs={[
          { title: "Dashboard", url: "/" },
          { title: "Matches", url: "/matches_list" },
          {
            title: matchData?.event_name,
            url: {
              pathname: `/match_dashboard/${event_id}`,
              state: { matchData },
            },
          },
        ]}
        currenPageTitle="Commentary"
      />
      <div className="row wrapper border-bottom white-bg mt-3 px-2 py-1 commentary_container">
        <div className="col-12">
          <div className="row">
            <div className="text-left col">
              {buttonData && buttonData.length
                ? buttonData.map((btn, index) => (
                  <button
                    key={index}
                    type="button"
                    // className={`${btn.button_class}` + " btn btn-secondary"}
                    className="btn btn-secondary"
                    style={{ backgroundColor: btn.button_color_code }}
                    onClick={() => handleBtnClick(btn)}
                  >
                    {btn.button_label}
                  </button>
                ))
                : null}
            </div>
          </div>
        </div>
      </div>

      <div id="render-sub-company-live-report">
        <div className="wrapper wrapper-content">
          <div className="row" style={{ margin: "0 !important" }}>
            <div className="match-index-row header-top-spacing">
              <div
                className="row"
                style={{ paddingBottom: "30px", margin: "auto auto" }}
              ></div>
            </div>
          </div>

          <div className="wrapper wrapper-content">
            <div className="row">
              <div className="col-lg-12"></div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Commentary;
