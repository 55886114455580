import config from "../lib/app-config";
import { performRequest } from "_apiConfig/apiHandler";
import { default as apiEndPoints } from "_apiConfig/apiEndPoints";

export const createNewManualMarket = (data) => {
  return performRequest(
    apiEndPoints.methodType.POST,
    `${config.api_baseurl}create_manual_market`,
    data
  );
};

export const listManualMarketMatchDashboard = (data) => {
  return performRequest(
    apiEndPoints.methodType.POST,
    `${config.api_baseurl}manual_match_list`,
    data
  );
};

export const listMarket = (data) => {
  return performRequest(
    apiEndPoints.methodType.POST,
    `${config.api_baseurl}market_list`,
    data
  );
};

export const listMatchDetail = (data) => {
  return performRequest(
    apiEndPoints.methodType.POST,
    `${config.api_baseurl}match_detail`,
    data
  );
};


export const createFancy = (data) => {
  return performRequest(
    apiEndPoints.methodType.POST,
    `${config.api_baseurl}create_manual_fancy`,
    data
  );
};

export const listFancyType = (data) => {
  return performRequest(
    apiEndPoints.methodType.POST,
    `${config.api_baseurl}fancy_type_list`,
    data
  );
};

export const createExistManualSession = (data) => {
  return performRequest(
    apiEndPoints.methodType.POST,
    `${config.api_baseurl}update_fancy_market_odds`,
    data
  );
};

export const reset = (data) => {

  return performRequest(
    apiEndPoints.methodType.POST,
    `${config.api_baseurl}reset_fancy_odds`,
    data
  );
};

export const createExistManualMarket = (data) => {
  return performRequest(
    apiEndPoints.methodType.POST,
    `${config.api_baseurl}update_market_odds`,
    data
  );
};

export const resetMarket = (data) => {
  return performRequest(
    apiEndPoints.methodType.POST,
    `${config.api_baseurl}reset_market_odds`,
    data
  );
};




