import React from "react";
import BlockedComponent from "components/Client/Blocked";
import { useLocation } from "react-router-dom";

function Blocked() {
  const location = useLocation();
  return (
    <BlockedComponent
      location={location}
      title="Clients Blocked List"
      role={[9]}
    />
  );
}

export default Blocked;
