import React, { Component } from "react";

import MatkaConfigForm from "../../components/MatkaConfig/MatkaConfigForm";
import { connect } from "react-redux";

class Add extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      
        <MatkaConfigForm
          {...this.props}
          breadcrumbTitle={"Fast Matka Config"}
        />
      
    );
  }
}
const mapstatetop = (state) => ({
  ...state,
});

export const FastMatkaConfig = connect(mapstatetop)(Add);
