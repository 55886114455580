import React, { Component } from 'react'

import ShowSettleComp from 'components/ManageLedger/ShowSettleComp';

export class ShowSettelment extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }
    render() {
        return (
            
                <ShowSettleComp />
            
        )
    }
}

export default ShowSettelment
