import React from "react";
import { connect, useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import AddUser from "components/common/AddUser";
import { createRequest } from "_actions/master/master.actions";
import AddNewUser from "components/AddNewUser/AddNewUser";

function Add(props) {
  const role = 5;
  const navigate = useNavigate();
  const params = useParams();
  const dispatch = useDispatch();

  const createUser = (sendObj) => {
    dispatch(
      createRequest(sendObj, (res) => {
        if (res) {
          navigate("/super-stockists");
        }
      })
    );
  };

  return (
    // <AddUser
    <AddNewUser
      navigationUrl="/super-stockists"
      onSubmitFunc={createUser}
      role={role}
      action={params?.id ? "Edit Super Stockists" : "Create Super Stockists"}
      breadcrumbTitle={
        params?.id ? "Edit Super Stockists" : "New Super Stockists"
      }
      navigate={navigate}
      parentType="superstockist"
      isEdit={Boolean(params?.id)}
      params={params}
      showFixSharing={true}
      {...props}
    />
  );
}
const mapstatetop = (state) => ({
  ...state,
});

export default connect(mapstatetop)(Add);
