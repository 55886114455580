import React, { Component } from "react";
import { connect } from "react-redux";
import SimpleReactValidator from "simple-react-validator";
import InnerPageHeader from "components/common/InnerPageHeader";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  createFastMatkaCategory,
  createMatkaCategory,
} from "_services/matkaservice";
import { toast } from "react-toastify";
import moment from "moment";
const categoryType = [
  { label: "Open", value: 1 },
  { label: "Close", value: 2 },
  { label: "Open to Close", value: 3 },
];
const weekDays = [1, 2, 3, 4, 5, 6, 7];
class MatkaCategoryForm extends Component {
  constructor(props) {
    super(props);
    console.log("this.props?.location.state",this.props?.location.state)
    this.state = {
      formValue: {
        // number: "",
        type: this.props?.location.state?.is_active == 0 ? false : true,
        category:
          categoryType[
            this.props?.location.state?.category_type
              ? this.props?.location.state?.category_type - 1
              : 0
          ]?.value,
        title: this.props?.location.state?.title
          ? this.props?.location.state?.title
          : "",
        week_day: this.props?.location.state?.week_day
          ? this.props?.location.state?.week_day
          : 1,
        open_time: this.props?.location.state?.open_time
          ? new Date(
              ` ${new Date().getFullYear}-08-24 ${
                this.props?.location.state?.open_time
              }`
            )
          : "",
        close_time: this.props?.location.state?.close_time
          ? new Date(
              `${new Date().getFullYear}-08-24 ${
                this.props?.location.state?.close_time
              }`
            )
          : "",
        title_color: this.props?.location.state?.title_color
          ? this.props?.location.state?.title_color
          : "#49418C",
        background_color: this.props?.location.state?.background_color
          ? this.props?.location.state?.background_color
          : "#ffffff",
      },
    };

    this.validator = new SimpleReactValidator();
    this.isFast = this.props?.type === "fast_matka";
    this.pathName = "/" +this.props.history?.location?.pathname.split("/")[1]

  }

  componentDidMount() {
    this.setState({ initialFormValue: this.state.formValue });
  }

  handleInput = (e) => {
    const {
      target: { value, name, type, checked },
    } = e;
    switch (type) {
      case "number":
        if (value.length <= 3) {
          this.setState((s) => ({
            formValue: { ...s.formValue, [name]: value },
          }));
        }
        break;
      case "checkbox":
        this.setState((s) => ({
          formValue: { ...s.formValue, [name]: !this.state?.formValue?.type },
        }));
        break;
      default:
        this.setState((s) => ({
          formValue: { ...s.formValue, [name]: value },
        }));
        break;
    }
  };

  onSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();

    const isValid =
      this.validator.fieldValid("title") &&
      (+this.state.formValue.category === 1
        ? this.validator.fieldValid("open_time")
        : true) &&
      (+this.state.formValue.category === 2
        ? this.validator.fieldValid("close_time")
        : true) &&
      (+this.state.formValue.category === 3
        ? this.validator.fieldValid("open_time") &&
          this.validator.fieldValid("close_time")
        : true);
    if (isValid) {
      this.submit(this.state.formValue);
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  };

  submit = async (data) => {
    const payload = {
      title: data?.title,
      open_time:
        +data?.category === 2
          ? ""
          : moment(new Date(data?.open_time)).format("hh:mm A"),

      category_type: +data?.category,
      week_days: +data?.week_day,
      background_color: data?.background_color,
      title_color: data?.title_color,
      is_active: data?.type === true ? 1 : 0,
    };
    if (this.props?.location.state?.id) {
      payload["id"] = this.props?.location.state?.id;
    }
    if (!this.isFast) {
      payload["close_time"] =
        +data?.category === 1
          ? ""
          : moment(new Date(data?.close_time)).format("hh:mm A");
    }
    try {
      const resp = this.isFast
        ? await createFastMatkaCategory(payload)
        : await createMatkaCategory(payload);
      if (resp?.data?.error === "") {
        this.props.navigate((this.isFast ? "/fast-" : "/")+`matka-category`);
      }
    } catch (error) {
      toast.error(error?.response?.data?.error);
    }
  };

  render() {
    const {
      formValue: {
        type,
        category,
        title,
        week_day,
        open_time,
        close_time,
        title_color,
        background_color,
      },
      formValue,
      initialFormValue,
      isLoading,
    } = this.state;
    const { breadcrumbTitle } = this.props;
    
    return (
      <>
        <InnerPageHeader
          pageTitle={breadcrumbTitle}
          breadcrumbs={[
            { title: "Dashboard", url: "/" },
            {
              title: (this.isFast ? "Fast" : "") + " Matka Category",
              url: (this.isFast ? "/fast-" : "/")+`matka-category`,
            },
          ]}
          currenPageTitle={breadcrumbTitle}
        />
        <div className="wrapper wrapper-content animated fadeInRight">
          <div className="row">
            <div className="col-lg-12">
              <div className="ibox float-e-margins">
                <div className="ibox-title">
                  <h5>{breadcrumbTitle}</h5>
                  <form
                    style={{ margin: "20px 50px" }}
                    onSubmit={this.onSubmit}
                    className="form-horizontal"
                  >
                    <div className="hr-line-dashed"></div>
                    <div className="form-group">
                      <label className="col-sm-3 control-label">Title</label>
                      <div className="col-sm-9">
                        <input
                          type="text"
                          className="form-control"
                          name="title"
                          id="last_name"
                          value={title}
                          onChange={(e) => this.handleInput(e)}
                        />
                        <em>
                          {this.validator.message("title", title, "required")}
                        </em>
                      </div>
                    </div>
                    <div className="hr-line-dashed"></div>
                    <div className="form-group">
                      <label className="col-sm-3 control-label">
                        Category Type
                      </label>
                      <div className="col-sm-9">
                        <select
                          className="form-control"
                          name="category"
                          id="event_type"
                          value={category}
                          onChange={(e) => this.handleInput(e)}
                        >
                          {categoryType
                            .filter((i) => (this.isFast ? i.value === 1 : i))
                            .map((item) => (
                              <option key={item} value={item?.value}>
                                {item?.label}
                              </option>
                            ))}
                        </select>
                        <em>
                          {this.validator.message(
                            "category",
                            category,
                            "required"
                          )}
                        </em>
                      </div>
                    </div>
                    {(+category === 1 || +category === 3) && (
                      <>
                        <div className="hr-line-dashed"></div>
                        <div className="form-group">
                          <label className="col-sm-3 control-label">
                            Open Time
                          </label>
                          <div className="col-sm-9">
                            <DatePicker
                              autoComplete="off"
                              id="datetime"
                              className="form-control"
                              name="open_time"
                              showTimeSelect={false}
                              showTimeSelectOnly
                              popperPlacement="w-front"
                              timeCaption="Time"
                              dateFormat="hh:mm a"
                              selected={open_time}
                              onChange={(e) => {
                                this.setState((s) => ({
                                  formValue: { ...s.formValue, open_time: e },
                                }));
                              }}
                            />
                            <em>
                              {this.validator.message(
                                "open_time",
                                open_time,
                                "required"
                              )}
                            </em>
                          </div>
                        </div>
                      </>
                    )}
                    {(+category === 2 || +category === 3) && (
                      <>
                        <div className="hr-line-dashed"></div>
                        <div className="form-group">
                          <label className="col-sm-3 control-label">
                            Close Time
                          </label>
                          <div className="col-sm-9">
                            <DatePicker
                              autoComplete="off"
                              id="Closetime"
                              className="form-control"
                              name="close_time"
                              showTimeSelect={false}
                              showTimeSelectOnly
                              // minTime={
                              //   new Date().getDate() ===
                              //   new Date(
                              //     open_time ?? new Date().getTime()
                              //   ).getDate()
                              //     ? new Date()
                              //     : new Date().setHours(0, 0, 0)
                              // }
                              // maxTime={new Date().setHours(23, 59, 59)}
                              timeCaption="Time"
                              dateFormat="hh:mm a"
                              selected={close_time}
                              onChange={(e) =>
                                this.setState((s) => ({
                                  formValue: {
                                    ...s.formValue,
                                    close_time: e,
                                  },
                                }))
                              }
                            />
                            {this.validator.message(
                              "close_time",
                              close_time,
                              "required"
                            )}
                          </div>
                        </div>
                      </>
                    )}

                    <div className="hr-line-dashed"></div>
                    <div className="form-group">
                      <label className="col-sm-3 control-label">
                        Week Days
                      </label>
                      <div className="col-sm-9">
                        <select
                          className="form-control"
                          name="week_day"
                          id="event_type"
                          value={week_day}
                          onChange={(e) => this.handleInput(e)}
                        >
                          {weekDays.map((item) => (
                            <option key={item} value={item}>
                              {item}
                            </option>
                          ))}
                        </select>
                        <em>
                          {this.validator.message(
                            "week_day",
                            week_day,
                            "required"
                          )}
                        </em>
                      </div>
                    </div>
                    <div className="hr-line-dashed"></div>
                    <div className="form-group chkbx_container">
                      <div className="col-sm-3 "></div>
                      <div className="col-sm-2">
                        <label className="control-label">Active</label>
                        <input
                          type="checkbox"
                          name="type"
                          id="type"
                          checked={type}
                          onChange={(e) => this.handleInput(e)}
                        />
                        <em>
                          {this.validator.message("type", type, "required")}
                        </em>
                      </div>
                      <div className="col-sm-3">
                        <div className="matka_color_input">
                          <label for="background_color">Background Color</label>
                          <div
                            style={{
                              position: "relative",
                              display: "inline-block",
                            }}
                          >
                            <button
                              style={{ backgroundColor: background_color }}
                              type="button"
                              className="matka_color_picker"
                            ></button>
                            <input
                              type="color"
                              id="background_color"
                              name="background_color"
                              value={background_color}
                              onChange={(e) => this.handleInput(e)}
                              style={{
                                opacity: 0,
                                position: "absolute",
                                left: 0,
                                top: 0,
                                width: "100%",
                                cursor: "pointer",
                              }}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-4">
                        <div className="matka_color_input">
                          <label for="title_color">Title Color</label>
                          <div
                            style={{
                              position: "relative",
                              display: "inline-block",
                            }}
                          >
                            <button
                              style={{ backgroundColor: title_color }}
                              type="button"
                              className="matka_color_picker"
                            ></button>
                            <input
                              type="color"
                              id="title_color"
                              name="title_color"
                              value={title_color}
                              onChange={(e) => this.handleInput(e)}
                              style={{
                                opacity: 0,
                                position: "absolute",
                                left: 0,
                                top: 0,
                                width: "100%",
                                cursor: "pointer",
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="form-group">
                      <div className="matka_form_submit">
                        <button
                          className="btn btn-white mr-3"
                          type="button"
                          style={{ marginRight: "1rem" }}
                          onClick={() => this.props.navigate(-1)}
                        >
                          Cancel
                        </button>
                        <button
                          className="btn btn-primary"
                          type="submit"
                          disabled={
                            JSON.stringify(formValue) ===
                              JSON.stringify(initialFormValue) || isLoading
                          }
                        >
                          Save Changes
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
const mapstatetop = (state) => ({
  ...state,
});

export default connect(mapstatetop)(MatkaCategoryForm);
