import React, { Component } from "react";
import { connect } from "react-redux";
import MaintenanceComponent from "./MaintenanceComponent";

class MaintanenceSetting extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return <MaintenanceComponent {...this.props} />;
  }
}
const mapstatetop = (state) => ({
  ...state,
});

export default connect(mapstatetop)(MaintanenceSetting);
