import React, { Component, Fragment } from "react";
import Select from "react-select";
import "./ManageLedger.css";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";
import moment from "moment";
import Pagination from "../common/Pagination";
import { getSportList } from "_services/sports.services";
import { profitLossReport, profitLossReportNew } from "_services/league.services";
import { alltime_pl } from "_services/profitloss.services";
import { Link } from "react-router-dom";
import { formatDateAndTime } from "utils";
import ReactPaginate from "react-paginate";
import { convertParseFloat } from "_helpers";

export class EarningReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      from_date: null,
      to_date: null,
      selectedSport: "",
      selectedSportOptions: [
        { label: "cricket", value: 0 },
        { label: "football", value: 1 },
        { label: "soccer", value: 2 },
      ],
      newSelectedSport: [],
      totalRecords: 0,
      perPage: 50,
      sportsData: [],
      earningProfitLossData: [],
      all_time: {},
      records_all: "",
      currentlyPage: 1,
      allTimeTotal: null,
    };
  }
  handleCallback = (from_date, to_date) => {
    this.setState({ from_date, to_date, currentlyPage: 1 }, () => {
      this.earningReportPL();
      this.get_all_time_pl();
    });
  };

  handleChange = (selectedSport) => {
    this.setState({ selectedSport }, () => {
      this.earningReportPL();
      this.get_all_time_pl();
      if (
        selectedSport !== "" &&
        selectedSport !== null &&
        selectedSport.length > 0
      ) {
        let newSelectedSport = [...selectedSport];
        this.setState({ newSelectedSport });
      } else {
        if (selectedSport == null) {
          this.setState({ newSelectedSport: [] });
        }
      }
    });
  };

  componentDidMount() {
    this.fetchAllSports();
    this.setState({
      from_date: null,
      to_date: null,
    });
    this.earningReportPL({});
    this.get_all_time_pl({});
  }

  get_all_time_pl = () => {
    const payload = {
      sports_id:
        this?.state?.selectedSport && this?.state?.selectedSport?.length
          ? this.state.selectedSport.map((item) => item.value)
          : [],
    };
    if (this.state.from_date && this.state.to_date) {
      payload["from_date"] = moment(
        moment.utc(this.state.from_date).local().format("YYYY-MM-DD 00:00:00"),
        "YYYY-MM-DD HH:mm:ss"
      )
        .utc()
        .format("YYYY-MM-DD HH:mm:ss");
      payload["to_date"] = moment(
        moment.utc(this.state.to_date).local().format("YYYY-MM-DD 23:59:00"),
        "YYYY-MM-DD HH:mm:ss"
      )
        .utc()
        .format("YYYY-MM-DD HH:mm:ss");
    }
    alltime_pl(payload)
      .then((response) => {
        if (response) {
          this.setState({ all_time: response.data.data.pl });
          if (this.state.from_date == null) {
            this.setState({ allTimeTotal: response.data.data.pl.pl_tot });
          }
        } else {
        }
      })
      .catch((error) => { });
  };

  fetchAllSports = () => {
    getSportList()
      .then((response) => {
        if (
          response &&
          response.data &&
          response.data.data &&
          response.data.data.length
        ) {
          let allSports = [];
          this.setState({ sportsData: response.data.data }, () => {
            this.state.sportsData.map((x) => {
              allSports.push({ label: x.sports_name, value: x.sport_id });
            });
            this.setState({ selectedSportOptions: allSports });
          });
        } else {
          this.setState({ sportsData: [] });
        }
      })
      .catch((error) => {
        this.setState({ sportsData: [] });
      });
  };

  earningReportPL = () => {
    const payload = {
      status: 1,
      current_page: this.state.currentlyPage,
      sports_id:
        this?.state?.selectedSport && this?.state?.selectedSport?.length
          ? this.state.selectedSport.map((item) => item.value)
          : [],
    };
    if (this.state.from_date && this.state.to_date) {
      payload["from_date"] = moment(
        moment.utc(this.state.from_date).local().format("YYYY-MM-DD 00:00:00"),
        "YYYY-MM-DD HH:mm:ss"
      )
        .utc()
        .format("YYYY-MM-DD HH:mm:ss");
      payload["to_date"] = moment(
        moment.utc(this.state.to_date).local().format("YYYY-MM-DD 23:59:00"),
        "YYYY-MM-DD HH:mm:ss"
      )
        .utc()
        .format("YYYY-MM-DD HH:mm:ss");
    }

    // profitLossReport(payload)
    profitLossReportNew(payload)
      .then((response) => {
        this.setState({
          earningProfitLossData: response?.data?.data?.pl_report,
          totalRecords: response?.data?.data?.total,
        });
        this.setState({
          records_all: Math.ceil(
            response?.data?.data?.total / this.state.perPage
          ),
        });
      })
      .catch((error) => {
        this.setState({ earningProfitLossData: [] });
      });
  };

  getRecordDetails = () => {
    const { currentlyPage, totalRecords, perPage, earningProfitLossData } =
      this.state;
    let startingCount =
      currentlyPage === 1 ? 1 : (currentlyPage - 1) * perPage + 1;
    let endingCount =
      totalRecords < perPage
        ? totalRecords
        : earningProfitLossData.length < perPage
          ? totalRecords
          : perPage * currentlyPage;

    return `Showing ${startingCount} to ${endingCount} of ${totalRecords} entries`;
  };

  handlePageClick = (e) => {
    const selectedPage = e.selected;
    this.setState({ currentlyPage: selectedPage + 1 }, () => {
      this.earningReportPL();
    });
  };
  resetClick = () => {
    this.setState(
      {
        currentlyPage: 1,
        from_date: null,
        to_date: null,
      },
      () => {
        this.earningReportPL();
        this.get_all_time_pl();
      }
    );
  };

  render() {
    const {
      all_time,
      selectedSport,
      selectedSportOptions,
      newSelectedSport,
      to_date,
      from_date,
      totalRecords,
      earningProfitLossData,
    } = this.state;
    return (
      <Fragment>
        <div className="row wrapper border-bottom white-bg page-heading">
          <div className="col-lg-10">
            <h2>Earning Report</h2>
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/">Dashboard</Link>
              </li>
              <li className="breadcrumb-item">
                <a>{JSON.parse(localStorage.getItem("profile"))?.username}</a>
              </li>
              <li className="breadcrumb-item active">
                <strong>Profit & Loss </strong>
              </li>
            </ol>
          </div>
          <div className="col-lg-2"></div>
        </div>
        <div className="row">
          <div className="col-lg-3 col-md-4 col-sm-12">
            <Select
              options={selectedSportOptions}
              onChange={this.handleChange}
              isMulti
              value={selectedSport ? selectedSport : ""}
              className="reactSelect"
              placeholder=""
            />
          </div>
          <DateRangePicker
            initialSettings={{
              startDate: from_date
                ? from_date.toDate()
                : moment().subtract(6, "days"),
              endDate: to_date ? to_date.toDate() : moment(),
              ranges: {
                Today: [moment().toDate(), moment().toDate()],
                Yesterday: [
                  moment().subtract(1, "days").toDate(),
                  moment().subtract(1, "days").toDate(),
                ],
                "Last 7 Days": [
                  moment().subtract(6, "days").toDate(),
                  moment().toDate(),
                ],
                "This Week": [
                  moment().startOf("isoWeek").toDate(),
                  moment().toDate(),
                ],
                "Last Week": [
                  moment().subtract(1, "week").startOf("isoWeek").toDate(),
                  moment().subtract(1, "week").endOf("isoWeek").toDate(),
                ],
                "Last 30 Days": [
                  moment().subtract(29, "days").toDate(),
                  moment().toDate(),
                ],
                "This Month": [
                  moment().startOf("month").toDate(),
                  moment().toDate(),
                ],
                "Last Month": [
                  moment().subtract(1, "month").startOf("month").toDate(),
                  moment().subtract(1, "month").endOf("month").toDate(),
                ],
              },
            }}
            onCallback={this.handleCallback}
          >
            <div
              id="reportrange"
              style={{ padding: 10 }}
              className="col-lg-3 col-md-3 col-sm-9 mlDatePicker"
            >
              <span
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  padding: 0,
                }}
              >
                <i
                  className={"glyphicon glyphicon-calendar  fa fa-calendar"}
                ></i>
                &nbsp;&nbsp;
                <span className="text-right">
                  {from_date && to_date
                    ? `${from_date.format("DD MMM, YYYY")} - ${to_date.format(
                      "DD MMM, YYYY"
                    )}`
                    : "-"}
                </span>
                &nbsp;&nbsp;
                <i className="fa fa-caret-down" aria-hidden="true"></i>
              </span>
            </div>
          </DateRangePicker>
          <div style={{ display: "flex", flexDirection: "row", marginTop: 10 }}>
            <div
              style={{
                marginleft: "10px",
                text: "center",
                display: "flex",
                marginRight: 20,
                marginLeft: 20,
              }}
            >
              <a
                className="btn btn-primary btn-xs search_date_btn text-center"
                onClick={() => this.resetClick()}
              >
                {" "}
                Reset{" "}
              </a>
            </div>
            <div
              style={{
                marginleft: "10px",
                text: "center",
                display: "flex",
                maxWidth: 150,
              }}
            >
              <a className="btn btn-primary btn-xs search_date_btn text-center">
                {" "}
                Search{" "}
              </a>
            </div>
          </div>
          {/* <div
           style={{  
                marginleft: "10px",
                text: "center",
                display: "flex",
              }}
          >
            <a className="btn btn-primary btn-xs search_date_btn text-center">Search</a>
          </div> */}
        </div>
        <div className="wrapper wrapper-content animated fadeInRight">
          <div className="row">
            <div className="col-12">
              <div className="ibox">
                <div className="ibox-title">
                  <h5>Summary</h5>
                </div>
                <div className="ibox-content">
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="table-responsive">
                        <table className="table table-striped table-bordered table-hover dataTables-example">
                          <thead>
                            <tr>
                              <th style={{ width: "65%" }}>All Time Total</th>
                              <th>{this.state.allTimeTotal}</th>
                            </tr>
                            <tr>
                              <th>{`Selected ${newSelectedSport.map(
                                (sportName) => {
                                  return sportName.label;
                                }
                              )} Total`}</th>
                              <th>{all_time?.pl_tot | "-"}</th>
                            </tr>
                          </thead>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12">
              <div className="ibox">
                <div className="ibox-title">
                  <h5>Earning Report</h5>
                  <div className="ibox-tools">
                    <a
                      className="dropdown-toggle"
                      data-toggle="dropdown"
                      href="#"
                      aria-expanded="true"
                    >
                      <i className="fa fa-wrench"></i>
                    </a>
                    <ul className="dropdown-menu dropdown-user">
                      <li>
                        <a
                          target="_blank"
                          href="/agent/agents/3116/ledger_show_pdf.pdf"
                        >
                          Export PDF
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="ibox-content">
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="table-responsive">
                        <table className="table table-striped table-bordered table-hover dataTables-example">
                          <thead>
                            <tr>
                              <th>DATE/TIME</th>
                              <th>Match Id</th>
                              <th>Match Title</th>
                              <th>Match Earnings</th>
                              <th>Commision Earnings</th>
                              {/* <th>Charges</th> */}
                              <th>Total Earnings</th>
                              <th>Info</th>
                            </tr>
                          </thead>
                          <tbody>
                            {earningProfitLossData?.map((el, ind) => {
                              return (
                                <tr key={ind}>
                                  <td>
                                    {el.created_at
                                      ? formatDateAndTime(
                                        "MMM DD, hh:mm:ss A",
                                        el.created_at
                                      )
                                      : "-"}
                                  </td>
                                  <td>{el.event_id}</td>
                                  <td>{el.event_name}</td>
                                  {/* <td>{convertParseFloat(el.match_earning)}</td> */}
                                  <td>{convertParseFloat(el.pl_earning)}</td>
                                  {/* <td>{convertParseFloat(el.comm_earning)}</td> */}
                                  <td>{Number(el.my_s_commission) + Number(el.my_m_commission)}</td>
                                  {/* <td>{convertParseFloat(el.other_charges)}</td> */}
                                  {/* <td>{convertParseFloat(el.total_earning)}</td> */}
                                  <td>{(Number(el.pl_earning) + Number(el.my_s_commission) + Number(el.my_m_commission)).toFixed(2)}</td>
                                  <td className="center" style={{ overflow: 'visible' }} >
                                    <div className="user-options" style={{ width: 'initial' }}>
                                      {el.sport_id != 6 ?
                                        <React.Fragment>
                                          {/* <Link to={`/profit-loss-summery-event/${el.event_id}/${el.user_id}/${el.event_name}`}><div className="opt-items" data-tooltip="Profit Loss Summery">
                                            <span>S</span>
                                          </div>
                                          </Link> */}
                                          <Link to={`/profit-loss-detail/${el.event_id}/${el.event_name}`}>
                                            <div className="opt-items" data-tooltip="Profit Loss Detail">
                                              <span>D</span>
                                            </div>
                                          </Link>
                                        </React.Fragment>
                                        :
                                        <div className="opt-items" data-tooltip="Bets">
                                          <Link to={``}><span>Bets</span></Link>
                                        </div>
                                      }
                                    </div>

                                  </td>
                                </tr>
                              );
                            })}

                            <tr>
                              <th>Total</th>
                              <th> - </th>
                              <th> - </th>
                              <th>
                                {Math.round(
                                  earningProfitLossData?.reduce(
                                    (a, v) => (a = a + v.pl_earning),
                                    0
                                  ) * 100
                                ) / 100}
                              </th>
                              <th>
                                {Math.round(
                                  earningProfitLossData?.reduce(
                                    (a, v) => (a = a + v.my_s_commission),
                                    0
                                  ) * 100
                                ) / 100}
                              </th>
                              {/* <th>
                                {Math.round(
                                  earningProfitLossData?.reduce(
                                    (a, v) => (a = a + v.other_charges),
                                    0
                                  ) * 100
                                ) / 100}
                              </th> */}
                              <th>
                                {Math.round(
                                  earningProfitLossData?.reduce(
                                    (a, v) => (a = a + v.pl_earning + v.my_s_commission),
                                    0
                                  ) * 100
                                ) / 100}
                              </th>
                              <th></th>
                            </tr>
                          </tbody>
                        </table>
                        <div className="row" style={{ margin: "15px 0px" }}>
                          <div className="col-xs-12 col-sm-6">
                            <div
                              className="dataTables_info"
                              id="DataTables_Table_0_info"
                              role="status"
                              aria-live="polite"
                            >
                              {totalRecords
                                ? this.getRecordDetails()
                                : "Showing 0 to 0 of 0 entries"}
                            </div>
                          </div>
                          <div className="col-xs-12 col-sm-6">
                            <div className="paginate_class">
                              <ReactPaginate
                                previousLabel={"prev"}
                                nextLabel={"next"}
                                breakLabel={"..."}
                                breakClassName={"break-me"}
                                pageCount={this.state.records_all}
                                marginPagesDisplayed={2}
                                pageRangeDisplayed={5}
                                onPageChange={this.handlePageClick}
                                containerClassName={"pagination"}
                                subContainerClassName={"pages pagination"}
                                activeClassName={"active"}
                              />
                            </div>
                          </div>
                          <div className="clearfix visible-xs"></div>
                        </div>
                      </div>
                    </div>
                    <div className="col-xs-12 col-sm-6"></div>

                    <div className="col-lg-12">
                      <div className="table-responsive">
                        <table className="table table-bordered dataTables-example">
                          <thead>
                            <tr>
                              <th>DATE/TIME</th>
                              <th>Match Id</th>
                              <th>Match Title</th>
                              <th>Match Earnings</th>
                              <th>Commision Earnings</th>
                              <th>Other Charges</th>
                              <th>Total Earnings</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              {/* <th colSpan="3" className="text-center">All Page Total</th> */}
                              <th>All Page Total</th>
                              <th>-</th>
                              <th>-</th>
                              <th>{all_time.match_earning}</th>
                              <th>{all_time.tot_comm}</th>
                              <th>{all_time.other_tot}</th>
                              <th>{all_time.pl_tot}</th>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default EarningReport;
