import React, { Component } from 'react'

import ListComponent from 'components/Staff/List';
export class List extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }
    render() {
        return (
            
                <ListComponent

                />
            

        )
    }
}

export default List