import React, { useState, useEffect, Fragment } from "react";
import Select from "react-select";
import "../../components/ManageLedger/ManageLedger.css";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";
import moment from "moment";
import { getSportList } from "_services/sports.services";
import { profitLossReport } from "_services/league.services";
import { alltime_pl } from "_services/profitloss.services";
import { Link, useParams } from "react-router-dom";
import { formatDateAndTime } from "utils";
import ReactPaginate from "react-paginate";
import { convertParseFloat } from "_helpers";

const PlReport = () => {
    const { user_id } = useParams()
    const [state, setState] = useState({
        from_date: null,
        to_date: null,
        selectedSport: "",
        selectedSportOptions: [
            { label: "cricket", value: 0 },
            { label: "football", value: 1 },
            { label: "soccer", value: 2 },
        ],
        newSelectedSport: [],
        totalRecords: 0,
        perPage: 50,
        sportsData: [],
        earningProfitLossData: [],
        all_time: {},
        records_all: "",
        currentlyPage: 1,
        allTimeTotal: null,
    });

    const handleCallback = (from_date, to_date) => {
        setState((prevState) => ({
            ...prevState,
            from_date,
            to_date,
            currentlyPage: 1,
        }));
        earningReportPL();
    };

    const handleChange = (selectedSport) => {
        setState((prevState) => ({
            ...prevState,
            selectedSport,
        }));
        earningReportPL();

        if (selectedSport && selectedSport.length > 0) {
            let newSelectedSport = [...selectedSport];
            setState((prevState) => ({ ...prevState, newSelectedSport }));
        } else {
            if (selectedSport == null) {
                setState((prevState) => ({ ...prevState, newSelectedSport: [] }));
            }
        }
    };

    useEffect(() => {
        fetchAllSports();
        setState((prevState) => ({
            ...prevState,
            from_date: null,
            to_date: null,
        }));
        earningReportPL({});
    }, []);

    const fetchAllSports = () => {
        getSportList()
            .then((response) => {
                if (
                    response &&
                    response.data &&
                    response.data.data &&
                    response.data.data.length
                ) {
                    let allSports = [];
                    setState((prevState) => ({
                        ...prevState,
                        sportsData: response.data.data,
                    }));
                    state.sportsData.forEach((x) => {
                        allSports.push({ label: x.sports_name, value: x.sport_id });
                    });
                    setState((prevState) => ({
                        ...prevState,
                        selectedSportOptions: allSports,
                    }));
                } else {
                    setState((prevState) => ({ ...prevState, sportsData: [] }));
                }
            })
            .catch(() => {
                setState((prevState) => ({ ...prevState, sportsData: [] }));
            });
    };

    const earningReportPL = () => {
        const payload = {
            user_id,
            status: 1,
            current_page: state.currentlyPage,
            sports_id:
                state?.selectedSport?.length
                    ? state.selectedSport.map((item) => item.value)
                    : [],
        };

        if (state.from_date && state.to_date) {
            payload["from_date"] = moment(
                moment.utc(state.from_date).local().format("YYYY-MM-DD 00:00:00"),
                "YYYY-MM-DD HH:mm:ss"
            )
                .utc()
                .format("YYYY-MM-DD HH:mm:ss");

            payload["to_date"] = moment(
                moment.utc(state.to_date).local().format("YYYY-MM-DD 23:59:00"),
                "YYYY-MM-DD HH:mm:ss"
            )
                .utc()
                .format("YYYY-MM-DD HH:mm:ss");
        }

        profitLossReport(payload)
            .then((response) => {
                setState((prevState) => ({
                    ...prevState,
                    earningProfitLossData: response?.data?.data?.pl_report,
                    totalRecords: response?.data?.data?.total,
                }));
                setState((prevState) => ({
                    ...prevState,
                    records_all: Math.ceil(response?.data?.data?.total / state.perPage),
                }));
            })
            .catch(() => {
                setState((prevState) => ({
                    ...prevState,
                    earningProfitLossData: [],
                }));
            });
    };

    const getRecordDetails = () => {
        const { currentlyPage, totalRecords, perPage, earningProfitLossData } = state;
        let startingCount =
            currentlyPage === 1 ? 1 : (currentlyPage - 1) * perPage + 1;
        let endingCount =
            totalRecords < perPage
                ? totalRecords
                : earningProfitLossData.length < perPage
                    ? totalRecords
                    : perPage * currentlyPage;

        return `Showing ${startingCount} to ${endingCount} of ${totalRecords} entries`;
    };

    const handlePageClick = (e) => {
        const selectedPage = e.selected;
        setState((prevState) => ({
            ...prevState,
            currentlyPage: selectedPage + 1,
        }));
        earningReportPL();
    };

    const resetClick = () => {
        setState((prevState) => ({
            ...prevState,
            currentlyPage: 1,
            from_date: null,
            to_date: null,
        }));
        earningReportPL();
    };

    const {
        all_time,
        selectedSport,
        selectedSportOptions,
        newSelectedSport,
        to_date,
        from_date,
        totalRecords,
        earningProfitLossData,
    } = state;

    return (
        <Fragment>
            <div className="row wrapper border-bottom white-bg page-heading">
                <div className="col-lg-10">
                    <h2>Earning Report</h2>
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item">
                            <Link to="/">Dashboard</Link>
                        </li>
                        <li className="breadcrumb-item">
                            <a>{JSON.parse(localStorage.getItem("profile"))?.username}</a>
                        </li>
                        <li className="breadcrumb-item active">
                            <strong>Profit & Loss</strong>
                        </li>
                    </ol>
                </div>
                <div className="col-lg-2"></div>
            </div>

            <div className="row">
                <div className="col-lg-3 col-md-4 col-sm-12">
                    <Select
                        options={selectedSportOptions}
                        onChange={handleChange}
                        isMulti
                        value={selectedSport ? selectedSport : ""}
                        className="reactSelect"
                        placeholder=""
                    />
                </div>

                <DateRangePicker
                    initialSettings={{
                        startDate: from_date
                            ? from_date.toDate()
                            : moment().subtract(6, "days"),
                        endDate: to_date ? to_date.toDate() : moment(),
                        ranges: {
                            Today: [moment().toDate(), moment().toDate()],
                            Yesterday: [
                                moment().subtract(1, "days").toDate(),
                                moment().subtract(1, "days").toDate(),
                            ],
                            "Last 7 Days": [
                                moment().subtract(6, "days").toDate(),
                                moment().toDate(),
                            ],
                            "This Week": [
                                moment().startOf("isoWeek").toDate(),
                                moment().toDate(),
                            ],
                            "Last Week": [
                                moment().subtract(1, "week").startOf("isoWeek").toDate(),
                                moment().subtract(1, "week").endOf("isoWeek").toDate(),
                            ],
                            "Last 30 Days": [
                                moment().subtract(29, "days").toDate(),
                                moment().toDate(),
                            ],
                            "This Month": [
                                moment().startOf("month").toDate(),
                                moment().toDate(),
                            ],
                            "Last Month": [
                                moment().subtract(1, "month").startOf("month").toDate(),
                                moment().subtract(1, "month").endOf("month").toDate(),
                            ],
                        },
                    }}
                    onCallback={handleCallback}
                >
                    <div
                        id="reportrange"
                        style={{ padding: 10 }}
                        className="col-lg-3 col-md-3 col-sm-9 mlDatePicker"
                    >
                        <span
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "center",
                                padding: 0,
                            }}
                        >
                            <i className={"glyphicon glyphicon-calendar  fa fa-calendar"}></i>
                            &nbsp;&nbsp;
                            <span className="text-right">
                                {from_date && to_date
                                    ? `${from_date.format("DD MMM, YYYY")} - ${to_date.format(
                                        "DD MMM, YYYY"
                                    )}`
                                    : "-"}
                            </span>
                            &nbsp;&nbsp;
                            <i className="fa fa-caret-down" aria-hidden="true"></i>
                        </span>
                    </div>
                </DateRangePicker>

                <div style={{ display: "flex", flexDirection: "row", marginTop: 10 }}>
                    <div
                        style={{
                            marginTop: "auto",
                            marginBottom: "auto",
                        }}
                    >
                        <button
                            type="button"
                            className="btn btn-primary"
                            onClick={() => earningReportPL()}
                        >
                            Filter
                        </button>
                    </div>
                    <div
                        style={{
                            marginLeft: 10,
                            marginTop: "auto",
                            marginBottom: "auto",
                        }}
                    >
                        <button
                            type="button"
                            className="btn btn-danger"
                            onClick={() => resetClick()}
                        >
                            Reset
                        </button>
                    </div>
                </div>
            </div>

            <div className="ibox">
                <div className="ibox-content">
                    <h4>Profit & Loss Report</h4>

                    <table className="table table-striped table-hover">
                        <thead>
                            <tr>
                                <th>DATE/TIME</th>
                                <th>Match Id</th>
                                <th>Match Title</th>
                                <th>Match Earnings</th>
                                <th>Commision Earnings</th>
                                <th>Total Earnings</th>
                            </tr>
                        </thead>
                        <tbody>
                            {earningProfitLossData.length > 0 &&
                                earningProfitLossData.map((item, index) => (
                                    <tr key={index}>
                                        <td> {item.date ? formatDateAndTime("MMM DD, hh:mm:ss A", item.date) : "-"}</td>
                                        <td>{item.event_id}</td>
                                        <td>{item.event_name}</td>
                                        <td>{convertParseFloat(item.match_earning)}</td>
                                        <td>{convertParseFloat(item.comm_earning)}</td>
                                        <td>{convertParseFloat(item.total_earning)}</td>
                                    </tr>
                                ))}
                        </tbody>
                    </table>

                    {totalRecords > state.perPage && (
                        <div>
                            <ReactPaginate
                                pageCount={state.records_all}
                                marginPagesDisplayed={1}
                                pageRangeDisplayed={2}
                                previousLabel={"<"}
                                nextLabel={">"}
                                onPageChange={handlePageClick}
                                containerClassName={"pagination"}
                                subContainerClassName={"pages pagination"}
                                activeClassName={"active"}
                                breakClassName={"page-item"}
                                breakLinkClassName={"page-link"}
                                pageClassName={"page-item"}
                                pageLinkClassName={"page-link"}
                                nextClassName={"page-item"}
                                previousClassName={"page-item"}
                                nextLinkClassName={"page-link"}
                                previousLinkClassName={"page-link"}
                            />
                        </div>
                    )}
                </div>
            </div>
        </Fragment>
    );
};

export default PlReport;
