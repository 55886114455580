import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { coinsReport } from "../../_services/casino.services";
import ReactPaginate from "react-paginate";

import moment from "moment";
import InnerPageHeader from "components/common/InnerPageHeader";
import DateRangePickerComponent from "components/common/DateRangePickerComponent";
import { useParams } from "react-router-dom";

const ChipStatement = () => {
    const { user_guid } = useParams();

    const [changePage, setChangePage] = useState(1);
    const [coins, setCoins] = useState([]);
    const [perPage] = useState(50);
    const [pageCount, setPageCount] = useState(0);
    const [fromDate, setFromDate] = useState(
        moment().subtract(15, 'days').startOf('day').toDate() // Default to 15 days ago
    );
    const [toDate, setToDate] = useState(
        moment().endOf('day').toDate() // Default to today
    );

    useEffect(() => {
        coinHistoryReport();
    }, [changePage]);

    useEffect(() => {
        coinHistoryReport()
    }, [fromDate])

    const coinHistoryReport = () => {
        let sendObj = {
            current_page: changePage,
            items_perpage: perPage,
            user_guid,
            report_type: 2,
            from_date: moment(fromDate).utc().format("YYYY-MM-DD HH:mm:ss"),
            to_date: moment(toDate).utc().format("YYYY-MM-DD HH:mm:ss")
        };

        coinsReport(sendObj)
            .then((response) => {
                if (response && response?.data?.data) {
                    setCoins(response?.data?.data?.coin_report);

                    // setPageCount(Math.ceil(response?.data?.data?.coin_report.length / perPage))

                    setPageCount(Math.ceil(response?.data?.data?.total / perPage));
                }
            })
            .catch((error) => {
                console.log(error, "error");
            });
    };

    const handlePageClick = (e) => {
        const selectedPage = e.selected;
        setChangePage(selectedPage + 1);
    };

    const handleEvent = (from_date, to_date) => {
        if (from_date && to_date) {
            setFromDate(from_date._d);
            setToDate(to_date._d);
        }
    }

    return (
        <>
            <InnerPageHeader
                pageTitle={'Chip Statement'}
                breadcrumbs={[
                    { title: "Dashboard", url: "/" },
                    // { title: type, url: `/userview/${type}/${id}/${username}` },
                ]}
                currenPageTitle="Chip Statement"
            />


            <div className="wrapper wrapper-content animated fadeInRight">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="ibox float-e-margins">
                            <div className="ibox-title">
                                <h5> {'Chip Statement'} </h5>
                            </div>
                            <div className="ibox-content">
                                <div className="row">
                                    <DateRangePickerComponent handleEvent={(from_date, to_date) => handleEvent(from_date, to_date)} fDate={fromDate} tDate={toDate} />
                                </div>
                                <br />


                                <div className="table-responsive">
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                {/* <th>Changelog ID</th> */}
                                                <th style={{ textAlign: 'left' }}>Created At</th>
                                                <th>Description</th>
                                                <th>Coins</th>
                                                {/* <th>Fix Limit</th> */}
                                                <th> User Balance </th>
                                                {/* <th>Match Commission</th>
                        <th>Session Commission</th> */}

                                            </tr>
                                        </thead>
                                        <tbody>
                                            {coins &&
                                                coins.map((element, index) => {
                                                    return (
                                                        <tr key={index}>
                                                            {/* <td>{element.order_no}</td> */}
                                                            <td>
                                                                {moment
                                                                    .utc(element.created_at)
                                                                    .local()
                                                                    .format("YYYY-MM-DD HH:mm a")}
                                                            </td>
                                                            <td style={{ whiteSpace: 'initial' }}>{element.remark}</td>
                                                            <td>
                                                                {element.credit_amount != 0
                                                                    ? element.credit_amount
                                                                    : element.debit_amount}
                                                            </td>
                                                            {/* <td>{element.fixed_limit}</td> */}
                                                            <td> {element.user_balance} </td>
                                                            {/* <td>{element.m_commission}</td>
                              <td>{element.s_commission}</td> */}

                                                        </tr>
                                                    );
                                                })}
                                        </tbody>
                                    </table>
                                </div>

                                <div className="paginate_class">
                                    <ReactPaginate
                                        previousLabel={"prev"}
                                        nextLabel={"next"}
                                        breakLabel={"..."}
                                        breakClassName={"break-me"}
                                        pageCount={pageCount}
                                        marginPagesDisplayed={2}
                                        pageRangeDisplayed={5}
                                        onPageChange={handlePageClick}
                                        containerClassName={"pagination"}
                                        subContainerClassName={"pages pagination"}
                                        activeClassName={"active"}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ChipStatement