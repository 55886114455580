/* eslint-disable */
import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";

const UpdateBalanceModal = (props) => {
  const [amount, setAmount] = useState("");
  const [remark, setRemark] = useState("");

  const {
    title,
    isShow,
    modalToggle,
    parentBalance,
    onSubmit,
    cancleText = "Back",
    loading = false,
  } = props;


  return (
    <div>
      {isShow && (
        <Modal
          centered
          show={isShow}
          onHide={() => modalToggle(false)}
          style={{ opacity: 1 }}
          backdrop={false}
        >
          <Modal.Header >{title}</Modal.Header>
          <Modal.Body>
            <div className=" gap-2">
              <div>
                <h5>
                  <b>{`${isShow?.username} ${isShow?.first_name ? '(' + isShow.first_name + ')' : ""}`}</b>
                </h5>
              </div>
              <div className="d-flex balance_box">
                <div className="anvGreenBtn">
                  Current Balance:{" "}
                  {amount
                    ? +isShow?.balance +
                    (title === "Withdraw" ? -amount : +amount)
                    : isShow?.balance}
                </div>
                {/* <div className="anvRedBtn">
                  Parent Balance:{" "}
                  {parentBalance
                    ? parentBalance + (title === "Withdraw" ? +amount : -amount)
                    : parentBalance}
                </div> */}
              </div>
              <div style={{ width: "100%" }}>
                <label>Amount</label>
                <input
                  className="form-control input-sm"
                  // aria-controls="DataTables_Table_0"
                  name="amount"
                  type="number"
                  value={amount}
                  placeholder={"Enter Amount"}
                  // onChange={(e) => { if (title === "Withdraw") { setAmount(e.target.value) } else { (amount < parentBalance) && setAmount(e.target.value) } }}
                  onChange={(e) => { setAmount(e.target.value) }}
                // onChange={(e) =>
                //   amount < parentBalance && setAmount(e.target.value)
                // }
                />
              </div>
              <div style={{ width: "100%" }}>
                <label>Remark</label>
                <input
                  className="form-control input-sm"
                  name="remark"
                  type="text"
                  value={remark}
                  placeholder={"Remark"}
                  onChange={(e) => { setRemark(e.target.value) }}
                />
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="outline-danger" onClick={() => modalToggle(false)}>
              {cancleText}
            </Button>
            <Button disabled={!(amount > 0)} onClick={() => onSubmit(amount, remark)}>
              {title}
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </div>
  );
};


export default UpdateBalanceModal