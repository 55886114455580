import React from "react";

import { connect } from "react-redux";
import BetSlipsComponent from "../../components/BetSlips/BetSlipsComponent";
import MatkaBetSlips from "components/BetSlips/MatkaBetSlips";
import { useLocation } from "react-router-dom";

function Bet_Slips(props) {
  const location = useLocation();
  return (
    <>
      {location?.state?.matchData?.sport_id === 999 ? (
        <MatkaBetSlips {...props} />
      ) : (
        <BetSlipsComponent {...props} />
      )}
    </>
  );
}
const mapstatetop = (state) => ({
  ...state,
});

export default connect(mapstatetop)(Bet_Slips);
