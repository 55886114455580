import { getSportList } from '_services';
import { betHistoryByUser, clientExposure } from '_services/userDetails.services';
import DateRangePickerComponent from 'components/common/DateRangePickerComponent';
import InnerPageHeader from 'components/common/InnerPageHeader';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

const ExposureDetails = () => {
    const { user_id } = useParams();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [data, setData] = useState(null);


    useEffect(() => {
        fetchExposure();
    }, [user_id]);



    const fetchExposure = () => {

        let sendObj = {
            user_id: parseInt(user_id) || '',
        };


        setLoading(true);

        clientExposure(sendObj)
            .then((response) => {
                if (response && response.data && response.data.data) {
                    const betData = response.data.data.limit_bets;
                    setData(betData);
                }
                setLoading(false);
            })
            .catch((error) => {
                console.log(error, "error");
                setError("Failed to fetch data.");
                setLoading(false);
            });
    };



    return (
        <div>
            <InnerPageHeader
                pageTitle={'Exposure Detail'}
                breadcrumbs={[
                    { title: "Dashboard", url: "/" },
                    // { title: parentType, url: navigationUrl },
                ]}
                currenPageTitle={'Exposure Detail'}
            />

            <div className="wrapper wrapper-content animated fadeInRight">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="ibox float-e-margins">
                            <div className="ibox-title">
                                <h5>Exposure Detail</h5>

                            </div>
                            <div className="ibox-content">

                                {loading ? (
                                    <p>Loading...</p>
                                ) : error ? (
                                    <p>{error}</p>
                                ) : (
                                    <div className="table-responsive">
                                        <table className="table table-bordered  dataTables-example">
                                            <thead>
                                                <tr className="custom-table-header ">
                                                    {/* <th style={{ textAlign: 'left' }}>EXPOSURE</th>
                                                    <th>EVENT</th>
                                                    <th>MARKET</th>
                                                    <th>EVENT ID</th>
                                                    <th>MARKET ID</th>
                                                    <th>UPDATED AT</th> */}


                                                    <th>USER</th>
                                                    <th>EVENT</th>
                                                    <th>MARKET NAME</th>
                                                    <th>RUNNER</th>
                                                    <th>RATE</th>
                                                    <th>RUN</th>
                                                    <th>MODE</th>
                                                    <th>AMOUNT</th>
                                                    <th>PROFIT</th>
                                                    <th>LOSS</th>
                                                    <th>DATE/TIME</th>
                                                    <th>IP</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {data && data.length > 0 ?
                                                    <>
                                                        {data.map((item, index) => {
                                                            return (
                                                                <tr key={index} className={item.betting_type == 1 ? "lay" : "back"}>
                                                                    {/* <td>{item.stake}</td>
                                                                    <td>{item.event_name}</td>
                                                                    <td>{item.market_name}</td>
                                                                    <td>{item.event_id}</td>
                                                                    <td>{item.market_id}</td>
                                                                    <td>{moment
                                                                        .utc(item.updated_at)
                                                                        .local()
                                                                        .format("DD-MM-YYYY HH:mm a")}</td> */}

                                                                    <td>{item.username}</td>
                                                                    <th>{item.event_name}</th>
                                                                    <td>{item.market_name}</td>
                                                                    <td>{item.bookmaker_type != 3 && item.team_name}</td>
                                                                    <td>{item.price}</td>
                                                                    <td>{item.line || ''}</td>
                                                                    <td>{
                                                                        item.bookmaker_type == 3 ?
                                                                            (item.betting_type == 2 ?
                                                                                "NO" : "YES")
                                                                            : item.betting_type == 2 ?
                                                                                "KHAI" : "LAGAI"}
                                                                    </td>
                                                                    <td>{item.stake}</td>
                                                                    <td>{item.payout}</td>
                                                                    <td>{item.liability}</td>
                                                                    <td>{moment
                                                                        .utc(item.created_at)
                                                                        .local()
                                                                        .format("DD-MM-YYYY HH:mm a")}</td>
                                                                    <td>{item.ip_address}</td>
                                                                </tr>);
                                                        })}
                                                    </>
                                                    :
                                                    <tr>
                                                        <td colSpan={9}>No Record Found</td>
                                                    </tr>
                                                }
                                            </tbody>

                                        </table>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ExposureDetails;
