import React from "react";
import { connect, useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import AddUser from "components/common/AddUser";
import { createRequest } from "_actions/agent/agent.actions";
import AddNewUser from "components/AddNewUser/AddNewUser";

function Add(props) {
  const role = 8;
  const navigationUrl = "/agent";
  const title = "Agents",
    parentType = "agent";
  const navigate = useNavigate();
  const params = useParams();
  const dispatch = useDispatch();

  const createUser = (sendObj) => {
    dispatch(
      createRequest(sendObj, (res) => {
        if (res) {
          navigate(navigationUrl);
        }
      })
    );
  };

  return (
    // <AddUser
    <AddNewUser
      navigationUrl={navigationUrl}
      parentType={parentType}
      onSubmitFunc={createUser}
      role={role}
      action={params?.id ? `Edit ${title}` : `Create ${title}`}
      breadcrumbTitle={params?.id ? `Edit ${title}` : `New ${title}`}
      navigate={navigate}
      isEdit={Boolean(params?.id)}
      params={params}
      showFixSharing={true}
      {...props}
    />
  );
}
const mapstatetop = (state) => ({
  ...state,
});

export default connect(mapstatetop)(Add);
