import { getSportList } from '_services';
import { betHistoryByUser } from '_services/userDetails.services';
import DateRangePickerComponent from 'components/common/DateRangePickerComponent';
import InnerPageHeader from 'components/common/InnerPageHeader';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

const BetHistory = () => {
    const { user_id, role, filter, market_id } = useParams();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [data, setData] = useState(null);
    const [fromDate, setFromDate] = useState(null);
    const [toDate, setToDate] = useState(null);
    const [sports, setSports] = useState([]);
    const [selectedSport, setSelectedSport] = useState('');
    const [keyword, setKeyword] = useState('');

    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to top when component mounts
        fetchSportsList();
    }, []);

    useEffect(() => {
        fetchBetHistory();
    }, [user_id, role, filter, market_id]);

    const fetchSportsList = () => {
        getSportList()
            .then((response) => {
                if (response && response.data && response.data.data && response.data.data.length) {
                    setSports(response.data.data);
                } else {
                    setSports([]);
                }
            })
            .catch((error) => {
                console.log(error);
                setSports([]);
            });
    };

    const fetchBetHistory = () => {

        const from_date = fromDate ? (moment(moment.utc(fromDate).local().format("YYYY-MM-DD 00:00:00"), "YYYY-MM-DD HH:mm:ss").utc().format("YYYY-MM-DD HH:mm:ss")) : "";
        const to_date = toDate ? (moment(moment.utc(toDate).local().format("YYYY-MM-DD 23:59:00"), "YYYY-MM-DD HH:mm:ss").utc().format("YYYY-MM-DD HH:mm:ss")) : '';


        let sendObj = {
            user_id: parseInt(user_id) || '',
            role: parseInt(role) || '',
            filter: parseInt(filter) || 1,
        };
        if (filter == 2) {
            sendObj.from_date = from_date;
            sendObj.to_date = to_date;
            sendObj.keyword = keyword;
            sendObj.sport_id = selectedSport;
        }

        if (market_id) {
            sendObj.market_id = market_id
        }

        setLoading(true);

        betHistoryByUser(sendObj)
            .then((response) => {
                if (response && response.data && response.data.data) {
                    const betData = response.data.data.bet_data;
                    setData(betData);
                }
                setLoading(false);
            })
            .catch((error) => {
                console.log(error, "error");
                setError("Failed to fetch data.");
                setLoading(false);
            });
    };

    const handleEvent = (from_date, to_date) => {
        if (from_date) {
            let fromDate = from_date._d;
            let toDate = to_date._d;
            setToDate(toDate);
            setFromDate(fromDate);
        }
    }


    return (
        <div>
            <InnerPageHeader
                pageTitle={'Bet History'}
                breadcrumbs={[
                    { title: "Dashboard", url: "/" },
                    // { title: parentType, url: navigationUrl },
                ]}
                currenPageTitle={'Bet History'}
            />
            {filter == 2 &&
                <div className="bet-history row">
                    <DateRangePickerComponent handleEvent={handleEvent} fDate={fromDate} tDate={toDate} />
                    <div className="input-container col-lg-3 col-md-4 col-sm-6 col-xs-12" style={{ margin: "11px 0px" }}>
                        <input
                            type="text"
                            className="form-control"
                            placeholder="username"
                            value={keyword}
                            onChange={(e) => setKeyword(e.target.value)}
                        />
                    </div>
                    <div className="select-container col-lg-3 col-md-4 col-sm-6 col-xs-12" style={{ margin: "11px 0px" }}>
                        <select
                            className="form-control"
                            onChange={(e) => setSelectedSport(e.target.value)}
                        >
                            <option value="">Select Sport</option>
                            {console.log(sports)}
                            {sports.map((sport) => (
                                <option key={sport.sport_id} value={sport.sport_id}>{sport.sports_name}</option>
                            ))}
                        </select>
                    </div>


                    <div className="col-lg-3 col-md-4 col-sm-6 col-xs-12" style={{ margin: "11px 0px" }}>
                        <button className="btn btn-primary btn-xs search_date_btn" onClick={fetchBetHistory}>Search</button>
                    </div>
                </div>}

            <div className="wrapper wrapper-content animated fadeInRight">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="ibox float-e-margins">
                            <div className="ibox-title">
                                <h5>{filter == 1 ? "Bet History" : "Pending Bets"}</h5>

                            </div>
                            <div className="ibox-content">

                                {loading ? (
                                    <p>Loading...</p>
                                ) : error ? (
                                    <p>{error}</p>
                                ) : (
                                    <div className="table-responsive">
                                        <table className="table table-bordered  dataTables-example">
                                            <thead>
                                                <tr className="custom-table-header ">
                                                    <th>USER</th>
                                                    {filter == 2 && <th>EVENT</th>}
                                                    <th>MARKET NAME</th>
                                                    <th>RUNNER</th>
                                                    <th>RATE</th>
                                                    <th>RUN</th>
                                                    <th>MODE</th>
                                                    <th>AMOUNT</th>
                                                    <th>RESULT</th>
                                                    {filter == 2 && <th>PROFIT</th>}
                                                    {filter == 2 && <th>LOSS</th>}
                                                    {filter == 1 && <th>TOTAL</th>}
                                                    <th>DATE/TIME</th>
                                                    <th>IP</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {data && data.length > 0 ?
                                                    <>
                                                        {data.map((item, index) => {
                                                            return (
                                                                <tr key={index} className={item.betting_type == 1 ? "lay" : "back"}>
                                                                    <td>{item.username}</td>
                                                                    {filter == 2 && <th>{item.event_name}</th>}
                                                                    <td>{item.market_name}</td>
                                                                    <td>{item.bookmaker_type != 3 && item.team_name}</td>
                                                                    <td>{item.price}</td>
                                                                    <td>{item.line || ''}</td>
                                                                    <td>{
                                                                        item.bookmaker_type == 3 ?
                                                                            (item.betting_type == 2 ?
                                                                                "NO" : "YES")
                                                                            : item.betting_type == 2 ?
                                                                                "KHAI" : "LAGAI"}
                                                                    </td>
                                                                    <td>{item.stake}</td>
                                                                    <td>{item.mkt_result} </td>
                                                                    {filter == 2 && <td>{item.payout}</td>}
                                                                    {filter == 2 && <td>{item.liability}</td>}
                                                                    {filter == 1 && <td>{item.pp}</td>}
                                                                    <td>{moment
                                                                        .utc(item.created_at)
                                                                        .local()
                                                                        .format("DD-MM-YYYY HH:mm a")}</td>
                                                                    <td>{item.ip_address}</td>
                                                                </tr>);
                                                        })}
                                                    </>
                                                    :
                                                    <tr>
                                                        <td colSpan={9}>No Record Found</td>
                                                    </tr>
                                                }
                                            </tbody>
                                            {filter == 1 && <tfoot style={{ color: '#000' }}>
                                                <tr>
                                                    <th></th>
                                                    <th></th>
                                                    <th>Total</th>
                                                    <th>{data.reduce((acc, bet) => acc + bet.stake, 0)}</th>
                                                    <th></th>
                                                    <th></th>
                                                    <th>{data.reduce((acc, bet) => acc + bet.pp, 0)}</th>
                                                    <th></th>
                                                    <th></th>
                                                </tr>
                                            </tfoot>}
                                        </table>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BetHistory;
