import React, { Component } from "react";

import { connect } from "react-redux";
import CollectionReportMDComponent from "../../components/CollectionReportMD/CollectionReportMD";
class CollectionReportMD extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      
        <CollectionReportMDComponent />
      
    );
  }
}
const mapstatetop = (state) => ({
  ...state,
});

export default connect(mapstatetop)(CollectionReportMD);
