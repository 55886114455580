import React, { useEffect, useState, useContext } from "react";
import Modal from "react-modal";
import { getMarketBook } from "_services";

function MarketBookModal(props) {
  const {
    isBookModal: marketData,
    isBookModal: { event_id, market_id },
    hideModal,
  } = props;
  const customStyles = {
    content: {
      top: "25%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -25%)",
      overflowY: "hidden",
      overflowX: "hidden",
      maxHeight: "80vh",
      minWidth: "400px",
      padding: 20,
    },
  };
  const [sessionEventData, updateSessionEventData] = useState([]);

  function afterOpenModal() {
    let sendData = {
      event_id: event_id,
      market_id: market_id,
    };
    getMarketBook(sendData)
      .then((response) => {
        if (response && response.data && response.data.data) {
          updateSessionEventData(response.data.data);
        } else {
        }
      })
      .catch((error) => { });

    // references are now sync'd and can be accessed.
  }
  console.log("event_id marketData", marketData);
  return (
    <Modal
      isOpen={event_id}
      onAfterOpen={afterOpenModal}
      onRequestClose={hideModal}
      style={customStyles}
      contentLabel="Example Modal"
    >
      <h4>
        <b>{marketData?.market_name} Book</b>
      </h4>
      <div style={{ maxHeight: "80vh", width: "100%", overflowY: "auto" }}>
        <table
          className="table"
          style={{ border: "1px solid #EFEFEF", marginBottom: 50 }}
        >
          <thead>
            <tr style={{ background: "#E0E0E0" }}>
              <th>S.No</th>
              <th>User Name</th>
              {marketData?.runners.map((item) => (
                <th style={{ borderLeft: "1px solid #EFEFEF" }}>
                  {item?.runnerName}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {sessionEventData?.length > 0 && (
              <>
                {sessionEventData.map((item, index) => {
                  return (
                    <tr key={item.range + index + item.net_pl}>
                      <td>{index + 1}</td>
                      <td>{item?.username}</td>
                      {marketData?.runners.map((_, key) => (
                        <td
                          style={{
                            borderLeft: "1px solid #EFEFEF",
                          }}
                        >
                          {Number(item[`key_${key}`]).toFixed(2)}
                        </td>
                      ))}
                    </tr>
                  );
                })}
                <tr key={"total"}>
                  <td></td>
                  <td>
                    <b>Total</b>
                  </td>
                  {marketData?.runners.map((_, key) => (
                    <td
                      key={`td_${key}`} // Ensure each <td> has a unique key
                      style={{
                        borderLeft: "1px solid #EFEFEF",
                      }}
                    >
                      <b>
                        {sessionEventData &&
                          sessionEventData?.reduce(
                            (v, e) => v + +e[`key_${key}`],
                            0
                          )}
                      </b>
                    </td>
                  ))}
                </tr>
              </>
            )}
          </tbody>
        </table>
      </div>
      <button
        onClick={hideModal}
        style={{ position: "fixed", bottom: 10, right: 20 }}
      >
        CLOSE
      </button>
    </Modal>
  );
}
export default MarketBookModal;
