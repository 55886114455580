import React, { Component } from 'react'

import AllSubCompReportComp from '../../components/ManageLedger/AllSubCompReportComp';
export class AllSubCompReport extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }
    render() {
        return (
            
                <AllSubCompReportComp />
            

        )
    }
}

export default AllSubCompReport