import React from "react";
import Master from "../../../components/ManualMarkets/List";
import { connect, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

function ManualMarkets(props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  return <Master {...props} navigate={navigate} dispatch={dispatch} />;
}
const mapstatetop = (state) => ({
  ...state,
});

export default connect(mapstatetop)(ManualMarkets);
