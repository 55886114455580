import React, { useContext, useEffect, useState } from "react";
import InnerPageHeader from "./InnerPageHeader";
import { ManageList } from "components/Manage/List";

import {
  usersDirectAgent,
  usersDirectClient,
} from "_services/userDetails.services";
import { userRoleDetails } from "_constants";
import { Link, useParams } from "react-router-dom";
import { BalanceModalContext } from "ContextHandler";
import { useNavigate } from 'react-router-dom';
import moment from "moment";
import { activeInactiveUsers, resetUserPassword } from "_services";
import { toast } from "react-toastify";
import Swal from "sweetalert2";

const DirectAgentsClients = (props) => {
  const navigate = useNavigate();
  const params = useParams()
  const { type, subtype, id, user_id } = params;
  const [openDropdown, setOpenDropdown] = useState(null);

  const toggleDropdown = (id) => {
    setOpenDropdown((prev) => (prev === id ? null : id));
  };

  const [state, setState] = useState({
    usersList: [],
    totalRecords: 0,
    perPage: 20,
    role: subtype === "directclients" ? 8 : 7,
    currentPage: 1,
  });

  const { balanceModal, setToggleBalanceModal } = useContext(BalanceModalContext);

  useEffect(() => {
    if (balanceModal === false) {
      if (subtype) {
        if (subtype === "directclients") {
          fetchDirectClients();
        }
        if (subtype === "directagents") {
          fetchDirectAgents();
        }
      }
    }
  }, [balanceModal, state.currentPage, id]);

  const fetchDirectAgents = () => {
    let sendObj = {};
    sendObj.current_page = state.currentPage;
    sendObj.items_perpage = state.perPage;
    sendObj.user_guid = id;
    usersDirectAgent(sendObj)
      .then((res) => {
        if (res && res.data && res.data.data && res.data.data.total_records) {
          setState((s) => ({
            ...s,
            usersList: res.data.data.userdownlist,
            totalRecords: res.data.data.total_records,
          }));
        } else {
          setState((s) => ({
            ...s,
            usersList: [],
            totalRecords: 0,
          }));
        }
      })
      .catch((error) => { });
  };

  const fetchDirectClients = () => {
    const { id } = params;
    let sendObj = {};
    sendObj.current_page = state.currentPage;
    sendObj.items_perpage = state.perPage;
    sendObj.user_guid = id;
    usersDirectClient(sendObj)
      .then((res) => {
        if (res && res.data && res.data.data && res.data.data.total_records) {
          setState((s) => ({
            ...s,
            usersList: res.data.data.userdownlist,
            totalRecords: res.data.data.total_records,
          }));
        } else {
          setState((s) => ({
            ...s,
            usersList: [],
            totalRecords: 0,
          }));
        }
      })
      .catch((error) => { });
  };

  const handlePageClick = (e) => {
    const selectedPage = e.selected;
    setState((s) => ({ ...s, currentPage: selectedPage + 1 }));
  };

  const statusChange = (confirmEvent, user, ind) => {
    let sendData = {};
    sendData.user_guid = user.user_guid;
    sendData.status = user.status == "0" ? 1 : 0;

    activeInactiveUsers(sendData)
      .then((response) => {
        if (response?.data?.message != "") {
          toast.success("user updated success.")
        }
        if (response) {

          if (balanceModal === false) {
            if (subtype) {
              if (subtype === "directclients") {
                fetchDirectClients();
              }
              if (subtype === "directagents") {
                fetchDirectAgents();
              }
            }
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const columnData = [
    // { name: "#", value: "serial" },
    { name: "Code", value: "userName" },
    { name: "Name", value: "name" },
    // { name: "Agent", value: "parent_name" },
    // { name: "Reference", value: "reference" },
    { name: "P/L", value: "ledger_balance" },
    { name: "Exposure", value: "exposure" },
    { name: "Coins", value: "balance" },
    { name: "D.O.J.", value: "doj" },
    // { name: "Share", value: "my_share" },
    { name: "Share %", value: "my_agent_share" },
    { name: "C Share %", value: "game_share" },
    { name: "SSN %", value: "session_commission" },
    // {
    //   name: "Master Comm",
    //   subColumns: [
    //     { name: "Match", value: "cricket_commission" },
    //     { name: "SSN", value: "session_commission" },
    //   ]
    // },
    // { name: "Status", value: "status" },
    { name: "Status", value: "user_lock" },
    { name: "Bet", value: "betAction" },
    { name: "Options", value: "options" },
    // { name: "Withdraw", value: "withdraw" },
    // { name: "Deposit", value: "deposit" },
    // { name: "Acc. Lock", value: "status" },
    // { name: "Bet Lock", value: "betAction" },
    // { name: "Casino Lock", value: "casinoAction" },
    // { name: "Matka Lock", value: "matkaAction" },
    // { name: "Actions", value: "action" },

  ];

  const getRowData = (list) => {
    return list?.map((item, index) => ({
      ...item,
      // name: `${item.first_name} ${item.last_name}`,
      userName: (

        <div className="name_eye">
          {/* <Link
            to={`/userview/collection-report/${item.user_guid}/${item.username}`}
          >
          
          </Link> */}

          {item.role != 9 ?
            <a
              href={`/userdetails/collection-report/directagents/${item.user_guid}/${item.user_id}`} >
              <div className="eye_icon" >
                <span className="user-abbr">{userRoleDetails[Number(item.role)]?.abbreviation}</span>
                {item.username}
              </div>
            </a>
            :
            <div className="eye_icon" >
              <span className="user-abbr">{userRoleDetails[Number(item.role)]?.abbreviation}</span>
              {item.username}
            </div>
          }


        </div>
      ),
      name: item.first_name,
      // parent_name: item.parent_name,
      // reference: item.reference,
      ledger_balance: item.ledger_balance,
      exposure: item.role == 9 ?
        <Link to={`/exposure-details/${item.user_id}`}>{item.exposure}</Link>
        : item.exposure
      ,
      doj: moment(item.created_at).format("DD-MM-yyy"),
      my_agent_share: item.role == 9 ? item.my_share : item.my_agent_share,
      game_share: item.role == 9 ? item.game_share : item.agent_game_share,
      // status: item.status == 1 ? "Active" : "Inactive",

      user_lock: (
        <button
          type="button"
          className="btn anvGreenBtn"
          onClick={() =>
            setToggleBalanceModal({
              ...item,
              act_type: "confrim",
              title: "Account",
              act_status: +item?.status === 1 ? "Lock" : "Unlock",
            })
          }
        >
          Account {+item?.status === 1 ? "Lock" : "Unlock"}
        </button>
      ),
      betAction: (
        <button
          type="button"
          className="btn anvYellowBtn"
          onClick={() =>
            setToggleBalanceModal({
              ...item,
              act_type: "confrim",
              title: "Bet",
              act_status: +item?.lock_betting !== 1 ? "Lock" : "Unlock",
            })
          }
        >
          Bet {+item?.lock_betting !== 1 ? "Lock" : "Unlock"}
        </button>
      ),

      options: <div className="user-options">
        {/* <div className="opt-items"><Link to={`${userRoleDetails[item.role]?.rootUrl}/edit/${item?.user_guid}`} state={{ selectedData: item }} ><span>&#9998;</span></Link></div> */}
        {/* {item.role != 9 && <div className="opt-items" data-tooltip="Add Client"><Link to={"/add"}><span>&#43;</span></Link></div>} */}
        <div className="opt-items" data-tooltip="Deposit" onClick={() => { setToggleBalanceModal({ ...item, act_type: "Deposit" }); toggleDropdown(openDropdown) }}><span>D</span></div>
        <div className="opt-items" data-tooltip="Withdraw" onClick={() => { setToggleBalanceModal({ ...item, act_type: "Withdraw" }); toggleDropdown(openDropdown) }}><span>W</span></div>
        <div className="opt-items" data-tooltip="Account Statement"><a href={`/userdetails/${userRoleDetails[state.role]?.type}/coinhistory/${item?.user_guid}/${item?.user_id}`}><span>A/C</span></a></div>
        <div className="opt-items" data-tooltip="Chip Statement"><Link to={`/chip-statement/${item.user_guid}`}><span>C/S-</span></Link></div>
        <div className="opt-items" data-tooltip="Chip Summary"><Link to={`/chip-summery/${item.user_id}`}><span>C/S</span></Link></div>
        {/* <div className="opt-items" data-tooltip="Profit/Loss"><Link to={`/profit-loss-report/${item.user_id}`}><span>P/L</span></Link></div> */}
        <div className="opt-items" data-tooltip="Settle Report"><Link to={`/settlement-history/${item.user_id}`}><span>S/R-</span></Link></div>
        {/* <div className="opt-items" data-tooltip="Settle Profit/Loss"><Link to={`/sports-report/${item.user_id}`}><span>S/PL</span></Link></div> */}
        {/* <div className="opt-items" data-tooltip="Change Password"><Link to={`/edit_password/${item.user_guid}`}> <span>P</span></Link></div> */}
        <div
          className="opt-items"
          data-tooltip="Change Password"
          onClick={(e) => {
            e.preventDefault();
            Swal.fire({
              title: 'Are you sure?',
              html: `Password of <strong>${item.username}</strong> will be reset to <strong>1234567!</strong>`, // Use 'html' instead of 'text'
              icon: 'warning',
              showCancelButton: true,
              confirmButtonColor: '#3085d6',
              cancelButtonColor: '#d33',
              confirmButtonText: 'Yes, change it!',
              customClass: {
                popup: 'custom-swal-popup', // Add custom class if needed
              },
            }).then((result) => {
              if (result.isConfirmed) {
                const payload = {
                  user_guid: item.user_guid
                }
                resetUserPassword(payload)
                  .then((response) => {
                    // Handle success
                    Swal.fire({
                      title: 'Success!',
                      text: 'Password has been reset successfully.',
                      icon: 'success',
                      confirmButtonText: 'Okay',
                      confirmButtonColor: 'green'
                    });
                  })
                  .catch((error) => {
                    // Handle error
                    Swal.fire({
                      title: 'Error!',
                      text: 'There was an error resetting the password.',
                      icon: 'error',
                      confirmButtonText: 'Okay',
                    });
                  });
              }
            });
          }}
        >
          <span>P</span>
        </div>
        <div className="opt-items" data-tooltip="Bet History"><Link to={`/bet_history_by_user/${item.user_id}/${item.role}/1`}><span>BH</span></Link></div>
        <div className="opt-items" data-tooltip="Pending Bets"><Link to={`/bet_history_by_user/${item.user_id}/${item.role}/2`}><span>PB</span></Link></div>
        {/* <div className="opt-items" data-tooltip="Settings"><Link to={`/settings/${item.user_id}`}><span>S</span></Link></div> */}
        <div className="opt-items" data-tooltip="Sport Settings"><Link to={`/sport-setting/${item.user_guid}`}><span>SS</span></Link></div>
      </div>,
      // serial: (
      //   <div className="name_add ">
      //     <button
      //       onClick={() => {
      //         setToggleBalanceModal({ ...item, act_type: "partnership" }); toggleDropdown(openDropdown)
      //       }
      //       }
      //     >
      //       <i class="fa-solid fa-plus"></i>{" "}
      //     </button>
      //     <div className="dropdown">
      //       <button className="dropbtn"
      //         onClick={() => toggleDropdown(item.user_id)}
      //       >
      //         <i className="fa-solid fa-square-caret-down"></i>
      //       </button>
      //       {openDropdown === item.user_id && (
      //         <div className="dropdown-content">
      //           <ul>

      //             <li> <Link
      //               to={`${userRoleDetails[item.role]?.rootUrl}/edit/${item?.user_guid}`}
      //               state={{ selectedData: item }}
      //             >

      //               Edit
      //             </Link></li>
      //             <li>
      //               <p
      //                 onClick={() => {
      //                   setToggleBalanceModal({ ...item, act_type: "Deposit" }); toggleDropdown(openDropdown)
      //                 }
      //                 }
      //               >
      //                 Deposit
      //               </p>
      //             </li>
      //             <li>  <p
      //               onClick={() => {
      //                 setToggleBalanceModal({ ...item, act_type: "Withdraw" }); toggleDropdown(openDropdown)
      //               }
      //               }
      //             >
      //               Withdraw
      //             </p></li>
      //             <li>
      //               <p onClick={(e) => { statusChange(e, item, false); toggleDropdown(openDropdown) }}>
      //                 {item.status == 1 ? "InActive" : "Active"}
      //               </p></li>
      //             {item.role != 9 && <li>
      //               <a
      //                 href={`/userdetails/collection-report/directagents/${item.user_guid}/${item.user_id}`} >
      //                 <div className="eye_icon" >
      //                   Downline
      //                 </div>
      //               </a>
      //             </li>}
      //             {item.role != 9 && <li>  <Link to={`/userdetails/${userRoleDetails[state.role]?.type
      //               }/directclients/${item?.user_guid}/${item?.user_id}`}>
      //               Clients
      //             </Link></li>}
      //             <li><a
      //               href={`/userdetails/${userRoleDetails[state.role]?.type
      //                 }/coinhistory/${item?.user_guid}/${item?.user_id}`}
      //             >
      //               Statement
      //             </a></li>
      //             <li>
      //               <a
      //                 href={`/account-operations/${item?.user_id}`}
      //               >
      //                 <p>
      //                   Account Operations
      //                 </p>
      //               </a>
      //             </li>


      //             <li>  <Link to={`/edit_password/${item.user_guid}`}>
      //               Reset Password
      //             </Link></li>


      //           </ul>
      //         </div>)}
      //     </div>
      //   </div>
      // ),
      cricket_commission: item.cricket_commission,
      session_commission: item.session_commission,
      // action: (
      //   <>
      //     <button
      //       className="btn anvYellowBtn btn-sm"
      //       onClick={(e) => {
      //         e.preventDefault();
      //         props.history.push({
      //           pathname: `${userRoleDetails[state.role]?.rootUrl}/edit/${item?.user_guid
      //             }`,
      //           state: { selectedData: item },
      //         });
      //       }}
      //     >
      //       Edit
      //     </button>
      //     &nbsp;&nbsp; &nbsp;&nbsp;
      //     <Link to={`/edit_password/${item.user_guid}`}>
      //       <button type="button" className="btn anvGreenBtn">
      //         CP
      //       </button>
      //     </Link>
      //   </>
      // ),
      // withdraw: (
      //   <button
      //     type="button"
      //     className="btn anvGreenBtn"
      //     onClick={() =>
      //       setToggleBalanceModal({ ...item, act_type: "Withdraw" })
      //     }
      //   >
      //     Withdraw
      //   </button>
      // ),
      // deposit: (
      //   <button
      //     type="button"
      //     className="btn anvYellowBtn"
      //     onClick={() =>
      //       setToggleBalanceModal({ ...item, act_type: "Deposit" })
      //     }
      //   >
      //     Deposit
      //   </button>
      // ),

      // casinoAction: (
      //   <button
      //     type="button"
      //     className="btn anvGreenBtn"
      //     onClick={() =>
      //       setToggleBalanceModal({
      //         ...item,
      //         act_type: "confrim",
      //         title: "Casino",
      //         act_status: +item?.lock_casino_bet !== 1 ? "Lock" : "Unlock",
      //       })
      //     }
      //   >
      //     Casino {+item?.lock_casino_bet !== 1 ? "Lock" : "Unlock"}
      //   </button>
      // ),
      // matkaAction: (
      //   <button
      //     type="button"
      //     className="btn anvYellowBtn"
      //     onClick={() =>
      //       setToggleBalanceModal({
      //         ...item,
      //         act_type: "confrim",
      //         title: "Matka",
      //       })
      //     }
      //   >
      //     Matka Lock
      //   </button>
      // ),
    }));
  };

  // const { usersList, totalRecords } = state;
  // const { type, subtype, id, username } = props.match.params;

  return (
    <>
      <ManageList
        title={subtype === "directclients" ? "Direct Clients" : "Direct Agents"}
        columnData={columnData}
        dataList={getRowData(state.usersList)}
        isLoading={state.isLoading}
        role={state.role}
        searchKey={state.keyword}
        pageCount={state.pageCount}
        totalRecords={state.totalRecords}
        currentPage={state.currentPage}
        handlePageClick={handlePageClick}
        perPage={state.perPage}
        history={props?.history}
        user_id={user_id}
        toggleDropdown={() => toggleDropdown(openDropdown)}
      />
    </>
  );
};
export default DirectAgentsClients;
