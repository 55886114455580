import React from "react";

import CreateGroupComponent from "components/Staff/CreateGroup";
import { useLocation } from "react-router-dom";
export function CreateGroup() {
  const location = useLocation();
  return <CreateGroupComponent stateData={location.state} />;
}

export default CreateGroup;
