import React, { Component } from "react";

import { connect } from "react-redux";
import SubCompanyLiveReportComponent from "../../components/SubCompanyLiveReport/SubCompanyLiveReport";

class SubCompanyLiveReport extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      
        <SubCompanyLiveReportComponent {...this.props} />
      
    );
  }
}
const mapstatetop = (state) => ({
  ...state,
});

export default connect(mapstatetop)(SubCompanyLiveReport);
