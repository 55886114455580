import { superstockistsConstants } from "../../_constants";
import { create, superStockList } from "../../_services";
import { toast } from "react-toastify";

export const createRequest = (data, callback) => {
  return async (dispatch) => {
    dispatch({
      type: superstockistsConstants.SUPERSTOCKIST_CREATE_REQUEST,
      data: {},
    });
    try {
      const response = await create(data);
      if (response?.error === "") {
        callback(response.data);
        toast.success("Recorded added successfully");
        dispatch({
          type: superstockistsConstants.SUPERSTOCKIST_CREATE_SUCCESS,
          data: response.data,
        });
      } else {
        toast.error(response?.error ? response?.error : "server error");
      }
    } catch (error) {
      toast.error(
        error?.response?.data?.error
          ? error?.response?.data?.error
          : "server error"
      );
      // callback(false);
      dispatch({
        type: superstockistsConstants.SUPERSTOCKIST_CREATE_FAILED,
        data: error,
      });
    }
  };
};

export const listRequest = (data, callback) => {
  return (dispatch) => {
    dispatch({
      type: superstockistsConstants.SUPERSTOCKIST_LIST_REQUEST,
      data: {},
    });
    superStockList(data)
      .then((response) => {
        callback(response.data);
        dispatch({
          type: superstockistsConstants.SUPERSTOCKIST_LIST_SUCCESS,
          data: response.data,
        });
      })
      .catch((error) => {
        callback(false);
        dispatch({
          type: superstockistsConstants.SUPERSTOCKIST_LIST_FAILURE,
          data: error.response,
        });
      });
  };
};
