import React, { Component } from "react";
import { debounce } from "lodash";
import ReactPaginate from "react-paginate";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ConfrimModal from "components/ConfirmModal"
import moment from "moment";
import InnerPageHeader from "../common/InnerPageHeader";

class List extends Component {
  constructor(props) {
    super(props);
    this.state = {
      role: 3,
      dataList: [],
      totalRecords: "",
      currentPage: 1,
      perPage: 10,
      pageCount: 0,
      selectedDate: new Date()
    };
  }

  debouncedLog = debounce((text) => this.fetchListData(text), 500);

  componentDidMount() {
    this.fetchListData();
  }

  fetchListData = (searchData = "") => {
  
  };

  getRecordDetails = () => {
    const { currentPage, totalRecords } = this.state;
    let startingCount =
      this.state.currentPage === 1
        ? 1
        : this.state.currentPage * this.state.perPage - 9;
    let endingCount =
      this.state.perPage < totalRecords
        ? this.state.currentPage === 1
          ? this.state.perPage
          : this.state.currentPage * 10
        : totalRecords;
    return `Showing ${startingCount} to ${endingCount} of ${totalRecords} entries`;
  };

  handlePageClick = (e) => {
    const selectedPage = e.selected;
    this.setState({ currentPage: selectedPage + 1 }, () => {
      // this.fetchListData();
    });
  };

  submit = () => [
    this.setState({ isShowModal: false })
  ]

  render() {
    const { dataList, totalRecords, currentPage, perPage, pageCount, selectedDate, isShowModal } = this.state;

    return (
      <>
        <InnerPageHeader
          pageTitle="Matka Numbers"
          breadcrumbs={[
            { title: "Dashboard", url: "/" },
            // { title: "Matka Numbers", url: "/master" },
          ]}
          currenPageTitle="List"
        />
        <div className="wrapper wrapper-content animated fadeInRight">
          <div className="row">
            <div className="col-lg-12">
              <div className="ibox float-e-margins">
                <div className="ibox-title">
                  <h5 style={{ fontWeight: 700 }}>All Numbers</h5>
                  <div className="ibox-tools">
                    <a
                      href="/add-matka-number"
                      className="btn btn-primary btn-xs"
                      onClick={(e) => {
                        e.preventDefault();
                        this.props.navigate("/add-matka-number");
                      }}
                      style={{ cursor: "pointer" }}
                    >
                      Add Number
                    </a>
                  </div>
                </div>
                <div className="ibox-content">
                  <div className="table-responsive">
                    <div
                      id="DataTables_Table_0_wrapper"
                      className="dataTables_wrapper form-inline dt-bootstrap"
                    >
                      <div className="d-flex justify-end">
                        <div
                          id="DataTables_Table_0_filter"
                          className="dataTables_filter"
                        >
                          <label>
                            <DatePicker
                              id="datetime"
                              minDate={new Date()}
                              dateFormat="yyyy-MM-dd"
                              className="form-control "
                              name="date"
                              selected={selectedDate}
                              onChange={(e) => this.setState({ selectedDate: e })}
                            />
                          </label>
                          <button className="btn btn-white btn-sm filter-button"> Filter</button>
                          <button className="btn btn-white filter-button"> Reset</button>
                        </div>
                      </div>

                      <table className="table table-striped table-bordered table-hover dataTables-example">
                        <thead>
                          <tr>
                            <th>S.No</th>
                            <th>Date</th>
                            <th>Category</th>
                            <th>Type</th>
                            <th>Number</th>
                            <th>Actions</th>
                          </tr>
                        </thead>
                        <tbody>
                          {dataList && dataList.length ? (
                            dataList.map((user, index) => (
                              <tr className="gradeX" key={index}>
                                <td>{(index + 1) + ((currentPage - 1) * perPage)}</td>
                                <td>
                                  {moment(new Date()).format("YYYY-MM-DD")}
                                </td>
                                <td>
                                  {user.first_name} {user.last_name}
                                </td>
                                <td className="center">{user.fixed_limit}</td>
                                <td className="center">{user.my_share}</td>
                                <td className="">
                                  <a
                                    className="btn btn-white btn-sm"
                                    href={`/edit-matka-number/${user?.user_guid}`}
                                    onClick={(e) => {
                                      e.preventDefault();
                                      this.props.navigate({
                                        pathname: `/edit-matka-number/${user?.user_guid}`,
                                        state: { selectedData: user },
                                      });
                                    }}
                                  >
                                    <i className="fa fa-pencil-square"></i> Edit
                                  </a>
                                  &nbsp;&nbsp; &nbsp;&nbsp;
                                  <a
                                    className="btn btn-white btn-sm"
                                    onClick={(e) => this.setState({ isShowModal: true })}
                                  >
                                    <i className="fa fa-trash"></i> Delete
                                  </a>
                                </td>
                              </tr>
                            ))
                          ) : (
                            <tr className="gradeX">
                              <td colSpan={7}>No data available in table</td>
                            </tr>
                          )}
                        </tbody>
                      </table>

                      <div className="row" style={{ margin: "15px 0px" }}>
                        <div className="col-xs-12 col-sm-6">
                          <div
                            className="dataTables_info"
                            id="DataTables_Table_0_info"
                            role="status"
                            aria-live="polite"
                          >
                            {totalRecords
                              ? this.getRecordDetails()
                              : "Showing 0 to 0 of 0 entries"}
                          </div>
                        </div>
                        <div className="col-xs-12 col-sm-6">
                          <div className="paginate_class">
                            <ReactPaginate
                              previousLabel={"prev"}
                              nextLabel={"next"}
                              breakLabel={"..."}
                              breakClassName={"break-me"}
                              pageCount={pageCount}
                              marginPagesDisplayed={2}
                              pageRangeDisplayed={5}
                              onPageChange={this.handlePageClick}
                              containerClassName={"pagination"}
                              subContainerClassName={"pages pagination"}
                              activeClassName={"active"}
                            />
                          </div>
                        </div>
                        <div className="clearfix visible-xs"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {isShowModal &&
              <ConfrimModal
                isShow={isShowModal}
                message={
                  <label>
                    Are you sure you want to delete{" "}
                    {/* <span style={{ color: "red" }}>{event_name}</span> match? */}
                  </label>
                }
                onCancle={() => this.setState({ isShowModal: false })}
                onSubmit={() => this.submit()}
              />}
          </div>
        </div>
      </>
    );
  }
}
export default List;
