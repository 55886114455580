import React from "react";
import Client from "../../components/Client/List";
import { connect } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

function Add(props) {
  const navigate = useNavigate();
  const location = useLocation()
  return <Client {...props} navigate={navigate} location={location}/>;
}
const mapstatetop = (state) => ({
  ...state,
});

export default connect(mapstatetop)(Add);
