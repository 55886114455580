import React, { useEffect, useState } from "react";
import { Modal, Button, Table } from "react-bootstrap";

export const PartnerInfoModal = (props) => {
  const [amount, setAmount] = useState({});

  const {
    list,
    isShow,
    modalToggle,
    cancleText = "Back",
    loading = false,
    uplineData,
  } = props;
  const profileData = JSON.parse(localStorage.getItem("profile"));

  useEffect(() => {
    newArrayCreate(list)
  }, [list])

  const newArrayCreate = (list) => {
    const object = {

    }
    const arraylist = list?.map((item) => {
      if (item.role == 3) {
        object["super_agent_share"] = item?.my_agent_share;
        object["super_share"] = item?.my_share;
      }
      if (item.role == 4) {
        object["mini_agent_share"] = item?.my_agent_share;
        object["mini_share"] = item?.my_share;
      }

      if (item.role == 5) {
        object["super_master_agent_share"] = item?.my_agent_share;
        object["super_master_share"] = item?.my_share;
      }

      if (item.role == 6) {
        object["master_agent_share"] = item?.my_agent_share;
        object["master_share"] = item?.my_share;
      }

      if (item.role == 7) {
        object["agent_share"] = item?.my_agent_share;
        object["share"] = item?.my_share;
      }


    });

    setAmount(object)

  }

  const ReversedTableHeader = ({ list }) => (
    <thead>
      <tr>
        {list.slice().reverse().map((item, index) => (
          <th style={{ lineHeight: 'initial', fontSize: '10px', height: '18px' }} key={index}>{item.title}</th>
        ))}
      </tr>
    </thead>
  );

  const ReversedTableRow = ({ list, dataKey }) => (
    <tbody>
      <tr>
        {list.slice().reverse().map((item, index) => (
          <td key={index} style={{ fontSize: '12px' }}>{item[dataKey]}</td>
        ))}
      </tr>
    </tbody>
  );


  const ReversedTable = ({ title, list, dataKey }) => (
    <div>
      <h3 style={{ fontWeight: 500, color: '#000' }}>{title}</h3>
      <Table striped bordered responsiv className="partner_table">
        <ReversedTableHeader list={list} />
        <ReversedTableRow list={list} dataKey={dataKey} />
      </Table>
    </div>
  );


  return (
    <div >
      {isShow && (
        <Modal
          className="parnership_modal"
          centered
          show={isShow}
          onHide={() => modalToggle(false)}
          //   style={{ opacity: 1 ,top:200,minHeight:300}}
          backdrop={false}
        >
          <Modal.Header className="partnerHeade" style={{ color: '#000' }} >
            <div className="d-flex" style={{ justifyContent: 'space-between', alignItems: 'center' }}>
              <p>Partnership Info - {uplineData.username}</p>
              <button
                type="button"
                className="btn-close"
                aria-label="Close"
                onClick={() => modalToggle(false)}
              >x</button>
            </div>
          </Modal.Header>
          <Modal.Body className="parsnal_body">
            <div className=" gap-2">
              <div></div>
              <div className="d-flex balance_box"></div>
              <div style={{ width: "100%" }}>
                {/* <Table striped bordered responsive className="partner_table">
                  <thead>
                    <tr>
                      <th>Super Admin</th>
                      <th>Mini Admin</th>
                      <th>Super Master</th>
                      <th>Master</th>
                      <th>Agent</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                    
                      <td>{isShow?.role==3?amount?.super_agent_share:amount?.super_share?amount?.super_share:0}</td>
                      <td>{isShow?.role==4?amount.mini_agent_share:amount?.mini_share?amount?.mini_share:0}</td>
                      <td>{isShow?.role==5?amount.super_master_agent_share:amount?.super_master_share?amount?.super_master_share:0}</td>
                      <td>{isShow?.role==6?amount.master_agent_share:amount?.master_share?amount?.master_share:0}</td>
                      <td>{isShow?.role==7?amount.agent_share:amount?.share?amount?.share:0}</td>
                    </tr>
                  </tbody>
                </Table> */}
                {list && <>
                  <ReversedTable title="Match Share" list={list} dataKey="my_agent_share" />
                  <ReversedTable title="Casino Share" list={list} dataKey="agent_game_share" />
                  <ReversedTable title="Cricket Commission" list={list} dataKey="my_cricket_commission" />
                  <ReversedTable title="Football Commission" list={list} dataKey="my_football_commission" />
                  <ReversedTable title="Tennis Commission" list={list} dataKey="my_tennis_commission" />
                </>}
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="outline-danger"
              disabled={loading}
              onClick={() => modalToggle(false)}
            >
              {cancleText}
            </Button>

          </Modal.Footer>
        </Modal>
      )}
    </div>
  );
};
