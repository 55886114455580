import React, { Component, Fragment } from "react";
import "./Language.css";
import InnerPageHeader from "components/common/InnerPageHeader";
export class Language extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeLanguage: "en",
    };
  }

  getRole = () => {
    var roleValue = "User";
    let role = localStorage.getItem("role") || null;
    switch (role) {
      case "1":
        return "Company";

      case "2":
        return "Sub Company";

      case "3":
        return "Sub Admin";

      case "4":
        return "Admin";

      case "5":
        return "Super Senior";

      case "6":
        return "Super";

      case "7":
        return "Dealer";

      case "8":
        return "Agent";

      case "9":
        return "Client";

      case "10":
        return "Staff";

      default:
        return "User";
    }

    // return roleValue;
  };

  handleClick = (language) => {
    let active = "";
    switch (language) {
      case "hi":
        active = language;
        break;
      case "en":
        active = language;
        break;
      case "ta":
        active = language;
        break;
      case "mr":
        active = language;
        break;
      default:
        active = "";
    }

    this.setState({ activeLanguage: active });
  };

  render() {
    const { activeLanguage } = this.state;
    return (
      <Fragment>
        <InnerPageHeader
          pageTitle={this.getRole()}
          breadcrumbs={[{ title: "Dashboard", url: "/" }]}
          currenPageTitle="Language"
        />
        <div className="wrapper wrapper-content animated fadeInRight">
          <div className="row">
            <div className="col-12">
              <div className="ibox">
                <div className="ibox-title">
                  <h5>Change Language</h5>
                </div>
                <div
                  className="ibox-content"
                  style={{ padding: "15px 20px 56px" }}
                >
                  <div className="dd">
                    <ol className="dd-list">
                      <li
                        className="dd-item"
                        onClick={() => {
                          this.handleClick("en");
                        }}
                      >
                        <div
                          className={
                            "dd-handle " +
                            (activeLanguage === "en" && " active-lang")
                          }
                        >
                          English
                        </div>
                      </li>
                      <li
                        className="dd-item"
                        onClick={() => {
                          this.handleClick("hi");
                        }}
                      >
                        <div
                          className={
                            "dd-handle " +
                            (activeLanguage === "hi" && " active-lang")
                          }
                        >
                          हिंदी
                        </div>
                      </li>
                      <li
                        className="dd-item"
                        onClick={() => {
                          this.handleClick("ta");
                        }}
                      >
                        <div
                          className={
                            "dd-handle " +
                            (activeLanguage === "ta" && " active-lang")
                          }
                        >
                          தமிழ்
                        </div>
                      </li>
                      <li
                        className="dd-item"
                        onClick={() => {
                          this.handleClick("mr");
                        }}
                      >
                        <div
                          className={
                            "dd-handle " +
                            (activeLanguage === "mr" && " active-lang")
                          }
                        >
                          मराठी
                        </div>
                      </li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default Language;
