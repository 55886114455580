import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Navigate, useLocation, useNavigate, useParams } from "react-router-dom";
import EditSubCompany from "components/SubCompany/Edit";
import { updateSubCompany } from "_services";
import { toast } from "react-toastify";

function Edit(props) {
  const location = useLocation()
  const params = useParams()
  const navigate = useNavigate()
  const [state, setState] = useState({
    role: 2,
    updateData: "",
  });

  useEffect(() => {
    if (location?.state?.selectedData?.user_guid) {
      setState({
        updateData: location.state.selectedData,
      });
    }
  }, []);

  const editSubCompany = (editObj = {}) => {
    updateSubCompany(editObj)
      .then((response) => {
        navigate(-1)
        toast.success(
          <div>
            <i
              className="fa fa-check"
              style={{ fontWeight: "bold", marginRight: "1rem" }}
              aria-hidden="true"
            ></i>
            <span> updated successfully</span>
          </div>,
          {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: false,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          }
        );
      })
      .catch((error) => {
        let msg = error.response?.data?.error;

        toast.error(
          <div>
            <i
              className="fa fa-check"
              style={{ fontWeight: "bold", marginRight: "1rem" }}
              aria-hidden="true"
            ></i>
            <span> {msg} </span>
          </div>,
          {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: false,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          }
        );
      });
  };

  let { redirect, updateData } = state;

  if (redirect) {
    return <Navigate to={redirect} />;
  }

  return (
    <EditSubCompany
      heading={
        updateData && updateData.username
          ? updateData.username
          : "New Sub Company"
      }
      breadcrumbTitle={
        updateData && updateData.username
          ? updateData.username
          : "New Sub Company"
      }
      breadcrumb={"Sub Company List"}
      navigationUrl={
        location?.state?.lastPath
          ? location?.state?.lastPath
          : "/manage-subcompany"
      }
      onSubmitFunc={editSubCompany}
      role={state.role}
      action={updateData ? "Edit Sub Company" : "Create Sub Company"}
      navigate={navigate}
      parentType="subcompany"
      isEdit={Boolean(updateData)}
      updateData={updateData}
      params={params}
      {...props}
    />
  );
}
const mapstatetop = (state) => ({
  ...state,
});

export default connect(mapstatetop)(Edit);
