import React from "react";
import { connect, useDispatch } from "react-redux";
import TournamentComponent from "../../../components/Tournament/TournamentComponent";

function Tournament(props) {
  const dispatch = useDispatch();

  return <TournamentComponent {...props} dispatch={dispatch} />;
}

const mapstatetop = (state) => ({
  ...state,
});

export default Tournament = connect(mapstatetop)(Tournament);
