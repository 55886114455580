import React, { useState, useEffect } from "react";
import { useLocation, useParams, Link } from "react-router-dom";
import InnerPageHeader from "components/common/InnerPageHeader";
import { getEventSetting, updateEventSetting } from "_services";
import { useSelector } from "react-redux";
import "./style.css";
import { toast } from "react-toastify";
import { delayList, stakeList } from "components/layouts/Helpers";
import PageTabs from "components/common/pageTabs";

function StakeDelayComponent() {
  const location = useLocation();

  const { matchData } = location && location.state ? location.state : {};

  const { event_id } = useParams();

  const [isLoading, updateLoading] = useState(false);

  const [globalSetting, updateGlobalSetting] = useState("");

  const userLogin = JSON.parse(localStorage.getItem("userLogin"));

  useEffect(() => {
    fetchEventSetting();
  }, []);

  function fetchEventSetting() {
    let sendData = {};
    sendData.event_id = event_id;
    getEventSetting(sendData)
      .then((response) => {
        if (response?.data?.data?.event?.event_id) {
          const settingData = response.data.data.event;
          updateGlobalSetting(settingData);
        }
      })
      .catch((error) => { });
  }

  function submitGlobalSetting() {
    const checkStack = stakeList.filter(
      (item) =>
        globalSetting[item?.name] === "" || globalSetting[item?.name] === null
    );
    const checkDelay = delayList.filter(
      (item) =>
        globalSetting[item?.name] === "" || globalSetting[item?.name] === null
    );
    if (checkStack.length > 0) {
      toast.error(checkStack[0].label + " require");
      return;
    }
    if (checkDelay.length > 0) {
      toast.error(checkDelay[0]?.label + " require");
      return;
    }
    if (!checkStack.length > 0 && !checkDelay.length > 0) {
      updateLoading(true);
      const data = {
        ...globalSetting,
        event_id: event_id,
      };
      updateEventSetting(data)
        .then((response) => {
          if (response && response.data) {
            toast("Event Setting updated");
            setTimeout(() => {
              updateLoading(false);
            }, 2000);
            fetchEventSetting();
          }
        })
        .catch((error) => {
          updateLoading(false);
        });
    }
  }

  function onSubmit(e) {
    e.preventDefault();
    submitGlobalSetting();
  }

  const onChangeValue = (e) => {
    const { name, value } = e.target;
    updateGlobalSetting((s) => ({ ...s, [name]: value }));
  };

  return (
    <>
      <InnerPageHeader
        pageTitle="Stake Delay"
        breadcrumbs={[
          { title: "Dashboard", url: "/" },
          { title: "Matches", url: "/matches_list" },
          // {
          //   title: matchData?.event_name,
          //   url: {
          //     pathname: `/match_dashboard/${event_id}`,
          //     state: { matchData },
          //   },
          // },
        ]}
        currenPageTitle="Stake Delay"
      />
      <PageTabs event_id={event_id}
        state={false}
        path={'/stake_delay/'} />
      <div className="wrapper wrapper-content animated fadeInRight">
        <div className="row">
          <div className="col-12">
            <div className="ibox">
              <div className="ibox-title">
                <h5>Stake Delay</h5>
              </div>
              <div className="ibox-content">
                <div className="row">
                  {stakeList.map((item) => (
                    <div className="col-sm-6" key={item?.label}>
                      <div className="form-group">
                        <label className="">{item?.label}</label>
                        <input
                          name={item?.name}
                          autoComplete="off"
                          type="text"
                          className="form-control"
                          value={globalSetting[item?.name]}
                          onChange={(e) => onChangeValue(e)}
                        />
                      </div>
                    </div>
                  ))}
                </div>

                <div className="ibox-title">
                  <h5>Tournament Delay Settings</h5>
                </div>

                <div className="row">
                  {delayList.map((item) => (
                    <div className="col-sm-6" key={item?.label}>
                      <div className="form-group">
                        <label className="">{item?.label}</label>
                        <input
                          name={item?.name}
                          autoComplete="off"
                          type="text"
                          className="form-control"
                          value={globalSetting[item?.name]}
                          onChange={(e) => onChangeValue(e)}
                        />
                      </div>
                    </div>
                  ))}
                </div>
                <div className="form-group row">
                  <div className="d-flex justify-end">
                    <button
                      type="submit"
                      className="btn btn-primary"
                      onClick={(e) => !isLoading && onSubmit(e)}
                    >
                      Save Changes
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id="render-sub-company-live-report">
        <div className="wrapper wrapper-content">
          <div className="row" style={{ margin: "0 !important" }}>
            <div className="match-index-row header-top-spacing">
              <div
                className="row"
                style={{ paddingBottom: "30px", margin: "auto auto" }}
              ></div>
            </div>
          </div>

          <div className="wrapper wrapper-content">
            <div className="row">
              <div className="col-lg-12"></div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default StakeDelayComponent;
