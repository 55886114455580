import React from "react";
import { useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { AddEditBanners } from "../../_services/banners.services";
import { toast } from "react-toastify";
import InnerPageHeader from "components/common/InnerPageHeader";

export default function Add(props) {
  const navigate = useNavigate();
  const location = useLocation()
  const param = useParams()

  const item = location.state.edit
  const [name, setName] = useState(item.name ? item.name : '');
  const [type, setType] = useState(item.type ? item.type : "Casino");
  const [desktop_banner, setDesktopBanner] = useState(null);
  const [desktop_image, setDesktopImage] = useState(item.desktop_banner ? item.desktop_banner : null);
  const [mobile_banner, setMobileBanner] = useState(null);
  const [mobile_image, setMobileImage] = useState(item.mobile_banner ? item.mobile_banner : null);

  function handleChange(e, type) {
    if (type) {
      setDesktopImage(URL.createObjectURL(e.target.files[0]));
      setDesktopBanner(e.target.files[0]);
    } else {
      setMobileImage(URL.createObjectURL(e.target.files[0]));
      setMobileBanner(e.target.files[0]);
    }
  }


  const onSubmit = (e) => {
    e.preventDefault();

    if (
      name === "" ||
      desktop_banner === null ||
      mobile_banner === null ||
      type === ""
    ) {
      toast.error(
        <div>
          <i
            className="fa fa-times"
            style={{ fontWeight: "bold", marginRight: "1rem" }}
            aria-hidden="true"
          ></i>
          <span>All filds required</span>
        </div>,
        {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: false,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        }
      );
      // toast.error("All filds required");
    } else {
      let sendObj = {};
      sendObj.id = param.banner_id;
      sendObj.name = name;
      sendObj.type = type;
      sendObj.desktop_banner = desktop_banner;
      sendObj.mobile_banner = mobile_banner;

      AddEditBanners(sendObj)
        .then(() => {
          navigate("/banners");
        })
        .catch((error) => {
          toast.error(
            <div>
              <i
                className="fa fa-times"
                style={{ fontWeight: "bold", marginRight: "1rem" }}
                aria-hidden="true"
              ></i>
              <span>Something went wrong. please try again.</span>
            </div>,
            {
              position: "top-center",
              autoClose: 3000,
              hideProgressBar: true,
              closeOnClick: false,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            }
          );
          // toast.error(" The username field must contain a unique value");
        });
    }
  };

  return (
    <>
      <InnerPageHeader
        pageTitle="Add Banners"
        breadcrumbs={[
          { title: "Dashboard", url: "/" },
          { title: "Banners List", url: "/banners" },
        ]}
        currenPageTitle="Add Banners"
      />
      <div className="wrapper wrapper-content animated fadeInRight">
        <div className="row">
          <div className="col-12">
            <div className="ibox">
              <div className="ibox-content">
                <div className="row">
                  <div className="col-lg-12">
                    <form autoComplete="off" enctype='multipart/form-data'>
                      <div className="form-group row">
                        <label className="col-sm-2 col-form-label">
                          Banner Name
                        </label>
                        <div className="col-sm-10">
                          <input
                            type="text"
                            name="name"
                            className="form-control"
                            placeholder="Enter Name"
                            value={name}
                            autoComplete="off"
                            onChange={(e) => setName(e.target.value)}
                          />
                        </div>
                      </div>
                      <hr />

                      <div className="form-group row">
                        <label className="col-sm-2 col-form-label">
                          Type
                        </label>
                        <div className="col-sm-10">
                          <input
                            type="text"
                            name="type"
                            className="form-control"
                            placeholder="banner type"
                            value={type}
                            autoComplete="off"
                            onChange={(e) => setType(e.target.value)}
                          />
                        </div>
                      </div>
                      <hr />
                      <div className="form-group row">
                        <label className="col-sm-2 col-form-label">
                          Desktop banner
                        </label>
                        <div className="col-sm-10">
                          <input class="image_upload5"
                            type="file"
                            name="bank_image[]"
                            id="bank_image"
                            placeholder=" "
                            accept="image/png,image/jpg, image/jpeg"
                            onChange={(e) => handleChange(e, true)}
                          />
                        </div>
                        <div className="dangerTxt">*  Allowed file type JPG,JPEG,PNG</div>
                      </div>
                      <div className="form-group row">
                        <label className="col-sm-2 col-form-label">
                          Mobile banner
                        </label>
                        <div className="col-sm-10">
                          <input class="image_upload5"
                            type="file"
                            name="bank_image[]"
                            id="bank_image"
                            placeholder=" "
                            accept="image/png,image/jpg, image/jpeg"
                            onChange={(e) => handleChange(e, false)}
                          />
                        </div>
                        <div className="dangerTxt">*  Allowed file type JPG,JPEG,PNG</div>
                      </div>
                      <div className="form-group row">
                        <label className="col-sm-2 col-form-label">
                        </label>
                        <div className="col-sm-10">
                          <div className="d-flex ">
                            {desktop_image && <img src={desktop_image} width={400} height={200} style={{ margin: "5px" }} />}
                            {mobile_image && <img src={mobile_image} width={400} height={200} style={{ margin: "5px" }} />}
                          </div>
                        </div>
                      </div>

                    </form>
                    <div className="form-group row">
                      <div className="col-sm-4 col-sm-offset-2">
                        <Link
                          className="btn btn-white mr-3"
                          type="button"
                          style={{ marginRight: "1rem" }}
                          to="./staff_list"
                        >
                          Cancel
                        </Link>
                        <button
                          type="button"
                          className="btn btn-primary"
                          onClick={(e) => onSubmit(e)}
                        >
                          Save Changes
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
