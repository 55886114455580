import React, { useState, useEffect } from "react";
import { useLocation, useParams, Link, useNavigate } from "react-router-dom";
import InnerPageHeader from "components/common/InnerPageHeader";
import { eventAnnouncement } from "_services";
import { useSelector } from "react-redux";
import "./style.css";
import { toast } from "react-toastify";
import PageTabs from "components/common/pageTabs";

function NewsUpdate(props) {
  const location = useLocation();
  const navigate = useNavigate();
  const { matchData } = location && location.state ? location.state : {};

  const { event_id } = useParams();

  const [isLoading, updateLoading] = useState(false);
  const [eventMessage, setEventMessage] = useState("");
  const userLogin = JSON.parse(localStorage.getItem("userLogin"));

  function onSubmit(e) {
    e.preventDefault();
    if (eventMessage != "") {
      const sendData = {
        event_id: event_id,
        announcement: eventMessage,
      };
      updateLoading(true);
      eventAnnouncement(sendData)
        .then((response) => {
          if (response && response.data) {
            toast(response.data.message ? response.message : "Event updated");
            setTimeout(() => {
              updateLoading(false);
              navigate(-1);
            }, 2000);
          } else {
            updateLoading(false);
          }
        })
        .catch((error) => {
          updateLoading(false);
        });
    }
  }

  return (
    <>
      <div className="row wrapper border-bottom white-bg page-heading">
        {/* <div className="col-lg-10">
          <h2>News/Updates</h2>
          <ol className="breadcrumb">
            <li>
              <a href="/">Dashboard</a>
            </li>
            <li>
              <a href="/matches_list">Matches</a>
            </li>
            <li className="breadcrumb-item active">
              <Link
                to={`/match_dashboard/${event_id}`}
                state={{ matchData: matchData }}
              >
                {matchData?.event_name}
              </Link>
            </li>
            <li className="breadcrumb-item active">
              <strong>News/Updates</strong>
            </li>
          </ol>
        </div> */}

        <div style={{ margin: "0 10px" }}>
          <InnerPageHeader
            pageTitle="News/Updates"
            breadcrumbs={[
              { title: "Dashboard", url: "/" },
              { title: "Matches", url: "/matches_list" },
            ]}
            currenPageTitle={`News/Updates`}
            teamName={`${matchData.event_name ? matchData.event_name : ''}`}
          />
        </div>


        <PageTabs event_id={event_id}
          state={false}
          path={'/newsupdates/'} />
        <div className="col-lg-2"></div>
      </div>
      <div className="wrapper wrapper-content animated fadeInRight">
        <div className="row">
          <div className="col-12">
            <div className="ibox">
              <div className="ibox-title">
                <h5>News/Updates</h5>
              </div>
              <div className="ibox-content">
                <div className="row">
                  <div className="col-lg-12">
                    <form>
                      <div className="form-group row">
                        <label
                          htmlFor="inputAgentAnnouncement"
                          className="col-sm-2 col-form-label"
                        >
                          Event Update
                        </label>
                        <div className="col-sm-10">
                          <textarea
                            type="textarea"
                            name="clientAnnouncement"
                            className="form-control"
                            id="inputAgentAnnouncement"
                            placeholder="Event Update"
                            value={eventMessage}
                            onChange={(e) => setEventMessage(e.target.value)}
                          />
                        </div>
                      </div>
                    </form>
                    <div className="form-group row">
                      <div className="col-sm-4 col-sm-offset-2">
                        <button
                          type="submit"
                          className="btn btn-primary"
                          onClick={(e) => !isLoading && onSubmit(e)}
                        >
                          Save Changes
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id="render-sub-company-live-report">
        <div className="wrapper wrapper-content">
          <div className="row" style={{ margin: "0 !important" }}>
            <div className="match-index-row header-top-spacing">
              <div
                className="row"
                style={{ paddingBottom: "30px", margin: "auto auto" }}
              ></div>
            </div>
          </div>

          <div className="wrapper wrapper-content">
            <div className="row">
              <div className="col-lg-12"></div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default NewsUpdate;
