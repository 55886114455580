import React, { Component } from "react";

import LedgesViewPage from "components/common/LedgesViewPage";
import DirectAgentsClients from "components/common/DirectAgentsClients";
import UserCashPayReceive from "components/common/UserCashPayReceive";
import CashLedger from "components/common/CashLedger";
import MatchLedger from "components/common/MatchLedger";

import CoinHistoryComp from "components/CoinHistoryComp/CoinHistoryComp";

import { connect } from "react-redux";
import { useParams } from "react-router-dom";

function LedgesView(props) {
  const params = useParams();
  const { subtype } = params;
  return (
    <>
      {subtype ? (
        <>
          {subtype === "directagents" || subtype === "directclients" ? (
            <DirectAgentsClients {...props} params={params} />
          ) : null}

          {subtype === "ledger" ? (
            <LedgesViewPage {...props} params={params} />
          ) : null}

          {subtype === "matchledger" ? (
            <MatchLedger {...props} params={params} />
          ) : null}

          {subtype === "cashledger" ? (
            <CashLedger {...props} params={params} />
          ) : null}

          {subtype === "coinhistory" ? (
            <CoinHistoryComp {...props} params={params} />
          ) : null}

          {subtype === "receivecash" || subtype === "paycash" ? (
            <UserCashPayReceive {...props} params={params} />
          ) : null}
        </>
      ) : null}
    </>
  );
}
const mapstatetop = (state) => ({
  ...state,
});

export default connect(mapstatetop)(LedgesView);
