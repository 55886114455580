import { settleMarketConstants } from "../../_constants";
import { settleMarket, cancelMarket, cancelFancy, settlementCancelMarket, cancelFancyBets } from "../../_services";
import { toast } from "react-toastify";

export const settleMarketRequest = (data, callback) => {
  return (dispatch) => {
    dispatch({ type: settleMarketConstants.SETTLE_MARKET_REQUEST, data: {} });
    settleMarket(data)
      .then((response) => {
        callback(response.data);
        toast.success("Recorded added successfully");
        dispatch({
          type: settleMarketConstants.SETTLE_MARKET_SUCCESS,
          data: response.data,
        });
      })
      .catch((error) => {
        if (
          error.response &&
          error.response.data &&
          error.response.data.error
        ) {
          toast.error(error.response.data.error);
        }
        callback(false);
        dispatch({
          type: settleMarketConstants.SETTLE_MARKET_FAILED,
          data: error,
        });
      });
  };
};

export const cancelMarketRequest = (data, callback) => {
  return (dispatch) => {
    dispatch({ type: settleMarketConstants.CANCEL_MARKET_REQUEST, data: {} });
    cancelMarket(data)
      .then((response) => {
        callback(response.data);
        toast.success("Market cancelled successfully");
        dispatch({
          type: settleMarketConstants.CANCEL_MARKET_SUCCESS,
          data: response.data,
        });
      })
      .catch((error) => {
        callback(false);
        dispatch({
          type: settleMarketConstants.CANCEL_MARKET_FAILED,
          data: error,
        });
      });
  };
};

export const cancelFancyRequest = (data, callback) => {
  return (dispatch) => {
    dispatch({ type: settleMarketConstants.CANCEL_FANCY_REQUEST, data: {} });
    cancelFancy(data)
      .then((response) => {
        callback(response.data);
        dispatch({
          type: settleMarketConstants.CANCEL_FANCY_SUCCESS,
          data: response.data,
        });
        // window.location.reload();
        toast.success("Fancy cancelled successfully");
      })
      .catch((error) => {
        console.log('error', error);

        callback(false);
        dispatch({
          type: settleMarketConstants.CANCEL_FANCY_FAILED,
          data: error,
        });
      });
  };
};
export const cancelBetsFancyRequest = (data, callback) => {
  return (dispatch) => {
    dispatch({ type: settleMarketConstants.CANCEL_FANCY_BETS_REQUEST, data: {} });
    cancelFancyBets(data)
      .then((response) => {
        callback(response.data);
        dispatch({
          type: settleMarketConstants.CANCEL_FANCY_BETS_SUCCESS,
          data: response.data,
        });
        // window.location.reload();
        toast.success("Fancy bets cancelled successfully");
      })
      .catch((error) => {
        console.log('error', error);

        callback(false);
        dispatch({
          type: settleMarketConstants.CANCEL_FANCY_BETS_FAILED,
          data: error,
        });
      });
  };
};
