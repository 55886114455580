const Images = {
    ICON: require("../../assets/img/logo.png"),
    CASINO: require("../../assets/img/casino.svg"),
    SOCCER: require("../../assets/img/soccer_1.svg"),
    TENNIS: require("../../assets/img/tennis-ball_2.svg"),
    CRICKET: require("../../assets/img/cricket-ball_1.svg"),
    FAST_MATKA: require("../../assets/img/fast_matka.svg"),
    MATKA: require("../../assets/img/matka.svg"),
    MAIN_STAR: require("../../assets/img/main_star.svg"),
    KALYAN_STAR: require("../../assets/img/kalyan_star.svg"),
    CLOCK: require("../../assets/img/cloack_icon2.svg"),
    CALENDER: require("../../assets/img/calender.svg"),
    POSITION: require("../../assets/img/position_icon.svg"),
    PIN_MATCH: require("../../assets/img/pinmatch_icon.svg"),
    CUP_ICON: require("../../assets/img/cup-icon.svg"),
    CASINO_ICON: require("../../assets/img/casino_icon.svg"),
    MOON_ICON: require("../../assets/img/moon-icon.svg"),
    VECTOR: require("../../assets/img/Vector_9.svg"),
    POLYGON: require("../../assets/img/polygon.svg"),
    USER_ICON: require("../../assets/img/user_icon.png"),
    POLITICS: require("../../assets/img/politics.svg"),
    SUCCESS_GIF: require("../../assets/img/success.gif"),
    DELETE_PNG: require("../../assets/img/delete.png"),
    BANNER: require("../../assets/img/banner-1.jpg"),
    BANNER_2: require("../../assets/img/banner-2.png"),
    BANNER_3: require("../../assets/img/banner-3.png"),
    BANNER_4: require("../../assets/img/banner-4.jpg"),
    BANNER_5: require("../../assets/img/banner5.jpg"),
    BANNER_6: require("../../assets/img/banner-6.jpg"),

    TROPHY: require("../../assets/img/trophy.svg"),
    ALL: require("../../assets/img/all.svg"),

    PIN_1: require("../../assets/img/pin_1.svg"),
    PIN: require("../../assets/img/pin.svg"),
    MIC: require("../../assets/img/mic.svg"),


}

export default Images