import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import {
  staffUserList,
  activeDeactiveStaff,
  deleteStaff,
} from "../../_services/staff.services";

import locked from "../../images/locked.png";
import unlocked from "../../images/unlocked.png";

import ReactPaginate from "react-paginate";
import InnerPageHeader from "components/common/InnerPageHeader";

export default function List() {
  const [staffList, setStaffList] = useState([]);

  useEffect(() => {
    userStaffList();
  }, []);

  const userStaffList = () => {
    staffUserList()
      .then((response) => {
        if (response?.data?.data) {
          setStaffList(response?.data?.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const statusChange = (id, status) => {
    let sendObj = {};
    sendObj.user_guid = id;
    sendObj.status = status == "0" ? 1 : 0;

    activeDeactiveStaff(sendObj)
      .then((response) => {
        if (response) {
          staffUserList()
            .then((response) => {
              if (response?.data?.data) {
                setStaffList(response?.data?.data);
              }
            })
            .catch((error) => {
              console.log(error);
            });
        }
      })
      .catch((error) => {
        console.log(error, "error");
      });
  };

  const deleteFunction = (id) => {
    let sendObj = {};
    sendObj.user_guid = id;

    deleteStaff(sendObj)
      .then((response) => {
        if (response) {
          staffUserList()
            .then((response) => {
              if (response?.data?.data) {
                setStaffList(response?.data?.data);
              }
            })
            .catch((error) => {
              console.log(error);
            });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <>
      <InnerPageHeader
        pageTitle="STAFF LIST"
        breadcrumbs={[{ title: "Dashboard", url: "/" }]}
        currenPageTitle="STAFF LIST"
      />
      <div className="wrapper wrapper-content animated fadeInRight">
        <div className="row">
          <div className="col-12">
            <div className="ibox">
              <div className="ibox-title">
                <h5>Staff List</h5>
                <div className="ibox-tools">
                  <Link to="/add_staff" className="btn btn-primary btn-xs">
                    Create Staff
                  </Link>
                </div>
              </div>
              <div className="ibox-content">
                <div className="table-responsive">
                  <table className="table table-bordered table-responsive  table-striped dataTables-example">
                    <thead>
                      <tr>
                        <th>ID</th>
                        <th>Staff Name</th>
                        <th>User Name</th>
                        <th>Group Name</th>
                        <th className="text-center"> Active / Inactive </th>
                        <th className="text-center" style={{ width: "14%" }}>
                          Action
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {staffList?.map((element, ind) => {
                        // console.log(element, "element")
                        return (
                          <tr key={ind}>
                            <td>{element.user_id}</td>
                            <td>{element.first_name}</td>
                            <td>{element.username}</td>
                            <td>{element.user_guid}</td>

                            <td className="text-center">
                              <a
                                onClick={(e) =>
                                  statusChange(
                                    element.user_guid,
                                    element.status
                                  )
                                }
                              >
                                <img
                                  src={element.status == 1 ? unlocked : locked}
                                />
                              </a>
                            </td>

                            <td>
                              <Link
                                className="btn btn-white btn-sm"
                                to="/edit_staff"
                                state={{ edit: element }}
                              >
                                <i className="fa fa-pencil-square"></i> Edit
                              </Link>
                              &nbsp;&nbsp;&nbsp;
                              <a
                                className="btn btn-white btn-sm"
                                onClick={(e) =>
                                  deleteFunction(element.user_guid)
                                }
                              >
                                <i className="fa fa-trash"></i> Delete
                              </a>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>

                  <div className="paginate_class">
                    <ReactPaginate
                      previousLabel={"prev"}
                      nextLabel={"next"}
                      breakLabel={"..."}
                      breakClassName={"break-me"}
                      // pageCount={pageCount}
                      marginPagesDisplayed={2}
                      pageRangeDisplayed={5}
                      // onPageChange={handlePageClick}
                      containerClassName={"pagination"}
                      subContainerClassName={"pages pagination"}
                      activeClassName={"active"}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
