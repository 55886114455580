import React, { useEffect, useState, useContext } from "react";
import Modal from 'react-modal';
import { getSessionBook } from "_services";

function PositionModal(props) {

  const customStyles = {
    content: {
      top: '25%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -25%)',
      overflowY: 'hidden',
      overflowX: 'hidden',
      maxHeight: '80vh',
      minWidth: '400px',
      padding: 20
    },
  };
  const [modalIsOpen, setIsOpen] = React.useState(true);
  const [sessionEventData, updateSessionEventData] = useState(null);


  function afterOpenModal() {
    let sendData = {};
    sendData.event_id = props.sessionData.event_id;
    sendData.session_id = props.sessionData.session_id;
    getSessionBook(sendData)
      .then((response) => {
        if (response && response.data && response.data.data) {
          updateSessionEventData(response.data.data);
        } else {
        }
      })
      .catch((error) => {
      });

    // references are now sync'd and can be accessed.
  }

  function closeModal() {
    props.hideModal()
  }

  return (
    <Modal
      isOpen={modalIsOpen}
      onAfterOpen={afterOpenModal}
      onRequestClose={closeModal}
      style={customStyles}
      contentLabel="Example Modal"
    >
      <div style={{ maxHeight: '80vh', width: '100%', overflowY: 'auto' }}>
        <table className="table" style={{ border: "1px solid #EFEFEF", marginBottom: 50 }}>
          <thead >
            <tr style={{ background: '#E0E0E0' }}>
              <th>RUNS</th>
              <th style={{ borderLeft: "1px solid #EFEFEF" }}>POSITION</th>
            </tr>
          </thead>
          <tbody>
            {sessionEventData &&
              sessionEventData.map((item, index) => {
                return <tr key={item.range + index + item.net_pl}>
                  <td>{item.range}</td>
                  <td style={{ borderLeft: "1px solid #EFEFEF", color: item.net_pl < 0 ? "red" : "green" }}>{item.net_pl}</td>
                </tr>
              })
            }
          </tbody>
        </table>
      </div>
      <button onClick={closeModal} style={{ position: 'fixed', bottom: 10, right: 20 }}>CLOSE</button>

    </Modal>

  )


}
export default (PositionModal);
