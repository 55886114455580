import axios from "axios";
import { toast } from "react-toastify";
import config from "../lib/app-config";
import { performRequest } from "_apiConfig/apiHandler";
import { default as apiEndPoints } from "_apiConfig/apiEndPoints";

// export const create = (data) => {
//   return axios
//     .post(config.api_baseurl + "users/create", data)
//     .then((response) => {
//       return response.data;
//     })
//     .catch((err) => {
//       if (err && err.message == "Network Error") {
//         toast.error("Please check your network connection.");
//       }
//       throw err;
//     });
// };

export const superStockList = (data) => {
  return performRequest(
    apiEndPoints.methodType.POST,
    `${config.api_baseurl}users/downline`,
    data
  );
};
