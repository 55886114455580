import config from "../lib/app-config";
import { performRequest } from "_apiConfig/apiHandler";
import { default as apiEndPoints } from "_apiConfig/apiEndPoints";

export const getSportList = (data = {}) => {
  return performRequest(
    apiEndPoints.methodType.POST,
    `${config.api_baseurl}get_all_sports`,
    data
  );
};

export const getmatchesList = (data = {}) => {
  return performRequest(
    apiEndPoints.methodType.POST,
    `${config.api_baseurl}match_list`,
    data
  );
};

export const getMatchLiveReport = (data = {}) => {
  return performRequest(
    apiEndPoints.methodType.POST,
    `${config.api_baseurl}match_detail`,
    data
  );
};

export const getBetSlip = (data = {}) => {
  return performRequest(
    apiEndPoints.methodType.POST,
    `${config.api_baseurl}bet_list`,
    data
  );
};

export const getMatchPosition = (data = {}) => {
  return performRequest(
    apiEndPoints.methodType.POST,
    `${config.api_baseurl}match_position`,
    data
  );
};

export const getBetListCount = (data = {}) => {
  return performRequest(
    apiEndPoints.methodType.POST,
    `${config.api_baseurl}bet_list_count`,
    data
  );
};

export const getMarketPosition = (data = {}) => {
  return performRequest(
    apiEndPoints.methodType.POST,
    `${config.api_baseurl}view_position`,
    data
  );
};

export const getSessionPosition = (data = {}) => {
  return performRequest(
    apiEndPoints.methodType.POST,
    `${config.api_baseurl}fancy_position`,
    data
  );
};

export const getCompanyReport = (data = {}) => {
  return performRequest(
    apiEndPoints.methodType.POST,
    `${config.api_baseurl}company_report`,
    data
  );
};

export const getDeclaredFancyLive = (data = {}) => {
  return performRequest(
    apiEndPoints.methodType.POST,
    `${config.api_baseurl}declared_fancy`,
    data
  );
};

export const getDeclaredMarketLive = (data = {}) => {
  return performRequest(
    apiEndPoints.methodType.POST,
    `${config.api_baseurl}declared_market`,
    data
  );
};

export const getDeclaredMarketSCLive = (data = {}) => {
  return performRequest(
    apiEndPoints.methodType.POST,
    `${config.api_baseurl}declared_market_sc`,
    data
  );
};

export const getDeclaredFancy = (data = {}) => {
  return performRequest(
    apiEndPoints.methodType.POST,
    `${config.api_baseurl}declared_fancy_sc`,
    data
  );
};

export const getLiveMatches = (data = {}) => {
  return performRequest(
    apiEndPoints.methodType.POST,
    `${config.api_baseurl}live_matches`,
    data
  );
};

export const getAgentReport = (data = {}) => {
  return performRequest(
    apiEndPoints.methodType.POST,
    `${config.api_baseurl}agent_report`,
    data
  );
};

export const getUserReport = (data = {}) => {
  return performRequest(
    apiEndPoints.methodType.POST,
    `${config.api_baseurl}user_report`,
    data
  );
};

export const getSubCompanyReport = (data = {}) => {
  return performRequest(
    apiEndPoints.methodType.POST,
    `${config.api_baseurl}sub_company_report`,
    data
  );
};

export const getFancyPosition = (data = {}) => {
  return performRequest(
    apiEndPoints.methodType.POST,
    `${config.api_baseurl}get_fancy_details`,
    data
  );
};

export const getCollectionReport = (data = {}) => {
  return performRequest(
    apiEndPoints.methodType.POST,
    `${config.api_baseurl}collection_report`,
    data
  );
};

export const getSubCompanayLiveReport = (data = {}) => {
  return performRequest(
    apiEndPoints.methodType.POST,
    `${config.api_baseurl}view_comp_position`,
    data
  );
};

export const getCommentaryButtons = (data = {}) => {
  return performRequest(
    apiEndPoints.methodType.POST,
    `${config.api_baseurl}get_commentary_buttons`,
    data
  );
};

export const setEventCommentary = (data = {}) => {
  return performRequest(
    apiEndPoints.methodType.POST,
    `${config.api_baseurl}set_event_commentary`,
    data
  );
};

/////// bet slip delete ////////

export const deleteBetSlip = (data = {}) => {
  return performRequest(
    apiEndPoints.methodType.POST,
    `${config.api_baseurl}cancel_bet`,
    data
  );
};

export const eventAnnouncement = (data = {}) => {
  return performRequest(
    apiEndPoints.methodType.POST,
    `${config.api_baseurl}event_announcement`,
    data
  );
};

export const updateLiveTVUrl = (data = {}) => {
  return performRequest(
    apiEndPoints.methodType.POST,
    `${config.api_baseurl}update_tv_url`,
    data
  );
};


export const updateScoreWidget = (data = {}) => {
  return performRequest(
    apiEndPoints.methodType.POST,
    `${config.api_baseurl}update_score_url`,
    data
  );
};

export const changeLocalMarketing = (data = {}) => {
  return performRequest(
    apiEndPoints.methodType.POST,
    `${config.api_baseurl}lockMarketBetting`,
    data
  );
};
export const showHideMarket = (data = {}) => {
  return performRequest(
    apiEndPoints.methodType.POST,
    `${config.api_baseurl}show_hide_market`,
    data
  );
};

export const changeFancyBetLock = (data = {}) => {
  return performRequest(
    apiEndPoints.methodType.POST,
    `${config.api_baseurl}fancyBetLock`,
    data
  );
};

export const getSettings = (data = {}) => {
  return performRequest(
    apiEndPoints.methodType.POST,
    `${config.api_baseurl}getSetting`,
    data
  );
};
export const updateSettings = (data = {}) => {
  return performRequest(
    apiEndPoints.methodType.POST,
    `${config.api_baseurl}updateSetting`,
    data
  );
};

export const getSCSessionPosition = (data = {}) => {
  return performRequest(
    apiEndPoints.methodType.POST,
    `${config.api_baseurl}fancy_pos_sc`,
    data
  );
};

export const updateEventSetting = (data = {}) => {
  return performRequest(
    apiEndPoints.methodType.POST,
    `${config.api_baseurl}updateEventSetting`,
    data
  );
};

export const updateEventType = (data = {}) => {
  return performRequest(
    apiEndPoints.methodType.POST,
    `${config.api_baseurl}update_event_text`,
    data
  );
};

export const updateMatchID = (data = {}) => {
  return performRequest(
    apiEndPoints.methodType.POST,
    `${config.api_baseurl}update_match_id`,
    data
  );
};

export const getEventText = (data = {}) => {
  return performRequest(
    apiEndPoints.methodType.POST,
    `${config.api_baseurl}get_event_text`,
    data
  );
};

export const getEventSetting = (data = {}) => {
  return performRequest(
    apiEndPoints.methodType.POST,
    `${config.api_baseurl}get_event_setting`,
    data
  );
};

export const getEventMarket = (data = {}) => {
  return performRequest(
    apiEndPoints.methodType.POST,
    `${config.api_baseurl}event_markets`,
    data
  );
};

export const getSessionBook = (data = {}) => {
  return performRequest(
    apiEndPoints.methodType.POST,
    `${config.api_baseurl}session_book`,
    data
  );
};

export const getDelaySetting = (data = {}) => {
  return performRequest(
    apiEndPoints.methodType.POST,
    `${config.api_baseurl}getDelaySetting`,
    data
  );
};

export const updateDelaySetting = (data = {}) => {
  return performRequest(
    apiEndPoints.methodType.POST,
    `${config.api_baseurl}updateDelaySetting`,
    data
  );
};

export const deleteDelaySetting = (data = {}) => {
  return performRequest(
    apiEndPoints.methodType.POST,
    `${config.api_baseurl}deleteDelaySetting`,
    data
  );
};


export const rollbackMarket = (data = {}) => {
  return performRequest(
    apiEndPoints.methodType.POST,
    `${config.api_baseurl}rollback_market`,
    data
  );
};

export const rollbackSession = (data = {}) => {
  return performRequest(
    apiEndPoints.methodType.POST,
    `${config.api_baseurl}rollback_session`,
    data
  );
};

//////////////////////// ----- match on/off for downlines ----- ///////////////////////

export const showHideEvent = (data = {}) => {
  return performRequest(
    apiEndPoints.methodType.POST,
    `${config.api_baseurl}show_hide_event`,
    data
  ).then((res) => res.data).catch(err => err)
};

export const changeCronStatus = (data = {}) => {
  return performRequest(
    apiEndPoints.methodType.POST,
    `${config.api_baseurl}updateCronStatus`,
    data
  ).then((res) => res.data).catch(err => err)
};

export const fancySectionLock = (data = {}) => {
  return performRequest(
    apiEndPoints.methodType.POST,
    `${config.api_baseurl}fancySectionLock`,
    data
  );
};

export const getMaintenanceSettings = (data = {}) => {
  return performRequest(
    apiEndPoints.methodType.POST,
    `${config.api_baseurl}maintenance_mode`,
    data
  );
};

export const updateMaintenanceSettings = (data = {}) => {
  return performRequest(
    apiEndPoints.methodType.POST,
    `${config.api_baseurl}update_maintenance_mode`,
    data
  );
};

export const getMarketBook = (data = {}) => {
  return performRequest(
    apiEndPoints.methodType.POST,
    `${config.api_baseurl}get_market_book`,
    data
  );
};

export const getSportsSetting = (data = {}) => {
  return performRequest(
    apiEndPoints.methodType.POST,
    `${config.api_baseurl}get_sports_setting`,
    data
  );
};
export const UpdateSportsSetting = (data = {}) => {
  return performRequest(
    apiEndPoints.methodType.POST,
    `${config.api_baseurl}update_sports_setting`,
    data
  );
};
export const completedGamesList = (data = {}) => {
  return performRequest(
    apiEndPoints.methodType.POST,
    `${config.api_baseurl}completed_games`,
    data
  );
};