import config from "../lib/app-config";
import { performRequest } from "_apiConfig/apiHandler";
import { default as apiEndPoints } from "_apiConfig/apiEndPoints";

export const casinoGamelist = (data = {}) => {
  return performRequest(
    apiEndPoints.methodType.POST,
    `${config.api_baseurl}casino_list`,
    data
  );
};


//////////////////////// ----- Coin History ----- ///////////////////////

export const coinsReport = (data = {}) => {
  return performRequest(
    apiEndPoints.methodType.POST,
    `${config.api_baseurl}coin_report`,
    data
  );
};


//////////////////////// ----- Result Details ----- ///////////////////////

export const casinoResult = (data = {}) => {
  return performRequest(
    apiEndPoints.methodType.POST,
    `${config.api_baseurl}casino_result`,
    data
  ).then((res) => res.data).catch(err => err)
};

//////////////////////// ----- Casino on/off for downlines ----- ///////////////////////

export const showHideCasino = (data = {}) => {
  return performRequest(
    apiEndPoints.methodType.POST,
    `${config.api_baseurl}show_hide_casino`,
    data
  ).then((res) => res.data).catch(err => err)
};

export const pendingCasinolist = (data = {}) => {
  return performRequest(
    apiEndPoints.methodType.POST,
    `${config.api_baseurl}pending_casino`,
    data
  );
};

export const casinoLiveReport = (data = {}) => {
  return performRequest(
    apiEndPoints.methodType.POST,
    `${config.api_baseurl}casino_live_report`,
    data
  );
};

export const cancelCasinoGame = (data = {}) => {
  return performRequest(
    apiEndPoints.methodType.POST,
    `${config.api_baseurl}cancel_casino_game`,
    data
  );
};

export const casinoProviders = (data = {}) => {
  return performRequest(
    apiEndPoints.methodType.POST,
    `${config.api_baseurl}casino_providers`,
    data
  );
};
export const updateUserCasinoStatus = (data = {}) => {
  return performRequest(
    apiEndPoints.methodType.POST,
    `${config.api_baseurl}update_user_casino_status`,
    data
  );
};
export const getSportsPl = (data = {}) => {
  return performRequest(
    apiEndPoints.methodType.POST,
    `${config.api_baseurl}sports_pl`,
    data
  );
};