import React, { useEffect, useState } from "react";
import "./ManageLedger.css";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";
import moment from "moment";

import ReactPaginate from "react-paginate";
import InnerPageHeader from "components/common/InnerPageHeader";

import { Link } from "react-router-dom";

import { formatDateAndTime } from "utils";
import { convertParseFloat } from "_helpers";
import { usersCashbook } from "_services";


export default function CashBook() {
  const [filter, setFilter] = useState({
    current_page: 1,
    items_perpage: 20,
    from_date: moment().subtract(29, "days"),
    to_date: moment(),
  });
  const [data, setData] = useState([null]);
  const [meta, setMeta] = useState(null);
  useEffect(() => {
    getCashbookEvent();
  }, [filter?.current_page]);
  const getCashbookEvent = async () => {
    try {
      const res = await usersCashbook({
        ...filter,
        from_date: moment.utc(filter?.from_date).local().format("YYYY-MM-DD"),
        to_date: moment.utc(filter?.to_date).local().format("YYYY-MM-DD"),
      });
      if (res) {
        setData(res?.data?.data?.records);
        setMeta({
          ...res?.data?.data?.total,
          totalCount: res?.data?.data?.count,
        });
      }
    } catch (error) { }
  };

  const handlePageClick = (e) => {
    const selectedPage = e.selected;
    setFilter((s) => ({ ...s, current_page: selectedPage + 1 }));
  };

  const handleEvent = (from_date, to_date) => {
    if (from_date) {
      setFilter((s) => ({ ...s, from_date, to_date }));
    }
  };
  return (
    <>
      <InnerPageHeader
        pageTitle="Cashbook"
        breadcrumbs={[{ title: "Dashboard", url: "/" }]}
        currenPageTitle="Cashbook"
      />
      <div className="row">
        <DateRangePicker
          initialSettings={{
            opens: "right",
            startDate: filter?.from_date
              ? filter?.from_date.toDate()
              : moment().subtract(29, "days"),
            endDate: filter?.to_date ? filter?.to_date.toDate() : moment(),
            ranges: {
              Today: [moment().toDate(), moment().toDate()],
              Yesterday: [
                moment().subtract(1, "days").toDate(),
                moment().subtract(1, "days").toDate(),
              ],
              "Last 7 Days": [
                moment().subtract(6, "days").toDate(),
                moment().toDate(),
              ],
              "This Week": [
                moment().startOf("isoWeek").toDate(),
                moment().toDate(),
              ],
              "Last Week": [
                moment().subtract(1, "week").startOf("isoWeek").toDate(),
                moment().subtract(1, "week").endOf("isoWeek").toDate(),
              ],
              "Last 30 Days": [
                moment().subtract(29, "days").toDate(),
                moment().toDate(),
              ],
              "This Month": [
                moment().startOf("month").toDate(),
                moment().toDate(),
              ],
              "Last Month": [
                moment().subtract(1, "month").startOf("month").toDate(),
                moment().subtract(1, "month").endOf("month").toDate(),
              ],
            },
          }}
          onCallback={(from_date, to_date) => handleEvent(from_date, to_date)}
        >
          <div
            id="reportrange"
            className="col-lg-3 col-md-3 col-sm-9 cbDatePicker"
          >
            <i className={"glyphicon glyphicon-calendar  fa fa-calendar"}></i>
            &nbsp;&nbsp;
            <span className="text-right">
              {filter?.from_date.format("DD MMM, YYYY") +
                " - " +
                filter?.to_date.format("DD MMM, YYYY")}
            </span>
            &nbsp;&nbsp;
            <i className="fa fa-caret-down" aria-hidden="true"></i>
          </div>
        </DateRangePicker>
        <div
          className="col-lg-3 col-md-4 col-sm-6 col-sm-3"
          style={{ margin: "11px 0px" }}
        >
          <button
            className="btn btn-primary btn-xs search_date_btn"
            onClick={() =>
              +filter?.current_page === 1
                ? getCashbookEvent()
                : setFilter((s) => ({ ...s, current_page: 1 }))
            }
          >
            Search
          </button>
        </div>
      </div>
      <div className="wrapper wrapper-content animated fadeInRight">
        <div className="row">
          <div className="col-12">
            <div className="ibox">
              <div className="ibox-title">
                <h5>Cashbook</h5>
              </div>
              <div className="ibox-content">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="table-responsive">
                      <table className="table table-striped table-bordered table-hover dataTables-example">
                        <thead>
                          <tr>
                            <th>S.No</th>
                            <th>DATE/TIME</th>
                            <th>User Name</th>
                            <th>Dena</th>
                            <th>Lena</th>
                            <th>Remark</th>
                          </tr>
                        </thead>
                        <tbody>
                          {data?.map((item, key) => (
                            <tr key={item?.username + key}>
                              <td>
                                {key +
                                  1 +
                                  (filter?.current_page - 1) *
                                  filter?.items_perpage}
                              </td>

                              <td>
                                {" "}
                                {item?.created_at
                                  ? formatDateAndTime(
                                    "MMM DD, hh:mm:ss A",
                                    item?.created_at
                                  )
                                  : "-"}
                              </td>
                              <td>{item?.username}</td>
                              <td>{item?.debit_amount}</td>
                              <td>{item?.credit_amount}</td>
                              <td
                                className={
                                  +item?.source === 2
                                    ? "text-danger"
                                    : "text-success"
                                }
                              >
                                {item?.remark}
                              </td>
                            </tr>
                          ))}
                          {data?.length > 0 && meta && (
                            <tr key={"cashtotal"}>
                              <td colSpan={3}>
                                {" "}
                                <b>Total</b>
                              </td>
                              <td>
                                <b>{convertParseFloat(meta?.denaTotal)}</b>
                              </td>
                              <td>
                                <b>{convertParseFloat(meta?.lenaTotal)}</b>
                              </td>
                              <td></td>
                            </tr>
                          )}
                        </tbody>
                      </table>

                      <div className="paginate_class">
                        <ReactPaginate
                          forcePage={filter?.current_page - 1}
                          previousLabel={"prev"}
                          nextLabel={"next"}
                          breakLabel={"..."}
                          breakClassName={"break-me"}
                          pageCount={Math.ceil(
                            meta?.totalCount / filter?.items_perpage
                          )}
                          marginPagesDisplayed={2}
                          pageRangeDisplayed={5}
                          onPageChange={handlePageClick}
                          containerClassName={"pagination"}
                          subContainerClassName={"pages pagination"}
                          activeClassName={"active"}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
