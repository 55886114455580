import React, { useEffect, useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import { profitLossSummery } from "_services";

const ProfitLossSummeryEvent = () => {
    const location = useLocation();
    const { user_id, event_id, event_name } = useParams();

    const [ProfitLossSummeryEvent, setData] = useState([]);

    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to the top of the page
    }, [location]);

    useEffect(() => {
        getProfitLossSummeryEvent()
    }, [user_id, event_id])


    const getProfitLossSummeryEvent = () => {
        let sendObj = {
            user_id,
            event_id
        };

        profitLossSummery(sendObj)
            .then((response) => {
                if (response && response?.data?.data) {
                    setData(response?.data?.data);
                }
            })
            .catch((error) => {
                console.log(error, "error");
            });
    };

    const handleBack = () => {
        window.history.back();  // Go back in the browser history
    };

    return (
        <>
            <div className="wrapper wrapper-content animated fadeInRight">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="ibox float-e-margins">
                            <div className="ibox-title" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <h5 style={{ textTransform: 'uppercase' }}> Summery of {event_name}</h5>
                                <button button onClick={handleBack} style={{ whiteSpace: 'nowrap' }}>← Back</button>
                            </div>
                            <div className="ibox-content">
                                <br />

                                <div className="table-responsive">
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th style={{ textAlign: 'left' }}>USERNAME</th>
                                                <th style={{ textAlign: 'left' }}>MY</th>
                                                <th style={{ textAlign: 'left' }}>DOWN-LINE</th>
                                                <th style={{ textAlign: 'left' }}>UP-LINE</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {ProfitLossSummeryEvent &&
                                                ProfitLossSummeryEvent.map((element, index) => {
                                                    return (
                                                        <tr key={index}>
                                                            <td>
                                                                <Link to={`/profit-loss-summery-event/${element.event_id}/${element.user_id}/${event_name}`}>
                                                                    {element.username}
                                                                </Link>
                                                            </td>
                                                            <td style={{ whiteSpace: 'initial' }}>
                                                                <span style={{ color: element.my_tot > 0 ? "green" : "red" }}>
                                                                    {element.my_tot}
                                                                </span>
                                                            </td>
                                                            <td style={{ whiteSpace: 'initial' }}>
                                                                <span style={{ color: element.dl_tot > 0 ? "green" : "red" }}>
                                                                    {element.dl_tot}
                                                                </span>
                                                            </td>
                                                            <td style={{ whiteSpace: 'initial' }}>
                                                                <span style={{ color: element.ul_tot > 0 ? "green" : "red" }}>
                                                                    {element.ul_tot}
                                                                </span>
                                                            </td>
                                                        </tr>
                                                    );
                                                })}
                                            <tr>
                                                <th>Total</th>
                                                <th style={{
                                                    color: Math.round(ProfitLossSummeryEvent?.reduce((a, v) => (a = a + v.my_tot), 0) * 100) / 100 > 0 ? 'green' : 'red',
                                                }} >
                                                    {Math.round(ProfitLossSummeryEvent?.reduce((a, v) => (a = a + v.my_tot), 0) * 100) / 100}
                                                </th>
                                                <th style={{
                                                    color: Math.round(ProfitLossSummeryEvent?.reduce((a, v) => (a = a + v.dl_tot), 0) * 100) / 100 > 0 ? 'green' : 'red',
                                                }} >
                                                    {Math.round(ProfitLossSummeryEvent?.reduce((a, v) => (a = a + v.dl_tot), 0) * 100) / 100}
                                                </th>
                                                <th style={{
                                                    color: Math.round(ProfitLossSummeryEvent?.reduce((a, v) => (a = a + v.ul_tot), 0) * 100) / 100 > 0 ? 'green' : 'red',
                                                }} >
                                                    {Math.round(ProfitLossSummeryEvent?.reduce((a, v) => (a = a + v.ul_tot), 0) * 100) / 100}
                                                </th>

                                            </tr>
                                        </tbody>
                                    </table>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div >
        </>
    );
}

export default ProfitLossSummeryEvent