import React from "react";
import AddMaster from "../../../components/ManualMarkets/Add";
import { connect, useDispatch } from "react-redux";

function Add() {
  const dispatch = useDispatch();
  return <AddMaster dispatch={dispatch} />;
}
const mapstatetop = (state) => ({
  ...state,
});

export default connect(mapstatetop)(Add);
