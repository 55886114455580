
import React from "react";
import { useLocation, Link, useParams } from "react-router-dom";


function SessionEndingReport() {
  const location = useLocation();
  const { matchData } = location.state;

  const { event_id } = useParams();

  return (
    <>
      {/* <InnerPageHeader
        pageTitle="Matches List"
        breadcrumbs={[
          { title: "Dashboard", url: "/" },
          { title: "Matches", url: "/matches_list" },
          { title: matchData.event_name, url: `/matches_list` },
        ]}
        currenPageTitle={"Session Earning Report"}
      /> */}
      <div className="row wrapper border-bottom white-bg page-heading">
        <div className="col-lg-10">
          <h2>Session Earning Report</h2>
          <ol className="breadcrumb">
            <li>
              <a href="/">Dashboard</a>
            </li>
            <li>
              <a href="/matches_list">Matches</a>
            </li>
            <li className="breadcrumb-item active">
              <Link to={`/match_dashboard/${event_id}`} state={{ matchData }}>
                {matchData?.event_name}
              </Link>
            </li>
            <li className="breadcrumb-item active">
              <strong>Session Earning Report</strong>
            </li>
          </ol>
        </div>
        <div className="col-lg-2"></div>
      </div>

      <div className="wrapper wrapper-content animated fadeInRight">
        <div className="row">
          <div className="col-lg-12">
            <div className="ibox float-e-margins">
              <div className="ibox-title">
                <h5>Agent</h5>
                <div className="ibox-tools"></div>
              </div>
              <div className="ibox-content table-responsive">
                <table className="table table-bordered table-responsive">
                  <thead>
                    <tr className="tbl-header-middle">
                      <th colSpan="1"></th>
                      <th colSpan="3">SYSTEM</th>
                      <th colSpan="2">My Share</th>
                      <th colSpan="1">Company Share</th>
                    </tr>
                    <tr>
                      <th>Client Name</th>
                      <th>SESSION</th>
                      <th>Comm. Session</th>
                      <th>TOTAL</th>
                      <th>Current % Share</th>
                      <th>SHR AMT</th>
                      <th>Final</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>BSC5059 (Guru)</td>
                      <td>0.0</td>
                      <td>0.0</td>
                      <td>0.0</td>
                      <td>50.0</td>
                      <td>0.0</td>
                      <td>0.0</td>
                    </tr>
                  </tbody>
                  <tfoot>
                    <tr>
                      <th>Total</th>
                      <th>0.0</th>
                      <th>0.0</th>
                      <th>0.0</th>
                      <th></th>
                      <th>0.0</th>
                      <th>0.0</th>
                    </tr>
                  </tfoot>
                </table>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-12">
            <div className="ibox float-e-margins">
              <div className="ibox-title">
                <h5>All Users</h5>
                <div className="ibox-tools"></div>
              </div>
              <div className="ibox-content table-responsive">
                <table className="table table-bordered table-responsive">
                  <thead>
                    <tr className="tbl-header-middle">
                      <th colSpan="1"></th>
                      <th colSpan="3">SYSTEM</th>
                      <th colSpan="2">My Share</th>
                      <th colSpan="1">Company Share</th>
                    </tr>
                    <tr>
                      <th>CLIENT NAME</th>
                      <th>SESSION</th>
                      <th>COMM. SESSION</th>
                      <th>TOTAL</th>
                      <th>Current % Share</th>
                      <th>SHR AMT</th>
                      <th>Final</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>BS55278 (Av) </td>
                      <td>0.0</td>
                      <td>0</td>
                      <td>0.0</td>
                      <td>0.0</td>
                      <td>0.0</td>
                      <td>0.0</td>
                    </tr>

                    <tr>
                      <td>BS55288 (Naredr) </td>
                      <td>0.0</td>
                      <td>0</td>
                      <td>0.0</td>
                      <td>0.0</td>
                      <td>0.0</td>
                      <td>0.0</td>
                    </tr>
                  </tbody>
                  <tfoot>
                    <tr>
                      <th></th>
                      <th>0.0</th>
                      <th>0</th>
                      <th>0.0</th>
                      <th></th>
                      <th>0.0</th>
                      <th>0.0</th>
                    </tr>
                  </tfoot>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SessionEndingReport;
