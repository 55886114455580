import { authConstants } from "../../_constants";
import { getStorage } from "../../utils";

let initialState = {
  userLogin: getStorage("userLogin", "object", {}),
  isLoader: false,
  domainData: null,
};

export function auth(state = initialState, action) {
  switch (action.type) {
    case authConstants.LOGIN_REQUEST:
      return {
        ...state,
        isLoader: true,
      };
    case authConstants.LOGIN_SUCCESS:
      localStorage.setItem("userLogin", JSON.stringify(action.data));
      action.data.role && localStorage.setItem("role", action.data.role);
      action.data.token && localStorage.setItem("token", action.data.token);
      return {
        ...state,
        userLogin: action.data,
        isLoader: false,
      };
    case authConstants.LOGOUT_REQUEST:
      return {
        ...state,
        isLoader: true,
      };
    case authConstants.LOGOUT_SUCCESS:
      return {
        ...state,
        userLogin: {},
        isLoader: false,
      };
    case authConstants.DOMAIN_DATA_REQUEST:
      return {
        ...state,
        isLoader: true,
      };
    case authConstants.DOMAIN_DATA_SUCCESS:
      return {
        ...state,
        domainData: action.data,
        isLoader: false,
      };
    case authConstants.FAILED_RESPONSE:
      return {
        ...state,
        isLoader: false,
      };
    default:
      return state;
  }
}
