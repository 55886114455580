import React, { useEffect, useMemo, useState } from 'react'
import InnerPageHeader from "./InnerPageHeader";
import { usersLedger, cashLedger, matchLedger } from "_services/userDetails.services";
import moment from "moment";

import DateRangePickerComponent from "./DateRangePickerComponent";
import ReactPaginate from 'react-paginate';

export default function CashLedger(props) {

    const { type, subtype, id, username } = props?.params;

    const [fromDate, setFromDate] = useState(null);
    const [toDate, setToDate] = useState(null);
    const [changePage, setChangePage] = useState(1);

    const [userData, setUserData] = useState([]);
    const [pageCount, setPageCount] = useState(0)
    const [perPage] = useState(50);


  useEffect(() => {
    fetchCashLedger();
  }, [changePage])

    useEffect(() => {
        if (subtype && subtype === "cashledger") {
            fetchCashLedger();
        }
    }, [fromDate])

    const fetchCashLedger = () => {

        const from_date = fromDate? (moment(moment.utc(fromDate).local().format("YYYY-MM-DD 00:00:00"),"YYYY-MM-DD HH:mm:ss").utc().format("YYYY-MM-DD HH:mm:ss")):"";
        const to_date =   toDate? (moment(moment.utc(toDate).local().format("YYYY-MM-DD 23:59:00"),"YYYY-MM-DD HH:mm:ss").utc().format("YYYY-MM-DD HH:mm:ss")):'';
        let sendObj = {};
        sendObj.user_guid = id;
        sendObj.from_date = from_date;
        sendObj.to_date = to_date;
        sendObj.filter = 1;
        sendObj.current_page = 1;
        sendObj.current_page = changePage;
        sendObj.items_perpage = perPage;
        cashLedger(sendObj)
            .then((response) => {

                if (response && response.data && response.data.data) {
                    setUserData(response?.data?.data);
                    setPageCount( Math.ceil(response?.data.data.total_records / perPage))
                }
            })
            .catch((error) => {
                console.log(error, "error")
            });

    };

    const handleEvent = (from_date, to_date) => {
        if(from_date){
            let fromDate = from_date._d;
            let toDate = to_date._d;
            setToDate(toDate);
            setFromDate(fromDate);
       }
    }


  const handlePageClick = (e) => {
    const selectedPage = e.selected;
    setChangePage(selectedPage + 1)
  };

    return (
        <>
            <InnerPageHeader
                pageTitle={type}
                breadcrumbs={[
                    { title: "Dashboard", url: "/" },
                    { title: type, url: `/userview/${type}/${id}/${username}` },
                ]}
                currenPageTitle="List"
            />

            <div className="row">
                <DateRangePickerComponent handleEvent={(from_date, to_date)=>handleEvent(from_date, to_date)} fDate={fromDate} tDate={toDate} />
                <div
                    className="col-lg-3 col-md-4 col-sm-6 col-xs-12"
                    style={{ margin: "11px 0px" }}
                >
                    <a className="btn btn-primary btn-xs search_date_btn">Search</a>
                </div>
            </div>

            <div className="wrapper wrapper-content animated fadeInRight">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="ibox float-e-margins">
                            <div className="ibox-title">
                                <h5>Agent Ledger</h5>
                                <div className="ibox-tools">
                                    <a
                                        className="dropdown-toggle"
                                        data-toggle="dropdown"
                                        href="#"
                                        aria-expanded="true"
                                    >
                                        <i className="fa fa-wrench"></i>
                                    </a>
                                    <ul className="dropdown-menu dropdown-user">
                                        <li>
                                            <a
                                                target="_blank"
                                                href="/agent/agents/3116/ledger_show_pdf.pdf"
                                            >
                                                Export PDF
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="ibox-content">
                                <div className="table-responsive">
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th>DATE/TIME</th>
                                                <th>Collection Name</th>
                                                <th>DEBIT</th>
                                                <th>CREDIT</th>
                                                <th>Balance</th>
                                                <th>Note</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                userData?.ledger?.map((el, index) => {
                                                    return (
                                                        <tr key={index}>
                                                            <td id="getdate_2794093">
                                                                <i className="fa fa-clock-o"></i>&nbsp;
                                                              {moment.utc(el.created_at).local().format('YYYY-MM-DD HH:mm a')}
                                                                 {/* {el.created_at} */}
                                                            </td>

                                                            <td>{el?.remark}</td>
                                                            <td> {el.debit_amount} </td>
                                                            <td>{el.credit_amount}</td>

                                                            <td>{el.ledger_balance}</td>
                                                            <td>
                                                                <div className="note-short-text full_text_modal">{el.note}</div>
                                                            </td>
                                                        </tr>
                                                    )
                                                })
                                            }

                                        </tbody>
                                    </table>
                                </div>
                                <div className="paginate_class">
                                <ReactPaginate
                                    previousLabel={"prev"}
                                    nextLabel={"next"}
                                    breakLabel={"..."}
                                    breakClassName={"break-me"}
                                    pageCount={pageCount}
                                    marginPagesDisplayed={2}
                                    pageRangeDisplayed={5}
                                    onPageChange={handlePageClick}
                                    containerClassName={"pagination"}
                                    subContainerClassName={"pages pagination"}
                                    activeClassName={"active"} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
