import Main from "components/layouts/Main";
import { Navigate } from "react-router-dom";
import { getStorage } from "utils";

export const ProtectRoutes = ({ children }) => {
  // const loggedIn = getStorage("logged-in");
  let data = getStorage("userLogin", "object");
  // let isLoggedIn = rest?.auth?.userLogin?.user_id ? true : false;

  // // if (!isLoggedIn) {
  // //   toast.error("Please login to continue.");
  // // }

  // if (localStorage.getItem("token") && !localStorage.getItem("profile")) {
  //   profileDetails()
  //     .then((response) => {
  //       if (response && response.data && response.data.data) {
  //         localStorage.setItem("profile", JSON.stringify(response.data.data));
  //       }
  //     })
  //     .catch((error) => {});
  // }

  if (!data) {
    return <Navigate to={"/login"} />;
  }
  return <Main> {children} </Main>;
};
