import React, { Component } from "react";

import { connect } from "react-redux";
import NumberForm from "components/MatkaNumber/NumberForm";

class Edit extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  editNumber = (data) => {

  };

  render() {
    return (
      
        <NumberForm
          {...this.props}
          type="fast"
          breadcrumbTitle={'Edit Number'}
          editNumber={this.editNumber}
          edit={true}
        />
      
    );
  }
}
const mapstatetop = (state) => ({
  ...state,
});
export const EditFastMatkaNumber = connect(mapstatetop)(Edit);
