import React from "react";
import { connect, useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import AddUser from "components/common/AddUser";
import { createRequest } from "_actions/master/master.actions";
import AddNewUser from "components/AddNewUser/AddNewUser";

function Add(props) {
  const role = 4;
  const navigate = useNavigate();
  const params = useParams();
  const dispatch = useDispatch();

  const createUser = (sendObj) => {
    dispatch(
      createRequest(sendObj, (res) => {
        if (res) {
          navigate("/master");
        }
      })
    );
  };

  return (
    // <AddUser
    <AddNewUser
      navigationUrl="/master"
      onSubmitFunc={createUser}
      role={role}
      action={params?.id ? "Edit Master" : "Create Master"}
      navigate={navigate}
      parentType="Master"
      isEdit={Boolean(params?.id)}
      params={params}
      showFixSharing={true}
      {...props}
    />
  );
}
const mapstatetop = (state) => ({
  ...state,
});

export const AddMaster = connect(mapstatetop)(Add);
