import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { coinsReport, getSportsPl } from "../../_services/casino.services";
import ReactPaginate from "react-paginate";

import moment from "moment";
import InnerPageHeader from "components/common/InnerPageHeader";
import DateRangePickerComponent from "components/common/DateRangePickerComponent";
import { useParams } from "react-router-dom";

const SportsPl = () => {
    const { user_id } = useParams();

    const [changePage, setChangePage] = useState(1);
    const [sportsPl, setSportsPL] = useState([]);
    const [total, setTotal] = useState(0);
    const [perPage] = useState(50);
    const [pageCount, setPageCount] = useState(0);
    const [fromDate, setFromDate] = useState(
        moment().subtract(15, 'days').startOf('day').toDate() // Default to 15 days ago
    );
    const [toDate, setToDate] = useState(
        moment().endOf('day').toDate() // Default to today
    );

    useEffect(() => {
        coinHistoryReport();
    }, [changePage]);

    useEffect(() => {
        coinHistoryReport()
    }, [fromDate])

    const coinHistoryReport = () => {
        let sendObj = {
            current_page: changePage,
            items_perpage: perPage,
            user_id,
            report_type: 2,
            from_date: moment(fromDate).utc().format("YYYY-MM-DD HH:mm:ss"),
            to_date: moment(toDate).utc().format("YYYY-MM-DD HH:mm:ss")
        };

        getSportsPl(sendObj)
            .then((response) => {
                if (response && response?.data?.data) {


                    const sportsArray = Object.entries(response?.data?.data); // Convert object to array of [key, value] pairs
                    setSportsPL(sportsArray);

                    const totalValue = sportsArray.reduce((sum, [, value]) => sum + value, 0);
                    setTotal(totalValue);

                    // setPageCount(Math.ceil(response?.data?.data?.coin_report.length / perPage))

                    // setPageCount(Math.ceil(response?.data?.data?.total / perPage));
                }
            })
            .catch((error) => {
                console.log(error, "error");
            });
    };

    const handlePageClick = (e) => {
        const selectedPage = e.selected;
        setChangePage(selectedPage + 1);
    };

    const handleEvent = (from_date, to_date) => {
        if (from_date && to_date) {
            setFromDate(from_date._d);
            setToDate(to_date._d);
        }
    }

    return (
        <>
            <InnerPageHeader
                pageTitle={'Sports PL'}
                breadcrumbs={[
                    { title: "Dashboard", url: "/" },
                    // { title: type, url: `/userview/${type}/${id}/${username}` },
                ]}
                currenPageTitle="Sports PL"
            />


            <div className="wrapper wrapper-content animated fadeInRight">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="ibox float-e-margins">
                            <div className="ibox-title">
                                <h5> {'Sports PL'} </h5>
                            </div>
                            <div className="ibox-content">
                                <div className="card-content">
                                    <div className="row">
                                        <div className="col s12">
                                            <ul className="collection with-header" style={{ margin: 0 }}>
                                                <li className="collection-header">
                                                    <h5>Sports Detail</h5></li>
                                                {sportsPl.map(([sport, value], index) => (
                                                    <li key={index} className="collection-item" style={{ paddingLeft: 10 }}>
                                                        <div>{sport.charAt(0).toUpperCase() + sport.slice(1)}: {value}</div>
                                                    </li>
                                                ))}
                                                <li className="collection-item" style={{ paddingLeft: 10 }}>
                                                    <div>Total: {total}</div>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default SportsPl