import React, { useEffect, useState } from "react";

import ReportList from "../../components/MatkaReport/List";
import { connect } from "react-redux";
import { reportData } from "./reportdata";
import SingleReportList from "../../components/MatkaReport/SingleReportList";
import { useNavigate, useParams } from "react-router-dom";

function List(props) {
  const [breadcrumbTitle, setBreadcrumbTitle] = useState();
  const params = useParams();
  const navigate = useNavigate();
  useEffect(() => {
    switch (params?.rp_type) {
      case "single_patti":
        setBreadcrumbTitle("Single Patti");
        break;
      case "double_patti":
        setBreadcrumbTitle("Double Patti");
        break;
      case "tripple_patti":
        setBreadcrumbTitle("Tripple Patti");
        break;
      case "jodi_patti":
        setBreadcrumbTitle("Jodi Patti");
        break;
      case "single_akda":
        setBreadcrumbTitle("Single Akda");
        break;
      default:
        navigate(-1);
        break;
    }
  }, [params]);

  return (
    <>
      {params?.rp_type === "single_akda" ? (
        <SingleReportList
          dataList={reportData}
          {...props}
          breadcrumbTitle={breadcrumbTitle + " Report"}
        />
      ) : (
        <ReportList
          dataList={reportData}
          {...props}
          breadcrumbTitle={breadcrumbTitle + " Report"}
        />
      )}
    </>
  );
}
const mapstatetop = (state) => ({
  ...state,
});

export const FastMatkaReport = connect(mapstatetop)(List);
