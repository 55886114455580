import React, { Component, Fragment } from "react";
import "./ManagePassword.css";
import { toast } from "react-toastify";
import { changePassword } from "../../_services/password.services";
import InnerPageHeader from "components/common/InnerPageHeader";

export class ManagePassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      oldPassword: "",
      confirmPassword: "",
      newPassword: "",
    };
  }

  handleInput = (e) => {
    let label = e.target.name;
    let value = e.target.value;
    if (label === "oldPassword") this.setState({ oldPassword: value });
    else if (label === "newPassword") this.setState({ newPassword: value });
    else this.setState({ confirmPassword: value });
  };

  onSubmit(e) {
    e.preventDefault();

    if (this.state.newPassword === this.state.confirmPassword) {
      let sendData = {};
      sendData.old_password = this.state.oldPassword;
      sendData.new_password = this.state.newPassword;
      sendData.confirm_password = this.state.confirmPassword;

      changePassword(sendData)
        .then(() => {
          toast("Password Change Successfully");
        })
        .catch(() => {
          toast("Failed , Password Not Change");
        });
    } else {
      toast("NewPassword and ConfirmPassword Doesn't match");
    }
  }

  getRole = () => {
    var roleValue = "User";
    let role = localStorage.getItem("role") || null;
    switch (role) {
      case "1":
        return "Company";

      case "2":
        return "Sub Company";

      case "3":
        return "Sub Admin";

      case "4":
        return "Admin";

      case "5":
        return "Super Senior";

      case "6":
        return "Super";

      case "7":
        return "Dealer";

      case "8":
        return "Agent";

      case "9":
        return "Client";

      case "10":
        return "Staff";

      default:
        return "User";
    }

    // return roleValue;
  };

  render() {
    const { oldPassword, confirmPassword, newPassword } = this.state;
    return (
      <Fragment>
        <InnerPageHeader
          pageTitle={this.getRole()}
          breadcrumbs={[{ title: "Dashboard", url: "/" }]}
          currenPageTitle="Manage Password"
        />
        <div className="wrapper wrapper-content animated fadeInRight">
          <div className="row">
            <div className="col-12">
              <div className="ibox">
                <div className="ibox-title">
                  <h5>Change Password</h5>
                </div>
                <div
                  className="ibox-content"
                  style={{ padding: "15px 20px 56px" }}
                >
                  <div className="row">
                    <div className="col-lg-12">
                      <form>
                        <div className="form-group row">
                          <label
                            htmlFor="inputOldPassword"
                            className="col-sm-2 col-form-label"
                          >
                            OLD PASSWORD
                          </label>
                          <div className="col-sm-10">
                            <input
                              type="password"
                              className="form-control"
                              id="inputNewPassword"
                              value={oldPassword}
                              name="oldPassword"
                              placeholder="OLD PASSWORD"
                              onChange={(e) => this.handleInput(e)}
                            />
                          </div>
                        </div>
                        <hr />
                        <div className="form-group row">
                          <label
                            htmlFor="inputNewPassword"
                            className="col-sm-2 col-form-label"
                          >
                            NEW PASSWORD&nbsp;
                            <span
                              className="d-inline-block"
                              data-toggle="tooltip"
                              title="New password should contain - atleast one number, one lowercase letter, one uppercase letter, length should be atleast 8 characters"
                            >
                              <i
                                className="fa fa-info-circle"
                                aria-hidden="true"
                              ></i>
                            </span>
                          </label>
                          <div className="col-sm-10">
                            <input
                              type="password"
                              name="newPassword"
                              className="form-control"
                              id="inputNewPassword"
                              placeholder="NEW PASSWORD"
                              value={newPassword}
                              onChange={(e) => this.handleInput(e)}
                            />
                          </div>
                        </div>
                        <hr />
                        <div className="form-group row">
                          <label
                            htmlFor="inputConfirmPassword"
                            className="col-sm-2 col-form-label"
                          >
                            CONFIRM PASSWORD
                          </label>
                          <div className="col-sm-10">
                            <input
                              type="password"
                              className="form-control"
                              id="inputConfirmPassword"
                              placeholder="CONFIRM PASSWORD"
                              value={confirmPassword}
                              inValid={newPassword !== confirmPassword}
                              onChange={(e) => this.handleInput(e)}
                            />
                          </div>
                        </div>
                      </form>
                      <div className="form-group row">
                        <div className="col-sm-4 col-sm-offset-2">
                          <button
                            type="submit"
                            className="btn btn-primary"
                            onClick={(e) => this.onSubmit(e)}
                          >
                            Save Changes
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default ManagePassword;
