import config from "../lib/app-config";
import { performRequest } from "_apiConfig/apiHandler";
import { default as apiEndPoints } from "_apiConfig/apiEndPoints";

export const getLeagueList = (data) => {
  return performRequest(
    apiEndPoints.methodType.POST,
    `${config.api_baseurl}get_sport_leagues`,
    data
  );
};

export const activeDeactiveLeague = (data) => {
  return performRequest(
    apiEndPoints.methodType.POST,
    `${config.api_baseurl}active_deactive_league`,
    data
  );
};

export const getLeagueEventsList = (data = {}) => {
  return performRequest(
    apiEndPoints.methodType.POST,
    `${config.api_baseurl}get_league_events`,
    data
  );
};

export const getLeagueMarketsList = (data = {}) => {
  return performRequest(
    apiEndPoints.methodType.POST,
    `${config.api_baseurl}get_event_markets`,
    data
  );
};


////////////--------- manage ledgers__collection report api ----------////////


export const getManageLedgersCollectionReport = (data = {}) => {
  return performRequest(
    apiEndPoints.methodType.POST,
    `${config.api_baseurl}collection_report`,
    data
  );
};



///////////////------------- manage ledgers__ my ledgers api --------------//////////


export const myLedgersReport = (data = {}) => {
  return performRequest(
    apiEndPoints.methodType.POST,
    `${config.api_baseurl}agentledger`,
    data
  );
};

///////////////------------- manage ledgers__ profit & loss api --------------//////////


export const profitLossReport = (data = {}) => {
  return performRequest(
    apiEndPoints.methodType.POST,
    `${config.api_baseurl}pl_report`,
    data
  );
};

export const profitLossReportNew = (data = {}) => {
  return performRequest(
    apiEndPoints.methodType.POST,
    `${config.api_baseurl}pl_report_new`,
    data
  );
};

export const profitLossMarket = (data = {}) => {
  return performRequest(
    apiEndPoints.methodType.POST,
    `${config.api_baseurl}profit_loss_market`,
    data
  );
};

export const marketLadger = (data = {}) => {
  return performRequest(
    apiEndPoints.methodType.POST,
    `${config.api_baseurl}market_ledger`,
    data
  );
};
export const profitLossSummery = (data = {}) => {
  return performRequest(
    apiEndPoints.methodType.POST,
    `${config.api_baseurl}profit_loss_summery`,
    data
  );
};
export const profitLossSummeryM = (data = {}) => {
  return performRequest(
    apiEndPoints.methodType.POST,
    `${config.api_baseurl}market_ledger`,
    data
  );
};


export const casinoLedgerDetail = (data = {}) => {
  return performRequest(
    apiEndPoints.methodType.POST,
    `${config.api_baseurl}casino_ledger_detail`,
    data
  );
};