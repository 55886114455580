import React from "react";
import { useEffect } from "react";
import { casinoLedgerDetail } from "_services/league.services";
import { useParams } from "react-router-dom";
import { useState } from "react";
import moment from "moment";
import InnerPageHeader from "components/common/InnerPageHeader";

export default function ShowSettleComp() {
  const { source_id, user_id } = useParams();
  const [ledgerList, updateLedgerList] = useState([]);
  const [totalCredit, updateTotalCredit] = useState(null);
  const [totalDebit, updateTotalDebit] = useState(null);

  useEffect(() => {
    getLedger();
  }, [source_id]);

  const getLedger = () => {
    const sendData = { source_id, user_id };
    casinoLedgerDetail(sendData)
      .then((response) => {
        updateLedgerList(response?.data?.data?.ledger);
        updateTotalCredit(response?.data?.data?.total_credit);
        updateTotalDebit(response?.data?.data?.total_debit);
      })
      .catch((error) => {
        updateLedgerList([]);
      });
  };

  return (
    <>
      <InnerPageHeader
        pageTitle="Match List"
        breadcrumbs={[{ title: "Dashboard", url: "/" }]}
        currenPageTitle="Show-Settlement"
      />

      <div className="wrapper wrapper-content animated fadeInRight body_fonts_style">
        <div className="row">
          {ledgerList &&
            ledgerList.map((ledgerItem, index) => {
              return (
                <div className="col-12">
                  <div className="ibox">
                    <div className="ibox-title">
                      {/* <h5 className="font_dark_1"> Tin Patti 20-20 17 Nov 12:00 AM Match ID : 1347273  </h5> */}
                      <h5 className="font_dark_1">
                        {ledgerItem.remark}{" "}
                        {moment
                          .utc(ledgerItem.created_at)
                          .local()
                          .format("DD MMM hh:mm A")}{" "}
                        {" Match ID : "}
                        {ledgerItem.source_id}
                      </h5>
                    </div>
                    <div className="ibox-content">
                      <div className="table-responsive">
                        <table className="table">
                          <thead>
                            <th style={{ width: "20%" }}>ID</th>
                            <th style={{ width: "25%" }}>Match ID</th>
                            <th style={{ width: "25%" }}>Match Title</th>
                            <th style={{ width: "15%" }}>Debit</th>
                            <th style={{ width: "15%" }}>Credit</th>
                          </thead>
                          <tbody>
                            <tr>
                              <td style={{ width: "20%" }}>
                                {" "}
                                {ledgerItem.txn_no}{" "}
                              </td>
                              <td style={{ width: "25%" }}>
                                {" "}
                                {ledgerItem.source_id}{" "}
                              </td>
                              <td style={{ width: "25%" }}>
                                {" "}
                                {ledgerItem.remark}{" "}
                                {moment
                                  .utc(ledgerItem.created_at)
                                  .local()
                                  .format("DD MMM hh:mm A")}{" "}
                              </td>
                              <td style={{ width: "15%" }}>
                                {ledgerItem.credit_amount}{" "}
                              </td>
                              <td style={{ width: "15%" }}>
                                {" "}
                                {ledgerItem.debit_amount}{" "}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          <div className="col-12">
            <div className="ibox">
              <div className="ibox-content">
                <div className="table-responsive">
                  <table className="table" style={{ border: 0 }}>
                    <tbody>
                      <tr>
                        <td style={{ width: "20%" }}> Total </td>
                        <td style={{ width: "25%" }}> </td>
                        <td style={{ width: "25%" }}> </td>
                        <td style={{ width: "15%" }}>{totalCredit} </td>
                        <td style={{ width: "15%" }}> {totalDebit}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
