import React from "react";
import MatkaCategoryForm from "../../components/MatkaCategory/MatkaCategoryForm";
import { connect } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

function Edit(props) {
  const location = useLocation();
  const navigate = useNavigate();
  return (
    <MatkaCategoryForm
      {...props}
      location={location}
      breadcrumbTitle={"Edit Category"}
      edit={true}
      navigate={navigate}
    />
  );
}
const mapstatetop = (state) => ({
  ...state,
});

export const EditMatkaCategory = connect(mapstatetop)(Edit);
