import config from "../lib/app-config";
import { performRequest } from "_apiConfig/apiHandler";
import { default as apiEndPoints } from "_apiConfig/apiEndPoints";

export const creditCompanyCoins = (data) => {
  return performRequest(
    apiEndPoints.methodType.POST,
    `${config.api_baseurl}users/credit_company_coins`,
    data
  );
};

export const listManualMarkets = (data) => {
  return performRequest(
    apiEndPoints.methodType.POST,
    `${config.api_baseurl}manual_match_list`,
    data
  );
};
