import InnerPageHeader from "components/common/InnerPageHeader";
import React, { useState, useEffect } from "react";
import "./ManageLedger.css";

import moment from "moment";
import { useNavigate } from "react-router-dom";
import { getManageLedgersCollectionReport, reset } from "_services";

import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";

export default function CollectionReport() {
  const navigate = useNavigate();

  const [collectionReportList, setCollectionReportList] = useState({
    1: [],
    2: [],
    3: [],
  });

  // const [fromDate, setfromDate] = useState(moment().subtract(6, 'days'))
  // const [toDate, settoDate] = useState(moment())

  const fetchCollectionReport = () => {
    let sendData = {};
    sendData.from_date = "";
    sendData.to_date = "";
    sendData.status = 1;
    getManageLedgersCollectionReport(sendData)
      .then((response) => {
        if (response && response.data && response.data.data) {
          setCollectionReportList(response.data.data);
        } else {
          setCollectionReportList([]);
        }
      })
      .catch((error) => {
        setCollectionReportList([]);
      });
  };


  useEffect(() => {
    fetchCollectionReport();
  }, []);

  const [state, setState] = useState({
    // start: moment().subtract(29, 'days') ? moment().subtract(29, 'days') : null,
    // end: moment() ? moment() : null,

    // start: moment().subtract(29, 'days'),
    // end: moment(),

    start: null,
    end: null,
  });

  const { start, end } = state;

  const handleCallback = (start, end) => {
    setState({ start, end });

    let dateObject = {
      from_date: moment(
        moment.utc(start).local().format("YYYY-MM-DD 00:00:00"),
        "YYYY-MM-DD HH:mm:ss"
      )
        .utc()
        .format("YYYY-MM-DD HH:mm:ss"),
      to_date: moment(
        moment.utc(end).local().format("YYYY-MM-DD 23:59:00"),
        "YYYY-MM-DD HH:mm:ss"
      )
        .utc()
        .format("YYYY-MM-DD HH:mm:ss"),
      status: 1,
    };

    getManageLedgersCollectionReport(dateObject)
      .then((response) => {
        if (response && response.data && response.data.data) {
          setCollectionReportList(response.data.data);
        } else {
          setCollectionReportList([]);
        }
      })
      .catch((error) => {
        setCollectionReportList([]);
      });
  };

  const label =
    start && end
      ? start.format("MMMM D, YYYY") + " - " + end.format("MMMM D, YYYY")
      : null;

  const reset = () => {
    fetchCollectionReport();
  };

  return (
    <>
      <InnerPageHeader
        pageTitle="Manage Ledger"
        breadcrumbs={[
          { title: "Dashboard", url: "/" },
          { title: "List", url: "/" },
        ]}
        currenPageTitle={"Collection Report"}
      />

      <div className="">
        <div className="row">
          <div className="col-md-12 flex_class_colletion">


            <DateRangePicker
              initialSettings={{
                opens: "right",
                startDate: start
                  ? start.toDate()
                  : moment().subtract(29, "days"),
                endDate: end ? end.toDate() : moment(),

                ranges: {
                  Today: [moment().toDate(), moment().toDate()],
                  Yesterday: [
                    moment().subtract(1, "days").toDate(),
                    moment().subtract(1, "days").toDate(),
                  ],
                  "Last 7 Days": [
                    moment().subtract(6, "days").toDate(),
                    moment().toDate(),
                  ],
                  "Last 30 Days": [
                    moment().subtract(29, "days").toDate(),
                    moment().toDate(),
                  ],
                  "This Month": [
                    moment().startOf("month").toDate(),
                    moment().endOf("month").toDate(),
                  ],
                  "Last Month": [
                    moment().subtract(1, "month").startOf("month").toDate(),
                    moment().subtract(1, "month").endOf("month").toDate(),
                  ],
                },
              }}
              onCallback={handleCallback}
            >
              <div
                id="reportrange"
                className="col-4"
                style={{
                  background: "#fff",
                  cursor: "pointer",
                  padding: "5px 10px",
                  border: "1px solid #ccc",
                  width: "25%",
                }}
              >
                <span style={{ float: "right" }}>
                  <i className="fa fa-calendar"></i>&nbsp;
                  <span> {label ? label : "-"} </span>{" "}
                  <i className="fa fa-caret-down"></i>
                </span>
              </div>
            </DateRangePicker>

            <div
              className="col-lg-4 col-md-4 col-sm-6 col-sm-3"
              style={{
                marginleft: "190px",
                text: "center",
                display: "flex",
                // marginTop: "11px"
              }}
            >
              <a
                className="btn btn-primary btn-xs search_date_btn text-center"
                onClick={reset}
              >
                {" "}
                Reset{" "}
              </a>
            </div>
          </div>
        </div>
      </div>

      <div className="wrapper wrapper-content animated fadeInRight">
        <div className="row">
          <div className="col-lg-4 col-md-4 col-sm-12">
            <div className="ibox">
              <div className="ibox-title">
                <h5 style={{ fontWeight: "bold" }}>
                  PAYMENT RECEIVING FROM (Lena He)
                </h5>
                <div className="ibox-tools">
                  <a
                    className="dropdown-toggle"
                    data-toggle="dropdown"
                    href="#"
                    aria-expanded="true"
                  >
                    <i className="fa fa-wrench"></i>
                  </a>
                  <ul className="dropdown-menu dropdown-user">
                    <li>
                      <a
                        target="_blank"
                        href="/agent/agents/3116/ledger_show_pdf.pdf"
                      >
                        Export PDF
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="ibox-content">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="table-responsive">
                      <table className="table  dataTables-example">
                        <thead>
                          <tr>
                            <th>Client</th>
                            <th>Balance</th>
                          </tr>
                        </thead>
                        <tbody>
                          {collectionReportList[1]?.map((el, ind) => {
                            return (
                              <tr key={ind}>
                                <td>
                                  <a
                                    href="/"
                                    onClick={(e) => {
                                      localStorage.setItem(
                                        "username",
                                        JSON.stringify(el.name)
                                      );
                                      e.preventDefault();
                                      navigate(
                                        // `/userview/collection-report/${el.user_guid}/${el.username}`
                                        `/userdetails/collection-report/receivecash/${el.user_guid}/${el.username}`
                                      );
                                    }}
                                  >
                                    {el.username} ({(el.first_name).trim()})
                                  </a>
                                </td>
                                <td>{el.ledger_balance}</td>
                              </tr>
                            );
                          })}
                          <tr>
                            <td>Total</td>
                            <td>
                              {" "}
                              {Math.round(
                                collectionReportList[1]?.reduce(
                                  (a, v) => (a = +a + +v.ledger_balance),
                                  0
                                ) * 100
                              ) / 100}{" "}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-4 col-sm-12">
            <div className="ibox">
              <div className="ibox-title">
                <h5 style={{ fontWeight: "bold" }}>
                  PAYMENT PAID TO (Dena He)
                </h5>
                <div className="ibox-tools">
                  <a
                    className="dropdown-toggle"
                    data-toggle="dropdown"
                    href="#"
                    aria-expanded="true"
                  >
                    <i className="fa fa-wrench"></i>
                  </a>
                  <ul className="dropdown-menu dropdown-user">
                    <li>
                      <a
                        target="_blank"
                        href="/agent/agents/3116/ledger_show_pdf.pdf"
                      >
                        Export PDF
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="ibox-content">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="table-responsive">
                      <table className="table  dataTables-example">
                        <thead>
                          <tr>
                            <th>Client</th>
                            <th>Balance</th>
                          </tr>
                        </thead>
                        <tbody>
                          {collectionReportList[2]?.map((el, ind) => {
                            return (
                              <tr key={ind}>
                                <td>
                                  <a
                                    href="/"
                                    onClick={(e) => {
                                      localStorage.setItem(
                                        "username",
                                        JSON.stringify(el.name)
                                      );
                                      e.preventDefault();
                                      navigate(
                                        // `/userview/collection-report/${el.user_guid}/${el.username}`
                                        `/userdetails/collection-report/paycash/${el.user_guid}/${el.username}`
                                      );
                                    }}
                                  >
                                    {el.username} ({el.first_name})
                                  </a>
                                </td>
                                <td>{el.ledger_balance}</td>
                              </tr>
                            );
                          })}
                          <tr>
                            <td>Total</td>
                            <td>
                              {" "}
                              {Math.round(
                                collectionReportList[2]?.reduce(
                                  (a, v) => (a = +a + +v.ledger_balance),
                                  0
                                ) * 100
                              ) / 100}{" "}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-4 col-sm-12">
            <div className="ibox">
              <div className="ibox-title">
                <h5 style={{ fontWeight: "bold" }}>
                  PAYMENT Clear (Clear Hai)
                </h5>
                <div className="ibox-tools">
                  <a
                    className="dropdown-toggle"
                    data-toggle="dropdown"
                    href="#"
                    aria-expanded="true"
                  >
                    <i className="fa fa-wrench"></i>
                  </a>
                  <ul className="dropdown-menu dropdown-user">
                    <li>
                      <a
                        target="_blank"
                        href="/agent/agents/3116/ledger_show_pdf.pdf"
                      >
                        Export PDF
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="ibox-content">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="table-responsive">
                      <table className="table  dataTables-example">
                        <thead>
                          <tr>
                            <th>Client</th>
                            <th>Balance</th>
                          </tr>
                        </thead>
                        <tbody>
                          {collectionReportList[3]?.map((el, ind) => {
                            return (
                              <tr key={ind}>
                                <td>
                                  <a
                                    href="/"
                                    onClick={(e) => {
                                      localStorage.setItem(
                                        "username",
                                        JSON.stringify(el.name)
                                      );
                                      e.preventDefault();
                                      navigate(
                                        `/userview/collection-report/${el.user_guid}/${el.username}`
                                      );
                                    }}
                                  >
                                    {el.username} ({el.first_name})
                                  </a>
                                </td>
                                <td>{el.ledger_balance}</td>
                              </tr>
                            );
                          })}

                          <tr>
                            <td>Total</td>
                            <td>
                              {" "}
                              {Math.round(
                                collectionReportList[3]?.reduce(
                                  (a, v) => (a = +a + +v.ledger_balance),
                                  0
                                ) * 100
                              ) / 100}{" "}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
