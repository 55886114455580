import React, { Fragment, useEffect, useRef, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'

const PageTabs = ({ event_id, state, path }) => {
    const location = useLocation();
    const { matchData } = location?.state;
    const userRole = JSON.parse(localStorage.getItem("userLogin"));

    const tabListRef = useRef(null);

    // UseEffect to scroll active tab into view on mount
    useEffect(() => {
        const activeTab = tabListRef.current.querySelector('.activeSwitchTab');
        if (activeTab) {
            const tabListWidth = tabListRef.current.offsetWidth;
            const tabWidth = activeTab.offsetWidth;
            const tabLeft = activeTab.offsetLeft;

            // Scroll so that the active tab is centered
            tabListRef.current.scrollLeft = tabLeft - (tabListWidth / 2 - tabWidth / 2);
        }
    }, [path]);

    return (
        <div className="switch-tabs">
            <ul className="tab-list" ref={tabListRef}>
                {[
                    { title: "Live Report", url: "/live_report/" },
                    { title: "Match Slips", url: "/bet_slips/" },
                    { title: "Session Bet Slip", url: "/session-bet-slips/" },
                    { title: "Company Report", url: "/company_report/" },
                    { title: "Client Report", url: "/client_report/" },
                    { title: "Collection Report", url: "/collection_report/" },
                ].map((item) => (
                    <li
                        key={item.title}
                        className={`tab-item ${path && path == item.url ? 'activeSwitchTab' : ''}`}
                    >
                        <Link
                            to={item.url + event_id}
                            state={{ matchData: state ? state : matchData }}
                        >
                            <p>{item.title}</p>
                        </Link>
                    </li>
                ))}

                {userRole && (userRole?.role == 1 || userRole?.role == 10) && (
                    <Fragment>
                        {[
                            { title: "News", url: "/newsupdates/" },
                            { title: "Live TV", url: "/livetv/" },
                            { title: "Score Widget", url: "/scorewidget/" },
                            { title: "Stake Delay", url: "/stake_delay/" },
                        ].map((item) => (
                            <li
                                key={item.title}
                                className={`tab-item ${path && path === item.url ? 'activeSwitchTab' : ''}`}
                            >
                                <Link
                                    to={item.url + event_id}
                                    state={{ matchData: state ? state : matchData }}
                                >
                                    <p>{item.title}</p>
                                </Link>
                            </li>
                        ))}
                    </Fragment>
                )}



                {/* <li>
                    {subCompanyLiveReportPage?.role == 1 ||
                        subCompanyLiveReportPage?.role == 9 ? (
                        <label className="dropdown">
                            <div className="dd-button bt btn-primar btn-l">
                                Settings
                            </div>

                            <input type="checkbox" className="dd-input" id="test" />

                            <ul className="dd-menu" style={{ paddingBottom: 20 }}>
                                {[
                                    { title: "Commentary", url: "/commentary/" },
                                    { title: "News/Updates", url: "/newsupdates/" },
                                    { title: "Stake Delay", url: "/stake_delay/" },
                                    { title: "Score Widget", url: "/scorewidget/" },
                                    { title: "Live TV", url: "/livetv/" },
                                    { title: " Event type", url: "/eventtype/" },
                                    { title: "Scoreboard", url: "/scoreboard/" },
                                    { title: "Match ID", url: "/match_id/" },
                                ].map((item) => (
                                    <li
                                        key={item?.title}
                                        onClick={() => {
                                            navigate(item?.url + event_id, {
                                                state: { state },
                                            });
                                        }}
                                    >
                                        {item?.title}
                                    </li>
                                ))}
                            </ul>
                        </label>
                    ) : null}
                </li> */}
            </ul>
        </div>
    )
}

export default PageTabs