import React from "react";

import EditComponent from "components/Staff/Edit";
import { useLocation } from "react-router-dom";

export function Edit() {
  const location = useLocation();
  return <EditComponent stateData={location?.state} />;
}

export default Edit;
