import { chipSummery, profileDetails, usersCashbook } from '_services';
import InnerPageHeader from 'components/common/InnerPageHeader';
import moment from 'moment';
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';

const SettlementHistory = () => {
    const { user_id } = useParams();
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to top when component mounts
    }, []);

    useEffect(() => {
        fetchSettlementHistory();
    }, [user_id]);

    const fetchSettlementHistory = () => {
        let sendObj = {
            user_id: user_id || '',
        };

        setLoading(true);

        usersCashbook(sendObj)
            .then((response) => {
                if (response && response.data && response.data.data) {
                    setData(response.data.data);
                }
                setLoading(false);
            })
            .catch((error) => {
                console.log(error, "error");
                setError("Failed to fetch data.");
                setLoading(false);
            });
    };

    return (
        <div>

            <div className="wrapper wrapper-content animated fadeInRight">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="ibox float-e-margins">
                            <div className="ibox-content">

                                {loading ? (
                                    <p>Loading...</p>
                                ) : error ? (
                                    <p>{error}</p>
                                ) : (
                                    <div >
                                        <div className="card-content">
                                            <div className="row">
                                                <div className="col s12">
                                                    <ul className="collection with-header" style={{ margin: 0 }}>
                                                        <li className="collection-header">
                                                            <h5>History (Settlement Report)</h5></li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <br />
                                        <div className="table-responsive">
                                            <table className="table table-bordered  dataTables-example">
                                                <thead>
                                                    <tr>
                                                        <th>No</th>
                                                        <th>USER</th>
                                                        <th>DESC</th>
                                                        <th>CREDIT</th>
                                                        <th>DEBIT</th>
                                                        <th>NOTE</th>
                                                        <th>TIME/DATE</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {data?.records?.length > 0 ? (
                                                        data.records.map((item, index) => (
                                                            <tr key={index}>
                                                                <td>{index + 1}</td>
                                                                <td>{item.username}</td>
                                                                <td>{item.remark}</td>
                                                                <td>{item.credit_amount}</td>
                                                                <td>{item.debit_amount}</td>
                                                                <td>{item.note}</td>
                                                                <td>{moment(item.created_at).format('DD-MM-yyyy hh:mm A')}</td>
                                                            </tr>
                                                        ))
                                                    ) : (
                                                        <tr>
                                                            <td colSpan={6}>No records found</td>
                                                        </tr>
                                                    )}

                                                </tbody>
                                            </table>

                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SettlementHistory