import React from "react";
import { useState } from "react";
import { useEffect } from "react";

import { Link, useParams } from "react-router-dom";
import { marketLadger } from "_services";

const ProfitLossSummery = () => {
    const { user_id, market_id } = useParams();

    const [profitLossSummery, setData] = useState([]);

    useEffect(() => {
        getProfitLossSummery()
    }, [user_id, market_id])


    const getProfitLossSummery = () => {
        let sendObj = {
            user_id,
            market_id
        };

        marketLadger(sendObj)
            .then((response) => {
                if (response && response?.data?.data) {
                    setData(response?.data?.data);
                }
            })
            .catch((error) => {
                console.log(error, "error");
            });
    };

    let currentUser = JSON.parse(localStorage.getItem("userLogin"))

    return (
        <>

            <div className="wrapper wrapper-content animated fadeInRight">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="ibox float-e-margins">
                            <div className="ibox-title">
                                <h5 style={{ textTransform: 'uppercase' }}> Summery</h5>
                            </div>
                            <div className="ibox-content">

                                <br />


                                <div className="table-responsive">
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th style={{ textAlign: 'left' }}>Username</th>
                                                <th style={{ textAlign: 'left' }}>Amount</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {profitLossSummery &&
                                                profitLossSummery.map((element, index) => {
                                                    return (
                                                        <tr key={index}>
                                                            <td>
                                                                {element.role != 9 ? <Link to={`/profit-loss-summery/${element.user_id}/${element.market_id}`}>
                                                                    {element.username}
                                                                </Link> :
                                                                    element.username
                                                                }
                                                            </td>
                                                            <td style={{ whiteSpace: 'initial' }}>
                                                                {
                                                                    element.debit_amount !== 0 ? (
                                                                        <span style={{ color: element.debit_amount > 0 ? "green" : "red" }}>
                                                                            {element.debit_amount}
                                                                        </span>
                                                                    ) : element.credit_amount !== 0 ? (
                                                                        <span style={{ color: element.credit_amount > 0 ? "green" : "red" }}>
                                                                            {element.credit_amount}
                                                                        </span>
                                                                    ) : null
                                                                }

                                                            </td>
                                                        </tr>
                                                    );
                                                })}
                                            <tr>
                                                <th></th>
                                                <th
                                                    style={{
                                                        color: Math.round(
                                                            profitLossSummery?.reduce(
                                                                (a, v) => (a = a + v.credit_amount),
                                                                0
                                                            ) * 100
                                                        ) / 100 > 0
                                                            ? 'green'
                                                            : 'red',
                                                    }}
                                                >
                                                    {Math.round(
                                                        profitLossSummery?.reduce(
                                                            (a, v) => (a = a + v.credit_amount),
                                                            0
                                                        ) * 100
                                                    ) / 100}
                                                </th>

                                            </tr>
                                        </tbody>
                                    </table>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ProfitLossSummery