import React, { Component } from 'react'

import CashBookComponent from '../../components/ManageLedger/CashBook';
export class CashBook extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }
    render() {
        return (
            
                <CashBookComponent />
            

        )
    }
}

export default CashBook