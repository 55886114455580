import React, { Component } from "react";
import InnerPageHeader from "../common/InnerPageHeader";
import { listMatchDetailRequest } from "../../_actions/manualmarketmatchdashboard/manualmarketmatchdashboard.actions";
import { Link } from "react-router-dom";

class List extends Component {
  constructor(props) {
    super(props);
  }

  state = {
    fancytypeList: [],
    manualmatchdetail: [],
    //runner_odds:[],
    selectionId: [],
    runnername: [],
    role: 3,
    currentPage: 1,
    event_id: "",
    event_name: "",
  };
  componentDidMount() {
    const { event_id, event_name } = this.props.params;

    this.state.event_name = event_name;
    this.state.event_id = event_id;
    let sendObjMatchDetail = {
      event_id: this.state.event_id,
    };

    this.props.dispatch(
      listMatchDetailRequest(sendObjMatchDetail, (resMatchDetail) => {
        if (resMatchDetail && resMatchDetail.data) {
          this.setState({
            manualmatchdetail: resMatchDetail.data.market,
            //runner_odds:resMatchDetail.data.runner_odds.ex,
            fancytypeList: resMatchDetail.data.fancy,
          });
        }
      })
    );
  }

  onButtonCLickEvent(e, user) {
    const { event_id, event_name } = this.state;

    e.preventDefault();
    this.props.navigate(
      `/manual-market-match-dashboard/addExistManualMarket/${event_id}/${event_name}/${user.market_id}/${user.runners[0].selectionId}/${user.runners[0].runnerName}/${user.runners[1].selectionId}/${user.runners[1].runnerName}/${user.market_type}`
    );
  }

  render() {
    const {
      fancytypeList,
      manualmatchdetail,
      selectionId,
      runnername,
      role,
      event_id,
      event_name,
    } = this.state;
    const breadcrumbPath = `/manual-markets`;
    return (
      <>
        <InnerPageHeader
          pageTitle={event_name}
          breadcrumbs={[
            { title: "Dashboard", url: "/" },
            { title: "Manual Market", url: breadcrumbPath },
          ]}
          currenPageTitle="Manual Market Dashboard"
        />
        <div className="wrapper wrapper-content animated fadeInRight">
          <div className="row">
            <div className="col-lg-12">
              <div className="ibox-content text-center">
                {manualmatchdetail.map((user, index) => (
                  <a
                    href="/"
                    className="btn btn-primary btn-xs mr-2"
                    key={index}
                    style={{ cursor: "pointer", marginLeft: "5px" }}
                    onClick={(e) => {
                      this.onButtonCLickEvent(e, user);
                    }}
                  >
                    {user.market_name}
                  </a>
                ))}

                <div className="dropdown btn-group">
                  <a
                    className="btn btn-primary btn-xs dropdown-toggle"
                    data-toggle="dropdown"
                    href="#"
                    style={{ marginLeft: "5px" }}
                  >
                    Action <span className="caret"></span>
                  </a>
                  <ul className="dropdown-menu">
                    {fancytypeList && fancytypeList.length ? (
                      fancytypeList.map((user, index) => (
                        <li key={index}>
                          <Link
                            to={`/manual-market-match-dashboard/addExistManualSession/${event_id}/${event_name}/${user.session_id}/${user.runner_name}/${user.lay_price1}/${user.back_price1}`}
                            style={{ cursor: "pointer" }}
                          >
                            {user.runner_name}
                          </Link>
                        </li>
                      ))
                    ) : (
                      <li>No Data available</li>
                    )}
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div className="row" style={{ marginTop: "22px" }}>
            <div className="col-lg-12">
              <div className="ibox float-e-margins">
                <div className="ibox-title">
                  <h5 style={{ fontWeight: 700 }}>Match Markets</h5>
                  <div className="ibox-tools">
                    <Link
                      to={`/manual-market-match-dashboard/add/${event_id}/${event_name}`}
                      className="btn btn-primary btn-xs"
                      style={{ cursor: "pointer" }}
                    >
                      Create New Manual Market
                    </Link>
                  </div>
                </div>
                <div className="ibox-content">
                  <div className="table-responsive">
                    <div
                      id="DataTables_Table_0_wrapper"
                      className="dataTables_wrapper form-inline dt-bootstrap"
                    >
                      <table className="table table-striped table-bordered table-hover dataTables-example">
                        <thead>
                          <tr>
                            <th>ID</th>
                            <th>Betfair Market Id</th>
                            <th>Betfair Market Type</th>
                            <th>Runner</th>
                          </tr>
                        </thead>
                        <tbody>
                          {manualmatchdetail && manualmatchdetail.length ? (
                            manualmatchdetail.map((user, index) => (
                              <tr className="gradeX" key={index}>
                                <td>{index + 1}</td>
                                <td>
                                  <Link
                                    to={`/manual-market-match-dashboard/addExistManualMarket/${event_id}/${event_name}/${user.market_id}/${user.runners[0].selectionId}/${user.runners[0].runnerName}/${user.runners[1].selectionId}/${user.runners[1].runnerName}/${user.market_type}`}
                                    style={{ cursor: "pointer" }}
                                  >
                                    {user.market_id}
                                  </Link>
                                </td>
                                <td>{user.market_name}</td>
                                <td className="center"></td>
                              </tr>
                            ))
                          ) : (
                            <tr className="gradeX">
                              <td colSpan={4}>No data available in table</td>
                            </tr>
                          )}
                        </tbody>
                      </table>

                      <div className="row" style={{ margin: "15px 0px" }}>
                        <div className="clearfix visible-xs"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row" style={{ marginTop: "22px" }}>
            <div className="col-lg-12">
              <div className="ibox float-e-margins">
                <div className="ibox-title">
                  <h5 style={{ fontWeight: 700 }}>Matches Session</h5>
                  <div className="ibox-tools">
                    <Link
                      to={`/manual-market-match-dashboard/addFancy/${event_id}/${event_name}`}
                      className="btn btn-primary btn-xs"
                      style={{ cursor: "pointer" }}
                    >
                      Create New Fancy Session
                    </Link>
                  </div>
                </div>
                <div className="ibox-content">
                  <div className="table-responsive">
                    <div
                      id="DataTables_Table_0_wrapper"
                      className="dataTables_wrapper form-inline dt-bootstrap"
                    >
                      <table className="table table-striped table-bordered table-hover dataTables-example">
                        <thead>
                          <tr>
                            <th>Session ID</th>
                            <th>Session</th>
                          </tr>
                        </thead>
                        <tbody>
                          {fancytypeList && fancytypeList.length ? (
                            fancytypeList.map((user, index) => (
                              <tr className="gradeX" key={index}>
                                <td>{user.session_id}</td>
                                <td>
                                  <Link
                                    to={`/manual-market-match-dashboard/addExistManualSession/${this.state.event_id}/${this.state.event_name}/${user.session_id}/${user.runner_name}/${user.lay_price1}/${user.back_price1}`}
                                    style={{ cursor: "pointer" }}
                                  >
                                    {user.runner_name}
                                  </Link>
                                </td>
                              </tr>
                            ))
                          ) : (
                            <tr className="gradeX">
                              <td colSpan={4}>No data available in table</td>
                            </tr>
                          )}
                        </tbody>
                      </table>

                      <div className="row" style={{ margin: "15px 0px" }}>
                        <div className="clearfix visible-xs"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
export default List;
