import React from "react";

import UserViewPage from "components/common/UserViewPage";
import { connect } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";

function UserView(props) {
  const location = useLocation();
  const params = useParams();
  const navigate = useNavigate();

  return (
    <UserViewPage
      {...props}
      location={location}
      params={params}
      navigate={navigate}
    />
  );
}
const mapstatetop = (state) => ({
  ...state,
});

export default connect(mapstatetop)(UserView);
