import React, { Component } from "react";
import InnerPageHeader from "./InnerPageHeader";
import { usersLedger } from "_services/userDetails.services";
import moment from "moment";
import DateRangePickerComponent from "./DateRangePickerComponent";
import ReactPaginate from "react-paginate";
import { Link } from "react-router-dom";

class LedgesViewPage extends Component {
  constructor(props) {
    super(props);
  }

  state = {
    user_guid: "",
    current_page: 1,
    items_perpage: 50,
    from_date: "",
    to_date: "",
    ledgerData: [],
  };
  componentDidMount() {
    const { type, subtype, id } = this.props?.params;
    if (subtype && subtype === "ledger") {
      if (id) {
        this.setState({ user_guid: id });
      }
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.user_guid !== this.state.user_guid ||
      prevState.from_date !== this.state.from_date ||
      prevState.to_date !== this.state.to_date ||
      prevState.current_page !== this.state.current_page
    ) {
      this.fetchUsersLedger();
    }
  }

  handleEvent(from_date, to_date) {
    if (from_date) {
      let fromDate = from_date._d;
      let toDate = to_date._d;
      this.setState({ to_date: toDate, from_date: fromDate });
    }
  }

  fetchUsersLedger = () => {
    if (this.state.user_guid) {
      const fromDate = this.state.from_date;
      const toDate = this.state.to_date;
      const from_date = fromDate
        ? moment(
            moment.utc(fromDate).local().format("YYYY-MM-DD 00:00:00"),
            "YYYY-MM-DD HH:mm:ss"
          )
            .utc()
            .format("YYYY-MM-DD HH:mm:ss")
        : "";
      const to_date = toDate
        ? moment(
            moment.utc(toDate).local().format("YYYY-MM-DD 23:59:00"),
            "YYYY-MM-DD HH:mm:ss"
          )
            .utc()
            .format("YYYY-MM-DD HH:mm:ss")
        : "";

      let sendObj = {};
      sendObj.user_guid = this.state.user_guid;
      sendObj.from_date = from_date;
      sendObj.to_date = to_date;
      sendObj.current_page = this.state.current_page;
      sendObj.items_perpage = this.state.items_perpage;
      usersLedger(sendObj)
        .then((response) => {
          if (
            response?.data?.data?.ledger &&
            response.data.data.ledger.length
          ) {
            this.setState({
              ledgerData: response.data.data.ledger,
              totalPageCount: Math.ceil(
                response?.data.data.total_records / this.state.items_perpage
              ),
            });
          } else {
            this.setState({
              ledgerData: [],
            });
          }
        })
        .catch((error) => {});
    }
  };

  handlePageClick = (e) => {
    const selectedPage = e.selected;
    this.setState({ current_page: selectedPage + 1 });
  };

  render() {
    const { masterList, totalRecords, ledgerData } = this.state;
    const { type, subtype, id, username } = this.props?.params;
    return (
      <>
        <InnerPageHeader
          pageTitle={type}
          breadcrumbs={[
            { title: "Dashboard", url: "/" },
            { title: type, url: `/userview/${type}/${id}/${username}` },
          ]}
          currenPageTitle="List"
        />

        <div className="row">
          <DateRangePickerComponent
            handleEvent={(from_date, to_date) =>
              this.handleEvent(from_date, to_date)
            }
            fDate={this.state.from_date}
            tDate={this.state.to_date}
          />

          <div
            className="col-lg-3 col-md-4 col-sm-6 col-xs-12"
            style={{ margin: "11px 0px" }}
          >
            <a className="btn btn-primary btn-xs search_date_btn">Search</a>
          </div>
        </div>

        <div className="wrapper wrapper-content animated fadeInRight">
          <div className="row">
            <div className="col-lg-12">
              <div className="ibox float-e-margins">
                <div className="ibox-title">
                  <h5>Agent Ledger</h5>
                  <div className="ibox-tools">
                    <a
                      className="dropdown-toggle"
                      data-toggle="dropdown"
                      href="#"
                      aria-expanded="true"
                    >
                      <i className="fa fa-wrench"></i>
                    </a>
                    <ul className="dropdown-menu dropdown-user">
                      <li>
                        <a
                          target="_blank"
                          href="/agent/agents/3116/ledger_show_pdf.pdf"
                        >
                          Export PDF
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="ibox-content">
                  <div className="table-responsive">
                    <table className="table">
                      <thead>
                        <tr>
                          <th>DATE/TIME</th>
                          <th>Collection Name</th>
                          <th>DEBIT</th>
                          <th>CREDIT</th>
                          <th>Balance</th>
                          <th>Note</th>
                        </tr>
                      </thead>
                      <tbody>
                        {ledgerData && ledgerData.length ? (
                          ledgerData.map((data, index) => (
                            <tr key={index}>
                              <td id="getdate_2794093">
                                <i className="fa fa-clock-o"></i>&nbsp;
                                {moment
                                  .utc(data.created_at)
                                  .local()
                                  .format("YYYY-MM-DD HH:mm a")}
                              </td>

                              <td>
                                {data?.source == 8 ? (
                                  <Link
                                    to={
                                      "/show_settlement/" +
                                      data.source_id +
                                      "/" +
                                      data.user_id
                                    }
                                  >
                                    {data?.remark}
                                  </Link>
                                ) : (
                                  data?.remark
                                )}
                              </td>

                              <td> {data?.debit_amount}</td>
                              <td>{data?.credit_amount}</td>

                              <td>{data?.ledger_balance}</td>
                              <td>
                                <div className="note-short-text full_text_modal">
                                  {data?.note}
                                </div>
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan={6}>No Data Found.</td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                  <div className="paginate_class">
                    <ReactPaginate
                      previousLabel={"prev"}
                      nextLabel={"next"}
                      breakLabel={"..."}
                      breakClassName={"break-me"}
                      pageCount={this.state.totalPageCount}
                      marginPagesDisplayed={2}
                      pageRangeDisplayed={5}
                      onPageChange={this.handlePageClick}
                      containerClassName={"pagination"}
                      subContainerClassName={"pages pagination"}
                      activeClassName={"active"}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
export default LedgesViewPage;
