import React, { Component } from "react";
import {  } from "react-router-dom";

import GameComp from "../../components/PendingGameComponent/PendingGameComp";
import { connect } from "react-redux";

class Games extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      
        <GameComp {...this.props} />
      
    );
  }
}
const mapstatetop = (state) => ({
  ...state,
});

export default connect(mapstatetop)(Games);
