import React, { Component } from "react";
import LoginComponent from "../../components/Login";
// import { getStorage } from '../../utils';
// import { Navigate } from 'react-router-dom';
import { connect } from "react-redux";
export const Login = (props) => {
//   var { user_id, role } = props.auth.userLogin;
  // if(user_id && role >0 ){
  //     return <Navigate to={"/dashboard"} />
  // }
  return (
    <>
      <LoginComponent />
    </>
  );
};
