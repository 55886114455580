import React, { Component } from "react";

import AddClient from "../../components/Client/Add";
import { connect } from "react-redux";
class Add extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return <AddClient {...this.props} />;
  }
}
const mapstatetop = (state) => ({
  ...state,
});

export default connect(mapstatetop)(Add);
