import { Navigate } from "react-router-dom";
import { getStorage } from "utils";

export const PublicRoute = ({ children }) => {
  let data  = getStorage("userLogin", "object");
  if (data) {
    return <Navigate to={"/dashboard"} />;
  }
  return children;
};
