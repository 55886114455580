import { profileDetails } from '_services';
import InnerPageHeader from 'components/common/InnerPageHeader';
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';

const Profile = () => {
    const { user_id } = useParams();
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to top when component mounts
    }, []);

    useEffect(() => {
        fetchProfile();
    }, [user_id]);

    const fetchProfile = () => {
        let sendObj = {
            user_id: user_id || '',
        };

        setLoading(true);

        profileDetails(sendObj)
            .then((response) => {
                if (response && response.data && response.data.data) {
                    setData(response.data.data);
                }
                setLoading(false);
            })
            .catch((error) => {
                console.log(error, "error");
                setError("Failed to fetch data.");
                setLoading(false);
            });
    };

    return (
        <div>
            {/* <InnerPageHeader
                pageTitle={'Chip Summery'}
                breadcrumbs={[
                    { title: "Dashboard", url: "/" },
                    // { title: parentType, url: navigationUrl },
                ]}
                currenPageTitle={'Chip Summery'}
            /> */}

            <div className="wrapper wrapper-content animated fadeInRight">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="ibox float-e-margins">
                            {/* <div className="ibox-title">
                                <h5>Chip Summery</h5>

                            </div> */}
                            <div className="ibox-content">

                                {loading
                                    ? <p>Loading...</p>
                                    : error
                                        ? <p>{error}</p>
                                        :
                                        <div className="card-content">
                                            <div className="row">
                                                <div className="col s12">
                                                    <ul className="collection with-header" style={{ margin: 0 }}>
                                                        <li className="collection-header">
                                                            <h5>USER DETAILS</h5>
                                                        </li>
                                                        <li className="collection-item" style={{ paddingLeft: 10 }}>
                                                            <div>Name : {data?.first_name} {data?.last_name}</div>
                                                        </li>
                                                        <li className="collection-item" style={{ paddingLeft: 10 }}>
                                                            <div>Username : {data?.username}</div>
                                                        </li>
                                                        <li className="collection-item" style={{ paddingLeft: 10 }}>
                                                            <div>Sports Share : {data?.my_agent_share}</div>
                                                        </li>
                                                        <li className="collection-item" style={{ paddingLeft: 10 }}>
                                                            <div>Casino Share : {data?.agent_game_share}</div>
                                                        </li>
                                                        <li className="collection-item" style={{ paddingLeft: 10 }}>
                                                            <div>Session Commission  : {data?.session_commission}</div>
                                                        </li>
                                                        <li className="collection-item" style={{ paddingLeft: 10 }}>
                                                            <div>P/L : {data?.total_pl} </div>
                                                        </li>
                                                    </ul>

                                                </div>
                                            </div>
                                        </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Profile