import { getDeclaredFancyLive, getEventMarket } from '_services';
import InnerPageHeader from 'components/common/InnerPageHeader';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

const PlusMinusReport = () => {
    const { event_id } = useParams();
    const userLogin = JSON.parse(localStorage.getItem("userLogin"));
    const [userRole, setUserRole] = useState(userLogin);
    const [isLoading, setLoading] = useState(false);
    const [allMarket, setAllMarket] = useState([]);
    const [declaredFancyData, setDeclaredFancyData] = useState([]);
    const [selectedMarkets, setSelectedMarkets] = useState(new Set());
    const [selectedDeclaredFancy, setSelectedDeclaredFancy] = useState(new Set());

    useEffect(() => {
        if (event_id) {
            fetchDeclaredFancy(event_id);
            fetchEventMarket(event_id); // Added fetchEventMarket to load market data
        }
    }, [event_id]);

    const fetchEventMarket = (event_id) => {
        setLoading(true);
        let sendData = { event_id };
        getEventMarket(sendData)
            .then((response) => {
                if (response && response.data && response.data.data) {
                    const allMarketList = response.data.data.market;
                    setAllMarket(allMarketList);
                    setSelectedMarkets(new Set(allMarketList.map((market) => market.market_name)));
                }
                setLoading(false);
            })
            .catch((error) => {
                console.error('Error fetching event market data:', error);
                setLoading(false);
            });
    };

    const fetchDeclaredFancy = (event_id) => {
        setLoading(true);
        const sendData = { event_id };
        getDeclaredFancyLive(sendData)
            .then((response) => {
                if (response?.data?.data && response.data.data.length > 0) {
                    const declaredFancyList = response.data.data;
                    setDeclaredFancyData(declaredFancyList);
                    setSelectedDeclaredFancy(new Set(declaredFancyList.map((declared) => declared.runner_name)));
                } else {
                    setDeclaredFancyData([]);
                    setSelectedDeclaredFancy(new Set());
                }
                setLoading(false);
            })
            .catch((error) => {
                console.error('Error fetching declared fancy data:', error);
                setDeclaredFancyData([]);
                setSelectedDeclaredFancy(new Set());
                setLoading(false);
            });
    };

    const handleSelectAllMarket = (e) => {
        if (e.target.checked) {
            setSelectedMarkets(new Set(allMarket.map((market) => market.market_name)));
        } else {
            setSelectedMarkets(new Set());
        }
    };

    const handleSelectAllDeclaredFancy = (e) => {
        if (e.target.checked) {
            setSelectedDeclaredFancy(new Set(declaredFancyData.map((declared) => declared.runner_name)));
        } else {
            setSelectedDeclaredFancy(new Set());
        }
    };

    const handleMarketCheckboxChange = (marketId) => {
        setSelectedMarkets(prevSelected => {
            const updatedSelection = new Set(prevSelected);
            if (updatedSelection.has(marketId)) {
                updatedSelection.delete(marketId);
            } else {
                updatedSelection.add(marketId);
            }
            return updatedSelection;
        });
    };

    const handleDeclaredFancyCheckboxChange = (declaredId) => {
        setSelectedDeclaredFancy(prevSelected => {
            const updatedSelection = new Set(prevSelected);
            if (updatedSelection.has(declaredId)) {
                updatedSelection.delete(declaredId);
            } else {
                updatedSelection.add(declaredId);
            }
            return updatedSelection;
        });
    };

    const isAllMarketSelected = allMarket.length > 0 && allMarket.every((market) => selectedMarkets.has(market.market_name));
    const isAllDeclaredFancySelected = declaredFancyData.length > 0 && declaredFancyData.every((declared) => selectedDeclaredFancy.has(declared.runner_name));

    return (
        <div>
            <InnerPageHeader
                pageTitle={`Plus Minus Report`}
                breadcrumbs={[
                    { title: "Dashboard", url: "/" },
                    { title: "Plus Minus Report", url: '' },
                ]}
                currenPageTitle="List"
            />
            <div className="wrapper wrapper-content animated fadeInRight">
                <div className="mx-0 mt-row">
                    <div className="col-lg-12">
                        <div className="ibox float-e-margins">
                            <div className="ibox-title">
                                <div>
                                    <div></div>
                                    <div></div>
                                    <div className="d-flex justify-start gap-2 overflow-x-scroll">
                                    </div>
                                </div>
                            </div>
                            <div className="ibox-content">
                                <div className="table-responsive">
                                    <table className="table table-striped table-hover dataTables-example">
                                        <thead>
                                            <tr>
                                                <th style={{ width: '40px' }}>
                                                    <input
                                                        type="checkbox"
                                                        checked={isAllMarketSelected}
                                                        onChange={handleSelectAllMarket}
                                                    />
                                                </th>
                                                <th style={{ textAlign: 'left' }}>Market</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {allMarket.map((market) => (
                                                <tr key={market.market_name}>
                                                    <td>
                                                        <input
                                                            type="checkbox"
                                                            checked={selectedMarkets.has(market.market_name)}
                                                            onChange={() => handleMarketCheckboxChange(market.market_name)}
                                                        />
                                                    </td>
                                                    <td
                                                        data-column="session_titl"
                                                        className="bet-place-tbl-t"
                                                        style={{ textAlign: "left" }}
                                                    >
                                                        {market.market_name}
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                    <table className="table table-striped table-hover dataTables-example">
                                        <thead>
                                            <tr>
                                                <th style={{ width: '40px' }}>
                                                    <input
                                                        type="checkbox"
                                                        checked={isAllDeclaredFancySelected}
                                                        onChange={handleSelectAllDeclaredFancy}
                                                    />
                                                </th>
                                                <th style={{ textAlign: 'left' }}>Session</th>
                                                <th>Declare</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {declaredFancyData.map((declared) => (
                                                <tr key={declared.runner_name}>
                                                    <td>
                                                        <input
                                                            type="checkbox"
                                                            checked={selectedDeclaredFancy.has(declared.runner_name)}
                                                            onChange={() => handleDeclaredFancyCheckboxChange(declared.runner_name)}
                                                        />
                                                    </td>
                                                    <td
                                                        data-column="session_titl"
                                                        className="bet-place-tbl-t"
                                                        style={{ textAlign: "left" }}
                                                    >
                                                        {declared.runner_name}
                                                    </td>
                                                    <td className="bet-place-tbl-t bet-val-cen">
                                                        {declared.settlement_result}
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PlusMinusReport;
