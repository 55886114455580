import { createRequest } from '_actions/agent/agent.actions';
import { profileDetails } from '_services';
import InnerPageHeader from 'components/common/InnerPageHeader'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

const NewClient = () => {
    const role = 9;
    let userRole = localStorage.getItem("role") || null;
    const navigate = useNavigate();
    const params = useParams();
    const dispatch = useDispatch();

    const createUser = (sendObj) => {
        sendObj.role = role;
        dispatch(
            createRequest(sendObj, (res) => {
                if (res) {
                    navigate('/my-clients');
                }
            })
        );
    };


    const [formData, setFormData] = useState({
        first_name: '',
        last_name: '',
        username: '',
        password: '',
        confirm_password: '',
        fixed_limit: '',
        cricket_commission: 0.0,
        session_commission: 0.0,
        match_commission: 0.0,
        football_commission: 0.0,
        tennis_commission: 0.0,
        matka_commission: 0.0,
        other_commission: 0.0,
        my_agent_share: 0.0,
        my_share: 0.0,
        my_agent_share: 0.0,
        game_share: 0.0,
        agent_game_share: 0.0,
        matka_agent_share: 0.0,
        matka_share: 0.0,
        theme: {}
    });

    const [errors, setErrors] = useState({});
    const [profileData, setProfileData] = useState({});

    const validateForm = () => {
        const newErrors = {};

        if (!formData.first_name) {
            newErrors.first_name = "Name is required";
        } else if (formData.first_name.length < 4) {
            newErrors.first_name = "Name must be at least 4 characters long";
        }

        if (!formData.username) {
            newErrors.username = "Username is required";
        } else if (formData.username.length < 4) {
            newErrors.username = "Username must be at least 4 characters long";
        }

        if (!formData.password) {
            newErrors.password = "Password is required";
        }

        if (!formData.confirm_password) {
            newErrors.confirm_password = "Confirm Password is required";
        } else if (formData.password !== formData.confirm_password) {
            newErrors.confirm_password = "Passwords do not match";
        }

        if (!formData.fixed_limit) {
            newErrors.fixed_limit = "fixed limit field is required";
        } else if (isNaN(formData.fixed_limit) || Number(formData.fixed_limit) < 0) {
            newErrors.fixed_limit = "fixed limit must be a positive number";
        }

        return newErrors;
    }

    useEffect(() => {
        getProfileDetail()
    }, [])

    const getProfileDetail = () => {
        profileDetails()
            .then((response) => {
                let data = response?.data?.data
                if (data) {
                    setProfileData(data)
                    setFormData({
                        ...formData,
                        my_agent_share: data.my_agent_share,
                        agent_game_share: data.agent_game_share,
                    });
                }
            }).catch((error) => {

            })
    }

    const handleChange = (e) => {
        const { name, value } = e.target;

        // Ensure the value is not greater than profileData.my_share
        if (name === 'my_share' && Number(value) > Number(profileData.my_agent_share)) {
            setErrors(prevState => ({ ...prevState, [name]: 'Value cannot exceed your total share.' }));
            return; // Prevent further state updates
        }
        // Ensure the value is not greater than profileData.my_share
        if (name === 'game_share' && Number(value) > Number(profileData.agent_game_share)) {
            setErrors(prevState => ({ ...prevState, [name]: 'Value cannot exceed your total share.' }));
            return; // Prevent further state updates
        }
        if (name === 'fixed_limit' && Number(value) > Number(profileData.fixed_limit)) {
            setErrors(prevState => ({ ...prevState, [name]: 'Value cannot exceed your total fix limit.' }));
            return; // Prevent further state updates
        }

        setFormData(prevState => ({ ...prevState, [name]: value }));
        setErrors(prevState => ({ ...prevState, [name]: '' })); // Clear error when input changes
        updateSharing(name, value);
    }

    const updateSharing = (name, value) => {
        if (name === 'my_share') {
            console.log("my_share", value);
            setFormData(prevState => {
                // const new_my_share = Number(prevState?.my_share) - Number(value);
                const new_my_share = Number(profileData?.my_agent_share) - Number(prevState.my_share);
                return { ...prevState, my_agent_share: new_my_share };
            });
        } else if (name === 'game_share') {
            setFormData(prevState => {
                // const new_my_share = Number(prevState?.my_share) - Number(value);
                const new_my_share = Number(profileData?.agent_game_share) - Number(prevState.game_share);
                return { ...prevState, agent_game_share: new_my_share };
            });
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        const newErrors = validateForm();

        if (Object.keys(newErrors).length > 0) {
            setErrors(newErrors);  // If there are errors, set them in the state
        } else {
            // Submit form (e.g., make API call)
            console.log('Form Data:', formData);
            createUser(formData)
        }
    }

    return (
        <React.Fragment>
            <InnerPageHeader
                pageTitle={'New Client'}
                breadcrumbs={[
                    { title: "Dashboard", url: "/" },
                    { title: 'Client', url: '/my-clients' },
                ]}
                currenPageTitle={"Create Client"}
            />

            <div className="wrappe wrapper-conten animated fadeInRight">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="ibox float-e-margins">
                            <div className="ibox-title">
                                <h5>{"Create"}</h5>
                            </div>

                            <div className="ibox-content">
                                <form onSubmit={handleSubmit} className="form-horizontal">

                                    <div className="new-box">
                                        <div className="heading col-sm-12"><p>Personal Details</p></div>
                                        <div className="col-sm-12">
                                            <div className="form-group col-md-6 col-sm-12" style={{ minHeight: '90px', marginBottom: 0 }}>
                                                <label className="control-label">
                                                    Name
                                                </label>
                                                <input
                                                    type="text"
                                                    className={`form-control ${errors.first_name ? 'is-invalid' : ''}`}
                                                    name="first_name"
                                                    id="first_name"
                                                    value={formData.first_name}
                                                    onChange={handleChange}
                                                />
                                                {errors.first_name && <div className="text-danger">{errors.first_name}</div>}
                                            </div>

                                            <div className="form-group col-md-6 col-sm-12" style={{ minHeight: '90px', marginBottom: 0 }}>
                                                <label className="control-label">
                                                    Username
                                                </label>
                                                <input
                                                    type="text"
                                                    className={`form-control ${errors.username ? 'is-invalid' : ''}`}
                                                    name="username"
                                                    id="username"
                                                    value={formData.username}
                                                    onChange={handleChange}
                                                    onKeyDown={(event) => {
                                                        if (event.code === 'Space') event.preventDefault();
                                                    }}
                                                />
                                                {errors.username && <div className="text-danger">{errors.username}</div>}
                                            </div>

                                            <div className="form-group col-md-6 col-sm-12" style={{ minHeight: '90px', marginBottom: 0 }}>
                                                <label className="control-label">
                                                    Password
                                                </label>
                                                <input
                                                    type="password"
                                                    className={`form-control ${errors.password ? 'is-invalid' : ''}`}
                                                    name="password"
                                                    id="password"
                                                    value={formData.password}
                                                    onChange={handleChange}
                                                />
                                                {errors.password && <div className="text-danger">{errors.password}</div>}
                                            </div>

                                            <div className="form-group col-md-6 col-sm-12" style={{ minHeight: '90px', marginBottom: 0 }}>
                                                <label className="control-label">
                                                    Confirm Password
                                                </label>
                                                <input
                                                    type="password"
                                                    className={`form-control ${errors.confirm_password ? 'is-invalid' : ''}`}
                                                    name="confirm_password"
                                                    id="confirm_password"
                                                    value={formData.confirm_password}
                                                    onChange={handleChange}
                                                />
                                                {errors.confirm_password && <div className="text-danger">{errors.confirm_password}</div>}
                                            </div>

                                            <div className="form-group col-md-6 col-sm-12" style={{ minHeight: '90px', marginBottom: 0 }}>
                                                <label className="control-label">
                                                    Free Chip
                                                </label>
                                                <input
                                                    type="number"
                                                    className={`form-control ${errors.fixed_limit ? 'is-invalid' : ''}`}
                                                    name="fixed_limit"
                                                    id="fixed_limit"
                                                    value={formData.fixed_limit}
                                                    onChange={handleChange}
                                                />
                                                <p>Available Balance: {Number(profileData.fixed_limit) - Number(formData.fixed_limit)}</p>
                                                {errors.fixed_limit && <div className="text-danger">{errors.fixed_limit}</div>}
                                            </div>

                                            <div className="form-group col-md-6 col-sm-12" style={{ minHeight: '90px', marginBottom: 0 }}>
                                                <label className="control-label">
                                                    Session Commission
                                                </label>
                                                <input
                                                    type="number"
                                                    className={`form-control ${errors.session_commission ? 'is-invalid' : ''}`}
                                                    name="session_commission"
                                                    id="session_commission"
                                                    value={formData.session_commission}
                                                    onChange={handleChange}
                                                />
                                                <p>Session Commission set from 0 to {userRole == 1 ? 100 : profileData.session_commission}</p>
                                                {errors.session_commission && <div className="text-danger">{errors.session_commission}</div>}
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row mt-3">
                                        <div className='col s12'>
                                            <table class="table col s12 m6 l4 no-padding partnership-table">
                                                <tbody>
                                                    <tr>
                                                        <th class="center-align">Position</th>
                                                        <th class="center-align">Sports Share</th>
                                                    </tr>
                                                    <tr>
                                                        <td class="center-align">Available Partnership</td>
                                                        <td class="center-align">
                                                            <label id="my_share">{profileData?.my_agent_share}</label>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td class="center-align">Down-line</td>
                                                        <td>
                                                            <div class="input-field col s12 m12 no-margin">
                                                                <input
                                                                    id="my_share"
                                                                    name="my_share"
                                                                    type="number"
                                                                    class="validate"
                                                                    value={formData.my_share}
                                                                    onChange={handleChange} />
                                                                {errors.my_share && <div className="text-danger">{errors.my_share}</div>}

                                                                <label for="my_share">Client
                                                                    Part.</label>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td class="center-align">My-Share</td>
                                                        <td class="center-align">
                                                            <label id="our_cricket_partnership">{formData.my_agent_share}</label>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>

                                            <table class="table col s12 m6 l4 no-padding partnership-table">
                                                <tbody>
                                                    <tr>
                                                        <th class="center-align">Position</th>
                                                        <th class="center-align">Casino Share</th>
                                                    </tr>
                                                    <tr>
                                                        <td class="center-align">Available Partnership</td>
                                                        <td class="center-align">
                                                            <label id="game_share">{profileData?.agent_game_share}</label>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td class="center-align">Down-line</td>
                                                        <td>
                                                            <div class="input-field col s12 m12 no-margin">
                                                                <input
                                                                    id="game_share"
                                                                    name="game_share"
                                                                    type="number"
                                                                    class="validate"
                                                                    value={formData.game_share}
                                                                    onChange={handleChange}
                                                                />
                                                                {errors.game_share && <div className="text-danger">{errors.game_share}</div>}

                                                                <label for="game_share">Client
                                                                    Part.</label>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td class="center-align">Game-Share</td>
                                                        <td class="center-align">
                                                            <label id="our_cricket_partnership">{formData.agent_game_share}</label>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>

                                        </div>
                                    </div>
                                    <br />
                                    <div className="form-group">
                                        <div className="col-sm-12">
                                            <button type="submit" className="btn btn-primary create-user-btn full-width-btn">Create Client</button>
                                        </div>
                                    </div>


                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default NewClient;
