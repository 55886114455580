import React, { Component } from "react";
import { debounce } from "lodash";
import ReactPaginate from "react-paginate";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ConfrimModal from "components/ConfirmModal";
import moment from "moment";
import InnerPageHeader from "../common/InnerPageHeader";
const categoryType = [
  "TIME",
  "MILAN DAY",
  "STAR DAY",
  "KALYAN",
  "STAR NIGHT",
  "MILAN NIGHT",
  "RAJDHANI",
  "MAIN BAZAR",
];

class List extends Component {
  constructor(props) {
    super(props);
    this.state = {
      role: 3,
      dataList: [],
      totalRecords: "",
      currentPage: 1,
      perPage: 10,
      pageCount: 0,
      selectedDate: new Date(),
    };
  }

  debouncedLog = debounce((text) => this.fetchListData(text), 500);

  componentDidMount() {
    this.fetchListData();
  }

  fetchListData = (searchData = "") => {};

  getRecordDetails = () => {
    const { currentPage, totalRecords } = this.state;
    let startingCount =
      this.state.currentPage === 1
        ? 1
        : this.state.currentPage * this.state.perPage - 9;
    let endingCount =
      this.state.perPage < totalRecords
        ? this.state.currentPage === 1
          ? this.state.perPage
          : this.state.currentPage * 10
        : totalRecords;
    return `Showing ${startingCount} to ${endingCount} of ${totalRecords} entries`;
  };

  handlePageClick = (e) => {
    const selectedPage = e.selected;
    this.setState({ currentPage: selectedPage + 1 }, () => {
      // this.fetchListData();
    });
  };

  submit = () => [this.setState({ isShowModal: false })];

  render() {
    const { isShowModal } = this.state;
    const { breadcrumbTitle } = this.props;
    let listArra = Object.keys(this.props.dataList.sp);
    listArra.push(listArra.shift());
    return (
      <>
        <InnerPageHeader
          pageTitle={breadcrumbTitle}
          breadcrumbs={[{ title: "Dashboard", url: "/" }]}
          currenPageTitle="List"
        />
        <div className="wrapper wrapper-content animated fadeInRight">
          <div className="row">
            <div className="col-lg-12">
              <div className="ibox float-e-margins">
                <div className="ibox-title">
                  <h5 style={{ fontWeight: 700 }}>{breadcrumbTitle}</h5>
                </div>
                <div className="ibox-content">
                  <div className="table-responsive">
                    <div
                      id="DataTables_Table_0_wrapper"
                      className="dataTables_wrapper form-inline dt-bootstrap"
                    >
                      <div className="d-flex justify-end">
                        <div
                          id="DataTables_Table_0_filter"
                          className="dataTables_filter"
                        >
                          <label>
                            {/* Search: */}
                            <DatePicker
                              id="datetime"
                              minDate={new Date()}
                              dateFormat="yyyy-MM-dd"
                              className="form-control "
                              name="date"
                              selected={new Date()}
                              // selected={selectedDate}
                              // onChange={(e) => this.setState({ selectedDate: e })}
                            />
                          </label>
                          <select
                            className="form-control"
                            name="category"
                            id="event_type"
                            // value={category}
                            // onChange={(e) => this.handleInput(e)}
                          >
                            {categoryType.map((item) => (
                              <option key={item} value={item}>
                                {item}
                              </option>
                            ))}
                          </select>
                          <select
                            style={{ marginLeft: "10px" }}
                            className="form-control"
                            name="type"
                            id="type"
                            // value={type}
                            // onChange={(e) => this.handleInput(e)}
                          >
                            <option value="open">
                              Open &nbsp; &nbsp; &nbsp; &nbsp;
                            </option>
                            <option value="close">Close</option>
                          </select>
                          {/* <button className="btn btn-white btn-sm filter-button"> Filter</button> */}
                          <button className="btn btn-white filter-button">
                            {" "}
                            Reset
                          </button>
                        </div>
                      </div>
                      <table className="table table-striped table-bordered table-hover dataTables-example">
                        <thead>
                          <tr>
                            {listArra.concat("Amount").map((item, ind) => (
                              <th className="report-data-row" key={item + ind}>
                                <div className="report-row">{item}</div>
                                {this?.props?.dataList?.sp[item]
                                  ? this?.props?.dataList?.sp[item]?.map(
                                      (num, key) => (
                                        <div className="report-box" key={num}>
                                          <div>
                                            {num}
                                            {item % 2 == 0 && key % 2 === 0 && (
                                              <>
                                                <div className="div-hr" />
                                                {num}
                                              </>
                                            )}
                                          </div>
                                        </div>
                                      )
                                    )
                                  : this?.props?.dataList?.sp[ind + 1]?.map(
                                      (num) => (
                                        <div className="report-box" key={num}>
                                          <div>{/* {num} */}0</div>
                                        </div>
                                      )
                                    )}
                              </th>
                            ))}
                          </tr>
                        </thead>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {isShowModal && (
              <ConfrimModal
                isShow={isShowModal}
                message={<label>Are you sure you want to delete </label>}
                onCancle={() => this.setState({ isShowModal: false })}
                onSubmit={() => this.submit()}
              />
            )}
          </div>
        </div>
      </>
    );
  }
}
export default List;
