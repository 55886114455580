import { agentConstants } from "../../_constants";
import { create, list } from "../../_services";
import { toast } from "react-toastify";

export const createRequest = (data, callback) => {
  return async (dispatch) => {
    dispatch({ type: agentConstants.AGENT_CREATE_REQUEST, data: {} });
    try {
      const response = await create(data);
      if (response?.error === "") {
        toast.success("Recorded added successfully");
        dispatch({
          type: agentConstants.AGENT_CREATE_SUCCESS,
          data: response.data,
        });
        callback(response.data);
      } else {
      toast.error(response?.error ? response?.error : "server error");
      }
    } catch (error) {
      toast.error(
        error?.response?.data?.error
          ? error?.response?.data?.error
          : "server error"
      );
      dispatch({
        type: agentConstants.AGENT_CREATE_FAILED,
        data: error,
      });
    }
  };
};

export const listRequest = (data, callback) => {
  return (dispatch) => {
    dispatch({ type: agentConstants.AGENT_LIST_REQUEST, data: {} });
    list(data)
      .then((response) => {
        callback(response.data);
        dispatch({
          type: agentConstants.AGENT_LIST_SUCCESS,
          data: response.data,
        });
      })
      .catch((error) => {
        callback(false);
        dispatch({
          type: agentConstants.AGENT_LIST_FAILED,
          data: error.response,
        });
      });
  };
};
