import React, { useState } from "react";
import { connect } from "react-redux";
import { updateLeagueStatus } from "_actions/league/activeDeactiveLeague.action";
import TagAndChips from "./../common/TagAndChips";
import refreshImg from "./../../images/refresh.png";
import { Link, useNavigate } from "react-router-dom";
import { activeDeactiveEvent } from "../../_services/tournament.services";
import { getLeagueEventsList } from "_services";
import axios from "axios";
import { formatDateAndTime } from "../../utils/index";

import { toast } from "react-toastify";

import { RotatingLines } from "react-loader-spinner";

import config from "../../lib/app-config";

function ExpandableTr({
  data,
  idx,
  handleSubTable,
  subTableMarketUrl,
  ActiveTabName,
  ...rest
}) {
  let navigate = useNavigate();
  const [Show, setShow] = useState(false);
  const [LeagueEventList, setLeagueEventList] = useState([{}, {}, {}, {}, {}]);

  let role = localStorage.getItem("role") || null;

  const [activeValue, setActiveValue] = useState([]);

  const [activeStatus, setActiveStatus] = useState([]);

  const [isSportLoading, setIsSportLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedId, setSelectedId] = useState(-1);

  const handleShowLeagueEvent = (league_id) => {
    setShow((prev) => !prev);
    if (!Show) {
      handleSubTable(league_id);
    } else {
      // setShow((prev) => !prev);
    }
  };

  const handleLeagueStatus = (league_id, active) => {
    if (!Show) {
      let sendObj = {
        league_id,
        status: active == "0" ? 1 : 0,
      };
      rest.dispatch(
        updateLeagueStatus(sendObj, (res) => {
          if (res) {
            // unhide //
            setLeagueEventList(res.data);
          }
        })
      );
      setActiveValue((data.active = sendObj.status));
    } else {
      // unhide //
      setShow((prev) => !prev);
    }
  };

  const handleCheckBox = (event_id, active) => {
    let sendObj = {};
    sendObj.event_id = event_id;
    sendObj.status = active == "1" ? 0 : 1;

    activeDeactiveEvent(sendObj)
      .then((response) => {
        if (response && data.subElements) {
          if (data.league_id) {
            let sendData = {};
            sendData.league_id = data.league_id;

            getLeagueEventsList(sendData).then((response) => {
              setActiveStatus((data.subElements = response.data.data));
            });
          }
        }
      })
      .catch((error) => { });
  };

  const handleSettingEvent = (data) => {
    setIsSportLoading(true);

    let datas = {
      league_id: data.league_id,
      sport_id: data?.sport_id,
    };

    axios
      .post(config.baseurl + "infotechfeed/fetch_event_by_id", datas)
      .then((response) => {
        setIsSportLoading(false);

        setTimeout(() => {
          window.scrollTo({
            top: document,
            behavior: "smooth",
          });
        }, 1000);

        // toast.success("Refresh successfully");
        toast.success(
          <div>
            <i
              className="fa fa-check"
              style={{ fontWeight: "bold", marginRight: "1rem" }}
              aria-hidden="true"
            ></i>
            <span>Refresh successfully</span>
          </div>,
          {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: false,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          }
        );
      })
      .catch((error) => {
        toast("Refresh again after that Event Updated successfully");
      });
  };

  const handleRefreshEvent = (event_id, selIndex) => {
    setSelectedId(selIndex);
    setIsLoading(true);

    axios
      .get(config.baseurl + "infotechfeed/fetch_fancy/" + event_id)
      .then((response) => {
        setIsLoading(false);
        setSelectedId(-1);
        setTimeout(() => {
          window.scrollTo({
            top: document,
            behavior: "smooth",
          });
        }, 1000);

        toast.success(
          <div>
            <i
              className="fa fa-check"
              style={{ fontWeight: "bold", marginRight: "1rem" }}
              aria-hidden="true"
            ></i>
            <span>Refresh successfully</span>
          </div>,
          {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: false,
            pauseOnHover: true,
            draggable: false,
            progress: undefined,
            theme: "colored",
          }
        );
      })
      .catch((error) => {
        toast("Refresh again after that Event Updated successfully");
      });
  };

  return (
    <>
      <tr className="gradeC">
        <td>{idx + 1}</td>
        <td>{data.league_id}</td>
        <td>{data.league_name}</td>
        <td className="d-flex justify-end gap-05">
          {role == 1 || role == 10 ? (
            <input
              style={{ transform: "scale(1.5)" }}
              type="checkbox"
              onClick={() => handleLeagueStatus(data.league_id, data.active)}
              checked={data.active}
            />
          ) : null}

          {/* <input
            type="checkbox"
            onClick={() => handleLeagueStatus(data.league_id, activeValue.active)}
            checked={activeValue.active}
            id={activeValue.league_id}
          /> */}

          <TagAndChips
            className="btn btn-xs btn-primary3"
            onClick={() => {
              handleShowLeagueEvent(data.league_id);
            }}
          >
            <i className={Show ? "fa fa-minus" : "fa fa-plus"}></i>
          </TagAndChips>

          {role == 1 || role == 10 ? (
            <div>
              {isSportLoading ? (
                <RotatingLines
                  strokeColor="grey"
                  strokeWidth="5"
                  animationDuration="0.75"
                  width="21"
                  visible={true}
                />
              ) : (
                <TagAndChips>
                  <img
                    className="checkbox-img"
                    src={refreshImg}
                    alt="refresh"
                    onClick={() => handleSettingEvent(data)}
                  />
                </TagAndChips>
              )}
            </div>
          ) : (
            ""
          )}
        </td>
      </tr>

      {Show && (
        <tr>
          <td colSpan={4}>
            <div className="ibox-content">
              <table className="table table-bordered">
                <thead>
                  <tr>
                    <th>S.NO</th>
                    <th>MATCH ID</th>
                    <th>MATCH NAME</th>
                    <th>OPEN DATE</th>
                    <th>ACTION</th>
                  </tr>
                </thead>
                <tbody>
                  {data.subElements && data.subElements.length ? (
                    data.subElements.map((league_event, idx) => {
                      // console.log(league_event, "league_event")
                      return (
                        <tr key={idx}>
                          <td>{idx + 1}</td>
                          <td>{league_event.event_id}</td>
                          <td>{league_event.event_name}</td>
                          <td>
                            {formatDateAndTime(
                              "MMM DD, hh:mm:ss A",
                              league_event.open_date
                            )}
                          </td>
                          <td className="d-flex justify-center gap-05" style={{ minWidth: "140px" }}>
                            {role == "1" || role == "10" ? (
                              <div className="css_gaping">
                                <input
                                  style={{ transform: "scale(1.5)" }}
                                  type="checkbox"
                                  onClick={(e) =>
                                    handleCheckBox(
                                      league_event.event_id,
                                      league_event.active
                                    )
                                  }
                                  checked={league_event.active}
                                // id={league_event.event_id}
                                />

                                <Link
                                  className="btn btn-primary btn-xs"
                                  to={`/fixture-market/${league_event.event_id}/${ActiveTabName}`}
                                  state={{ detail: league_event }}
                                >
                                  Edit Market
                                </Link>

                                {/* <TagAndChips className="btn btn-primary btn-xs">
                                  <i
                                    className="fa fa-gear"
                                    // onClick={() => handleSettingEvent(league_event.league_id)}
                                  ></i>
                                </TagAndChips> */}

                                {ActiveTabName === "fancy" && (
                                  <>
                                    {isLoading && idx == selectedId ? (
                                      <RotatingLines
                                        key={idx}
                                        strokeColor="grey"
                                        strokeWidth="5"
                                        animationDuration="0.75"
                                        width="20"
                                        visible={true}
                                      />
                                    ) : (
                                      <TagAndChips>
                                        <img
                                          className="checkbox-img"
                                          src={refreshImg}
                                          alt="refresh"
                                          onClick={() =>
                                            handleRefreshEvent(
                                              league_event.event_id,
                                              idx
                                            )
                                          }
                                        />
                                      </TagAndChips>
                                    )}
                                  </>
                                )}
                              </div>
                            ) : null}
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan={5}>No Data Found.</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </td>
        </tr>
      )}
    </>
  );
}

const mapstatetop = (state) => ({
  ...state,
});
// export default ExpandableTr
export default connect(mapstatetop)(ExpandableTr);
