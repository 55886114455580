import React, { Component } from "react";
import Main from "../../../components/layouts/Main";
import { connect } from "react-redux";
import moment from "moment";
import CompletedGamesComponent from "components/MatchesList/CompletedGamesComponent";
class CompletedGames extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (

      <CompletedGamesComponent data={moment().toISOString()} />

    );
  }
}
const mapstatetop = (state) => ({
  ...state,
});

export default connect(mapstatetop)(CompletedGames);
