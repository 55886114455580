export const settleMarketConstants = {
  SETTLE_MARKET_REQUEST: "SETTLE_MARKET_REQUEST",
  SETTLE_MARKET_SUCCESS: "SETTLE_MARKET_SUCCESS",
  SETTLE_MARKET_FAILED: "SETTLE_MARKET_FAILED",
  CANCEL_MARKET_REQUEST: "CANCEL_MARKET_REQUEST",
  CANCEL_MARKET_SUCCESS: "CANCEL_MARKET_SUCCESS",
  CANCEL_MARKET_FAILED: "CANCEL_MARKET_FAILED",
  CANCEL_FANCY_REQUEST: "CANCEL_MARKET_REQUEST",
  CANCEL_FANCY_SUCCESS: "CANCEL_MARKET_SUCCESS",
  CANCEL_FANCY_FAILED: "CANCEL_MARKET_FAILED",
  CANCEL_FANCY_BETS_REQUEST: "CANCEL_MARKET_REQUEST",
  CANCEL_FANCY_BETS_SUCCESS: "CANCEL_MARKET_SUCCESS",
  CANCEL_FANCY_BETS_FAILED: "CANCEL_MARKET_FAILED",
};
