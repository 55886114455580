import { userRoleDetails } from '_constants';
import { profileDetails, settlementNew } from '_services';
import InnerPageHeader from 'components/common/InnerPageHeader';
import { SettlementModal } from 'modals';
import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom';

const SettlementNew = () => {
    const { user_id } = useParams();
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [isModalOpen, setModalOpen] = useState(false);
    const [selectedUserData, setSelectedUserData] = useState(null);
    const [settlementType, setSettlementType] = useState(null);

    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to top when component mounts
    }, []);

    useEffect(() => {
        fetchSettlement();
    }, [user_id]);

    const fetchSettlement = () => {
        let sendObj = {
            user_id: user_id || '',
        };

        setLoading(true);

        settlementNew(sendObj)
            .then((response) => {
                if (response && response.data && response.data.data) {
                    setData(response.data.data);
                }
                setLoading(false);
            })
            .catch((error) => {
                console.log(error, "error");
                setError("Failed to fetch data.");
                setLoading(false);
            });
    };

    const fetchData = () => {
        closeModal()
        fetchSettlement();
    }

    const openModal = (user, type) => {
        setSelectedUserData(user);
        setModalOpen(true);
        setSettlementType(type)
    };

    const closeModal = () => {
        setModalOpen(false);
        setSelectedUserData(null);
        setSettlementType(null)
    };


    const totalLedgerBalance1 = Array.isArray(data?.lena_dena["1"])
        ? data.lena_dena["1"].reduce((tt, item) => tt + (item.ledger_balance || 0), 0)
        : 0;

    const totalLedgerBalance2 = Array.isArray(data?.lena_dena["2"])
        ? data.lena_dena["2"].reduce((tt, item) => tt + (item.ledger_balance || 0), 0)
        : 0;

    const totalMyCash = Number(data?.my_cash) || 0;
    const totalMyPl = Number(data?.my_pl) || 0;
    const total_comm = Number(data?.total_comm) || 0;
    const totalUplinePl = Number(data?.upline_pl) || 0;

    const totalPositive = totalLedgerBalance1 + Math.max(totalMyCash, 0) + Math.max(totalMyPl, 0) + Math.max(total_comm, 0) + Math.max(totalUplinePl, 0);
    // const totalNegative = totalLedgerBalance2 + Math.min((totalMyCash), 0) + Math.min(totalMyPl, 0) + Math.min(totalUplinePl, 0);
    const totalNegative = totalLedgerBalance2
        + Math.abs(Math.min(totalMyCash, 0))
        + Math.abs(Math.min(totalMyPl, 0))
        + Math.abs(Math.min(total_comm, 0))
        + Math.abs(Math.min(totalUplinePl, 0));


    const handleBack = () => {
        window.history.back();  // Go back in the browser history
    };
    return (
        <div>
            {/* <InnerPageHeader
                pageTitle={'Settlement'}
                breadcrumbs={[
                    { title: "Dashboard", url: "/" },
                    // { title: parentType, url: navigationUrl },
                ]}
                currenPageTitle={'Settlement'}
            /> */}

            <div className="wrapper wrapper-content animated fadeInRight">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="ibox float-e-margins">
                            {/* <div className="ibox-title">
                                <h5>Settlement</h5>
                            </div> */}
                            <div className="ibox-content">

                                {loading ? (
                                    <p>Loading...</p>
                                ) : error ? (
                                    <p>{error}</p>
                                ) : (
                                    <div className="card-content">
                                        <div className="row">
                                            <div className="col s12">
                                                <ul className="collection with-header" style={{ margin: 0 }}>
                                                    <li className="collection-header" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                        <h5>Settlement {data?.username && `(${data?.username})`}</h5>
                                                        {user_id && <button onClick={handleBack} style={{ whiteSpace: 'nowrap' }}>← Back</button>}
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>

                                        <div className="settlement-block">
                                            <div className="row">
                                                {/* Positive table */}
                                                <div className="col-6">
                                                    <table className="settlement-new-table table-green">
                                                        <thead>
                                                            <tr>
                                                                <th>UT</th>
                                                                <th>USERNAME</th>
                                                                <th>CHIP</th>
                                                                <th>ACTION</th>
                                                            </tr>
                                                        </thead>
                                                        <tfoot>
                                                            <tr>
                                                                <td colSpan={2} className='text-right'>Total</td>
                                                                <td colSpan={2}>{Math.abs(totalPositive)}</td>
                                                            </tr>
                                                        </tfoot>
                                                        <tbody>
                                                            {data?.lena_dena?.["1"]?.length > 0 && data.lena_dena["1"].map((item) => (
                                                                <tr key={item.user_id}>
                                                                    <td><span className="user-abbr">{userRoleDetails[Number(item.role)]?.abbreviation}</span></td>
                                                                    <td>{item.role == 9 ?
                                                                        item.username
                                                                        :
                                                                        <Link to={`/settlement-new/${item.user_id}`}>{item.username}</Link>}
                                                                    </td>
                                                                    <td style={{ color: 'green' }}>{item.ledger_balance}</td>
                                                                    <td>
                                                                        <div className='action-btn'>
                                                                            <button onClick={() => openModal(item, 1)}>Settlement</button>
                                                                            <button> <Link to={`/settlement-history/${item.user_id}`}>History</Link></button>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            ))}
                                                            {totalMyCash > 0 && (<tr>
                                                                <td>#</td>
                                                                <td>My Cash</td>
                                                                <td style={{ color: 'green' }}>{Math.abs(totalMyCash)}</td>
                                                                <td></td>
                                                            </tr>
                                                            )}
                                                            {totalMyPl > 0 && (

                                                                <>

                                                                    <tr>
                                                                        <td>#</td>
                                                                        <td>My PL</td>
                                                                        <td style={{ color: 'green' }}>{Math.abs(totalMyPl)}</td>
                                                                        <td></td>
                                                                    </tr>



                                                                </>
                                                            )}

                                                            {total_comm > 0 && (

                                                                <>
                                                                    <tr>
                                                                        <td>#</td>
                                                                        <td>My Commission</td>
                                                                        <td style={{ color: 'green' }}>{Math.abs(total_comm)}</td>
                                                                        <td></td>
                                                                    </tr>

                                                                </>
                                                            )}


                                                            {totalUplinePl > 0 && (<tr>
                                                                <td>#</td>
                                                                <td>Upline PL</td>
                                                                <td style={{ color: 'green' }}>{Math.abs(totalUplinePl)}</td>
                                                                <td></td>
                                                            </tr>
                                                            )}

                                                        </tbody>
                                                    </table>
                                                </div>

                                                {/* Negative table */}
                                                <div className="col-6">
                                                    <table className="settlement-new-table table-red">
                                                        <thead>
                                                            <tr>
                                                                <th>UT</th>
                                                                <th>USERNAME</th>
                                                                <th>CHIP</th>
                                                                <th>ACTION</th>
                                                            </tr>
                                                        </thead>
                                                        <tfoot>
                                                            <tr>
                                                                <td colSpan={2} className='text-right'>Total</td>
                                                                <td colSpan={2}>{Math.abs(totalNegative)}</td>
                                                            </tr>
                                                        </tfoot>
                                                        <tbody>
                                                            {data?.lena_dena?.["2"]?.length > 0 && data.lena_dena["2"].map((item) => (
                                                                <tr key={item.user_id}>
                                                                    <td><span className="user-abbr">{userRoleDetails[Number(item.role)]?.abbreviation}</span></td>
                                                                    <td>{item.role == 9 ?
                                                                        item.username
                                                                        :
                                                                        <Link to={`/settlement-new/${item.user_id}`}>{item.username}</Link>}
                                                                    </td>
                                                                    <td style={{ color: 'red' }}>{item.ledger_balance}</td>
                                                                    <td>
                                                                        <div className='action-btn'>
                                                                            <button onClick={() => openModal(item, 2)}>Settlement</button>
                                                                            <button> <Link to={`/settlement-history/${item.user_id}`}>History</Link></button>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            ))}
                                                            {totalMyCash < 0 && (
                                                                <tr>
                                                                    <td>#</td>
                                                                    <td>My Cash</td>
                                                                    <td style={{ color: 'red' }}>{Math.abs(totalMyCash)}</td>
                                                                    <td></td>
                                                                </tr>
                                                            )}
                                                            {totalMyPl < 0 && (

                                                                <>

                                                                    <tr>
                                                                        <td>#</td>
                                                                        <td>My PL</td>
                                                                        <td style={{ color: 'red' }}>{Math.abs(totalMyPl)}</td>
                                                                        <td></td>
                                                                    </tr>



                                                                </>

                                                            )}
                                                            {totalUplinePl < 0 && (<tr>
                                                                <td>#</td>
                                                                <td>Upline PL</td>
                                                                <td style={{ color: 'red' }}>{Math.abs(totalUplinePl)}</td>
                                                                <td></td>
                                                            </tr>
                                                            )}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {isModalOpen && <SettlementModal isOpen={isModalOpen} onClose={closeModal} fetchData={fetchData} userData={selectedUserData} type={settlementType} />}
        </div>
    )
}

export default SettlementNew