import React, { Component } from 'react'

import Password from "../../components/MasterPassword/Password"
// import PasswordComponent from '../../components/Password/Password';
export class RolePassword extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }
    render() {
        return (
            
                <Password />
            

        )
    }
}

export default RolePassword;