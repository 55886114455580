import React, { Component, Fragment } from "react";
import "./Client.css";
import { getCommisionLimits } from "_services";
import {
  commissionLimitUpdate,
  commissionLimitClientUpdate,
} from "_services/manageclient.services";
import { userRoleKeys } from "_constants";
import { toast } from "react-toastify";
import ClipLoader from "react-spinners/ClipLoader";
import ConfrimModal from "components/ConfirmModal";
import Loader from "components/common/Loader";
import InnerPageHeader from "components/common/InnerPageHeader";
import { depositBalance, withdrawBalance } from "_services";
import { UpdateBalanceModal } from "modals";

export class CommissionLimit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      posting: false,
      currentRole: "",
      commissionLimit: "",
      summary: "",
      updateType: "",
      updateIndex: -1,
      showConfirmModal: {},
      keyword: "",
      loading: true,
      UpdateBalanceModalShow: false,
      eventLoading: false,
      UpdateBalanceModalTitle: null,
      selectedUser: null
    };
  }

  componentDidMount() {
    this.setState({ currentRole: Number(localStorage.getItem("role")) });
    this.fetchComissionLimits();
  }

  fetchComissionLimits = () => {
    let sendData = {};
    sendData.keyword = this.state?.keyword;
    getCommisionLimits(sendData)
      .then((response) => {
        if (
          response &&
          response.data &&
          response.data.data &&
          response.data.data.commision_limit
        ) {
          this.setState({
            commissionLimit: response.data.data.commision_limit,
            loading: false,
          });
        }
        if (
          response &&
          response.data &&
          response.data.data &&
          response.data.data.summary
        ) {
          this.setState({
            summary: response.data.data.summary,
            loading: false,
          });
        }
      })
      .catch((error) => {
        this.setState({
          loading: false,
        });
      });
  };

  handleChange = (data, index, e) => {
    const { name, value } = e.target;
    if (isNaN(Number(e.target.value)) && e.target.value != "-") return;

    let users = this.state.commissionLimit;

    users[index].map((user, i) => {
      if (user.user_id == data.user_id)
        users[index][i] = { ...user, [name]: value };
    });
    this.setState({ commissionLimit: users });
  };

  onSubmitFixedLimitForm = (value) => {
    if (value) {
      this.setState({ showConfirmModal: { data: value } });
    } else {
      const { data } = this.state.showConfirmModal;
      this.setState({ updateLoaderFlag: true });
      setTimeout(() => {
        let sendData = {};
        sendData.user_id = data.user_id;
        sendData.fixed_limit = data.fixed_limit;
        commissionLimitUpdate(sendData)
          .then((response) => {
            if (response) {
              // toast("Fixed Limit Updated");
              this.setState({ updateLoaderFlag: false, showConfirmModal: {} });
              toast.success(
                <div>
                  <i
                    className="fa fa-check"
                    style={{ fontWeight: "bold", marginRight: "1rem" }}
                    aria-hidden="true"
                  ></i>
                  <span>Fixed Limit Updated</span>
                </div>,
                {
                  position: "top-center",
                  autoClose: 3000,
                  hideProgressBar: true,
                  closeOnClick: false,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: "colored",
                }
              );
            }
          })
          .catch((error) => {
            this.setState({ updateLoaderFlag: false });
            if (error?.response?.data?.error) {
              // toast("Fixed Limit And Current Limit Not Updated");
              // toast("Fixed Limit Not Updated");
              toast.error(
                <div>
                  <i
                    className="fa fa-cancel"
                    style={{ fontWeight: "bold", marginRight: "1rem" }}
                    aria-hidden="true"
                  ></i>
                  <span>
                    {error?.response?.data?.error || "Fixed Limit Not Updated"}
                  </span>
                </div>,
                {
                  position: "top-center",
                  autoClose: 3000,
                  hideProgressBar: true,
                  closeOnClick: false,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: "colored",
                }
              );
            }
          });
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      }, 500);
    }
  };

  onSubmitFixedAndCurrentLimitForm = (value, ind, type) => {
    if (value) {
      this.setState({ showConfirmModal: { data: value, type } });
    } else {
      const { data } = this.state.showConfirmModal;
      this.setState({ updateLoaderFlag: true });
      setTimeout(() => {
        let sendData = {};
        sendData.user_guid = data.user_guid;
        sendData.fixed_limit = data.fixed_limit;
        sendData.current_limit = data.balance;
        commissionLimitClientUpdate(sendData)
          .then((response) => {
            if (response) {
              this.setState({ updateLoaderFlag: false, showConfirmModal: {} });
              toast.success(
                <div>
                  <i
                    className="fa fa-check"
                    style={{ fontWeight: "bold", marginRight: "1rem" }}
                    aria-hidden="true"
                  ></i>
                  <span>Fixed Limit And Current Limit Updated</span>
                </div>,
                {
                  position: "top-center",
                  autoClose: 3000,
                  hideProgressBar: true,
                  closeOnClick: false,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: "colored",
                }
              );
            }
            setTimeout(() => {
              window.location.reload();
            }, 1500);
          })
          .catch((error) => {
            this.setState({ updateLoaderFlag: false });

            if (error?.response?.data?.error) {
              // toast("Fixed Limit And Current Limit Not Updated");
              // toast("Fixed Limit Not Updated");
              toast.error(
                <div>
                  <i
                    className="fa fa-cancel"
                    style={{ fontWeight: "bold", marginRight: "1rem" }}
                    aria-hidden="true"
                  ></i>
                  <span>
                    {error?.response?.data?.error || "Fixed Limit Not Updated"}
                  </span>
                </div>,
                {
                  position: "top-center",
                  autoClose: 3000,
                  hideProgressBar: true,
                  closeOnClick: false,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: "colored",
                }
              );
            }
            setTimeout(() => {
              window.location.reload();
            }, 1500);
          });
      }, 1000 * 6);
    }
  };

  onChangeSearch = (value) => {
    if (this.state.typingTimeout) {
      clearTimeout(this.state.typingTimeout);
    }
    this.setState({
      keyword: value,
      typing: false,
      typingTimeout: setTimeout(() => {
        this.setState({
          loading: true,
        });
        this.fetchComissionLimits();
      }, 1000),
    });
  };

  setToggleBalanceModal = (item, type) => {
    this.setState({ selectedUser: item, UpdateBalanceModalTitle: type, UpdateBalanceModalShow: true })
  }

  modalHide = () => {
    this.setState({ UpdateBalanceModalShow: false })
  }
  updateUserBalance = async (amount, remark) => {
    const profileData = JSON.parse(localStorage.getItem("userLogin"))
    const paload = {
      user_guid: this.state.selectedUser?.user_guid,
      amount: amount,
      remark,
    };
    this.setState({ eventLoading: true });
    if (this.state.UpdateBalanceModalTitle === "Withdraw") {
      try {
        const res = await withdrawBalance(paload);
        this.handleResponse(res, Number(profileData.balance) + Number(amount));
        this.modalHide()
      } catch (error) {
        if (error.response.data.error !== "") { toast.error(error.response.data.error) }
        this.setState({ eventLoading: false });

      }
    }

    if (this.state.UpdateBalanceModalTitle === "Deposit") {
      try {
        const res = await depositBalance(paload);

        this.handleResponse(res, Number(profileData.balance) - Number(amount));
        this.modalHide()
      } catch (error) {
        if (error.response.data.error !== "") { toast.error(error.response.data.error) }
        this.setState({ eventLoading: false });

      }
    }
  };

  handleResponse = (res, amount) => {

    if (res?.data?.message) {
      toast.success(res?.data?.message);
      let currentUser = JSON.parse(localStorage.getItem("userLogin"))
      currentUser.balance = Number(amount).toFixed(2)
      localStorage.setItem("userLogin", JSON.stringify(currentUser));
      this.fetchComissionLimits();
    }
    if (res?.data?.error) toast.error(res?.data?.error);
    this.modalHide();
    this.setState({ eventLoading: false });
  };

  render() {
    const {
      currentRole,
      commissionLimit,
      summary,
      updateLoaderFlag,
      showConfirmModal,
      UpdateBalanceModalTitle,
      selectedUser,
      eventLoading,
      UpdateBalanceModalShow
    } = this.state;

    const profileData = localStorage.getItem("userLogin")
      ? +JSON.parse(localStorage.getItem("userLogin")).balance
      : 0;

    const UpdateBalanceModalProps = {
      title: UpdateBalanceModalTitle,
      isShow: selectedUser,
      parentBalance: profileData,
      modalToggle: this.modalHide,
      onSubmit: (amount, remark) => this.updateUserBalance(amount, remark),
      loading: eventLoading,
      cancleText: "Cancel",
    }

    return (
      <Fragment>
        <InnerPageHeader
          pageTitle="Deposit & Withdraw "
          breadcrumbs={[{ title: "Dashboard", url: "/" }]}
          currenPageTitle="Deposit & Withdraw "
        />
        <div className="wrapper wrapper-content animated fadeInRight body_fonts_style">
          {this.state.loading && <Loader />}
          <div
            className="row"
            style={{
              position: "relative",
            }}
          >
            <div
              className="d-flex align-center justify-between "
              style={{
                position: "absolute",
                top: "2px",
                right: "20px",
              }}
            >
              <h5 className="font_dark_1 p-1">Search:</h5>
              <input
                type="search"
                className="form-control input-sm p-1"
                placeholder=""
                aria-controls="DataTables_Table_0"
                value={this.state.keyword}
                onChange={(e) => this.onChangeSearch(e.target.value)}
              />
            </div>
            {commissionLimit[userRoleKeys.sub_company]?.length &&
              commissionLimit[userRoleKeys.sub_company]
              ? currentRole < 2 && (
                <div className="col-12">
                  <div className="ibox">
                    <div className="ibox-title">
                      <h5 className="font_dark_1">Sub Company</h5>
                    </div>
                    <div className="ibox-content">
                      <div className="table-responsive">
                        <table className="table table-bordered  dataTables-example">
                          <thead>
                            <tr>
                              <th></th>
                              <th></th>
                              <th colSpan="2" className="text-center">
                                Client Commission
                              </th>
                              <th colSpan="2" className="text-center">
                                Client Limit
                              </th>
                              {/* <th colSpan="2"></th> */}
                            </tr>
                            <tr className="custom-table-header ">
                              <th>ID</th>
                              <th>Client Name</th>
                              <th>Match Commission</th>
                              <th>Session Commission</th>

                              <th>Current Limit</th>
                              <th>Used Limit</th>
                              {/* <th>Actions</th> */}
                            </tr>
                          </thead>
                          <tbody>
                            {commissionLimit &&
                              commissionLimit[userRoleKeys.sub_company] &&
                              commissionLimit[userRoleKeys.sub_company]
                                .length ? (
                              commissionLimit[userRoleKeys.sub_company].map(
                                (data, index) => (
                                  <tr key={index}>
                                    <td>{index + 1}</td>
                                    <td>
                                      {data.username} ({data.first_name})
                                    </td>
                                    <td>
                                      <input
                                        className="responsive_input"
                                        value={data.match_commission}
                                        type="number"
                                        disabled
                                      />
                                    </td>
                                    <td>
                                      <input
                                        className="responsive_input"
                                        value={data.session_commission}
                                        type="number"
                                        disabled
                                      />
                                    </td>

                                    <td className="d-flex">

                                      <input
                                        type="number"
                                        name="balance"
                                        value={data.balance}
                                        disabled
                                        onChange={this.handleChange.bind(
                                          this,
                                          data,
                                          userRoleKeys.sub_company
                                        )}
                                      />
                                      <div>
                                        <button
                                          type="button"
                                          className="btn anvGreenBtn"
                                          onClick={() =>
                                            this.setToggleBalanceModal(data, "Withdraw")
                                          }
                                        >
                                          Withdraw
                                        </button>
                                      </div>
                                      <div>
                                        <button
                                          type="button"
                                          className="btn anvYellowBtn"
                                          onClick={() =>
                                            this.setToggleBalanceModal(data, "Deposit")
                                          }
                                        >
                                          Deposit
                                        </button>
                                      </div>


                                    </td>
                                    <td>
                                      <input
                                        value={data.used_limit}
                                        type="number"
                                        name="UsedLimit"
                                        disabled
                                      />
                                    </td>
                                    {/* <td>
                                      <a
                                        className="btn btn-primary btn-sm"
                                        onClick={() =>
                                          this.onSubmitFixedLimitForm(
                                            data,
                                            index,
                                            "subcompany"
                                          )
                                        } //dfdf
                                      >
                                        {data.showloader == 1 && (
                                          <ClipLoader
                                            color={"#FFFFFF"}
                                            loading={true}
                                            size={16}
                                          />
                                        )}
                                        Update
                                      </a>
                                    </td> */}
                                  </tr>
                                )
                              )
                            ) : (
                              <tr>
                                <td colSpan={7}> No Data Found.</td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              )
              : null}

            {commissionLimit[userRoleKeys.sub_admin]?.length &&
              commissionLimit[userRoleKeys.sub_admin]
              ? currentRole < 3 && (
                <div className="col-12">
                  <div className="ibox">
                    <div className="ibox-title">
                      <h5 className="font_dark_1">Sub admin</h5>
                    </div>
                    <div className="ibox-content">
                      <div className="table-responsive">
                        <table className="table table-bordered table-responsive dataTables-example">
                          <thead>
                            <tr>
                              <th></th>
                              <th></th>
                              <th colSpan="2" className="text-center">
                                Client Commission
                              </th>
                              <th colSpan="2" className="text-center">
                                Client Limit
                              </th>
                              {/* <th colSpan="2"></th> */}
                            </tr>
                            <tr className="custom-table-header ">
                              <th>ID</th>
                              <th>Client Name</th>
                              <th>Match Commission</th>
                              <th>Session Commission</th>

                              <th>Current Limit</th>
                              <th>Used Limit</th>
                              {/* <th>Actions</th> */}
                            </tr>
                          </thead>
                          <tbody>
                            {commissionLimit &&
                              commissionLimit[userRoleKeys.sub_admin] &&
                              commissionLimit[userRoleKeys.sub_admin].length ? (
                              commissionLimit[userRoleKeys.sub_admin].map(
                                (data, index) => (
                                  <tr key={index}>
                                    <td>{index + 1}</td>
                                    <td>
                                      {data.username} ({data.first_name})
                                    </td>
                                    <td>
                                      <input
                                        className="responsive_input"
                                        value={data.match_commission}
                                        type="number"
                                        disabled
                                      />
                                    </td>
                                    <td>
                                      <input
                                        className="responsive_input"
                                        value={data.session_commission}
                                        type="number"
                                        disabled
                                      />
                                    </td>

                                    <td className="d-flex">
                                      <input
                                        type="number"
                                        name="balance"
                                        value={data.balance}
                                        disabled
                                        onChange={this.handleChange.bind(
                                          this,
                                          data,
                                          userRoleKeys.sub_admin
                                        )}
                                      />

                                      <div>
                                        <button
                                          type="button"
                                          className="btn anvYellowBtn"
                                          style={{ marginLeft: '5px' }}
                                          onClick={() =>
                                            this.setToggleBalanceModal(data, "Deposit")
                                          }
                                        >
                                          Deposit
                                        </button>
                                      </div>
                                      <div>
                                        <button
                                          type="button"
                                          className="btn anvGreenBtn"
                                          style={{ marginLeft: '5px' }}
                                          onClick={() =>
                                            this.setToggleBalanceModal(data, "Withdraw")
                                          }
                                        >
                                          Withdraw
                                        </button>
                                      </div>


                                    </td>
                                    <td>
                                      <input
                                        value={data.used_limit}
                                        type="number"
                                        name="UsedLimit"
                                        disabled
                                      />
                                    </td>
                                    {/* <td>
                                      <a
                                        className="btn btn-primary btn-sm"
                                        onClick={() =>
                                          this.onSubmitFixedLimitForm(
                                            data,
                                            index,
                                            "master"
                                          )
                                        }
                                      >
                                        {data.showloader == 1 && (
                                          <ClipLoader
                                            color={"#FFFFFF"}
                                            loading={true}
                                            size={16}
                                          />
                                        )}
                                        Update
                                      </a>
                                    </td> */}
                                  </tr>
                                )
                              )
                            ) : (
                              <tr>
                                <td colSpan={7}> No Data Found.</td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              )
              : null}

            {commissionLimit[userRoleKeys.admin]?.length &&
              commissionLimit[userRoleKeys.admin]
              ? currentRole < 4 && (
                <div className="col-12">
                  <div className="ibox">
                    <div className="ibox-title">
                      <h5 className="font_dark_1">Admin</h5>
                    </div>
                    <div className="ibox-content">
                      <div className="table-responsive">
                        <table className="table table-bordered table-responsive dataTables-example">
                          <thead>
                            <tr>
                              <th></th>
                              <th></th>
                              <th colSpan="2" className="text-center">
                                Client Commission
                              </th>
                              <th colSpan="2" className="text-center">
                                Client Limit
                              </th>
                              {/* <th colSpan="2"></th> */}
                            </tr>
                            <tr className="custom-table-header ">
                              <th>ID</th>
                              <th>Client Name</th>
                              <th>Match Commission</th>
                              <th>Session Commission</th>
                              <th>Current Limit</th>
                              <th>Used Limit</th>
                              {/* <th>Actions</th> */}
                            </tr>
                          </thead>
                          <tbody>
                            {commissionLimit &&
                              commissionLimit[userRoleKeys.admin] &&
                              commissionLimit[userRoleKeys.admin]
                                .length ? (
                              commissionLimit[
                                userRoleKeys.admin
                              ].map((data, index) => (
                                <tr key={index}>
                                  <td>{index + 1}</td>
                                  <td>
                                    {data.username} ({data.first_name})
                                  </td>
                                  <td>
                                    <input
                                      className="responsive_input"
                                      value={data.match_commission}
                                      type="number"
                                      disabled
                                    />
                                  </td>
                                  <td>
                                    <input
                                      className="responsive_input"
                                      value={data.session_commission}
                                      type="number"
                                      disabled
                                    />
                                  </td>

                                  <td className="d-flex">
                                    <input
                                      type="number"
                                      name="balance"
                                      value={data.balance}
                                      disabled
                                      onChange={this.handleChange.bind(
                                        this,
                                        data,
                                        userRoleKeys.admin
                                      )}
                                    />

                                    <div>
                                      <button
                                        type="button"
                                        className="btn anvGreenBtn"
                                        style={{ marginLeft: '5px' }}
                                        onClick={() =>
                                          this.setToggleBalanceModal(data, "Withdraw")
                                        }
                                      >
                                        Withdraw
                                      </button>
                                    </div>
                                    <div>
                                      <button
                                        type="button"
                                        className="btn anvYellowBtn"
                                        style={{ marginLeft: '5px' }}
                                        onClick={() =>
                                          this.setToggleBalanceModal(data, "Deposit")
                                        }
                                      >
                                        Deposit
                                      </button>
                                    </div>
                                  </td>
                                  <td>
                                    <input
                                      value={data.used_limit}
                                      type="number"
                                      name="UsedLimit"
                                      disabled
                                    />
                                  </td>
                                  {/* <td>
                                    <a
                                      className="btn btn-primary btn-sm"
                                      onClick={() =>
                                        this.onSubmitFixedLimitForm(
                                          data,
                                          index,
                                          "superstokist"
                                        )
                                      }
                                    >
                                      {data.showloader == 1 && (
                                        <ClipLoader
                                          color={"#FFFFFF"}
                                          loading={true}
                                          size={16}
                                        />
                                      )}
                                      Update
                                    </a>
                                  </td> */}
                                </tr>
                              ))
                            ) : (
                              <tr>
                                <td colSpan={7}> No Data Found.</td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              )
              : null}

            {commissionLimit[userRoleKeys.super_senior]?.length &&
              commissionLimit[userRoleKeys.super_senior]
              ? currentRole < 5 && (
                <div className="col-12">
                  <div className="ibox">
                    <div className="ibox-title">
                      <h5 className="font_dark_1">Super senior</h5>
                    </div>
                    <div className="ibox-content">
                      <div className="table-responsive">
                        <table className="table table-bordered table-responsive dataTables-example">
                          <thead>
                            <tr>
                              <th></th>
                              <th></th>
                              <th colSpan="2" className="text-center">
                                Client Commission
                              </th>
                              <th colSpan="2" className="text-center">
                                Client Limit
                              </th>
                              {/* <th colSpan="2"></th> */}
                            </tr>
                            <tr className="custom-table-header ">
                              <th>ID</th>
                              <th>Client Name</th>
                              <th>Match Commission</th>
                              <th>Session Commission</th>

                              <th>Current Limit</th>
                              <th>Used Limit</th>
                              {/* <th>Actions</th> */}
                            </tr>
                          </thead>
                          <tbody>
                            {commissionLimit &&
                              commissionLimit[userRoleKeys.super_senior] &&
                              commissionLimit[userRoleKeys.super_senior].length ? (
                              commissionLimit[userRoleKeys.super_senior].map(
                                (data, index) => (
                                  <tr key={index}>
                                    <td>{index + 1}</td>
                                    <td>
                                      {data.username} ({data.first_name})
                                    </td>
                                    <td>
                                      <input
                                        className="responsive_input"
                                        value={data.match_commission}
                                        type="number"
                                        disabled
                                      />
                                    </td>
                                    <td>
                                      <input
                                        className="responsive_input"
                                        value={data.session_commission}
                                        type="number"
                                        disabled
                                      />
                                    </td>

                                    <td className="d-flex">
                                      <input
                                        type="number"
                                        name="balance"
                                        value={data.balance}
                                        disabled
                                        onChange={this.handleChange.bind(
                                          this,
                                          data,
                                          userRoleKeys.super_senior
                                        )}
                                      />

                                      <div>
                                        <button
                                          type="button"
                                          className="btn anvGreenBtn"
                                          style={{ marginLeft: '5px' }}
                                          onClick={() =>
                                            this.setToggleBalanceModal(data, "Withdraw")
                                          }
                                        >
                                          Withdraw
                                        </button>
                                      </div>
                                      <div>
                                        <button
                                          type="button"
                                          className="btn anvYellowBtn"
                                          style={{ marginLeft: '5px' }}
                                          onClick={() =>
                                            this.setToggleBalanceModal(data, "Deposit")
                                          }
                                        >
                                          Deposit
                                        </button>
                                      </div>
                                    </td>
                                    <td>
                                      <input
                                        value={data.used_limit}
                                        type="number"
                                        name="UsedLimit"
                                        disabled
                                      />
                                    </td>
                                    {/* <td>
                                      <a
                                        className="btn btn-primary btn-sm"
                                        onClick={() =>
                                          this.onSubmitFixedLimitForm(
                                            data,
                                            index,
                                            "stokist"
                                          )
                                        }
                                      >
                                        {data.showloader == 1 && (
                                          <ClipLoader
                                            color={"#FFFFFF"}
                                            loading={true}
                                            size={16}
                                          />
                                        )}
                                        Update
                                      </a>
                                    </td> */}
                                  </tr>
                                )
                              )
                            ) : (
                              <tr>
                                <td colSpan={7}> No Data Found.</td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              )
              : null}

            {commissionLimit[userRoleKeys.super]?.length &&
              commissionLimit[userRoleKeys.super]
              ? currentRole < 6 && (
                <div className="col-12">
                  <div className="ibox">
                    <div className="ibox-title">
                      <h5 className="font_dark_1">Super</h5>
                    </div>
                    <div className="ibox-content">
                      <div className="table-responsive">
                        <table className="table table-bordered table-responsive dataTables-example">
                          <thead>
                            <tr>
                              <th></th>
                              <th></th>
                              <th colSpan="2" className="text-center">
                                Client Commission
                              </th>
                              <th colSpan="2" className="text-center">
                                Client Limit
                              </th>
                              {/* <th colSpan="2"></th> */}
                            </tr>
                            <tr className="custom-table-header ">
                              <th>ID</th>
                              <th>Client Name</th>
                              <th>Match Commission</th>
                              <th>Session Commission</th>

                              <th>Current Limit</th>
                              <th>Used Limit</th>
                              {/* <th>Actions</th> */}
                            </tr>
                          </thead>
                          <tbody>
                            {commissionLimit &&
                              commissionLimit[userRoleKeys.super] &&
                              commissionLimit[userRoleKeys.super].length ? (
                              commissionLimit[userRoleKeys.super].map(
                                (data, index) => (
                                  <tr key={index}>
                                    <td>{index + 1}</td>
                                    <td>
                                      {data.username} ({data.first_name})
                                    </td>
                                    <td>
                                      <input
                                        className="responsive_input"
                                        value={data.match_commission}
                                        type="number"
                                        disabled
                                      />
                                    </td>
                                    <td>
                                      <input
                                        className="responsive_input"
                                        value={data.session_commission}
                                        type="number"
                                        disabled
                                      />
                                    </td>

                                    <td className="d-flex">
                                      <input
                                        type="number"
                                        name="balance"
                                        value={data.balance}
                                        disabled
                                        onChange={this.handleChange.bind(
                                          this,
                                          data,
                                          userRoleKeys.super
                                        )}
                                      />

                                      <div>
                                        <button
                                          type="button"
                                          className="btn anvGreenBtn"
                                          style={{ marginLeft: '5px' }}
                                          onClick={() =>
                                            this.setToggleBalanceModal(data, "Withdraw")
                                          }
                                        >
                                          Withdraw
                                        </button>
                                      </div>
                                      <div>
                                        <button
                                          type="button"
                                          className="btn anvYellowBtn"
                                          style={{ marginLeft: '5px' }}
                                          onClick={() =>
                                            this.setToggleBalanceModal(data, "Deposit")
                                          }
                                        >
                                          Deposit
                                        </button>
                                      </div>
                                    </td>
                                    <td>
                                      <input
                                        value={data.used_limit}
                                        type="number"
                                        name="UsedLimit"
                                        disabled
                                      />
                                    </td>
                                    {/* <td>
                                      <a
                                        className="btn btn-primary btn-sm"
                                        onClick={() =>
                                          this.onSubmitFixedLimitForm(
                                            data,
                                            index,
                                            "agent"
                                          )
                                        }
                                      >
                                        {data.showloader == 1 && (
                                          <ClipLoader
                                            color={"#FFFFFF"}
                                            loading={true}
                                            size={16}
                                          />
                                        )}
                                        Update
                                      </a>
                                    </td> */}
                                  </tr>
                                )
                              )
                            ) : (
                              <tr>
                                <td colSpan={7}> No Data Found.</td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              )
              : null}
            {commissionLimit[userRoleKeys.dealer]?.length &&
              commissionLimit[userRoleKeys.dealer]
              ? currentRole < 7 && (
                <div className="col-12">
                  <div className="ibox">
                    <div className="ibox-title">
                      <h5 className="font_dark_1">Dealer</h5>
                    </div>
                    <div className="ibox-content">
                      <div className="table-responsive">
                        <table className="table table-bordered table-responsive dataTables-example">
                          <thead>
                            <tr>
                              <th></th>
                              <th></th>
                              <th colSpan="2" className="text-center">
                                Client Commission
                              </th>
                              <th colSpan="2" className="text-center">
                                Client Limit
                              </th>
                              {/* <th colSpan="2"></th> */}
                            </tr>
                            <tr className="custom-table-header ">
                              <th>ID</th>
                              <th>Client Name</th>
                              <th>Match Commission</th>
                              <th>Session Commission</th>

                              <th>Current Limit</th>
                              <th>Used Limit</th>
                              {/* <th>Actions</th> */}
                            </tr>
                          </thead>
                          <tbody>
                            {commissionLimit &&
                              commissionLimit[userRoleKeys.dealer] &&
                              commissionLimit[userRoleKeys.dealer].length ? (
                              commissionLimit[userRoleKeys.dealer].map(
                                (data, index) => (
                                  <tr key={index}>
                                    <td>{index + 1}</td>
                                    <td>
                                      {data.username} ({data.first_name})
                                    </td>
                                    <td>
                                      <input
                                        className="responsive_input"
                                        value={data.match_commission}
                                        type="number"
                                        disabled
                                      />
                                    </td>
                                    <td>
                                      <input
                                        className="responsive_input"
                                        value={data.session_commission}
                                        type="number"
                                        disabled
                                      />
                                    </td>

                                    <td className="d-flex">
                                      <input
                                        type="number"
                                        name="balance"
                                        value={data.balance}
                                        disabled
                                        onChange={this.handleChange.bind(
                                          this,
                                          data,
                                          userRoleKeys.dealer
                                        )}
                                      />

                                      <div>
                                        <button
                                          type="button"
                                          className="btn anvGreenBtn"
                                          style={{ marginLeft: '5px' }}
                                          onClick={() =>
                                            this.setToggleBalanceModal(data, "Withdraw")
                                          }
                                        >
                                          Withdraw
                                        </button>
                                      </div>
                                      <div>
                                        <button
                                          type="button"
                                          className="btn anvYellowBtn"
                                          style={{ marginLeft: '5px' }}
                                          onClick={() =>
                                            this.setToggleBalanceModal(data, "Deposit")
                                          }
                                        >
                                          Deposit
                                        </button>
                                      </div>
                                    </td>
                                    <td>
                                      <input
                                        value={data.used_limit}
                                        type="number"
                                        name="UsedLimit"
                                        disabled
                                      />
                                    </td>
                                    {/* <td>
                                      <a
                                        className="btn btn-primary btn-sm"
                                        onClick={() =>
                                          this.onSubmitFixedLimitForm(
                                            data,
                                            index,
                                            "agent"
                                          )
                                        }
                                      >
                                        {data.showloader == 1 && (
                                          <ClipLoader
                                            color={"#FFFFFF"}
                                            loading={true}
                                            size={16}
                                          />
                                        )}
                                        Update
                                      </a>
                                    </td> */}
                                  </tr>
                                )
                              )
                            ) : (
                              <tr>
                                <td colSpan={7}> No Data Found.</td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              )
              : null}
            {commissionLimit[userRoleKeys.agent]?.length &&
              commissionLimit[userRoleKeys.agent]
              ? currentRole < 8 && (
                <div className="col-12">
                  <div className="ibox">
                    <div className="ibox-title">
                      <h5 className="font_dark_1">Agent</h5>
                    </div>
                    <div className="ibox-content">
                      <div className="table-responsive">
                        <table className="table table-bordered table-responsive dataTables-example">
                          <thead>
                            <tr>
                              <th></th>
                              <th></th>
                              <th colSpan="2" className="text-center">
                                Client Commission
                              </th>
                              <th colSpan="2" className="text-center">
                                Client Limit
                              </th>
                              {/* <th colSpan="2"></th> */}
                            </tr>
                            <tr className="custom-table-header ">
                              <th>ID</th>
                              <th>Client Name</th>
                              <th>Match Commission</th>
                              <th>Session Commission</th>

                              <th>Current Limit</th>
                              <th>Used Limit</th>
                              {/* <th>Actions</th> */}
                            </tr>
                          </thead>
                          <tbody>
                            {commissionLimit &&
                              commissionLimit[userRoleKeys.agent] &&
                              commissionLimit[userRoleKeys.agent].length ? (
                              commissionLimit[userRoleKeys.agent].map(
                                (data, index) => (
                                  <tr key={index}>
                                    <td>{index + 1}</td>
                                    <td>
                                      {data.username} ({data.first_name})
                                    </td>
                                    <td>
                                      <input
                                        className="responsive_input"
                                        value={data.match_commission}
                                        type="number"
                                        disabled
                                      />
                                    </td>
                                    <td>
                                      <input
                                        className="responsive_input"
                                        value={data.session_commission}
                                        type="number"
                                        disabled
                                      />
                                    </td>

                                    <td className="d-flex">
                                      <input
                                        type="number"
                                        name="balance"
                                        value={data.balance}
                                        disabled
                                        onChange={this.handleChange.bind(
                                          this,
                                          data,
                                          userRoleKeys.agent
                                        )}
                                      />

                                      <div>
                                        <button
                                          type="button"
                                          className="btn anvGreenBtn"
                                          style={{ marginLeft: '5px' }}
                                          onClick={() =>
                                            this.setToggleBalanceModal(data, "Withdraw")
                                          }
                                        >
                                          Withdraw
                                        </button>
                                      </div>
                                      <div>
                                        <button
                                          type="button"
                                          className="btn anvYellowBtn"
                                          style={{ marginLeft: '5px' }}
                                          onClick={() =>
                                            this.setToggleBalanceModal(data, "Deposit")
                                          }
                                        >
                                          Deposit
                                        </button>
                                      </div>
                                    </td>
                                    <td>
                                      <input
                                        value={data.used_limit}
                                        type="number"
                                        name="UsedLimit"
                                        disabled
                                      />
                                    </td>
                                    {/* <td>
                                      <a
                                        className="btn btn-primary btn-sm"
                                        onClick={() =>
                                          this.onSubmitFixedLimitForm(
                                            data,
                                            index,
                                            "agent"
                                          )
                                        }
                                      >
                                        {data.showloader == 1 && (
                                          <ClipLoader
                                            color={"#FFFFFF"}
                                            loading={true}
                                            size={16}
                                          />
                                        )}
                                        Update
                                      </a>
                                    </td> */}
                                  </tr>
                                )
                              )
                            ) : (
                              <tr>
                                <td colSpan={7}> No Data Found.</td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              )
              : null}

            {commissionLimit[userRoleKeys.client]?.length &&
              commissionLimit[userRoleKeys.client] ? (
              <div className="col-12">
                <div className="ibox">
                  <div className="ibox-title">
                    <h5 className="font_dark_1">Client</h5>
                  </div>
                  <div className="ibox-content">
                    <div className="table-responsive">
                      <table className="table table-bordered table-responsive table-nowrap dataTables-example">
                        <thead>
                          <tr>
                            <th></th>
                            <th></th>
                            <th colSpan="2" className="text-center">
                              Client Commission
                            </th>
                            <th colSpan="2" className="text-center">
                              Client Limit
                            </th>
                            {/* <th colSpan="2"></th> */}
                          </tr>
                          <tr className="custom-table-header ">
                            <th>ID</th>
                            <th>Client Name</th>
                            <th>Match Commission</th>
                            <th>Session Commission</th>

                            <th>Current Limit</th>
                            <th>Used Limit</th>
                            {/* <th>Actions</th> */}
                          </tr>
                        </thead>
                        <tbody>
                          {commissionLimit &&
                            commissionLimit[userRoleKeys.client] &&
                            commissionLimit[userRoleKeys.client].length ? (
                            commissionLimit[userRoleKeys.client].map(
                              (data, index) => (
                                <tr key={index}>
                                  <td>{index + 1}</td>
                                  <td>
                                    {data.username} ({data.first_name})
                                  </td>
                                  <td>
                                    <input
                                      className="responsive_input"
                                      value={data.match_commission}
                                      type="number"
                                      disabled
                                    />
                                  </td>
                                  <td>
                                    <input
                                      className="responsive_input"
                                      value={data.session_commission}
                                      type="number"
                                      disabled
                                    />
                                  </td>

                                  <td className="d-flex">
                                    <input
                                      type="number"
                                      name="balance"
                                      value={data.balance}
                                      disabled
                                      onChange={this.handleChange.bind(
                                        this,
                                        data,
                                        userRoleKeys.client
                                      )}
                                    />

                                    <div>
                                      <button
                                        type="button"
                                        className="btn anvGreenBtn"
                                        style={{ marginLeft: '5px' }}
                                        onClick={() =>
                                          this.setToggleBalanceModal(data, "Withdraw")
                                        }
                                      >
                                        Withdraw
                                      </button>
                                    </div>
                                    <div>
                                      <button
                                        type="button"
                                        className="btn anvYellowBtn"
                                        style={{ marginLeft: '5px' }}
                                        onClick={() =>
                                          this.setToggleBalanceModal(data, "Deposit")
                                        }
                                      >
                                        Deposit
                                      </button>
                                    </div>
                                  </td>
                                  <td>
                                    <input
                                      value={data.used_limit}
                                      type="number"
                                      name="UsedLimit"
                                      disabled
                                    />
                                  </td>
                                  {/* <td>
                                    <a
                                      className="btn btn-primary btn-sm"
                                      onClick={() =>
                                        this.onSubmitFixedAndCurrentLimitForm(
                                          data,
                                          index,
                                          "client"
                                        )
                                      }
                                    >
                                      {data.showloader == 1 && (
                                        <ClipLoader
                                          color={"#FFFFFF"}
                                          loading={true}
                                          size={16}
                                        />
                                      )}
                                      Update
                                    </a>
                                  </td> */}
                                </tr>
                              )
                            )
                          ) : (
                            <tr>
                              <td colSpan={8}> No Data Found.</td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            ) : null}

            <div className="col-12">
              <div className="ibox">
                <div className="ibox-title">
                  <h5 className="font_dark_1">Summary</h5>
                </div>
                <div className="ibox-content">
                  <div className="table-responsive">
                    <table className="table table-bordered table-responsive dataTables-example">
                      <thead>
                        <tr>
                          <th>Fix Limit</th>
                          <th>Limit Distributed Below</th>
                          <th>Limit With Clients</th>
                          <th>Ledger Exposure</th>
                          <th>Direct Client Loss Limit</th>
                          <th>Remaining Limit</th>
                        </tr>
                      </thead>
                      {summary ? (
                        <tbody>
                          <tr>
                            <td>{summary.fixed_limit}</td>
                            <td>{summary.limit_to_agent}</td>
                            <td>{summary.client_limit}</td>
                            <td>{summary.ledger_balance}</td>
                            <td>{summary.client_loss_limit}</td>
                            <td>{summary.remaining_balance}</td>
                          </tr>
                        </tbody>
                      ) : (
                        <tbody>
                          <tr>
                            <td>0</td>
                            <td>0</td>
                            <td>0</td>
                            <td>0</td>
                            <td>0</td>
                            <td>0</td>
                          </tr>
                        </tbody>
                      )}
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {showConfirmModal.data && (
          <ConfrimModal
            isShow={Boolean(showConfirmModal.data)}
            message={
              <label>
                Are you sure you want to update{" "}
                <span style={{ color: "red" }}>
                  {showConfirmModal?.data?.username}
                </span>{" "}
                limit?
              </label>
            }
            onCancle={() => this.setState({ showConfirmModal: false })}
            onSubmit={() =>
              // showConfirmModal?.type === "client"
              //   ? this.onSubmitFixedAndCurrentLimitForm()
              //   : this.onSubmitFixedLimitForm()
              this.onSubmitFixedAndCurrentLimitForm()
            }
            loading={updateLoaderFlag}
          />
        )}

        {UpdateBalanceModalShow && <UpdateBalanceModal {...UpdateBalanceModalProps} />}
      </Fragment>
    );
  }
}

export default CommissionLimit;
