import React, { Component } from 'react'

import LanguageComponent from 'components/Language/Language';
export class Language extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }
    render() {
        return (
            
                <LanguageComponent />
            

        )
    }
}

export default Language