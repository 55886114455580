import React, { useState, useEffect, useRef } from "react";
import { Link, Navigate, useLocation, useNavigate } from "react-router-dom";
import { logoutUser } from "../../_actions";
import { connect, useSelector } from "react-redux";
import { detectBody, detectMobView } from "components/layouts/Helpers";
import logo from '../logo.png';
import { slice } from "lodash";
import { smoothlyMenu } from "../layouts/Helpers";
import $ from "jquery";

const Navigation = (props) => {
  const { domainData } = useSelector(state => state.auth)
  const location = useLocation()
  const [redirect, setRedirect] = useState("");
  const [width, setWidth] = useState(window.innerWidth);
  const [height, setHeight] = useState(window.innerHeight);
  const [activeRoute, setActiveRoute] = useState({ subMenu: location.pathname });
  const [activeMenu, setActiveMenu] = useState(null);
  const [isNavOpen, setIsNavOpen] = useState(false); // Track if nav is open on mobile
  const navbarRef = useRef(null);

  const handleMenuClick = (menu) => {
    setActiveMenu((prev) => (prev === menu ? null : menu)); // Toggle submenu
  };


  // Close nav when clicking outside the navbar (mobile only)
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (navbarRef.current && !navbarRef.current.contains(event.target)) {
        setIsNavOpen(false); // Close the nav
      }
    };

    if (isNavOpen && detectMobView()) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isNavOpen]);



  const updateDimensions = () => {
    setWidth(window.innerWidth);
    setHeight(window.innerHeight);
    detectBody();
  };

  const componentWillUnmount = () => {
    window.removeEventListener("resize", updateDimensions);
  };

  useEffect(() => {
    updateDimensions();
    window.addEventListener("resize", updateDimensions);
    return () => {
      componentWillUnmount();
    };
  }, []);

  const logOut = () => {
    props.dispatch(
      logoutUser((result) => {
        if (result) {
          // console.log(result);
          setRedirect("login");
          window.location.reload();
        } else {
          setRedirect("login");
        }
      })
    );
  };

  const getRole = () => {
    let role = localStorage.getItem("role") || null;
    switch (role) {
      case "1":
        return "Company";

      case "2":
        return "Sub Company";

      case "3":
        return "Sub Admin";

      case "4":
        return "Admin";

      case "5":
        return "Super Senior";

      case "6":
        return "Super";

      case "7":
        return "Dealer";

      case "8":
        return "Agent";

      case "9":
        return "Client";

      case "10":
        return "Staff";

      default:
        return "User";
    }
  };

  let role = localStorage.getItem("role");
  var menuList = props.auth?.userLogin?.menu ? props.auth?.userLogin?.menu : [];

  if (redirect === "login") {
    return <Navigate to="/dashboard" />;
  }
  // console.log("active",activeRoute)
  // console.log("activeRoute", location.pathname)

  const toggleNavigation = () => {
    $("body").toggleClass("mini-navbar");
    applySidebarClassName();
  };
  const applySidebarClassName = () => {
    if ($(".mini-navbar").length > 0) {
      $("#scrollable-container").addClass("scrollable-menu");
      $("#scrollable-container").removeClass("scrollable-container");
    } else {
      $("#scrollable-container").addClass("scrollable-container");
      $("#scrollable-container").removeClass("scrollable-menu");
    }
    smoothlyMenu();
  }

  return (
    <>
      {/* <nav
        className={"navbar-default navbar-static-side"}
        // id={!detectMobView() ? "scrollable-container" : ""}
        id={"scrollable-container"}
        role="navigation"
        style={{ background: 'var(--primary-color)', color: 'var(--primary-foreground)' }} >
        <ul className="nav metismenu" id="side-menu">
          <div className="logo-header" style={{ position: "relative", zIndex: 1000 }}>
            <li className="nav-header">
              <div className="dropdown profile-element"> {" "}
                <span></span>
                <a data-toggle="dropdown" className="dropdown-toggle" href="#">
                  <span className="clear"> {" "}
                    <img src={domainData && domainData.logo} alt="Error" style={{ backgroundSize: 'cover', width: '16rem' }} />
                  </span>{" "}
                </a>
                {detectMobView() && <p
                  className="navbar-minimalize minimalize-styl-2  " >
                  <i className="fa fa-bars" onClick={() => toggleNavigation()}></i>{" "}
                </p>}
              </div>
            </li>
          </div>
          <div className="drawer">
            {menuList.map((menus, mainIndex) => (

              <li className={
                (activeRoute?.menu === menus?.label ? "menuActive " : "") +
                (mainIndex === 0 ? " navbar-first-li" : "")
              } key={mainIndex}  >

                {menus.submenu.length > 0 ? (
                  <>
                    <button className={
                      (menus?.submenu?.length > 0 ? (menus?.submenu.some(item => ("/" + item?.link) === activeRoute?.subMenu) ? "submenu_active " : "") : activeRoute?.menu === menus?.label ? "submenu_active " : "") +
                      (menus?.submenu?.length == 0 && ("/" + menus?.link === activeRoute?.subMenu ? "submenu_active" : "")) +

                      " _menuMain"
                    }
                      onClick={() => {
                        menus?.link === ""
                          ? (
                            setActiveRoute(s => ({
                              ...s, menu:
                                activeRoute?.menu === menus?.label ? "" : menus?.label
                            }))
                          )
                          :
                          (props.navigate(`/${menus?.link}`))
                      }
                      } data-toggle="dropdown" >
                      <i className={menus.icon.replace("icon", "fa fa")}></i>
                      <span className="nav-label"> {menus.label} </span>
                      <span className="fa fa-caret-down" id="backward_icon"></span>
                    </button>
                    <ul
                      style={{ background: 'var(--primary-color)', color: 'var(--primary-foreground)', }}
                      className={
                        // (detectMobView() ? "sub_menu_scroll " : "") +
                        (
                          // width <= 768
                          // ? "nav nav-second-level h-auto dropdown-menu submenu-text"
                          // :
                          "nav nav-second-level h-auto menu-responsive")
                      }
                    >
                      {(role === "1" ? menus.submenu : menus.submenu.filter((item) => item.label !== "Maintenance Mode")).map((subM, index) => (
                        <li key={index}>
                          <Link onClick={(e) => {
                            const mLink = "/" + subM.link;
                            const mPath = window.location.pathname;
                            setActiveRoute(s => ({ ...s, subMenu: `/${subM.link}` }))
                            if (mLink.includes(mPath)) {
                              props.navigate(`/${subM.link}`);
                              window.location.reload();
                              if (detectMobView()) { toggleNavigation() }
                            } else {
                              props.navigate(`/${subM.link}`);
                              if (detectMobView()) { toggleNavigation() }
                            }
                          }}
                            // className={width <= 768 ? "submenu-text" : ""}
                            to={`/${subM.link}`} >
                            <i className={subM.icon.replace("icon", "fa fa")}  ></i>
                            {subM.label}
                          </Link>
                        </li>
                      ))}
                    </ul>
                  </>
                ) : (
                  <Link
                    className={
                      (menus?.submenu?.length > 0 ?
                        (menus?.submenu.some(item => ("/" + item?.link) === activeRoute?.subMenu) ?
                          "submenu_active " : "")
                        : activeRoute?.menu === menus?.label ? "submenu_active "
                          : "") +
                      (menus?.submenu?.length == 0 && ("/" + menus?.link === activeRoute?.subMenu ? "submenu_active" : "")) +

                      " _menuMain"
                    } to={`/${menus.link}`}
                    onClick={() => {
                      setActiveRoute(s => ({

                        ...s, subMenu: s?.menu === "/" + menus?.link ? "" : "/" + menus?.link
                      })); if (detectMobView()) {
                        toggleNavigation()
                      }
                    }
                    }
                  >
                    <i className={menus.icon.replace("icon", "fa fa")}></i>
                    <span className="nav-label">{menus.label}</span>
                  </Link>
                )}
              </li>
            ))}
          </div>
        </ul>
      </nav> */}
      {/* <div style={{
        width: "100%",
        height: "100%",
        position: "fixed",
        top: 0,
        left: 0,
        background: "rgba(0, 0, 0, 0.2)",
        zIndex: "100000"
      }}> */}


      <nav
        ref={navbarRef}
        className="navbar-default navbar-static-side"
        id="scrollable-container"
        role="navigation"
      >
        <ul className="nav metismenu" id="side-menu">
          {/* Logo and Profile Section */}
          <div className="logo-header">
            <li className="nav-header">
              <div className="profile-element">
                <Link to="/" className="dropdown-toggle">
                  <span className="clear">
                    <img
                      src={domainData?.logo}
                      alt="Logo"
                      className="logo-img"
                    />
                  </span>
                </Link>
                {detectMobView() && (
                  <span className="navbar-minimalize" onClick={() => toggleNavigation()}>
                    <i className="fa fa-bars mobile-fa-bars"></i>
                  </span>
                )}
              </div>
            </li>
          </div>


          {/* Drawer and Menu List */}
          <div className="drawer">
            {menuList.map((menu, index) => (
              <li
                key={index}
                className={`menu-item ${activeRoute?.menu === menu?.label ? 'menu-active' : ''} ${index === 0 ? 'navbar-first-li' : ''}`}
              >
                {menu.submenu.length > 0 ? (
                  <>
                    {/* Main Menu with Submenu */}
                    <button
                      className={`menu-main ${activeMenu === menu.label ? 'submenu-active' : ''
                        }`}
                      onClick={() => handleMenuClick(menu.label)}
                    >
                      <i className={menu.icon.replace('icon', 'fa fa')}></i>
                      <span className="nav-label">{menu.label}</span>
                      <span className="fa fa-caret-down"></span>
                    </button>

                    {/* Submenu Items */}
                    {activeMenu === menu.label && (
                      <ul className="submenu">
                        {(role === '1' ? menu.submenu : menu.submenu.filter((item) => item.label !== 'Maintenance Mode')).map(
                          (subMenu, subIndex) => (
                            <li key={subIndex} className="submenu-item">
                              <Link
                                to={`/${subMenu.link}`}
                                onClick={(e) => {
                                  const mLink = `/${subMenu.link}`;
                                  const mPath = window.location.pathname;
                                  setActiveRoute((s) => ({ ...s, subMenu: mLink }));
                                  if (mLink.includes(mPath)) {
                                    props.navigate(mLink);
                                    window.location.reload();
                                    if (detectMobView()) {
                                      toggleNavigation();
                                    }
                                  } else {
                                    props.navigate(mLink);
                                    if (detectMobView()) {
                                      toggleNavigation();
                                    }
                                  }
                                }}
                              >
                                <i className={subMenu.icon.replace('icon', 'fa fa')}></i>
                                {subMenu.label}
                              </Link>
                            </li>
                          )
                        )}
                      </ul>
                    )}
                  </>
                ) : (
                  <Link
                    className={`menu-main ${activeRoute?.menu === menu?.label ||
                      `/${menu.link}` === activeRoute?.subMenu
                      ? 'submenu-active'
                      : ''
                      }`}
                    to={`/${menu.link}`}
                    onClick={() => {
                      setActiveRoute((s) => ({
                        ...s,
                        subMenu: s?.menu === `/${menu?.link}` ? '' : `/${menu?.link}`,
                      }));
                      if (detectMobView()) {
                        toggleNavigation();
                      }
                    }}
                  >
                    <i className={menu.icon.replace('icon', 'fa fa')}></i>
                    <span className="nav-label">{menu.label}</span>
                  </Link>
                )}
              </li>
            ))}
            <li className={`menu-item`}>

              <button
                className={`menu-main `}
                onClick={logOut}
              >
                <i className="fa fa-sign-out"></i>
                <span className="nav-label">Logout</span>
              </button>

            </li>
          </div>
        </ul>
      </nav>
      {/* </div> */}


    </>
  );
};

const mapStateToProps = (state) => ({
  ...state,
});

export default connect(mapStateToProps)(Navigation);
