import React from "react";
import AddFancy from "../../../components/ManualMarketMatchDashboard/AddFancy";
import { connect, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

function Add() {
  const dispatch = useDispatch();
  const params = useParams();
  return (
    <AddFancy dispatch={dispatch} params={params}>
      Add Master Page
    </AddFancy>
  );
}
const mapstatetop = (state) => ({
  ...state,
});

export default connect(mapstatetop)(Add);
