import React, { Component } from "react";

import CommissionLimitComponent from "components/Client/CommissionLimit";
export class CommissionLimit extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      
        <CommissionLimitComponent {...this.props} />
      
    );
  }
}

export default CommissionLimit;
