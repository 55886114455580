import React, { useEffect, useState } from "react";

import { Link, useLocation, useParams } from "react-router-dom";
import { profitLossSummery, profitLossSummeryM } from "_services";

const ProfitLossSummeryMarket = () => {
    const location = useLocation();
    const { market_id, event_id, user_id } = useParams();

    const [ProfitLossSummeryMarket, setData] = useState([]);

    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to the top of the page
    }, [location]);

    useEffect(() => {
        getProfitLossSummeryMarket()
    }, [market_id, event_id, user_id])


    const getProfitLossSummeryMarket = () => {
        let sendObj = {
            market_id,
            event_id
        };

        if (user_id) {
            sendObj.user_id = user_id
        }

        profitLossSummeryM(sendObj)
            .then((response) => {
                if (response && response?.data?.data) {
                    setData(response?.data?.data);
                }
            })
            .catch((error) => {
                console.log(error, "error");
            });
    };

    const handleBack = () => {
        window.history.back();  // Go back in the browser history
    };

    return (
        <>
            <div className="wrapper wrapper-content animated fadeInRight">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="ibox float-e-margins">
                            <div className="ibox-title" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <h5 style={{ textTransform: 'uppercase' }}> Summery</h5>
                                <button button onClick={handleBack} style={{ whiteSpace: 'nowrap' }}>← Back</button>
                            </div>
                            <div className="ibox-content">
                                <br />

                                <div className="table-responsive">
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th style={{ textAlign: 'left' }}>USERNAME</th>
                                                <th style={{ textAlign: 'left' }}>AMOUNT</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {ProfitLossSummeryMarket &&
                                                ProfitLossSummeryMarket.map((element, index) => {
                                                    return (
                                                        <tr key={index}>
                                                            <td>
                                                                <Link to={`/profit-loss-summery-market/${market_id}/${event_id}/${element.user_id}`}>
                                                                    {element.username}
                                                                </Link>
                                                            </td>
                                                            <td style={{ whiteSpace: 'initial' }}>
                                                                <span style={{ color: element.my_tot > 0 ? "green" : "red" }}>
                                                                    {element.my_tot}
                                                                </span>
                                                            </td>

                                                        </tr>
                                                    );
                                                })}
                                            <tr>
                                                <th>Total</th>
                                                <th style={{
                                                    color: Math.round(ProfitLossSummeryMarket?.reduce((a, v) => (a = a + v.my_tot), 0) * 100) / 100 > 0 ? 'green' : 'red',
                                                }} >
                                                    {Math.round(ProfitLossSummeryMarket?.reduce((a, v) => (a = a + v.my_tot), 0) * 100) / 100}
                                                </th>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div >
        </>
    );
}

export default ProfitLossSummeryMarket