import React, { useState } from "react";

export const UplineContext = React.createContext(null);
export const BalanceModalContext = React.createContext(null);

export const ContextHandler = ({ children }) => {
  const [uplineModal, setToggleUpline] = useState(false); // {userType,role,callback}
  const [balanceModal, setToggleBalanceModal] = useState(false); // {userType,role,callback}

  return (
    <UplineContext.Provider value={{ uplineModal, setToggleUpline }}>
      <BalanceModalContext.Provider value={{ balanceModal, setToggleBalanceModal }}>
        <div>{children}</div>
      </BalanceModalContext.Provider>
    </UplineContext.Provider>
  );
};
