import React, { Component } from "react";

import NumberForm from "../../components/MatkaNumber/NumberForm";
import { connect } from "react-redux";

class Add extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  addNumber = (data) => {};

  render() {
    return (
      
        <NumberForm
          type="fast"
          breadcrumbTitle={"Fast Matka Settlement"}
          addNumber={this.addNumber}
        />
      
    );
  }
}
const mapstatetop = (state) => ({
  ...state,
});

export const AddFastMatkaNumber = connect(mapstatetop)(Add);
