import React, { useState, useEffect } from "react";
import { formatDateAndTime } from "../../utils/index";
import { ReactSearchAutocomplete } from "react-search-autocomplete";

import { useLocation, useParams, Link, useNavigate } from "react-router-dom";
import {
  getBetSlip,
  getFancyPosition,
  getBetListCount,
  deleteBetSlip,
} from "_services";
import { userListByKeywordRequest } from "../../_actions/userlistbykeyword/userlistbykeyword.actions";

import ReactPaginate from "react-paginate";
import Select from "react-select";
import ConfrimModal from "components/ConfirmModal";
import InnerPageHeader from "components/common/InnerPageHeader";
import { addBetTrClass } from "components/layouts/Helpers";
import { convertParseFloat } from "_helpers";
import PageTabs from "components/common/pageTabs";

function SessionBetSlipsComponent(props) {
  const location = useLocation();
  const navigate = useNavigate();
  const { matchData } = location?.state;
  const [userListByKeyword, updateList] = useState([]);

  const { event_id } = useParams();

  const [sessionBetSlipList, setSessionBetSlipList] = useState([]);
  const [initialData, setInitialData] = useState([]);
  const [fancyPositionList, setFancyPositionList] = useState([]);
  const [fancyOptions, setFancyOptions] = useState([]);
  const [sessionBetSlipCounts, setSessionBetSlipCounts] = useState("");
  // const [selectedSession, setSelectedSession] = useState("");
  const [selectedSession, setSelectedSession] = useState({
    label: "All Sessions",
    value: "",
  });

  const userLogin = JSON.parse(localStorage.getItem("userLogin"));

  const [currentPage, setCurrentPage] = useState(1);
  const [perPage] = useState(50);
  const [pageCount, setPageCount] = useState(0);
  const [showDeleteModal, setShowDeleteModal] = useState({});
  const [filterdData, setFilterData] = useState({});

  useEffect(() => {
    if (event_id) {
      fetchSessionBetSlipCount();
      fetchFancyList();
    }
  }, []);

  useEffect(() => {
    if (event_id) {
      fetchSessionBetSlip();
    }
  }, [currentPage, filterdData]);

  const fetchSessionBetSlip = (item) => {
    let sendData = { ...filterdData, ...item };
    sendData.current_page = currentPage;
    sendData.items_perpage = perPage;
    sendData.bookmaker_type = 3;
    sendData.event_id = event_id;
    getBetSlip(sendData)
      .then((response) => {
        if (response?.data?.data?.bet_data?.length) {
          // setInitialData(response.data.data.bet_data);
          let dat = JSON.parse(JSON.stringify(response.data.data.bet_data));
          dat.forEach((element) => {
            element.extra_No_1 = getNoYesValue(element, "No") * -1;
            element.extra_Yes_1 = getNoYesValue(element, "Yes") * -1;
            element.extra_my_share = getMyShare(element.upline_data);
            element.extra_after_No_2 =
              getMyShareAfterData(element, "No", element.upline_data) * -1;
            element.extra_after_Yes_2 =
              getMyShareAfterData(element, "Yes", element.upline_data) * -1;
            element.extra_plus_minus = getPlusMinus(element) * -1;
          });

          setInitialData(dat);
          setSessionBetSlipList(dat);

          setPageCount(Math.ceil(response.data.data.total_records / perPage));
        } else {
          setSessionBetSlipList([]);
          setInitialData([]);
        }
      })
      .catch((error) => {
        setSessionBetSlipList([]);
        setInitialData([]);
      });
  };

  const handlePageClick = (e) => {
    const selectedPage = e.selected;
    setCurrentPage(selectedPage + 1);
  };

  const fetchSessionBetSlipCount = () => {
    let sendData = {};
    sendData.bookmaker_type = 3;
    sendData.event_id = event_id;
    getBetListCount(sendData)
      .then((response) => {
        if (response && response.data && response.data.data) {
          setSessionBetSlipCounts(response.data.data);
        } else {
          setSessionBetSlipCounts("");
        }
      })
      .catch((error) => {
        setSessionBetSlipCounts("");
      });
  };

  const fetchFancyList = () => {
    let sendData = {};
    sendData.event_id = event_id;
    getFancyPosition(sendData)
      .then((response) => {
        if (
          response &&
          response.data &&
          response.data.data &&
          response.data.data.length
        ) {
          setFancyPositionList(response.data.data);
          let options = response.data.data.map((val) => {
            return {
              label: val.runner_name,
              value: val.session_id,
            };
          });
          options.unshift({ label: "All Sessions", value: "" });
          setFancyOptions(options);
        } else {
          setFancyPositionList([]);
        }
      })
      .catch((error) => {
        setFancyPositionList([]);
      });
  };

  const getNoYesValue = (data, type) => {
    try {
      if (data && data.runner_pl && data.runner_pl[type]) {
        return data.runner_pl[type];
      } else {
        return 0;
      }
    } catch (error) {
      return 0;
    }
  };

  const getMyShare = (uplineData) => {
    try {
      if (uplineData && uplineData.length) {
        // using ( == ) to ( === )

        let dat = uplineData.find((val) => val.parent_id === userLogin.user_id);
        if (dat) {
          return dat.my_share;
        } else {
          return 0;
        }
      } else {
        return 0;
      }
    } catch (error) {
      return 0;
    }
  };

  const getMyShareAfterData = (dat, position, uplineData) => {
    try {
      let value = getNoYesValue(dat, position);
      let share = getMyShare(uplineData);
      let percentageValue = (share * value) / 100;
      return percentageValue;
    } catch (error) {
      return 0;
    }
  };

  // using ( == ) to ( === )

  const getPlusMinus = (dat) => {
    try {
      if (dat.betting_type) {
        if (dat.betting_type === 1) {
          return getMyShareAfterData(dat, "Yes", dat.upline_data);
        } else {
          return getMyShareAfterData(dat, "No", dat.upline_data);
        }
      } else {
        return 0;
      }
    } catch (error) {
      return 0;
    }
  };

  const getSum = (key) => {
    if (key === "extra_plus_minus") {
      return sessionBetSlipList.reduce((total, value) => {
        return total + parseFloat(
          (value.winning_status === "1") ? - (value.pp * value.extra_my_share) / 100 :
            (value.winning_status === "2") ? (value.pp * value.extra_my_share) / 100 : 0
        );
      }, 0);
    } else {
      try {
        let val = sessionBetSlipList.reduce((total, value) => {
          return total + parseFloat(value[key]);
        }, 0);
        return val;
      } catch (error) {
        return 0;
      }
    }
  };

  const handleChange = (e) => {
    try {
      setSelectedSession(e);
      if (e.value) {
        let data = initialData.filter((val) => val.market_id == e.value);
        setSessionBetSlipList(data);
      } else {
        setSessionBetSlipList(initialData);
      }
    } catch (erro) { }

    // For normal Select dropdown functionality
    // try {
    //   setSelectedSession(e.target.value);
    //   if (e.target.value) {
    //     let data = initialData.filter(
    //       (val) => val.market_id === e.target.value
    //     );
    //     setSessionBetSlipList(data);
    //   } else {
    //     setSessionBetSlipList(initialData);
    //   }
    // } catch (erro) {}
  };

  const handleDeleteFn = (event) => {
    if (event) {
      let sendData = {
        betslip_uid: event.betslip_uid,
        user_id: event.user_id
      };
      setShowDeleteModal(sendData)
    } else if (showDeleteModal.user_id) {
      setShowDeleteModal(state => ({ ...state, loading: true }))
      deleteBetSlip(showDeleteModal)
        .then((response) => {
          if (response) {
            fetchSessionBetSlip();
            setShowDeleteModal({})
          }
        })
        .catch((error) => {
          setShowDeleteModal(state => ({ ...state, loading: false }))
        });
    }
  };

  const formatResult = (item) => {
    return (
      <>
        <span style={{ display: "block", textAlign: "left" }}>{item.name}</span>
      </>
    );
  };


  const handleOnSelect = (item) => {
    setFilterData((state) => ({ ...state, ...item }))
  }

  const handleInput = (e) => {
    const value = e.target.value;
  }

  const onClear = (e) => {
    setCurrentPage(1);
    fetchSessionBetSlip();
  }


  const handleOnSearch = (string, results) => {
    if (string.length >= 4) {
      props.dispatch(
        userListByKeywordRequest({ keyword: string }, (res) => {
          if (res && res.data) {
            updateList(res.data.users);


          }
        })
      );
    }
  };

  return (
    <div>
      <InnerPageHeader
        pageTitle="Session Bet Slips"
        breadcrumbs={[
          { title: "Dashboard", url: "/" },
          { title: "Matches", url: "/matches_list" },
          // {
          //   title: matchData?.event_name, url: {
          //     pathname: `/match_dashboard/${event_id}`,
          //     state: { matchData: matchData },
          //   }
          // },
        ]}
        currenPageTitle={`Session Bet Slips`}
        teamName={`${matchData.event_name ? matchData.event_name : ''}`}
      />
      <PageTabs event_id={event_id} state={false} path={'/session-bet-slips/'} />
      <div className="row">
        <div className="col-lg-3">
          <div className="ibox float-e-margins">
            <div className="ibox-title">
              <h5>Total Session Bets</h5>
            </div>
            <div className="ibox-content">
              <h1 className="no-margins">
                {sessionBetSlipCounts && sessionBetSlipCounts.total_bets
                  ? sessionBetSlipCounts.total_bets
                  : 0}
              </h1>
            </div>
          </div>
        </div>

        <div className="col-lg-3">
          <div className="ibox float-e-margins">
            <div className="ibox-title">
              <h5>Settled Session Bets</h5>
            </div>
            <div className="ibox-content">
              <h1 className="no-margins">
                {sessionBetSlipCounts && sessionBetSlipCounts.settelled_bets
                  ? sessionBetSlipCounts.settelled_bets
                  : 0}
              </h1>
            </div>
          </div>
        </div>

        <div className="col-lg-3">
          <div className="ibox float-e-margins">
            <div className="ibox-title">
              <h5>Unsettled Session Bets</h5>
            </div>
            <div className="ibox-content">
              <h1 className="no-margins">
                {sessionBetSlipCounts && sessionBetSlipCounts.unsettelled_bets
                  ? sessionBetSlipCounts.unsettelled_bets
                  : 0}
              </h1>
            </div>
          </div>
        </div>

        <div className="col-lg-3">
          <div className="ibox float-e-margins">
            <div className="ibox-title">
              <h5>Reverted Session Bets</h5>
            </div>
            <div className="ibox-content">
              <h1 className="no-margins">
                {sessionBetSlipCounts && sessionBetSlipCounts.cancelled_bets
                  ? sessionBetSlipCounts.cancelled_bets
                  : 0}
              </h1>
            </div>
          </div>
        </div>
      </div>

      <div className="wrapper wrapper-content animated fadeInRight">

        <div className="row">
          <div style={{ marginBottom: 20, width: 288, paddingRight: 5, position: 'relative', zIndex: 999 }}>
            <ReactSearchAutocomplete
              items={userListByKeyword}
              onSelect={(item) => handleOnSelect({ user_id: item.user_id })}
              onSearch={(e) => handleOnSearch(e)}
              onChange={(e) => handleInput(e)}
              onClear={(e) => onClear(e)}
              placeholder="Search by user name"
              className="input-sm form-control input-s-sm inline auto-input"
              name="search"
              formatResult={(item) => formatResult(item)}
            />
          </div>
          <div className="col-sm-5 m-b-xs">
            <select
              className="input-sm form-control input-s-sm inline"
              id="user_ids"
              name="user_id"
              style={{ height: "34px" }}
            >
              <option value="">All Users</option>
            </select>
          </div>
          <div className="col-sm-5 m-b-xs">
            <Select
              options={fancyOptions}
              onChange={(item) => {
                handleOnSelect({ session_id: item.value })
                setSelectedSession(item);
              }
              }
              value={selectedSession}
            />
          </div>
          <div className="col-lg-12">
            <div className="ibox float-e-margins">
              <div className="ibox-title">
                <h5> Session Bet Slip</h5>
                <div className="ibox-tools"><button className="btn btn-primary" onClick={() => window.location.reload()}>Refresh</button></div>
              </div>

              <div className="ibox-content table-responsive">
                <table className="table table-bordered table-striped">
                  <thead>
                    <tr className="tbl-header-middle">
                      <th colSpan="10">Bet</th>
                      <th colSpan="3">My Share / Position</th>
                      <th colSpan="3">Settlement</th>
                    </tr>
                  </thead>

                  <thead>
                    <tr className="table-inner-header">
                      <th>Bet ID</th>
                      <th>DATE</th>
                      <th>User</th>
                      <th>Session Title</th>
                      <th>RATE</th>
                      <th>RUNS</th>
                      <th>AMOUNT</th>
                      <th>MODE</th>
                      <th>No</th>
                      <th>Yes</th>
                      <th>My Share</th>
                      <th>No</th>
                      <th>Yes</th>
                      <th>Status</th>
                      <th>Result</th>
                      <th>Action</th>
                    </tr>
                  </thead>

                  {sessionBetSlipList && sessionBetSlipList.length ? (
                    <>
                      <tbody>
                        {sessionBetSlipList.map((bets, index) => (
                          <tr key={index} style={{ backgroundColor: bets.betting_type == "1" ? "#72bbef" : bets.betting_type == "2" ? "#f994ba" : "" }}>
                            <td>{bets.betslip_uid}</td>

                            <td>
                              <i className="fa fa-clock-o"></i>&nbsp;
                              {formatDateAndTime(
                                "MMM DD, hh:mm:ss A",
                                bets.created_at
                              )}
                            </td>

                            <td>
                              <a
                                href="/"
                                onClick={(e) => {
                                  e.preventDefault();
                                  navigate(
                                    `/userview/client/${bets.user_guid}/${bets.username}`
                                  );
                                }}
                              >
                                {bets.username} ({bets.name})
                              </a>
                            </td>
                            <td>{bets.market_name}</td>
                            <td>
                              {/* {bets.display_price} */}
                              {bets.display_price
                                ? parseFloat(bets.display_price).toFixed(2)
                                : "-"}
                            </td>
                            <td>{bets.line}</td>
                            <td>{bets.stake}</td>
                            {/* using ( == ) to ( === ) */}
                            <td>{bets.betting_type == "1" ? "YES" : "NO"}</td>
                            <td>{(bets.extra_No_1 % 1 == 0) ? bets.extra_No_1 : (bets.extra_No_1).toFixed(2)}</td>
                            <td>{(bets.extra_Yes_1 % 1 == 0) ? bets.extra_Yes_1 : (bets.extra_Yes_1).toFixed(2)}</td>
                            <td>{bets.extra_my_share}</td>
                            <td>{(bets.extra_after_No_2 % 1 == 0) ? bets.extra_after_No_2 : (bets.extra_after_No_2).toFixed(2)}</td>
                            <td>{(bets.extra_after_Yes_2 % 1 == 0) ? bets.extra_after_Yes_2 : (bets.extra_after_Yes_2).toFixed(2)}</td>
                            {/* using ( == ) to ( === ) */}
                            <td>
                              {bets.winning_status == "0" && (
                                <span className="label label-info">
                                  Pending
                                </span>
                              )}
                              {/* using ( == ) to ( === ) */}
                              {bets.winning_status == "3" && (
                                <span className="label label-danger">
                                  Cancelled
                                </span>
                              )}

                              {(bets.winning_status == "1" ||
                                bets.winning_status == "2") && (
                                  <span className="label label-primary">
                                    Settle
                                  </span>
                                )}
                            </td>

                            <td>
                              {/* {(bets.winning_status == "1") ? -(bets.pp * bets.extra_my_share) / 100 :
                                (bets.winning_status == "2") ? (bets.pp * bets.extra_my_share) / 100 : ''} */}
                              {(bets?.settlement_result) || ""}
                            </td>
                            <td>
                              {userLogin.role == 1 &&
                                bets.winning_status == "0" && (
                                  <button
                                    type="button"
                                    className="btn btn-primary"
                                    onClick={() => handleDeleteFn(bets)}
                                  >
                                    DELETE
                                  </button>
                                )}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                      <tfoot>
                        <tr>
                          <th></th>
                          <th></th>
                          <th>Total</th>
                          <th></th>
                          <th></th>
                          <th></th>
                          <th>{convertParseFloat(getSum("stake"))}</th>
                          <th></th>
                          <th>{convertParseFloat(getSum("extra_No_1"))}</th>
                          <th>{convertParseFloat(getSum("extra_Yes_1"))}</th>
                          <th></th>
                          <th>{convertParseFloat(getSum("extra_after_No_2"))}</th>
                          <th>{convertParseFloat(getSum("extra_after_Yes_2"))}</th>
                          <th></th>
                          <th>{convertParseFloat(getSum("extra_plus_minus"))}</th>
                        </tr>
                      </tfoot>
                    </>
                  ) : (
                    <tbody>
                      <td colSpan={15}>No Data Found</td>
                    </tbody>
                  )}
                </table>

                <div className="paginate_class">
                  <ReactPaginate
                    previousLabel={"prev"}
                    nextLabel={"next"}
                    breakLabel={"..."}
                    breakClassName={"break-me"}
                    pageCount={pageCount}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={5}
                    onPageChange={handlePageClick}
                    containerClassName={"pagination"}
                    subContainerClassName={"pages pagination"}
                    activeClassName={"active"}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {
        showDeleteModal.user_id &&
        <ConfrimModal
          isShow={Boolean(showDeleteModal.user_id)}
          message="Are you sure you want to delete"
          onCancle={() => setShowDeleteModal(false)}
          onSubmit={() => handleDeleteFn()}
          loading={showDeleteModal.loading}
        />
      }
    </div >
  );
}

export default SessionBetSlipsComponent;
