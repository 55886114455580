import React, { Component, createRef } from "react";
import InnerPageHeader from "../common/InnerPageHeader";
import { listRequest } from "../../_actions/master/master.actions";
import {
  betLockUnLock,
  casinolockUnlock,
  activeInactiveUsers,
  list,
  matkalockUnlock,
  pertnerInfoApi,
} from "../../_services/master.services";
import { toast } from "react-toastify";

import { debounce } from "lodash";

import ReactPaginate from "react-paginate";
import { Link, NavLink } from "react-router-dom";
import { userRoleDetails, userRoleKeys } from "_constants";
import { depositBalance, resetUserPassword, withdrawBalance } from "_services";
import { UpdateBalanceModal } from "modals";
import { PartnerInfoModal } from "components/common/PartnerInfoModal";
import moment from "moment";
import Swal from "sweetalert2";

class List extends Component {
  constructor(props) {
    super(props);
    this.state = {
      role: props?.role,
      keyword: "",
      UserData: {},
      confirmEvent: "",

      masterList: [],
      totalRecords: "",
      currentPage: 1,
      perPage: 10,
      pageCount: 0,
      UpdateBalanceModalShow: false,
      PartnerInfoModalShow: false,
      eventLoading: false,
      UpdateBalanceModalTitle: null,
      selectedUser: null,
      openDropdown: null,
      persnalInfoList: [],
    };
  }

  debouncedLog = debounce((text) => this.fetchMasterData(text), 500);


  toggleDropdown = (id) => {
    this.setState((prevState) => ({
      openDropdown: prevState.openDropdown === id ? null : id,
    }));
  };


  componentDidMount() {
    this.fetchMasterData(this.state.keyword);
    this.scrollToTop();
  }

  fetchMasterData = (searchData = "") => {
    let sendObj = {
      user_guid: "",
      current_page: this.state.currentPage,
      items_perpage: this.state.perPage,
      keyword: searchData,
      role: this.state.role,
    };

    this.props.dispatch(
      listRequest(sendObj, (res) => {
        if (res && res.data) {
          this.setState({
            masterList: res.data.userdownlist,
          });

          this.setState({
            totalRecords: res.data.total_records,
          });

          this.setState({
            pageCount: Math.ceil(res.data.total_records / this.state.perPage),
          });
        } else {
          this.setState({
            masterList: [],
            totalRecords: 0,
          });
        }
      })
    );
  };

  getRecordDetails = () => {
    const { currentPage, totalRecords } = this.state;
    let startingCount =
      this.state.currentPage === 1
        ? 1
        : this.state.currentPage * this.state.perPage - 9;
    let endingCount =
      this.state.perPage < totalRecords
        ? this.state.currentPage === 1
          ? this.state.perPage
          : this.state.currentPage * 10
        : totalRecords;
    return `Showing ${startingCount} to ${endingCount} of ${totalRecords} entries`;
  };

  handleChange = (e) => {
    this.setState({ keyword: e.target.value });
    this.debouncedLog(e.target.value);
  };

  statusChange = (confirmEvent, user, ind) => {
    let sendData = {};
    sendData.user_guid = user.user_guid;
    sendData.status = user.status == "0" ? 1 : 0;

    activeInactiveUsers(sendData)
      .then((response) => {
        if (response?.data?.message != "") {
          toast.success("user updated success.")
        }
        if (response) {
          let sendObj = {
            user_guid: user.user_guid,
            current_page: this.state.currentPage,
            items_perpage: this.state.perPage,
            keyword: this.state.keyword,
            role: this.state.role,
          };


          list(sendObj)
            .then((response) => {
              this.setState({ masterList: response.data.data.userdownlist });
            })
            .catch((error) => {
              console.log(error);
            });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  lockUnlockBet = (event, user, ind) => {
    let sendData = {};
    sendData.user_guid = user.user_guid;
    sendData.lock_betting = user.lock_betting == "0" ? 1 : 0;

    betLockUnLock(sendData)
      .then((response) => {
        if (response) {
          let sendObj = {
            user_guid: user.user_guid,
            current_page: this.state.currentPage,
            items_perpage: this.state.perPage,
            keyword: this.state.keyword,
            role: this.state.role,
          };

          list(sendObj)
            .then((response) => {
              this.setState({ masterList: response.data.data.userdownlist });
            })
            .catch((error) => {
              console.log(error);
            });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  lockUnlockCasino(event, user, ind) {
    if (event) {
      let sendData = {};
      sendData.user_guid = user.user_guid;
      sendData.lock_casino_bet = user.lock_casino_bet == "0" ? 1 : 0;
      try {
        casinolockUnlock(sendData)
          .then((response) => {
            if (response) {
              let sendObj = {
                user_guid: user.user_guid,
                current_page: this.state.currentPage,
                items_perpage: this.state.perPage,
                keyword: this.state.keyword,
                role: this.state.role,
              };

              list(sendObj)
                .then((response) => {
                  this.setState({
                    masterList: response.data.data.userdownlist,
                  });
                })
                .catch((error) => {
                  console.log(error);
                });
            }
          })
          .catch((error) => {
            console.log(error);
          });
      } catch (error) {
        console.log(error, "error");
      }
    }
  }

  scrollToTop = () => {
    setTimeout(() => {
      window.scrollTo({
        top: document,
        behavior: "smooth",
      });
    }, 1000);
    return true;
  };

  handlePageClick = (e) => {
    const selectedPage = e.selected;
    this.setState({ currentPage: selectedPage + 1 }, () => {
      this.fetchMasterData(this.state.keyword);
    });
  };

  statusChange = (confirmEvent, user, ind) => {
    let sendData = {};
    sendData.user_guid = user.user_guid;
    sendData.status = user.status == "0" ? 1 : 0;

    activeInactiveUsers(sendData)
      .then((response) => {
        if (response?.data?.message != "") {
          toast.success("user updated success.")
        }
        if (response) {
          this.fetchMasterData();
        }
      })
      .catch((error) => {
        toast.error("Error");
      });
  };

  lockUnlockBet = (event, user, ind) => {
    let sendData = {};
    sendData.user_guid = user.user_guid;
    sendData.lock_betting = user.lock_betting == "0" ? 1 : 0;

    betLockUnLock(sendData)
      .then((response) => {
        if (response) {
          this.fetchMasterData();
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  lockUnlockCasino(event, user, ind) {
    if (event) {
      let sendData = {};
      sendData.user_guid = user.user_guid;
      sendData.lock_casino_bet = user.lock_casino_bet == "0" ? 1 : 0;
      try {
        casinolockUnlock(sendData)
          .then((response) => {
            if (response) {
              this.fetchMasterData();
            }
          })
          .catch((error) => {
            console.log(error);
          });
      } catch (error) {
        console.log(error, "error");
      }
    }
  }
  lockUnlockMatka(event, user, ind) {
    if (event) {
      let sendData = {};
      sendData.user_guid = user.user_guid;
      sendData.lock_matka = user.lock_matka == "0" ? 1 : 0;
      try {
        matkalockUnlock(sendData)
          .then((response) => {
            if (response) {
              this.fetchMasterData();
            }
          })
          .catch((error) => {
            console.log(error);
          });
      } catch (error) {
        console.log(error, "error");
      }
    }
  }

  setToggleBalanceModal = (item, type) => {
    this.setState({ selectedUser: item, UpdateBalanceModalTitle: type, UpdateBalanceModalShow: true })
  }


  modalHide = () => {
    this.setState({ UpdateBalanceModalShow: false })
    this.setState({ PartnerInfoModalShow: false })
  }
  updateUserBalance = async (amount, remark) => {
    const profileData = JSON.parse(localStorage.getItem("userLogin"))
    const paload = {
      user_guid: this.state.selectedUser?.user_guid,
      amount: amount,
      remark
    };
    this.setState({ eventLoading: true });
    if (this.state.UpdateBalanceModalTitle === "Withdraw") {
      try {
        const res = await withdrawBalance(paload);
        this.handleResponse(res, profileData.balance + amount);
        this.modalHide()
      } catch (error) {
        if (error?.response?.data?.error) {
          toast.error(error.response.data.error);

        }
        this.setState({ eventLoading: false });
        // this.modalHide()
      }
    }

    if (this.state.UpdateBalanceModalTitle === "Deposit") {
      try {
        const res = await depositBalance(paload);
        this.handleResponse(res, profileData.balance - amount);
        this.modalHide()
      } catch (error) {
        if (error?.response?.data?.error) {
          toast.error(error.response.data.error);

        }
        this.setState({ eventLoading: false });
        // this.modalHide()
      }
    }
  };

  handleResponse = (res, amount) => {

    if (res?.data?.message) {
      toast.success(res?.data?.message);
      let currentUser = JSON.parse(localStorage.getItem("userLogin"))
      currentUser.balance = amount
      localStorage.setItem("userLogin", JSON.stringify(currentUser));
      this.fetchMasterData(this.state.keyword);
    }
    if (res?.data?.error) toast.error(res?.data?.error);
    this.modalHide();
    this.setState({ eventLoading: false });
  };



  partnerInfoHandle = async (user) => {
    let sendData = {};
    sendData.user_id = user.user_id;
    try {
      const response = await pertnerInfoApi(sendData);
      if (response) {
        // updateUserDetails(sendData);
        this.setState({ persnalInfoList: response?.data?.data?.users })
        // console.log("response", response)
        this.setState({ PartnerInfoModalShow: true })

      }
    } catch (error) {
      // handleActionError(error);
    }
  };

  render() {
    const { masterList, totalRecords, currentPage, perPage, pageCount,
      eventLoading,
      selectedUser,
      UpdateBalanceModalTitle,
      UpdateBalanceModalShow,
      PartnerInfoModalShow,
      persnalInfoList
    } = this.state;

    const profileData = localStorage.getItem("userLogin")
      ? +JSON.parse(localStorage.getItem("userLogin")).balance
      : 0;

    const UpdateBalanceModalProps = {
      title: UpdateBalanceModalTitle,
      isShow: selectedUser,
      parentBalance: profileData,
      modalToggle: this.modalHide,
      onSubmit: (amount, remark) => this.updateUserBalance(amount, remark),
      loading: eventLoading,
      cancleText: "Cancel",
    }
    const PartnerInfoModalProps = {
      isShow: PartnerInfoModalShow,
      modalToggle: this.modalHide,
      list: persnalInfoList,
      uplineData: [{ username: "company" }],
      loading: eventLoading,
      cancleText: "Cancel"

    }
    const { title, navigationUrl } = this.props;
    return (
      <>
        <InnerPageHeader
          pageTitle={`${title} List`}
          breadcrumbs={[
            { title: "Dashboard", url: "/" },
            { title: title, url: { navigationUrl } },
          ]}
          currenPageTitle="List"
        />
        <div className="wrapper wrapper-content animated fadeInRight">
          <div className="row">
            <div className="col-lg-12">
              <div className="ibox float-e-margins">
                <div className="ibox-title">
                  <h5 style={{ fontWeight: 700 }}>All {title}</h5>
                  <div className="ibox-tools">
                    <Link
                      to={navigationUrl + "/add"}
                      className="btn btn-primary btn-xs"
                      style={{ cursor: "pointer" }}
                    >
                      Create {title}
                    </Link>
                    {/* <Link
                      to={navigationUrl + "/update-limit"}
                      className="btn btn-primary btn-xs"
                      style={{ cursor: "pointer" }}
                    >
                      Update Limit
                    </Link> */}
                  </div>
                </div>
                <div className="ibox-content">
                  <div className="table-responsive">
                    <div
                      id="DataTables_Table_0_wrapper"
                      className="dataTables_wrapper form-inline dt-bootstrap"
                    >
                      {/* <div className="dt-buttons btn-group">
                        <a
                          className="dt-button buttons-csv buttons-html5"
                          tabIndex="0"
                          aria-controls="DataTables_Table_0"
                          href="#"
                          style={{ marginRight: "0.5rem" }}
                        >
                          <span>CSV</span>
                        </a>
                        <a
                          className="dt-button buttons-pdf buttons-html5"
                          tabIndex="0"
                          aria-controls="DataTables_Table_0"
                          href="#"
                        >
                          <span>PDF</span>
                        </a>
                      </div> */}

                      <div
                        id="DataTables_Table_0_filter"
                        className="dataTables_filter"
                      >
                        <label>
                          Search:
                          <input
                            type="search"
                            className="form-control input-sm"
                            placeholder=""
                            aria-controls="DataTables_Table_0"
                            value={this.state.keyword}
                            onChange={this.handleChange}
                          />
                        </label>
                      </div>

                      <table className="table table-striped table-hover dataTables-example">
                        <thead>
                          <tr onClick={() => this.toggleDropdown(this.state.openDropdown)}>
                            {/* <th>S.No </th> */}
                            {/* <th rowSpan="2">#</th> */}
                            <th rowSpan="2">Code</th>
                            <th rowSpan="2">Name</th>
                            {/* <th rowSpan="2">{this.state.role == 3 ? "Company" : userRoleDetails[Number(this.state.role) - 1]?.title}</th> */}
                            {/* <th rowSpan="2">Reference</th> */}
                            <th rowSpan="2">P/L</th>
                            <th rowSpan="2">Exposure</th>
                            {/* <th>User Name</th>*/}
                            {/* <th>Name</th> */}
                            <th rowSpan="2">Coins</th>
                            <th rowSpan="2">D.O.J.</th>
                            <th rowSpan="2">Share %</th>
                            {/* <th>My Share</th> */}
                            {/* <th>Max Share</th> */}
                            <th rowSpan="2">C Share %</th>
                            <th rowSpan="2">SSN %</th>
                            {/* combine */}
                            {/* <th colSpan="2" style={{ padding: 0, margin: 0, lineHeight: 0, height: 'initial', fontSize: '12px' }}>Master Comm %</th> */}
                            <th rowSpan="2">Status</th>
                            {/* <th rowSpan="2">User Lock</th> */}
                            <th rowSpan="2">Bet</th>
                            <th rowSpan="2">Options</th>
                            {/* <th>Withdraw</th>
                            <th>Deposit</th>*/}

                            {/* <th>Casino Lock</th>  */}
                            {/* <th>Matka Lock</th> */}
                            {/* <th>Casino Lock/Hide</th> */}
                            {/* <th rowSpan="2">Actions</th> */}
                          </tr>
                          {/* <tr>
                            <th style={{ borderRadius: 0, padding: 0, margin: 0, lineHeight: 0, height: 'initial', fontSize: '12px' }}>Match</th>
                            <th style={{ borderRadius: 0, padding: 0, margin: 0, lineHeight: 0, height: 'initial', fontSize: '12px' }}>SSN</th>
                          </tr> */}
                        </thead>
                        <tbody>
                          {masterList && masterList.length ? (
                            masterList.map((user, index) => (
                              <tr className="gradeX" key={index}>
                                {/* <td>{user.agent_id}</td> */}
                                {/* <td>
                                  {index + 1 + (currentPage - 1) * perPage}
                                </td> */}
                                {/* <td style={{ overflow: 'initial' }}>
                                  <div className="name_add ">
                                    <button
                                      onClick={() => {
                                        this.partnerInfoHandle(user); this.toggleDropdown(this.state.openDropdown)
                                      }
                                      }
                                    >
                                      <i class="fa-solid fa-plus"></i>{" "}
                                    </button>

                                    <div className="dropdown">
                                      <button className="dropbtn"
                                        onClick={() => this.toggleDropdown(user.user_id)}
                                      >
                                        <i className="fa-solid fa-square-caret-down"></i>
                                      </button>
                                      {this.state.openDropdown === user.user_id && (<div className="dropdown-content">
                                        <ul>
                                          <li> <Link
                                            to={`${navigationUrl}/edit/${user?.user_guid}`}
                                            state={{ selectedData: user }}
                                          >
                                            Edit
                                          </Link></li>
                                          <li>
                                            <p
                                              onClick={() => {
                                                this.setToggleBalanceModal(user, "Deposit"); this.toggleDropdown(this.state.openDropdown)
                                              }
                                              }
                                            >
                                              Deposit
                                            </p>
                                          </li>

                                          <li>  <p
                                            onClick={() => {
                                              this.setToggleBalanceModal(user, "Withdraw"); this.toggleDropdown(this.state.openDropdown)
                                            }
                                            }
                                          >
                                            Withdraw
                                          </p></li>
                                          <li> <p onClick={(e) => { this.statusChange(e, user, false); this.toggleDropdown(this.state.openDropdown) }}>
                                            {user.status == 1 ? "InActive" : "Active"}
                                          </p></li>

                                          <li>{user.role != 9 && <a
                                            href={`/userdetails/${userRoleDetails[this.state.role]?.type
                                              }/directagents/${user.user_guid}/${user.user_id}`} >
                                            <div className="eye_icon" >
                                              Downline
                                            </div>
                                          </a>}
                                          </li>

                                          <li>{user.role != 9 && <a
                                            href={`/userdetails/${userRoleDetails[this.state.role]?.type
                                              }/directclients/${user?.user_guid}/${user?.user_id}`} >
                                            <div className="eye_icon" >
                                              Client
                                            </div>
                                          </a>}
                                          </li>


                                          <li> <a
                                            href={`/userdetails/${userRoleDetails[this.state.role]?.type
                                              }/coinhistory/${user?.user_guid}/${user?.user_id}`}
                                          >
                                            Statement
                                          </a></li>
                                          <li>
                                            <a
                                              href={`/account-operations/${user?.user_id}`}
                                            >
                                              <p>
                                                Account Operations
                                              </p>
                                            </a>
                                          </li>
                                          <li>  <a
                                            href="/"
                                            onClick={(e) => {
                                              e.preventDefault();
                                              this.props.navigate(
                                                `/edit_password/${user.user_guid}`
                                              );
                                            }}

                                          >
                                            Reset Password
                                          </a></li>
                                        </ul>

                                      </div>)}
                                    </div>
                                  </div>
                                </td> */}
                                <td onClick={() => this.toggleDropdown(this.state.openDropdown)}>

                                  {user.role != 9 ? <a
                                    href={`/userdetails/${userRoleDetails[this.state.role]?.type
                                      }/directagents/${user.user_guid}/${user.user_id}`} >
                                    <div className="eye_icon" >
                                      <span className="user-abbr">{userRoleDetails[Number(user.role)]?.abbreviation}</span>
                                      {user.username}
                                    </div>
                                  </a> :
                                    user.username}


                                </td>
                                <td onClick={() => this.toggleDropdown(this.state.openDropdown)}>
                                  {user.first_name && (`${user.first_name}`)}
                                </td>

                                {/* <td onClick={() => this.toggleDropdown(this.state.openDropdown)}>{user.parent_name}</td> */}
                                {/* <td onClick={() => this.toggleDropdown(this.state.openDropdown)}>{user.reference}</td> */}
                                <td onClick={() => this.toggleDropdown(this.state.openDropdown)}>{user.ledger_balance}</td>
                                <td onClick={() => this.toggleDropdown(this.state.openDropdown)}>
                                  {user.role == 9 ?
                                    <Link to={`/exposure-details/${user.user_id}`}>{user.exposure}</Link>
                                    : user.exposure
                                  }
                                </td>
                                {/* <td>
                                  {user.first_name} {user.last_name}
                                </td> */}
                                <td td className="center" onClick={() => this.toggleDropdown(this.state.openDropdown)}> {user.balance}</td>
                                <td td className="center" onClick={() => this.toggleDropdown(this.state.openDropdown)}> {moment(user.created_at).format('DD-MM-yyyy')}</td>
                                <td td className="center" onClick={() => this.toggleDropdown(this.state.openDropdown)}> {user.my_agent_share}</td>
                                {/* <td className="center" onClick={() => this.toggleDropdown(this.state.openDropdown)}>{user.my_share}</td> */}


                                <td className="center" onClick={() => this.toggleDropdown(this.state.openDropdown)}>{user.agent_game_share}</td>
                                {/* <td className="center" onClick={() => this.toggleDropdown(this.state.openDropdown)}>{user.cricket_commission}</td> */}
                                <td className="center" onClick={() => this.toggleDropdown(this.state.openDropdown)}>{user.session_commission}</td>
                                {/* <td className="center" onClick={() => this.toggleDropdown(this.state.openDropdown)}>{user.status == 1 ? "Active" : "InActive"}</td> */}
                                <td className="center">
                                  <label className="switch">
                                    <input
                                      type="checkbox"
                                      checked={user.status == "1"}
                                      onChange={(e) =>
                                        this.statusChange(e, user, false)
                                      }
                                    />
                                    <span className="slider round"></span>
                                  </label>
                                </td>
                                <td className="center">

                                  <label className="switch">
                                    <input
                                      type="checkbox"
                                      checked={
                                        user.lock_betting == "0"
                                      }
                                      onChange={(e) =>
                                        this.lockUnlockBet(e, user, false)
                                      }
                                    />
                                    <span className="slider round"></span>
                                  </label>
                                </td>

                                <td className="center" style={{ overflow: 'visible' }} >
                                  <div className="user-options">
                                    {/* <div className="opt-items"><Link to={`${navigationUrl}/edit/${user?.user_guid}`} state={{ selectedData: user }} ><span>&#9998;</span></Link></div> */}
                                    {/* {user.role != 9 && <div className="opt-items" data-tooltip="Add Client"><Link to={`/my-clients/add/${user.user_id}`}><span>&#43;</span></Link></div>} */}
                                    <div className="opt-items" data-tooltip="Deposit" onClick={() => { this.setToggleBalanceModal(user, "Deposit"); this.toggleDropdown(this.state.openDropdown) }}><span>D</span></div>
                                    <div className="opt-items" data-tooltip="Withdraw" onClick={() => { this.setToggleBalanceModal(user, "Withdraw"); this.toggleDropdown(this.state.openDropdown) }}><span>W</span></div>
                                    <div className="opt-items" data-tooltip="Account Statement"><a href={`/userdetails/${userRoleDetails[this.state.role]?.type}/coinhistory/${user?.user_guid}/${user?.user_id}`}><span>A/C</span></a></div>
                                    <div className="opt-items" data-tooltip="Chip Statement"><Link to={`/chip-statement/${user.user_guid}`}><span>C/S-</span></Link></div>
                                    <div className="opt-items" data-tooltip="Chip Summary"><Link to={`/chip-summery/${user.user_id}`}><span>C/S</span></Link></div>
                                    {/* <div className="opt-items" data-tooltip="Profit/Loss"><Link to={`/profit-loss-report/${user.user_id}`}><span>P/L</span></Link></div> */}
                                    <div className="opt-items" data-tooltip="Settle Report"><Link to={`/settlement-history/${user.user_id}`}><span>S/R-</span></Link></div>
                                    {/* <div className="opt-items" data-tooltip="Settle Profit/Loss"><Link to={`/sports-report/${user.user_id}`}><span>S/PL</span></Link></div> */}
                                    {/* <div className="opt-items" data-tooltip="Change Password" onClick={(e) => { e.preventDefault(); this.props.navigate(`/edit_password/${user.user_guid}`); }} > <span>P</span></div> */}
                                    <div
                                      className="opt-items"
                                      data-tooltip="Change Password"
                                      onClick={(e) => {
                                        e.preventDefault();
                                        Swal.fire({
                                          title: 'Are you sure?',
                                          html: `Password of <strong>${user.username}</strong> will be reset to <strong>1234567!</strong>`, // Use 'html' instead of 'text'
                                          icon: 'warning',
                                          showCancelButton: true,
                                          confirmButtonColor: '#3085d6',
                                          cancelButtonColor: '#d33',
                                          confirmButtonText: 'Yes, change it!',
                                          customClass: {
                                            popup: 'custom-swal-popup', // Add custom class if needed
                                          },
                                        }).then((result) => {
                                          if (result.isConfirmed) {
                                            const payload = {
                                              user_guid: user.user_guid
                                            }
                                            resetUserPassword(payload)
                                              .then((response) => {
                                                // Handle success
                                                Swal.fire({
                                                  title: 'Success!',
                                                  text: 'Password has been reset successfully.',
                                                  icon: 'success',
                                                  confirmButtonText: 'Okay',
                                                  confirmButtonColor: 'green'
                                                });
                                              })
                                              .catch((error) => {
                                                // Handle error
                                                Swal.fire({
                                                  title: 'Error!',
                                                  text: 'There was an error resetting the password.',
                                                  icon: 'error',
                                                  confirmButtonText: 'Okay',
                                                });
                                              });
                                          }
                                        });
                                      }}
                                    >
                                      <span>P</span>
                                    </div>
                                    <div className="opt-items" data-tooltip="Bet History"><Link to={`/bet_history_by_user/${user.user_id}/${user.role}/1`}><span>BH</span></Link></div>
                                    <div className="opt-items" data-tooltip="Pending Bets"><Link to={`/bet_history_by_user/${user.user_id}/${user.role}/2`}><span>PB</span></Link></div>
                                    {/* <div className="opt-items" data-tooltip="Settings"><Link to={`/settings/${user.user_id}`}><span>S</span></Link></div> */}
                                    <div className="opt-items" data-tooltip="Sport Settings"><Link to={`/sport-setting/${user.user_guid}`}><span>SS</span></Link></div>
                                  </div>

                                </td>
                                {/* <td>
                                  <button
                                    type="button"
                                    className="btn anvGreenBtn"
                                    onClick={() =>
                                      this.setToggleBalanceModal(user, "Withdraw")
                                    }
                                  >
                                    Withdraw
                                  </button>
                                </td>
                                <td>
                                  <button
                                    type="button"
                                    className="btn anvYellowBtn"
                                    onClick={() =>
                                      this.setToggleBalanceModal(user, "Deposit")
                                    }
                                  >
                                    Deposit
                                  </button>
                                </td> */}


                                {/* <td className="center">
                                  <label className="switch">
                                    <input
                                      type="checkbox"
                                      checked={
                                        user.lock_casino_bet == "0"
                                      }
                                      onChange={(e) =>
                                        this.lockUnlockCasino(e, user, false)
                                      }
                                    />
                                    <span className="slider round"></span>
                                  </label>
                                </td> */}
                                {/* <td className="center">
                                  <label className="switch">
                                    <input
                                      type="checkbox"
                                      checked={user && user.lock_matka == "0"}
                                      onChange={(e) =>
                                        this.lockUnlockMatka(e, user, false)
                                      }
                                    />
                                    <span className="slider round"></span>
                                  </label>
                                </td> */}
                                {/* <td className="center">
                                  <Link
                                    className="btn btn-primary btn-sm"
                                    to={`/casino-setting/${user?.user_id}`}
                                    state={{ selectedData: user }}
                                  > Casino Lock/Hide
                                  </Link>
                                </td> */}
                                {/* <td className="">
                                  <Link
                                    to={`${navigationUrl}/edit/${user?.user_guid}`}
                                    state={{ selectedData: user }}
                                    className="btn btn-white btn-sm"
                                  >
                                    <i className="fa fa-pencil-square"></i> Edit
                                  </Link>
                                  &nbsp;&nbsp; &nbsp;&nbsp;

                                  <a
                                    href="/"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      this.props.navigate(
                                        `/edit_password/${user.user_guid}`
                                      );
                                    }}

                                    className="btn btn-white btn-sm"
                                  >
                                    <i className="fa fa-key"></i> CP
                                  </a>
                                </td> */}
                              </tr>
                            ))
                          ) : (
                            <tr className="gradeX">
                              <td colSpan={7}>No data available in table</td>
                            </tr>
                          )}
                        </tbody>
                      </table>

                      <div className="row" style={{ margin: "15px 0px" }} onClick={() => this.toggleDropdown(this.state.openDropdown)}>
                        <div className="col-xs-12 col-sm-6">
                          <div
                            className="dataTables_info"
                            id="DataTables_Table_0_info"
                            role="status"
                            aria-live="polite"
                          >
                            {totalRecords
                              ? this.getRecordDetails()
                              : "Showing 0 to 0 of 0 entries"}
                          </div>
                        </div>
                        <div className="col-xs-12 col-sm-6">
                          <div className="paginate_class">
                            <ReactPaginate
                              previousLabel={"prev"}
                              nextLabel={"next"}
                              breakLabel={"..."}
                              breakClassName={"break-me"}
                              pageCount={this.state.pageCount}
                              marginPagesDisplayed={2}
                              pageRangeDisplayed={5}
                              onPageChange={this.handlePageClick}
                              containerClassName={"pagination"}
                              subContainerClassName={"pages pagination"}
                              activeClassName={"active"}
                            />
                          </div>
                        </div>
                        <div className="clearfix visible-xs"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div >
        </div >
        {UpdateBalanceModalShow && <UpdateBalanceModal {...UpdateBalanceModalProps} />}
        {PartnerInfoModalShow && <PartnerInfoModal {...PartnerInfoModalProps} />}

      </>
    );
  }
}
export default List;
