import config from "../lib/app-config";
import { performRequest } from "_apiConfig/apiHandler";
import { default as apiEndPoints } from "_apiConfig/apiEndPoints";

export const createManualMarkets = (data) => {
  return performRequest(
    apiEndPoints.methodType.POST,
    `${config.api_baseurl}create_manual_event`,
    data
  );
};

