import React, { Component } from 'react'

import OtherChargesCmp from '../../components/OtherCharges/OtherChargesCmp';
export class OtherCharges extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }
    render() {
        return (
            
                <OtherChargesCmp />
            

        )
    }
}

export default OtherCharges