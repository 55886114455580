import React, { Component } from "react";

import { connect } from "react-redux";
import CommentaryComponent from "../../components/Commentary/Commentary";

class Commentary extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      
        <CommentaryComponent {...this.props} />
      
    );
  }
}
const mapstatetop = (state) => ({
  ...state,
});

export default connect(mapstatetop)(Commentary);
