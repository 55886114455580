import React, { Component } from "react";
import { useNavigate, useParams } from "react-router-dom";
import AddNewManualMarket from "../../../components/ManualMarketMatchDashboard/Add";
import { connect, useDispatch } from "react-redux";

function Add(props) {
  const params = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  return (
    <AddNewManualMarket
      {...props}
      params={params}
      dispatch={dispatch}
      navigate={navigate}
    />
  );
}
const mapstatetop = (state) => ({
  ...state,
});

export default connect(mapstatetop)(Add);
