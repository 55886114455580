import { getDeclaredFancyLive } from '_services';
import InnerPageHeader from 'components/common/InnerPageHeader';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

const DeclaredFancy = () => {
    const { event_id } = useParams(); // Extract event_id from URL parameters
    const userLogin = JSON.parse(localStorage.getItem("userLogin"));
    const [userRole, setUserRole] = useState(userLogin);
    const [isLoading, setLoading] = useState(false);
    const [declaredFancyData, setDeclaredFancyData] = useState([]);

    useEffect(() => {
        if (event_id) {
            fetchDeclaredFancy(event_id);
        }
    }, [event_id]); // Re-run when event_id changes

    const fetchDeclaredFancy = (event_id) => {
        setLoading(true);
        const sendData = { event_id };
        getDeclaredFancyLive(sendData)
            .then((response) => {
                if (response?.data?.data && response.data.data.length > 0) {
                    setDeclaredFancyData(response.data.data);
                } else {
                    setDeclaredFancyData([]);
                }
                setLoading(false);
            })
            .catch((error) => {
                console.error('Error fetching declared fancy data:', error); // Improved error logging
                setDeclaredFancyData([]);
                setLoading(false);
            });
    };

    return (
        <div>
            <InnerPageHeader
                pageTitle="Declared Fancy"
                breadcrumbs={[
                    { title: "Dashboard", url: "/" },
                    { title: "Declared Fancy", url: '' },
                ]}
                currenPageTitle="List"
            />

            <div className="wrapper wrapper-content animated fadeInRight">
                <div className="mx-0 mt-row">
                    <div className="col-lg-12">
                        <div className="ibox float-e-margins">
                            <div className="ibox-title">
                                <div>
                                    <div></div>
                                    <div></div>
                                    <div className="d-flex justify-start gap-2 overflow-x-scroll">
                                    </div>
                                </div>
                            </div>
                            <div className="ibox-content">
                                <div className="table-responsive">
                                    <table className="table table-striped table-hover dataTables-example">
                                        <thead>
                                            <tr>
                                                <th>Session</th>
                                                <th>Settled at</th>
                                                <th>Status</th>
                                                {/* <th>Action</th> */}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {declaredFancyData.map((declared, index) => (
                                                <tr key={index}>
                                                    <td
                                                        data-column="session_titl"
                                                        className="bet-place-tbl-t"
                                                        style={{ textAlign: "left" }}
                                                    >
                                                        {declared.runner_name}
                                                    </td>
                                                    <td className="bet-place-tbl-t bet-val-cen">
                                                        {declared.settlement_result}
                                                    </td>
                                                    <td
                                                        className="bet-place-tbl-t bet-val-cen"
                                                        style={{
                                                            color: declared.pl >= 0 ? "#228C22" : "#FF0000",
                                                        }}
                                                    >
                                                        {declared.pl}
                                                    </td>
                                                    {/* {userRole?.role === 1 && (
                                                        <td className="bet-place-tbl-t bet-val-cen">
                                                            <a
                                                                className="bt rollback_btn"
                                                                data-method="get"
                                                                data-remote="true"
                                                            // onClick={() => showConfirmPoppup("session", declared)}
                                                            >
                                                                Rollback
                                                            </a>
                                                        </td>
                                                    )} */}
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DeclaredFancy;
