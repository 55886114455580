import { BannerConsumer } from "components/layouts/Main";
import React, { Fragment, useState } from "react";
import { useEffect } from "react";
import { toast } from "react-toastify";
import { getDataBaseList, getBackUp } from "_services/announcement.services";
import InnerPageHeader from "components/common/InnerPageHeader";
const DatabaseBackUp = () => {
  const [dataBaseList, setDataBaseList] = useState("");
  const [loadding, setLoadding] = useState(false);

  useEffect(() => {
    getDataBase();
  }, []);

  const getDataBase = async () => {
    const res = await getDataBaseList();
    if (res) {
      setDataBaseList(res?.data?.data);
    }
  };

  const getSubmitDB = async () => {
    setLoadding(true);
    const res = await getBackUp();
    if (res?.data?.message === "Success") {
      getDataBase();
      setLoadding(false);
      toast.success(res?.data?.message);
    }
  };

  return (
    <>
      {" "}
      <BannerConsumer>
        {(value) => {
          return (
            <Fragment>
              <InnerPageHeader
                pageTitle="Database Backup"
                breadcrumbs={[{ title: "Dashboard", url: "/" }]}
                currenPageTitle="Database Backup"
              />
              <div
                className="wrapper wrapper-content animated fadeInRight"
                style={{ paddingBottom: 0 }}
              >
                <div className="row">
                  <div className="col-12">
                    <div className="ibox">
                      <div className="ibox-title">
                        <h5>Database Backup</h5>
                      </div>
                      <div className="ibox-content">
                        <div className="row">
                          <div className="col-lg-12">
                            <div className="form-group row">
                              <div className="col-sm-4 col-sm-offset-2">
                                {loadding ? (
                                  <button
                                    type="submit"
                                    className="btn btn-primary"
                                    disabled
                                  >
                                    Process..
                                  </button>
                                ) : (
                                  <button
                                    type="submit"
                                    className="btn btn-primary"
                                    onClick={(e) => getSubmitDB()}
                                  >
                                    DB Backup
                                  </button>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="ibox-content">
                <div className="table-responsive">
                  <div
                    id="DataTables_Table_0_wrapper"
                    className="dataTables_wrapper form-inline dt-bootstrap"
                  >
                    <table className="table table-striped table-bordered table-hover dataTables-example">
                      <thead>
                        <tr>
                          <th>S.No</th>
                          <th>Type</th>
                          <th>Date</th>
                          {/* <th>Action</th> */}
                        </tr>
                      </thead>
                      <tbody>
                        {dataBaseList ? (
                          dataBaseList.map((item, index) => (
                            <tr className="gradeX" key={index}>
                              <td>{index + 1}</td>
                              <td>{item?.dn_name}</td>
                              <td>{item?.date}</td>
                              {/* <td
                                                            className="pointer"
                                                            onClick={() =>
                                                                this.setState({ deleteConfirm: item?._id })
                                                            }
                                                        >
                                                            <i className="fa fa-trash"></i> Delete
                                                        </td> */}
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan={7} style={{ textAlign: "center" }}>
                              No Data found
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>

                    <div className="row" style={{ margin: "15px 0px" }}>
                      <div className="clearfix visible-xs"></div>
                    </div>
                  </div>
                </div>
              </div>
            </Fragment>
          );
        }}
      </BannerConsumer>
    </>
  );
};

export default DatabaseBackUp;
