import _ from 'lodash';

const DevHost     = ["localhost"];
const LiveHost    = ["admin.future9club.com", "future9club.com"];
const StagHost     = ["top2020.pro","top2020.in","www.top2020.pro","www.top2020.in"];

const hostname = window.location.hostname;

const live = {
  baseURL: "https://adminapi.future9club.com/api",
  socketURL: "https://node.future9club.com"
};

const dev = {
  baseURL: "https://adminapi.future9club.com/api",
  socketURL: "https://node.future9club.com"
};

const stag = {
  baseURL: "https://adminapi.future9club.com/api",
  socketURL: "https://node.future9club.com"
};


export const AppConfig =  _.includes(LiveHost, hostname)? live: (_.includes(StagHost, hostname)? stag: dev);

// export const AppConfig =  _.includes(LiveHost, hostname) ? dev: live;
