import { activeInactiveUsers, betLockUnLock, casinolockUnlock, matkalockUnlock, profileDetails } from '_services';
import InnerPageHeader from 'components/common/InnerPageHeader';
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';

const SportSetting = () => {
    const { user_guid } = useParams();
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to top when component mounts
    }, []);

    useEffect(() => {
        fetchProfile();
    }, [user_guid]);

    const fetchProfile = () => {
        let sendObj = {
            user_guid: user_guid || '',
        };

        setLoading(true);

        profileDetails(sendObj)
            .then((response) => {
                if (response && response.data && response.data.data) {
                    setData(response.data.data);
                }
                setLoading(false);
            })
            .catch((error) => {
                console.log(error, "error");
                setError("Failed to fetch data.");
                setLoading(false);
            });
    };


    const statusChange = (confirmEvent, user, ind) => {
        let sendData = {};
        sendData.user_guid = user.user_guid;
        sendData.status = user.status == "0" ? 1 : 0;

        activeInactiveUsers(sendData)
            .then((response) => {

                if (response) {
                    fetchProfile();
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const lockUnlockBet = (event, user, ind) => {
        let sendData = {};
        sendData.user_guid = user.user_guid;
        sendData.lock_betting = user.lock_betting == "0" ? 1 : 0;

        betLockUnLock(sendData)
            .then((response) => {
                if (response) {

                    fetchProfile();
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const lockUnlockCasino = (event, user, ind) => {
        if (event) {
            let sendData = {};
            sendData.user_guid = user.user_guid;
            sendData.lock_casino_bet = user.lock_casino_bet == "0" ? 1 : 0;
            try {
                casinolockUnlock(sendData)
                    .then((response) => {
                        if (response) {

                            fetchProfile();
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            } catch (error) {
                console.log(error, "error");
            }
        }
    }
    const lockUnlockMatka = (event, user, ind) => {
        if (event) {
            let sendData = {};
            sendData.user_guid = user.user_guid;
            sendData.lock_matka = user.lock_matka == "0" ? 1 : 0;
            try {
                matkalockUnlock(sendData)
                    .then((response) => {
                        if (response) {
                            fetchProfile();
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            } catch (error) {
                console.log(error, "error");
            }
        }
    }

    return (
        <div>
            <InnerPageHeader
                pageTitle={'User Setting'}
                breadcrumbs={[
                    { title: "Dashboard", url: "/" },
                    // { title: parentType, url: navigationUrl },
                ]}
                currenPageTitle={'User Setting'}
            />

            <div className="wrapper wrapper-content animated fadeInRight">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="ibox float-e-margins">
                            <div className="ibox-title">
                                <h5>User Setting</h5>

                            </div>
                            <div className="ibox-content">

                                {loading ? (
                                    <p>Loading...</p>
                                ) : error ? (
                                    <p>{error}</p>
                                ) : (
                                    <div className="card-content">
                                        <div className="row">
                                            <div className="col s12">
                                                <ul className="collection with-header" style={{ margin: 0 }}>
                                                    <li className="collection-header">
                                                        <h5>User Setting</h5></li>
                                                    <li className="collection-item" style={{ paddingLeft: 10 }}>
                                                        <div className='sport-item'>
                                                            <div className='sport-name'>User Lock</div>
                                                            <div className='action'>
                                                                <small>De-Active</small>
                                                                <label className="switch">
                                                                    <input
                                                                        type="checkbox"
                                                                        checked={data.status == "1"}
                                                                        onChange={(e) =>
                                                                            statusChange(e, data, false)
                                                                        }
                                                                    />
                                                                    <span className="slider round"></span>
                                                                </label>
                                                                <small>Active</small>
                                                            </div>
                                                            <div></div>
                                                        </div>
                                                    </li>
                                                    <li className="collection-item" style={{ paddingLeft: 10 }}>
                                                        <div className='sport-item'>
                                                            <div className='sport-name'>Bet Lock</div>
                                                            <div className='action'>
                                                                <small>De-Active</small>
                                                                <label className="switch">
                                                                    <input
                                                                        type="checkbox"
                                                                        checked={
                                                                            data.lock_betting == "0"
                                                                        }
                                                                        onChange={(e) =>
                                                                            lockUnlockBet(e, data, false)
                                                                        }
                                                                    />
                                                                    <span className="slider round"></span>
                                                                </label>
                                                                <small>Active</small>

                                                            </div>
                                                            <div></div>
                                                        </div>
                                                    </li>
                                                    <li className="collection-item" style={{ paddingLeft: 10 }}>
                                                        <div className='sport-item'>
                                                            <div className='sport-name'>Casino Lock</div>
                                                            <div className='action'>
                                                                <small>De-Active</small>
                                                                <label className="switch">
                                                                    <input
                                                                        type="checkbox"
                                                                        checked={
                                                                            data.lock_casino_bet == "0"
                                                                        }
                                                                        onChange={(e) =>
                                                                            lockUnlockCasino(e, data, false)
                                                                        }
                                                                    />
                                                                    <span className="slider round"></span>
                                                                </label>
                                                                <small>Active</small>
                                                            </div>
                                                            <div></div>
                                                        </div>
                                                    </li>
                                                    <li className="collection-item" style={{ paddingLeft: 10 }}>
                                                        <div className='sport-item'>
                                                            <div className='sport-name'>Matka Lock</div>
                                                            <div className='action'>
                                                                <small>De-Active</small>
                                                                <label className="switch">
                                                                    <input
                                                                        type="checkbox"
                                                                        checked={data && data.lock_matka == "0"}
                                                                        onChange={(e) =>
                                                                            lockUnlockMatka(e, data, false)
                                                                        }
                                                                    />
                                                                    <span className="slider round"></span>
                                                                </label>
                                                                <small>Active</small>
                                                            </div>
                                                            <div></div>
                                                        </div>
                                                    </li>

                                                </ul>

                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SportSetting