import React, { Component } from "react";
import InnerPageHeader from "./InnerPageHeader";
import { Link } from "react-router-dom";
import Pagination from "./Pagination";
import { usersReceiveCash, usersPayCash } from "_services/userDetails.services";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";


import { profileDetails } from '../../_services/userprofile.services'
import { isCompositeComponent } from "react-dom/test-utils";

class UserCashPayReceive extends Component {
  constructor(props) {
    super(props);
    this.profileDetailsDataReceive();
  }
  state = {
    remark: "",
    amount: "",
    totalRecords: 0,
    current_page: 1,
    items_perpage: 100,
    UserProps: {},
    newLedgerBal: '',
    btnValue: false,
  };

  componentDidMount() { }

  capitalizeFirstLetter(string) {
    if (string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    } else {
      return "User";
    }
  }

  handleSubmit = (e) => {
    e.preventDefault();

    this.setState({ btnValue: true });

    if (this.state.amount) {
      const { id, subtype, type, username } = this.props?.params;
      let sendObj = {};
      sendObj.user_guid = id;
      sendObj.amount = this.state.amount;
      sendObj.remark = this.state.remark;
      sendObj.type = subtype === "receivecash" ? 2 : 1;
      if (subtype) {
        if (subtype === "receivecash") {
          usersReceiveCash(sendObj)
            .then((res) => {
              // toast.success("Balance Successfully updated");
              toast.success(
                <div>
                  <i
                    className="fa fa-check"
                    style={{ fontWeight: "bold", marginRight: "1rem" }}
                    aria-hidden="true"
                  ></i>
                  <span>Balance Successfully updated</span>
                </div>,
                {
                  position: "top-center",
                  autoClose: 3000,
                  hideProgressBar: true,
                  closeOnClick: false,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: "colored",
                }
              );

              if (res && res?.data?.data?.ledger_balance) {

                this.setState({ newLedgerBal: res?.data?.data?.ledger_balance })

                this.setState({ amount: "" })
                this.setState({ remark: "" })

                this.setState({ btnValue: false })

              }

              // this.history.push(`/userview/${type}/${id}/${username}`);
            })
            .catch((error) => {
              console.log(error)
              toast.error(error.response.data.error);
            });
        } else {
          usersPayCash(sendObj)
            .then((res) => {
              // toast.success("Balance Successfully updated");
              toast.success(
                <div>
                  <i
                    className="fa fa-check"
                    style={{ fontWeight: "bold", marginRight: "1rem" }}
                    aria-hidden="true"
                  ></i>
                  <span>Balance Successfully updated</span>
                </div>,
                {
                  position: "top-center",
                  autoClose: 3000,
                  hideProgressBar: true,
                  closeOnClick: false,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: "colored",
                }
              );

              if (res && res?.data?.data?.ledger_balance) {

                this.setState({ newLedgerBal: res?.data?.data?.ledger_balance })

                this.setState({ amount: "" })
                this.setState({ remark: "" })

                this.setState({ btnValue: false })

              }

              // this.history.push(`/userview/${type}/${id}/${username}`);
            })
            .catch((error) => {
              toast.error(error.response.data.error);
            });
        }
      }
    } else {
      toast.error("Please Enter Amount , Amount field must contain a number greater than 0.");

      setTimeout(() => {
        this.setState({ btnValue: false });
      }, 1500);
    }
  };

  profileDetailsDataReceive = () => {

    const { id, subtype, type, username } = this.props?.params;
    let sendData = {};
    sendData.user_guid = id;
    sendData.amount = this.state.amount;
    sendData.remark = this.state.remark;
    sendData.type = subtype === "receivecash" ? 2 : 1;
    profileDetails(sendData)
      .then((response) => {
        this.setState({ UserProps: response?.data?.data });

        this.setState({ newLedgerBal: response?.data?.data?.ledger_balance });

      })
      .catch((error) => { });
  };

  render() {
    const { totalRecords, UserProps, newLedgerBal } = this.state;
    const { type, subtype, id, username } = this.props?.params;


    return (
      <>
        <InnerPageHeader
          pageTitle={type}
          breadcrumbs={[
            { title: "Dashboard", url: "/" },
            { title: type, url: `/userview/${type}/${id}/${username}` },
            // { title: this.capitalizeFirstLetter(type), url: "/master" },
          ]}
          currenPageTitle={
            subtype
              ? subtype === "receivecash"
                ? "Recieve Cash"
                : "Pay Cash"
              : null
          }
        />

        <div className="wrapper wrapper-content animated fadeInRight">
          <div className="row">
            <div className="col-lg-12">
              <div className="ibox float-e-margins">
                <div className="ibox-title">
                  <h5>

                    {this.state.UserProps.role && this.state.UserProps.role == '7' ? (subtype
                      ? subtype === "receivecash"
                        ? "Receive Cash From User"
                        : "Pay Cash To User"
                      : null) : ''}

                    {this.state.UserProps.role && this.state.UserProps.role != '7' ? (subtype
                      ? subtype === "receivecash"
                        ? "Receive Cash From Agent"
                        : "Pay Cash To Agent"
                      : null) : ''}

                  </h5>
                  {(subtype !== "receivecash") || (subtype !== "paycash") &&
                    <div className="ibox-tools">
                      <a className="collapse-link">
                        <i className="fa fa-chevron-up"></i>
                      </a>
                      <a
                        className="dropdown-toggle"
                        data-toggle="dropdown"
                        href="#"
                        aria-expanded="false"
                      >
                        <i className="fa fa-wrench"></i>
                      </a>
                      <ul className="dropdown-menu dropdown-user">
                        <li>
                          <a href="#">Market Settle</a>
                        </li>
                        <li>
                          <a href="#">Unsettle Market</a>
                        </li>
                      </ul>
                      <a className="close-link">
                        <i className="fa fa-times"></i>
                      </a>
                    </div>}
                </div>
                <div className="ibox-content table-responsive">
                  <form className="form-horizontal" onSubmit={this.handleSubmit}>
                    <div style={{ display: "none" }}>
                      <input name="utf8" type="hidden" value="✓" />
                    </div>
                    <div className="form-group">
                      <label className="col-lg-2 control-label">
                        Agent Name:
                      </label>
                      <div className="col-lg-9">
                        <p className="form-control-static">{username}</p>
                      </div>
                    </div>
                    <div className="form-group">
                      <label className="col-lg-2 control-label">
                        Ledger Balance:
                      </label>
                      <div className="col-lg-9">
                        <p className="form-control-static available-coin" id="">
                          {/* {this.state.UserProps.ledger_balance} */}
                          {this.state.newLedgerBal}
                        </p>
                      </div>
                    </div>
                    <div className="form-group">
                      <label className="col-sm-2 control-label">
                        Updates Coins
                      </label>
                      <div className="col-sm-9">
                        <input
                          type="number"
                          id=""
                          name="amount"
                          className="form-control added-coin"
                          value={this.state.amount}
                          onChange={(e) =>
                            this.setState({ amount: e.target.value })
                          }
                          min={1}
                        />
                      </div>
                    </div>
                    <div className="form-group">
                      <label className="col-sm-2 control-label">Note</label>
                      <div className="col-sm-9">
                        <textarea
                          id=""
                          name="remark"
                          className="form-control added-note"
                          value={this.state.remark}
                          onChange={(e) =>
                            this.setState({ remark: e.target.value })
                          }
                        ></textarea>
                      </div>
                    </div>
                    <div className="form-group">
                      <div className="col-sm-9 col-sm-offset-3">
                        <button
                          className="btn btn-primary"
                          type="button"
                          disabled={this.state.btnValue}
                          onClick={(e) => this.handleSubmit(e)}
                        >
                          Save Changes
                        </button>
                      </div>
                    </div>
                  </form>{" "}
                </div>
              </div>
            </div>
          </div>
        </div>

      </>
    );
  }
}
export default UserCashPayReceive;
