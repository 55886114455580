import React from "react";
import ManualMarketMatchDashboard from "../../../components/ManualMarketMatchDashboard/List";
import { connect, useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

function List(props) {
  const params = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate()
  return (
    <ManualMarketMatchDashboard
      {...props}
      params={params}
      dispatch={dispatch}
      navigate={navigate}
    />
  );
}
const mapstatetop = (state) => ({
  ...state,
});

export default connect(mapstatetop)(List);
