import React, { useEffect, useState } from "react";
import { companyAnnouncement } from "_services/announcement.services";
import Mic from "../../images/Vector.png"
import { userProfileRequest } from "_actions/userprofile/userprofile.actions";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";

function Banner(props) {
  const dispatch = useDispatch();
  const [announcement, setAnnouncement] = useState("");
  const [profileData, setProfileData] = useState("");
  let role = localStorage.getItem("role") || null;

  useEffect(() => {
    getCompanyAnnouncement();
  }, [props.toggleBanner]);

  const getCompanyAnnouncement = () => {
    companyAnnouncement()
      .then((response) => {
        if (
          response &&
          response.data &&
          response.data.data &&
          response.data.data.admin_agent_msg
        ) {
          setAnnouncement({
            admin_agent_msg: response.data.data.admin_agent_msg,
            user_msg: response.data.data.user_msg,
          });
        } else {
          setAnnouncement("");
        }
      })
      .catch(() => {
        setAnnouncement("");
      });
  };
  const clientAnnouncementView = () => {
    const list = [];
    for (const key in announcement) {
      if (Object.hasOwnProperty.call(announcement, key)) {
        list.push(
          <li className="px-2">
            &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; {announcement[key]}
            &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
          </li>
        );
      }
    }
    return list;
  };

  useEffect(() => {
    let sendObj = {
      role: role,
    };
    dispatch(
      userProfileRequest(sendObj, (res) => {
        if (res && res.data) {
          setProfileData(res?.data);
        }
      })
    );
  }, [role]);

  return (
    <>
      <div className="row border-bottom">
        {/* <div className="bl-n-pl">
          <div className="bl">BL : <span>{profileData?.balance}</span> </div>
          <div className="pl">Pl : <span><Link to={'/settlement-new'}>{profileData?.total_pl}</Link></span></div>
          <div ></div>
        </div> */}
        <ul className="marquee d-flex">
          <span className="mic">
            <img src={Mic} />
          </span>
          <marquee>{clientAnnouncementView()}</marquee>
        </ul>
      </div>
    </>
  );
}
export default Banner;
