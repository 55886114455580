import React, { useContext, useEffect, useState } from "react";
import InnerPageHeader from "../common/InnerPageHeader";
import ReactPaginate from "react-paginate";
import { UplineContext } from "ContextHandler";
import { userRoleDetails } from "_constants";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
  depositBalance, withdrawBalance,
  casinolockUnlock,
  betLockUnLock,
  activeInactiveUsers,
  matkalockUnlock,
  pertnerInfoApi,
  profileDetails,
} from "_services";
import ConfrimModal from "components/ConfirmModal";
import { PartnerInfoModal } from "components/common/PartnerInfoModal";
import { UpdateBalanceModal } from "modals";
import { BalanceModalContext } from "ContextHandler";

export const ManageList = (props) => {
  const {
    columnData,
    isLoading = false,
    role,
    dataList,
    keyword,
    handleSearch,
    pageCount,
    totalRecords,
    currentPage,
    handlePageClick,
    perPage,
    title,
    user_id,
    toggleDropdown
  } = props;

  const { balanceModal, setToggleBalanceModal } = useContext(BalanceModalContext);
  const [tDataList, setDataList] = useState([]);
  const [eventLoading, setLoading] = useState(false);
  const [persnalInfoList, setPersnalInfo] = useState()
  const profileData = JSON.parse(localStorage.getItem("profile"));
  const parentBalance = localStorage.getItem("profile")
    ? +JSON.parse(localStorage.getItem("profile")).balance
    : 0;

  const [uplineData, setUpLineData] = useState(null)

  const history = useNavigate();

  useEffect(() => {
    const sendObj = {
      user_id: user_id
    }
    profileDetails(sendObj)
      .then((response) => {
        if (response && response.data && response.data.data) {
          setUpLineData(response.data.data)

        }
      })
      .catch((error) => {
      });
  }, [user_id])

  useEffect(() => {
    setDataList(dataList);
    if (balanceModal?.act_type === "partnership") {
      partnerInfoHandle(balanceModal)
    }
  }, [dataList]);

  const goBack = () => {
    window.history.back();
  };

  // const RowElement = ({ item, column }) => (
  //   <tr>
  //     {column?.map((colItem, i) => {
  //       return (
  //         <>
  //           <td key={i} style={{ overflow: i == 0 ? 'initial' : '' }} onClick={i == 0 ? () => { } : toggleDropdown}>{item[`${colItem.value}`]}</td>

  //         </>
  //       );
  //     })}
  //   </tr>
  // );

  const RowElement = ({ item, column }) => (
    <tr>
      {column?.map((colItem, i) => (
        colItem.subColumns ? (
          colItem.subColumns.map((subColItem, subInd) => (
            <td key={i + "-" + subInd} style={{ overflow: i == 0 ? 'initial' : column.length - 1 ? 'visible' : '' }} onClick={i == 0 ? () => { } : null}>
              {item[`${subColItem.value}`]}
            </td>
          ))
        ) : (
          <td key={i} style={{ overflow: i == 0 ? 'initial' : column.length - 1 ? 'visible' : '' }} onClick={i == 0 ? () => { } : null}>
            {item[`${colItem.value}`]}
          </td>
        )
      ))}
    </tr>
  );

  const getRecordDetails = () => {
    const startingCount =
      currentPage === 1 ? 1 : currentPage * perPage - (perPage - 1);
    const endingCount =
      perPage < totalRecords
        ? currentPage === 1
          ? perPage
          : currentPage * perPage
        : totalRecords;
    return `Showing ${startingCount} to ${endingCount} of ${totalRecords} entries`;
  };

  const updateUserBalance = async (amount) => {
    const paload = {
      user_guid: balanceModal?.user_guid,
      amount: amount,
    };
    setLoading(true);
    if (balanceModal?.act_type === "Withdraw") {
      try {
        const res = await withdrawBalance(paload);
        handleResponse(res, +profileData?.balance + +amount);
        setToggleBalanceModal(false);
      } catch (error) {
        if (error?.response?.data?.error) {
          toast.error(error.response.data.error);

        }
        setLoading(false);

      }
    }

    if (balanceModal?.act_type === "Deposit") {
      try {
        const res = await depositBalance(paload);
        // handleResponse(res, +profileData?.balance - +amount);
        handleResponse(res, profileData.balance - amount);
        setToggleBalanceModal(false);
      } catch (error) {
        if (error?.response?.data?.error) {
          toast.error(error.response.data.error);

        }
        setLoading(false);

      }
    }
  };

  const handleResponse = (res, amount) => {
    if (res?.data?.message) {
      toast.success(res?.data?.message);
      localStorage.setItem(
        "profile",
        JSON.stringify({
          ...profileData,
          balance: amount,
        })
      );
    }
    if (res?.data?.error) toast.error(res?.data?.error);
    setToggleBalanceModal(false);
    setLoading(false);
  };

  const statusChange = (user) => {
    let sendData = {};
    sendData.user_guid = user.user_guid;
    sendData.status = user.status == "0" ? 1 : 0;
    activeInactiveUsers(sendData)
      .then((response) => {
        if (response?.data?.message != "") {
          toast.success("user updated success.")
        }
        if (response) {
          updateUserDetails(sendData);
        }
      })
      .catch((error) => {
        handleActionError(error);
      });
  };

  const lockUnlockBet = (user) => {
    let sendData = {};
    sendData.user_guid = user.user_guid;
    sendData.lock_betting = user.lock_betting == "0" ? 1 : 0;

    betLockUnLock(sendData)
      .then((response) => {
        if (response) {
          updateUserDetails(sendData);
        }
      })
      .catch((error) => {
        handleActionError(error);
      });
  };

  const lockUnlockCasino = async (user) => {
    let sendData = {};
    sendData.user_guid = user.user_guid;
    sendData.lock_casino_bet = user.lock_casino_bet == "0" ? 1 : 0;
    try {
      const response = await casinolockUnlock(sendData);
      if (response) {
        updateUserDetails(sendData);
      }
    } catch (error) {
      handleActionError(error);
    }
  };

  const partnerInfoHandle = async (user) => {
    let sendData = {};
    sendData.user_id = user.user_id;

    try {
      const response = await pertnerInfoApi(sendData);
      if (response) {
        // updateUserDetails(sendData);
        setPersnalInfo(response?.data?.data?.users)
        // console.log("response", response)
      }
    } catch (error) {
      handleActionError(error);
    }
  };

  const lockUnlockMatka = async (user) => {
    let sendData = {};
    sendData.user_guid = user.user_guid;
    sendData.lock_matka = user.lock_matka == "0" ? 1 : 0;
    try {
      const response = await matkalockUnlock(sendData);
      if (response) {
        updateUserDetails(sendData);
      }
    } catch (error) {
      handleActionError(error);
    }
  };

  const handleActionError = (error) => {
    setLoading(false);
  };

  const updateUserDetails = (obj) => {
    const listArray = [...tDataList];
    const ind = listArray.findIndex(
      (item) => item?.user_guid === balanceModal?.user_guid
    );
    listArray.splice(ind, 1, { ...listArray[ind], ...obj });
    setDataList(listArray);
    setToggleBalanceModal(false);
    setLoading(false);
  };

  const submitConfrim = () => {
    setLoading(true);

    switch (balanceModal?.title) {
      case "Casino":
        lockUnlockCasino(balanceModal);
        break;
      case "Matka":
        lockUnlockMatka(balanceModal);
        break;
      case "Account":
        statusChange(balanceModal);
        break;
      case "Bet":
        lockUnlockBet(balanceModal);
        break;
    }
  };
  const ModalUI = () => {
    switch (balanceModal?.act_type) {
      case "confrim":
        return (
          <ConfrimModal
            isShow={true}
            message={`Are you sure you want to ${balanceModal?.act_status} ${balanceModal?.title}`}
            onCancle={setToggleBalanceModal}
            onSubmit={() => submitConfrim()}
            loading={eventLoading}
          />
        );

      case "partnership":
        return (
          <PartnerInfoModal
            isShow={balanceModal}
            modalToggle={setToggleBalanceModal}
            list={persnalInfoList}
            uplineData={uplineData}
            // onSubmit={(amount) => updateUserBalance(amount)}
            loading={eventLoading}
            cancleText="Cancel"
          />
        );
      default:
        return (
          <UpdateBalanceModal
            title={balanceModal?.act_type}
            isShow={balanceModal}
            // parentBalance={parentBalance}
            parentBalance={Number(uplineData.balance)}
            modalToggle={setToggleBalanceModal}
            onSubmit={(amount) => updateUserBalance(amount)}
            loading={eventLoading}
            cancleText="Cancel"
          />
        );
    }
  };

  return (
    <>
      <InnerPageHeader
        pageTitle={(uplineData && uplineData.role ? userRoleDetails[(Number(uplineData.role) == 1 ? 2 : Number(uplineData.role)) + 1].title : "") + " List"}
        breadcrumbs={[
          { title: "Dashboard", url: "/" },
          // {
          //   // title: title ? title : uplineData && uplineData.role ? userRoleDetails[Number(uplineData.role) + 1]?.title : "",
          //   // title: uplineData && uplineData.role ? userRoleDetails[Number(uplineData.role) + 1]?.title : "",
          //   url: userRoleDetails[role]?.rootUrl,
          // },
        ]}
        currenPageTitle="List"
      />

      <div className="wrapper wrapper-content animated fadeInRight">
        <div className="row">
          <div className="col-lg-12">
            <div className="ibox float-e-margins">
              <div className="ibox-title" style={{ display: 'flex', justifyContent: 'space-between' }}>
                <h5 style={{ fontWeight: 700 }}>
                  {uplineData && uplineData.role ? userRoleDetails[Number(uplineData.role) + 1]?.title : ""} Details
                </h5>
                <div>
                  {title && <button onClick={goBack}>Back</button>}
                  {!title && (
                    <div className="ibox-tools">
                      <UplineContext.Consumer>
                        {({ setToggleUpline }) => (
                          <button
                            className="btn anvRedBtn midSiz_btn"
                            onClick={() => {
                              setToggleUpline({
                                userType: userRoleDetails[role]?.title,
                                role: role,
                                callBack: (pguid) =>
                                  history.push(
                                    userRoleDetails[role]?.rootUrl +
                                    "/add/" +
                                    pguid
                                  ),
                              });
                            }}
                          >
                            Create {userRoleDetails[role]?.title}
                          </button>
                        )}
                      </UplineContext.Consumer>
                    </div>
                  )}
                </div>
              </div>
              <div className="ibox-content">
                <div className="table-responsive">
                  <div
                    id="DataTables_Table_0_wrapper"
                    className="dataTables_wrapper form-inline dt-bootstrap"
                  >
                    {handleSearch && (
                      <>
                        {/* <div className="dt-buttons btn-group">
                          <a
                            className="dt-button buttons-csv buttons-html5"
                            tabIndex="0"
                            aria-controls="DataTables_Table_0"
                            href="#"
                            style={{ marginRight: "0.5rem" }}
                          >
                            <span>CSV</span>
                          </a>
                          <a
                            className="dt-button buttons-pdf buttons-html5"
                            tabIndex="0"
                            aria-controls="DataTables_Table_0"
                            href="#"
                          >
                            <span>PDF</span>
                          </a>
                        </div> */}

                        <div
                          id="DataTables_Table_0_filter"
                          className="dataTables_filter"
                        >
                          <label>
                            Search:
                            <input
                              type="search"
                              className="form-control input-sm"
                              placeholder=""
                              aria-controls="DataTables_Table_0"
                              value={keyword}
                              onChange={handleSearch}
                            />
                          </label>
                        </div>
                      </>
                    )}

                    <table className="table table-striped table-bordered table-hover dataTables-example">
                      <thead onClick={toggleDropdown}>
                        <tr>
                          {columnData?.map((item, ind) => (
                            <>
                              {item.subColumns ? <th className="sorting" key={item?.name + ind} colSpan={2} style={{ padding: "0px", margin: "0px", lineHeight: 0, height: "initial", fontSize: "12px" }}>
                                {item?.name}
                              </th>
                                : <th className="sorting" key={item?.name + ind} rowSpan={2}>
                                  {item?.name == "Agent" ? uplineData && uplineData.role ? userRoleDetails[Number(uplineData.role)]?.title : '' : item?.name}
                                </th>}
                            </>
                          ))}
                        </tr>
                        <tr>
                          {columnData?.map((item, ind) => (
                            item.subColumns ? (
                              item.subColumns.map((subItem, subInd) => (
                                <th style={{ borderRadius: "0px", padding: "0px", margin: "0px", lineHeight: "0", height: 'initial', fontSize: "12px" }} className="sorting" key={subItem?.name + subInd}>
                                  {subItem?.name}
                                </th>
                              ))
                            ) : (
                              <></>
                            )
                          ))}
                        </tr>
                      </thead>

                      <tbody>
                        {isLoading || tDataList?.length <= 0 ? (
                          <tr>
                            <td
                              className="no-data-available"
                              colSpan={9}
                              rowSpan={1}
                            >
                              {tDataList?.length <= 0 ? (
                                "No data available in table"
                              ) : (
                                <>Loading...</>
                              )}
                            </td>
                          </tr>
                        ) : (
                          tDataList?.map((item, i) => (
                            <RowElement
                              key={item?.user_guid}
                              item={item}
                              column={columnData}
                            />
                          ))
                        )}
                      </tbody>
                    </table>
                    <div className="row" style={{ margin: "15px 0px" }} onClick={toggleDropdown}>
                      <div className="col-xs-12 col-sm-6">
                        <div
                          className="dataTables_info"
                          id="DataTables_Table_0_info"
                          role="status"
                          aria-live="polite"
                        >
                          {totalRecords
                            ? getRecordDetails()
                            : "Showing 0 to 0 of 0 entries"}
                        </div>
                      </div>
                      <div className="col-xs-12 col-sm-6">
                        <div className="paginate_class">
                          <ReactPaginate
                            previousLabel={"prev"}
                            nextLabel={"next"}
                            breakLabel={"..."}
                            breakClassName={"break-me"}
                            pageCount={Math.ceil(totalRecords / perPage)}
                            marginPagesDisplayed={2}
                            pageRangeDisplayed={5}
                            initialPage={currentPage - 1}
                            forcePage={currentPage - 1}
                            onPageChange={handlePageClick}
                            containerClassName={"pagination"}
                            subContainerClassName={"pages pagination"}
                            activeClassName={"active"}
                          />
                        </div>
                      </div>
                      <div className="clearfix visible-xs"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {balanceModal?.user_id && <ModalUI />}
    </>
  );
};
