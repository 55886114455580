import React from "react";

import MatkaCategoryForm from "../../components/MatkaCategory/MatkaCategoryForm";
import { connect } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

function Add(props) {
  const location = useLocation();
  const navigate = useNavigate();
  return (
    <MatkaCategoryForm
      {...props}
      location={location}
      breadcrumbTitle={"Add Category"}
      navigate={navigate}
    />
  );
}
const mapstatetop = (state) => ({
  ...state,
});

export const AddMatkaCategory = connect(mapstatetop)(Add);
