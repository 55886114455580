import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  Navigate,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import { editUser } from "_services";
import EditClient from "components/Client/Edit";
import { toast } from "react-toastify";

function Edit(props) {
  const [state, setState] = useState({
    role: 8,
    updateData: "",
  });

  const location = useLocation();
  const navigate = useNavigate();
  const params = useParams();

  useEffect(() => {
    if (location?.state?.selectedData?.user_guid) {
      setState({
        updateData: location.state.selectedData,
      });
    }
  }, []);

  const editClient = (editObj = {}) => {
    editUser(editObj)
      .then(() => {
        navigate(-1);
        toast.success(
          <div>
            <i
              className="fa fa-check"
              style={{ fontWeight: "bold", marginRight: "1rem" }}
              aria-hidden="true"
            ></i>
            <span>updated successfully</span>
          </div>,
          {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: false,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          }
        );
      })
      .catch((error) => {
        let msg = error.response.data.error;

        if (msg) {
          toast.error(
            <div>
              <i
                className="fa fa-check"
                style={{ fontWeight: "bold", marginRight: "1rem" }}
                aria-hidden="true"
              ></i>
              <span>{msg}</span>
            </div>,
            {
              position: "top-center",
              autoClose: 3000,
              hideProgressBar: true,
              closeOnClick: false,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            }
          );
        } else {
          toast.error(
            <div>
              <i
                className="fa fa-check"
                style={{ fontWeight: "bold", marginRight: "1rem" }}
                aria-hidden="true"
              ></i>
              <span> Error !! </span>
            </div>,
            {
              position: "top-center",
              autoClose: 3000,
              hideProgressBar: true,
              closeOnClick: false,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            }
          );
        }
      });
  };

  let { redirect, updateData } = state;

  if (redirect) {
    return <Navigate to={redirect} />;
  }

  return (
    <EditClient
      heading={
        updateData && updateData.username ? updateData.username : "New User"
      }
      breadcrumbTitle={
        updateData && updateData.username ? updateData.username : "New User"
      }
      breadcrumb="User"
      navigationUrl={
        location?.state?.lastPath ? location?.state?.lastPath : "/my-clients"
      }
      onSubmitFunc={editClient}
      role={state.role}
      action={params?.id ? "Edit Client" : "New Client"}
      navigate={navigate}
      parentType="client"
      isEdit={Boolean(updateData)}
      params={params}
      {...props}
    />
  );
}
const mapstatetop = (state) => ({
  ...state,
});

export default connect(mapstatetop)(Edit);
