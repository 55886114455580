import React, { Component } from "react";
import {} from "react-router-dom";

import GlobalSearchComponent from "../../components/GlobalSearch/GlobalSearch";
//import withAuth from "../../../_helpers/withAuth";
import { connect } from "react-redux";

class GlobalSearch extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return <GlobalSearchComponent {...this.props} />;
  }
}
const mapstatetop = (state) => ({
  ...state,
});

export default connect(mapstatetop)(GlobalSearch);
