import React, { useCallback, useEffect, useState } from "react";
import { Link, Navigate } from "react-router-dom";
import { smoothlyMenu } from "../layouts/Helpers";
import $ from "jquery";
import { logoutUser } from "../../_actions";
import { useDispatch, useSelector } from "react-redux";
import { IoPersonSharp } from "react-icons/io5";
import { useScreenDetector } from "hooks/useScreenDetector";
import io from "socket.io-client"; // Import socket.io client
import config from "../../lib/app-config";

const TopHeader = (props) => {
  const userProfile = useSelector(state => state.userProfile)
  const { domainData } = useSelector(state => state.auth)
  const { isMobile } = useScreenDetector()

  const [state, setState] = useState({
    redirect: "",
    isToggle: false,
  });
  const [toggleDropdown, setToggleDropdown] = useState(false)
  const dispatch = useDispatch()
  const userLogin = JSON.parse(localStorage.getItem("userLogin"));

  const getRole = () => {
    let role = localStorage.getItem("role") || null;
    switch (role) {
      case "1":
        return "Company";

      case "2":
        return "Sub Company";

      case "3":
        return "Sub Admin";

      case "4":
        return "Admin";

      case "5":
        return "Super Senior";

      case "6":
        return "Super";

      case "7":
        return "Dealer";

      case "8":
        return "Agent";

      case "9":
        return "Client";

      case "10":
        return "Staff";

      default:
        return "User";
    }
  };

  const handleReloadPageStatus = useCallback((payload) => {
    if (payload) {
      window.location.reload()
      dispatch({
        type: "SOCKET_DATA",
        payload: { reloadPage: true },
      });
    }
  }, [dispatch]);

  useEffect(() => {
    // Establish socket connection
    const socket = io(config.socketurl, {
      reconnectionDelayMax: 100000,
      extraHeaders: {},
    });

    // Listen for the 'reloadPage' event from the socket server
    socket.on("logout_agent", handleReloadPageStatus);

    // Cleanup: Remove event listener and disconnect socket when component unmounts
    return () => {
      socket.off("logout_agent", handleReloadPageStatus); // Remove listener for reloadPage
      socket.disconnect(); // Disconnect the socket connection
    };
  }, [handleReloadPageStatus]); // Only re-run when handleReloadPageStatus changes



  useEffect(() => {
    applySidebarClassName();
  }, []);

  const toggleNavigation = () => {
    $("body").toggleClass("mini-navbar");
    applySidebarClassName();
  };

  const applySidebarClassName = () => {
    if ($(".mini-navbar").length > 0) {
      $("#scrollable-container").addClass("scrollable-menu");
      $("#scrollable-container").removeClass("scrollable-container");
    } else {
      $("#scrollable-container").addClass("scrollable-container");
      $("#scrollable-container").removeClass("scrollable-menu");
    }

    smoothlyMenu();
  };

  const logOut = () => {
    dispatch(
      logoutUser((result) => {
        if (result) {
          setState({ redirect: "login" });
        } else {
          setState({ redirect: "login" });
        }
      })
    );
  };
  let { redirect } = state;
  if (redirect == "login") {
    return <Navigate to={"/login"} />;
  }

  const handleOutsideClick = (e) => {
    if (!e.target.closest('.custom-dropdown-menu') && !e.target.closest('#user-logo')) {
      setToggleDropdown(false);  // Close dropdown if clicked outside
    }
  };




  return (
    <div className="row border-bottom anvMain_nav">
      <nav
        className="navbar navbar-static-top"
        id="top-navbar"
        role="navigation"
        style={{
          marginBottom: 0,
        }}
      >
        <div className="navbar-header">
          <a
            className="navbar-minimalize minimalize-styl-2  "
            onClick={() => toggleNavigation()}
          >
            <i className="fa fa-bars"></i>{" "}
          </a>
        </div>

        {isMobile && <div className="profile-element">
          <Link to="/" className="dropdown-toggle">
            <span className="clear">
              <img
                src={domainData?.logo}
                alt="Logo"
                className="logo-img logo-m"
              />
            </span>
          </Link>

        </div>}


        {/* User Credentials JSX */}
        <div id="user-dropdown-data1">
          <div id="user-credentials">
            <span className="block m-t-xs">
              {" "}
              <span style={{ fontSize: '15px' }}>
                {userLogin?.username}
              </span>
            </span>{" "}
            {/* <span className="text-muted text-xs block">
              {getRole()}
            </span> */}
            <p className="bl_x"><span>BL: {Math.abs(userProfile?.data?.balance) || 0} </span>{!isMobile && "|"}<span>PL: {userProfile?.data?.total_pl || 0}</span></p>
            {" "}
          </div>
          <div id="user-logo" style={{ marginRight: "5px" }} onClick={() => { setToggleDropdown(!toggleDropdown) }}>
            <IoPersonSharp size={'25px'} color="#000" />
          </div>

          {toggleDropdown && (
            <div className="custom-menu-d" onClick={() => { setToggleDropdown(false) }}>
              <div className="custom-dropdown-menu">
                <div className="custom-dropdown-item" onClick={logOut}> <i className="fa fa-sign-out " style={{ marginRight: '4px' }}></i>Sign out</div>
              </div>
            </div>
          )}


          {/* <ul className="nav" style={{ paddingRight: '20px', cursor: 'pointer' }}>
            <li>
              <i className="fa fa-sign-out" onClick={logOut}
                style={{ fontSize: '20px' }}></i>
            </li>
          </ul> */}

        </div>

      </nav >
    </div >
  );
};

// export default connect(mapStateToProps,null,null,{forwardRef:true})(SignInModal);

export default TopHeader;
