import React, { useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import InnerPageHeader from "components/common/InnerPageHeader";
import "./style.css";

function Delay() {
  const location = useLocation();

  const { matchData } = location && location.state ? location.state : {};

  const { event_id } = useParams();

  return (
    <>
      <InnerPageHeader
        pageTitle="Delay"
        breadcrumbs={[
          { title: "Dashboard", url: "/" },
          { title: "Matches", url: "/matches_list" },
          {
            title: matchData?.event_name,
            url: {
              pathname: `/match_dashboard/${event_id}`,
              state: { matchData },
            },
          },
        ]}
        currenPageTitle="Delay"
      />
      <div className="wrapper wrapper-content animated fadeInRight">
        <div className="row">
          <div className="col-12">
            <div className="ibox">
              <div className="ibox-title">
                <h5>Delay</h5>
              </div>
              <div className="ibox-content">
                <div className="row">
                  <div className="col-sm-6">
                    <div className="form-group">
                      <label className="">Min Match Odd Delay</label>
                      <input
                        name="min_odd_limit"
                        autoComplete="off"
                        type="text"
                        className="form-control"
                        value=""
                      />
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="form-group">
                      <label className="">Max Match Odd Delay</label>
                      <input
                        id="max_odd_limit"
                        name="max_odd_limit"
                        autoComplete="off"
                        type="text"
                        className="form-control"
                        value=""
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-6">
                    <div className="form-group">
                      <label className="">Min Fancy Delay</label>
                      <input
                        name="min_odd_limit"
                        autoComplete="off"
                        type="text"
                        className="form-control"
                        value=""
                      />
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="form-group">
                      <label className="">Max Fancy Delay</label>
                      <input
                        id="max_odd_limit"
                        name="max_odd_limit"
                        autoComplete="off"
                        type="text"
                        className="form-control"
                        value=""
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-6">
                    <div className="form-group">
                      <label className="">Min Other Market Delay</label>
                      <input
                        name="min_odd_limit"
                        autoComplete="off"
                        type="text"
                        className="form-control"
                        value=""
                      />
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="form-group">
                      <label className="">Max Other Market Delay</label>
                      <input
                        id="max_odd_limit"
                        name="max_odd_limit"
                        autoComplete="off"
                        type="text"
                        className="form-control"
                        value=""
                      />
                    </div>
                  </div>
                </div>
                <div className="form-group row">
                  <div className="col-sm-4 col-sm-offset-2">
                    <button
                      type="submit"
                      className="btn btn-primary"
                      // onClick={(e) => this.onSubmit(e, value)}
                    >
                      Save Changes
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id="render-sub-company-live-report">
        <div className="wrapper wrapper-content">
          <div className="row" style={{ margin: "0 !important" }}>
            <div className="match-index-row header-top-spacing">
              <div
                className="row"
                style={{ paddingBottom: "30px", margin: "auto auto" }}
              ></div>
            </div>
          </div>

          <div className="wrapper wrapper-content">
            <div className="row">
              <div className="col-lg-12"></div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Delay;
