import React, { useEffect, useRef, useState } from "react";
import SimpleReactValidator from "simple-react-validator";
import InnerPageHeader from "components/common/InnerPageHeader";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  getMatkaCategory,
  matkaActiveGameRequest,
  createMatkaNumber,
  matkaResult,
  getFastMatkaCategory,
  fastMatkaResult,
  fastMatkaActiveGameRequest,
  createFastMatkaNumber,
} from "_services/matkaservice";
import moment from "moment";
import { toast } from "react-toastify";
import Loader from "components/common/Loader";
import { isAscending } from "components/layouts/Helpers";
import ReactPaginate from "react-paginate";
import { firstLetterCapital, formatDateAndTime } from "utils";

const NumberForm = (props) => {
  const isFast = props?.type;
  const [state, setState] = useState({
    formValue: {
      date: new Date(),
      number: "",
      type: "",
      category: "",
    },
  });
  const [dataList, setDataList] = useState([]);
  const [resultList, setResultList] = useState([]);
  const [respData, setRespData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [numberError, setNumberError] = useState(false);
  const [categoryLoading, setCategoryLoading] = useState(false);
  const [filterDate, setFilterDate] = useState(new Date());
  const validator = useRef(new SimpleReactValidator());

  const [, setForceUpdate] = useState(false);
  const {
    formValue: { date, type, category, number },
    formValue,
    initialFormValue,
  } = state;
  const { breadcrumbTitle } = props;

  useEffect(() => {
    fetchListData();
  }, []);

  useEffect(() => {
    if (category !== "") {
      handleCategoryType();
    }
  }, [category]);

  useEffect(() => {
    fetchResultList(new Date());
  }, []);

  const fetchListData = async () => {
    try {
      const res = isFast
        ? await getFastMatkaCategory()
        : await getMatkaCategory();
      if (res && res?.data) {
        setDataList(res?.data?.records);
      } else {
        setDataList([]);
      }
    } catch (error) {
      setDataList([]);
    }
  };

  const fetchResultList = async (date) => {
    const payload = {
      date: moment(new Date(date)).format("yyyy-MM-DD"),
    };
    try {
      const res = isFast
        ? await fastMatkaResult(payload)
        : await matkaResult(payload);
      if (res && res?.data?.data) {
        setResultList(res?.data?.data);
      } else {
        setResultList([]);
      }
    } catch (error) {
      setResultList([]);
    }
  };
  const handleInput = (e) => {
    const {
      target: { value, name, type },
    } = e;
    if (type === "number") {
      if (value.length <= 3) {
        setState((s) => ({
          formValue: { ...s.formValue, [name]: value },
        }));
        if (true) {
          validator.current.showMessageFor("number");
        }
      }
    } else {
      setState((s) => ({ formValue: { ...s.formValue, [name]: value } }));
    }
  };

  const handleCategoryType = async () => {
    if (category !== "") {
      setCategoryLoading(true);
    }
    const payload = {
      market_date: moment(new Date(date)).format("yyyy-MM-DD"),
      category_id: category,
    };
    const resp = isFast
      ? await fastMatkaActiveGameRequest(payload)
      : await matkaActiveGameRequest(payload);
    if (resp?.data?.data) {
      setRespData(resp?.data?.data);
      setCategoryLoading(false);
    }
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    if (validator.current.allValid()) {
      if (isAscending(number) === true) {
        setIsLoading(true);
        const payload = {
          event_id: type,
          settlement_result: number,
        };
        try {
          const resp = isFast
            ? await createFastMatkaNumber(payload)
            : await createMatkaNumber(payload);
          if (resp?.data?.data) {
            toast.success(resp?.data?.message);
            setTimeout(() => {
              window.location.reload();
            }, [1000]);
          }
        } catch (error) {
          setIsLoading(false);
          toast.error(error?.response?.data?.error);
        }
      } else {
        setIsLoading(false);
        setNumberError("Invalid result");
        setForceUpdate((prevState) => !prevState);
      }
    } else {
      setIsLoading(false);
      validator.current.showMessages();
      if (validator.current.fieldValid("number")) {
        setNumberError(isAscending(number) === true ? "" : "Invalid number");
      }
      setForceUpdate((prevState) => !prevState);
    }
  };

  return (
    <>
      <InnerPageHeader
        pageTitle={breadcrumbTitle}
        breadcrumbs={[{ title: "Dashboard", url: "/" }]}
        currenPageTitle={breadcrumbTitle}
      />
      <div className="wrapper wrapper-content animated fadeInRight">
        <div className="row">
          <div className="col-lg-12">
            <div className="ibox float-e-margins">
              <div className="ibox-title">
                <h5>{breadcrumbTitle}</h5>
                <form
                  style={{ margin: "20px 50px" }}
                  onSubmit={onSubmit}
                  className="form-horizontal"
                >
                  <div className="hr-line-dashed"></div>
                  <div className="form-group">
                    <label className="col-sm-3 control-label">Date</label>
                    <div className="col-sm-9">
                      <DatePicker
                        id="datetime"
                        maxDate={new Date()}
                        dateFormat="yyyy-MM-dd"
                        className="form-control"
                        name="date"
                        selected={date}
                        onChange={(e) =>
                          setState((s) => ({
                            formValue: { ...s.formValue, date: e },
                          }))
                        }
                      />
                      {validator.current.message("date", date, "required")}
                    </div>
                  </div>
                  <div className="hr-line-dashed"></div>
                  <div className="form-group">
                    <label className="col-sm-3 control-label">Category</label>
                    <div className="col-sm-9">
                      <select
                        className="form-control"
                        name="category"
                        id="event_type"
                        value={category}
                        onChange={(e) => handleInput(e)}
                      >
                        <option key={"select"} value={""}>
                          Select Category
                        </option>
                        {dataList?.map((item, index) => (
                          <option key={index} value={item.category_id}>
                            {item.title}
                          </option>
                        ))}
                      </select>
                      <em>
                        {validator.current.message(
                          "category",
                          category,
                          "required"
                        )}
                      </em>
                    </div>
                  </div>
                  <div className="hr-line-dashed"></div>
                  <div className="form-group ">
                    {categoryLoading ? (
                      <div className="pt_loader">
                        <Loader />
                      </div>
                    ) : (
                      <></>
                    )}
                    <label className="col-sm-3 control-label">Type</label>
                    <div className="col-sm-9">
                      <select
                        className="form-control"
                        name="type"
                        id="type"
                        value={type}
                        onChange={(e) => handleInput(e)}
                      >
                        <option value="">Selet Type</option>
                        {respData?.map((item, index) => (
                          <option key={index} value={item.event_id}>
                            {item.event_name}
                          </option>
                        ))}
                      </select>
                      <em>
                        {validator.current.message("type", type, "required")}
                      </em>
                    </div>
                  </div>
                  <div className="hr-line-dashed"></div>
                  <div className="form-group">
                    <label className="col-sm-3 control-label">Number</label>
                    <div className="col-sm-9">
                      <input
                        type="number"
                        className="form-control"
                        name="number"
                        id="last_name"
                        value={number}
                        onChange={(e) => handleInput(e)}
                      />
                      <em>
                        {numberError ? (
                          <div className="srv-validation-message">
                            {numberError}
                          </div>
                        ) : (
                          validator.current.message(
                            "number",
                            number,
                            "required|numeric|min:3"
                          )
                        )}
                      </em>
                      {}
                    </div>
                  </div>
                  <div className="form-group">
                    <div className="matka_form_submit">
                      <button
                        className="btn btn-white mr-3"
                        type="button"
                        style={{ marginRight: "1rem" }}
                        onClick={() => props.history.goBack()}
                      >
                        Cancel
                      </button>
                      <button
                        className="btn btn-primary"
                        type="submit"
                        disabled={
                          JSON.stringify(formValue) ===
                            JSON.stringify(initialFormValue) || isLoading
                        }
                        // onClick={() => handleCategoryType()}
                      >
                        Save Changes
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div className="row ibox-content">
          <div className="filter_box">
            <div className="d-flex align-center">
              <label className="col-sm-3 control-label">Date</label>
              <div className="col-sm-9">
                <DatePicker
                  id="datetime"
                  maxDate={new Date()}
                  dateFormat="yyyy-MM-dd"
                  className="form-control"
                  name="date"
                  selected={filterDate}
                  onChange={(e) => setFilterDate(e)}
                />
                {validator.current.message("date", date, "required")}
              </div>
            </div>
            <div>
              <button
                className="filter_btn btn-primary mr-3"
                onClick={() => fetchResultList(filterDate)}
              >
                Search
              </button>
              <button
                className="filter_btn btn-white ml-3"
                onClick={() => {
                  setFilterDate(new Date());
                  fetchResultList(new Date());
                }}
              >
                Reset
              </button>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-12">
              <div className="table-responsive">
                <table className="table table-striped table-bordered table-hover dataTables-example">
                  <thead>
                    <tr>
                      <th>S.No</th>
                      <th>Date</th>
                      <th>Category</th>
                      <th>Type</th>
                      <th>Number</th>
                    </tr>
                  </thead>
                  <tbody>
                    {resultList && resultList.length ? (
                      resultList.map((matches, index) => (
                        <tr key={index}>
                          <td>
                            {
                              index + 1
                              // + ((pageChange - 1) * perPage)
                            }
                          </td>

                          <td>
                            {matches?.market_date
                              ? formatDateAndTime(
                                  "YYY-MM-DD",
                                  matches?.market_date
                                )
                              : "-"}
                          </td>

                          <td>
                            {matches?.category_title
                              ? matches?.category_title
                              : "-"}
                          </td>
                          <td>
                            {matches?.matka_market_type
                              ? firstLetterCapital(matches?.matka_market_type)
                              : "-"}
                          </td>
                          <td>
                            {matches.won_by >= 0 ? matches.won_by : "-"}{" "}
                            {matches?.won_akhar >= 0
                              ? ` - ${matches?.won_akhar}`
                              : ""}
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td align="center" colSpan={5}>
                          No Data Found.
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="paginate_class">
              <ReactPaginate
                previousLabel={"prev"}
                nextLabel={"next"}
                breakLabel={"..."}
                breakClassName={"break-me"}
                // pageCount={pageCount}
                // marginPagesDisplayed={2}
                // pageRangeDisplayed={5}
                // onPageChange={handlePageClick}
                containerClassName={"pagination"}
                subContainerClassName={"pages pagination"}
                activeClassName={"active"}
              />
            </div>

            {/* <div className="col-lg-12 d-flex justify-end gap-1">
                    <TagAndChips className="btn btn-default btn">
                      {"<"}
                    </TagAndChips>
                    <TagAndChips className="btn btn-primary2">
                      {"1"}
                    </TagAndChips>
                    <TagAndChips className="btn btn-default">{">"}</TagAndChips>
                  </div> */}
          </div>
        </div>
      </div>
    </>
  );
};
export default NumberForm;
