import InnerPageHeader from "components/common/InnerPageHeader";
import React, { Component, Fragment } from "react";
export class Search extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <Fragment>
        <InnerPageHeader
          pageTitle="SEARCH"
          breadcrumbs={[{ title: "Dashboard", url: "/" }]}
          currenPageTitle="Search"
        />
        <div className="wrapper wrapper-content animated fadeInRight">
          <div className="row">
            <div className="col-12">
              <div className="ibox">
                <div className="ibox-title">
                  <h5>{"Search"}</h5>
                </div>
                <div className="ibox-content">
                  <div className="row">
                    <div className="col-lg-12">Search</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default Search;
