import React, { useState, useEffect, Fragment } from "react";
import "./Dashboard.css";
import { userProfileRequest } from "_actions/userprofile/userprofile.actions";
import { useDispatch } from "react-redux";
import { getmatchesList, getSportList, logoutAgents, logoutClients } from "_services";
import { FaStar } from 'react-icons/fa';
import moment from "moment";
import { Link } from "react-router-dom";
import { formatDateAndTime, gameDate } from "utils";
import { Images } from "_constants";
import { FaClock } from "react-icons/fa6";
import { useScreenDetector } from "hooks/useScreenDetector";
import { toast } from "react-toastify";

const Dashboard = () => {
  const dispatch = useDispatch();
  const { isMobile } = useScreenDetector()


  let role = localStorage.getItem("role") || null;
  const [state, setState] = useState({
    userdata: [],
    role: 0,
    roleName: [
      "Company",
      "Company",
      "Sub company",
      "Sub admin",
      "Admin",
      "Super senior",
      "Super",
      "Dealer",
      "Agent",
      "Client",
      "Staff"
    ],
  });
  // Roles
  // Role 1 - Company
  // Role 2 - Sub Company
  // Role 3 - Sub admin
  // Role 4 - Admin
  // Role 5 - super senior
  // Role 6 - Super
  // Role 7 - dealer
  // Role 8 - Agent
  // Role 9 - client
  // Role 8 - Staff

  const [sports, setSports] = useState([]);
  const [selectedSport, setSelectedSport] = useState(4);
  const [matchListData, setMatchListData] = useState([]);
  const [pageChange, setPageChange] = useState(1);
  const [perPage] = useState(100);
  const [pageCount, setPageCount] = useState(0);

  const [filter, setFilter] = useState(1);
  const [loading, setLoading] = useState(false);

  const iconMap = {
    1: Images.SOCCER.default,
    2: Images.TENNIS.default,
    4: Images.CRICKET.default,
    999: Images.MATKA.default,
    777: Images.MAIN_STAR.default,
    888: Images.KALYAN_STAR.default,
    6: Images.CASINO.default,
    333: Images.POLITICS.default,
    444: Images.TROPHY.default
  };


  useEffect(() => {
    setState((s) => ({ ...s, role }));

    let sendObj = {
      role: role,
    };
    dispatch(
      userProfileRequest(sendObj, (res) => {
        if (res && res.data) {
          setState((s) => ({ ...s, userdata: res.data }));
        }
      })
    );
  }, [role]);

  useEffect(() => {
    fetchSportsList();
  }, []);

  useEffect(() => {
    fetchMatchList()
  }, [pageChange, selectedSport]);

  const fetchMatchList = (sport) => {
    if (sport) {
      setSelectedSport(sport)
      setPageChange(1)
      setPageCount(0)
      fetchMarketList(sport);
    } else {
      if (selectedSport && selectedSport) {
        fetchMarketList(selectedSport);
      }
    }
  }

  const fetchSportsList = () => {
    getSportList()
      .then((response) => {
        if (response && response.data && response.data.data && response.data.data.length) {
          setSports(response.data.data);
        } else {
          setSports([]);
        }
      })
      .catch((error) => {
        setSports([]);
      });

  };
  const LogoutAgents = () => {
    setLoading(true)
    logoutAgents()
      .then((response) => {
        if (response) {
          setLoading(false)
          toast.success(response.data.message || 'Logout succesfull')
        }
      })
      .catch((error) => {
        setLoading(false)
        toast.error('Something went wrong.')
      });

  };

  const LogoutClients = () => {
    setLoading(true)
    logoutClients()
      .then((response) => {
        if (response) {
          setLoading(false)
          toast.success(response.data.message || 'Logout succesfull')
        }
      })
      .catch((error) => {
        setLoading(false)
        toast.error('Something went wrong.')
      });

  };

  const handleSelectSport = (sport_id) => {
    setSelectedSport(sport_id);
  };

  const fetchMarketList = (id = false) => {
    if (id || id == "") {
      let sendData = {};
      sendData.sport_id = id;
      sendData.current_page = pageChange;
      sendData.items_perpage = perPage;
      sendData.in_play = 1;
      sendData.to_date = moment(new Date()).format("yyyy-MM-DD HH:mm:ss");
      sendData.from_date = "2022-06-01 18:30:00";
      getmatchesList(sendData)
        .then((response) => {
          if (response && response.data && response.data.data && response.data.data.records && response.data.data.records.length
          ) {
            let resData = JSON.parse(
              JSON.stringify(response.data.data.records)
            );

            setMatchListData(response.data.data.records);

            setPageCount(
              Math.ceil(response?.data?.data?.total_records / perPage)
            );
          } else {
            setMatchListData([]);
          }
        })
        .catch((error) => {
          setMatchListData([]);
        });
    }
  };

  const marketAbbreviations = {
    match_odd: 'MO',
    bookmaker: 'BM',
    other_market: 'OM',
    fancy: 'F',
    other_fancy: 'OF',
  };

  return (
    <div className="wrapper  white-bg  wrapper-content animated fadeInRight">
      {role == 1 && <div className="ibox-tools " style={{ margin: '5px', }}>
        <button className="btn btn-primary btn-xs" disabled={loading} onClick={() => { LogoutClients() }} style={{ cursor: "pointer", marginRight: '5px', }} >Logout all clients</button>

        <button className="btn btn-primary btn-xs" disabled={loading} onClick={() => { LogoutAgents() }} style={{ cursor: "pointer" }} >Logout all panels</button>
      </div>}

      <div className="ibox">
        <div className="inplay-upcoming">
          <div className={`itm ${filter == 1 ? "active-itm" : ''}`} onClick={() => setFilter(1)}><FaClock size={20} /><span>INPLAY</span></div>
        </div>

        <div className="sport-tabs">
          {sports.map((sport) => (
            <div
              className={`sport-tab ${selectedSport === sport.sport_id ? 'selected' : ''}`}
              key={sport.sport_id}
              onClick={() => handleSelectSport(sport.sport_id)}
            >
              <div className="sport-icon">
                {<img src={iconMap[sport.sport_id]} /> || <FaStar />}
              </div>
              <p>{sport.sports_name}</p>
            </div>
          ))}
        </div>

        <br />
        <div className="table-wrapper">
          <table className="custom-table custom-table-bordered">
            {!isMobile &&
              <thead className="custom-table-head">
                <tr>
                  <th className="f_td"></th>
                  <th className="s_td"></th>
                  <th className="t_td">
                    <div className="_1x2">
                      <span>1</span>
                      <span>x</span>
                      <span>2</span>
                    </div>
                  </th>
                </tr>
              </thead>}
            <tbody>
              {matchListData && matchListData.length > 0 ? (
                matchListData.map((item, index) => (
                  <Fragment>
                    {!isMobile ?
                      (<tr key={index}>
                        <td className="f_td custom-anchor text-right text-nowrap" >
                          <div className="d-flex flex-row align-items-end justify-content-end">

                            {(item.in_play) ?
                              <div className="d-flex align-items-center font-weight-bold text-danger">
                                <svg style={{ marginRight: '4px' }} className="pulse-animation" xmlns="http://www.w3.org/2000/svg" width="4" height="4" viewBox="0 0 4 4" fill="none">
                                  <circle cx="2" cy="2" r="2" fill="#F60000" />
                                </svg>
                                LIVE
                              </div>
                              : gameDate(item.open_date) === "Live" ?
                                <div className="d-flex align-items-center font-weight-bold text-danger">
                                  <svg style={{ marginRight: '4px' }} className="pulse-animation" xmlns="http://www.w3.org/2000/svg" width="4" height="4" viewBox="0 0 4 4" fill="none">
                                    <circle cx="2" cy="2" r="2" fill="#F60000" />
                                  </svg>
                                  LIVE
                                </div>
                                : <Fragment>
                                  <div className='ttomorrow_bx'>{gameDate(item.open_date)}</div>
                                  <div className='custom-tomorrow-bx text-nowrap'>
                                    {formatDateAndTime("hh:mm A", item.open_date)}
                                  </div>
                                </Fragment>}
                          </div>
                        </td>
                        <td className="s_td text-nowrap" >
                          <Link to={{ pathname: `/live_report/${item.event_id}`, }}
                            state={{ matchData: item }}
                            style={{ color: '#262626' }}
                          >
                            <p className="m-0"><b>{item.event_name}</b></p>
                            <small>{item.league_name}</small>
                          </Link>
                        </td>
                        <td className="t_td text-center text-nowrap" >
                          <div className="runner_odds">
                            <div className="back"><span>{(item?.runner_odds[0] && JSON.parse(item?.runner_odds) && JSON.parse(item.runner_odds)[0]?.ex?.availableToBack?.[0]?.price) ? JSON.parse(item.runner_odds)[0]?.ex?.availableToBack?.[0]?.price : '--'}</span></div>
                            <div className="lay"><span>{(item?.runner_odds[0] && JSON.parse(item?.runner_odds) && JSON.parse(item.runner_odds)[0]?.ex?.availableToLay?.[0]?.price) ? JSON.parse(item.runner_odds)[0]?.ex?.availableToLay?.[0]?.price : '--'}</span></div>

                            <div className={(item?.runner_odds[2] && JSON.parse(item?.runner_odds) && JSON.parse(item.runner_odds)[2]?.ex?.availableToBack?.[2]?.price) ? "back" : "back_lay_nill"}>
                              <span>{(item?.runner_odds[2] && JSON.parse(item?.runner_odds) && JSON.parse(item.runner_odds)[2]?.ex?.availableToBack?.[2]?.price) ? JSON.parse(item.runner_odds)[2]?.ex?.availableToBack?.[2]?.price : '--'}</span>
                            </div>
                            <div className={(item?.runner_odds[2] && JSON.parse(item?.runner_odds) && JSON.parse(item.runner_odds)[2]?.ex?.availableToLay?.[2]?.price) ? "lay" : "back_lay_nill"}>
                              <span>{(item?.runner_odds[2] && JSON.parse(item?.runner_odds) && JSON.parse(item.runner_odds)[2]?.ex?.availableToLay?.[2]?.price) ? JSON.parse(item.runner_odds)[2]?.ex?.availableToLay?.[2]?.price : '--'}</span>
                            </div>

                            <div className="back"><span>{(item?.runner_odds[1] && JSON.parse(item?.runner_odds) && JSON.parse(item.runner_odds)[1]?.ex?.availableToBack?.[1]?.price) ? JSON.parse(item.runner_odds)[1]?.ex?.availableToBack?.[1]?.price : '--'}</span></div>
                            <div className="lay"><span>{(item?.runner_odds[1] && JSON.parse(item?.runner_odds) && JSON.parse(item.runner_odds)[1]?.ex?.availableToLay?.[1]?.price) ? JSON.parse(item.runner_odds)[1]?.ex?.availableToLay?.[1]?.price : '--'}</span></div>
                          </div>

                          {item.market_count && (
                            <div style={{ marginTop: "4px", float: 'right' }}>
                              {item.market_count?.match_odd > 0 && (
                                <span><span className="market_count_name">{marketAbbreviations.match_odd}</span><span className="market_count"> {item.market_count.match_odd}</span></span>
                              )}
                              {item.market_count?.bookmaker > 0 && (
                                <span><span className="market_count_name">{marketAbbreviations.bookmaker}</span> <span className="market_count"> {item.market_count.bookmaker}</span></span>
                              )}
                              {item.market_count?.other_market > 0 && (
                                <span><span className="market_count_name">{marketAbbreviations.other_market}</span> <span className="market_count"> {item.market_count.other_market}</span></span>
                              )}
                              {item.market_count?.fancy > 0 && (
                                <span><span className="market_count_name">{marketAbbreviations.fancy}</span><span className="market_count"> {item.market_count.fancy}</span></span>
                              )}
                              {item.market_count?.other_fancy > 0 && (
                                <span><span className="market_count_name">{marketAbbreviations.other_fancy}</span><span className="market_count"> {item.market_count.other_fancy}</span></span>
                              )}
                            </div>
                          )}
                        </td>
                      </tr>) :
                      (
                        <Fragment>
                          <tr>
                            <td className="f_td_m" >
                              <div className="custom-anchor text-center text-nowrap tm">
                                {(item.in_play) ?
                                  <div className="d-flex align-items-center font-weight-bold text-danger">
                                    <svg style={{ marginRight: '4px' }} className="pulse-animation" xmlns="http://www.w3.org/2000/svg" width="4" height="4" viewBox="0 0 4 4" fill="none">
                                      <circle cx="2" cy="2" r="2" fill="#F60000" />
                                    </svg>
                                    LIVE
                                  </div>
                                  : gameDate(item.open_date) === "Live" ?
                                    <div className="d-flex align-items-center font-weight-bold text-danger">
                                      <svg style={{ marginRight: '4px' }} className="pulse-animation" xmlns="http://www.w3.org/2000/svg" width="4" height="4" viewBox="0 0 4 4" fill="none">
                                        <circle cx="2" cy="2" r="2" fill="#F60000" />
                                      </svg>
                                      LIVE
                                    </div>
                                    : <Fragment>
                                      <div className='ttomorrow_bx' style={{ whiteSpace: 'initial' }}>{gameDate(item.open_date)}</div>
                                      <div className='custom-tomorrow-bx ' >
                                        {formatDateAndTime("hh:mm A", item.open_date)}
                                      </div>
                                    </Fragment>}

                              </div>
                              <div className="event_nm text-nowrap">
                                <Link to={{ pathname: `/live_report/${item.event_id}`, }}
                                  state={{ matchData: item }}
                                  style={{ color: '#262626' }}
                                >
                                  <p className="m-0"><b>{item.event_name} </b></p>
                                  <small>{item.league_name}</small>
                                </Link>

                                {item.market_count && (
                                  <div style={{ marginTop: "4px", float: 'left' }}>
                                    {item.market_count?.match_odd > 0 && (
                                      <span><span className="market_count_name">{marketAbbreviations.match_odd}</span><span className="market_count"> {item.market_count.match_odd}</span></span>
                                    )}
                                    {item.market_count?.bookmaker > 0 && (
                                      <span><span className="market_count_name">{marketAbbreviations.bookmaker}</span> <span className="market_count"> {item.market_count.bookmaker}</span></span>
                                    )}
                                    {item.market_count?.other_market > 0 && (
                                      <span><span className="market_count_name">{marketAbbreviations.other_market}</span> <span className="market_count"> {item.market_count.other_market}</span></span>
                                    )}
                                    {item.market_count?.fancy > 0 && (
                                      <span><span className="market_count_name">{marketAbbreviations.fancy}</span><span className="market_count"> {item.market_count.fancy}</span></span>
                                    )}
                                    {item.market_count?.other_fancy > 0 && (
                                      <span><span className="market_count_name">{marketAbbreviations.other_fancy}</span><span className="market_count"> {item.market_count.other_fancy}</span></span>
                                    )}
                                  </div>
                                )}
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="s_td_m">
                              <div className="runner_odds_m">
                                <div className="back"><span>{(item?.runner_odds[0] && JSON.parse(item?.runner_odds) && JSON.parse(item.runner_odds)[0]?.ex?.availableToBack?.[0]?.price) ? JSON.parse(item.runner_odds)[0]?.ex?.availableToBack?.[0]?.price : '--'}</span></div>
                                <div className="lay"><span>{(item?.runner_odds[0] && JSON.parse(item?.runner_odds) && JSON.parse(item.runner_odds)[0]?.ex?.availableToLay?.[0]?.price) ? JSON.parse(item.runner_odds)[0]?.ex?.availableToLay?.[0]?.price : '--'}</span></div>

                                <div className={(item?.runner_odds[2] && JSON.parse(item?.runner_odds) && JSON.parse(item.runner_odds)[2]?.ex?.availableToBack?.[2]?.price) ? "back" : "back_lay_nill"}>
                                  <span>{(item?.runner_odds[2] && JSON.parse(item?.runner_odds) && JSON.parse(item.runner_odds)[2]?.ex?.availableToBack?.[2]?.price) ? JSON.parse(item.runner_odds)[2]?.ex?.availableToBack?.[2]?.price : '--'}</span>
                                </div>
                                <div className={(item?.runner_odds[2] && JSON.parse(item?.runner_odds) && JSON.parse(item.runner_odds)[2]?.ex?.availableToLay?.[2]?.price) ? "lay" : "back_lay_nill"}>
                                  <span>{(item?.runner_odds[2] && JSON.parse(item?.runner_odds) && JSON.parse(item.runner_odds)[2]?.ex?.availableToLay?.[2]?.price) ? JSON.parse(item.runner_odds)[2]?.ex?.availableToLay?.[2]?.price : '--'}</span>
                                </div>

                                <div className="back"><span>{(item?.runner_odds[1] && JSON.parse(item?.runner_odds) && JSON.parse(item.runner_odds)[1]?.ex?.availableToBack?.[1]?.price) ? JSON.parse(item.runner_odds)[1]?.ex?.availableToBack?.[1]?.price : '--'}</span></div>
                                <div className="lay"><span>{(item?.runner_odds[1] && JSON.parse(item?.runner_odds) && JSON.parse(item.runner_odds)[1]?.ex?.availableToLay?.[1]?.price) ? JSON.parse(item.runner_odds)[1]?.ex?.availableToLay?.[1]?.price : '--'}</span></div>
                              </div>

                            </td>
                          </tr>
                        </Fragment>
                      )
                    }
                  </Fragment>
                ))
              ) : (
                <tr>
                  <td colSpan="3" className="text-center" style={{ border: 'none' }}>No matches available</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};
export default Dashboard;
