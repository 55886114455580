import React, { Component } from "react";

import { connect } from "react-redux";
import StakeDelayComponent from "components/Commentary/StakeDelayComponent.js";

class StakeDelay extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      
        <StakeDelayComponent {...this.props} />
      
    );
  }
}
const mapstatetop = (state) => ({
  ...state,
});

export default connect(mapstatetop)(StakeDelay);
