import React from "react";
import AddExistManualMarket from "../../../components/ManualMarketMatchDashboard/AddExistManualMarket";
import { connect, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

function Add() {
  const dispatch = useDispatch();
  const params = useParams();
  return <AddExistManualMarket dispatch={dispatch} params={params} />;
}
const mapstatetop = (state) => ({
  ...state,
});

export default connect(mapstatetop)(Add);
