

import React, { useEffect, useMemo, useState } from 'react'
import { userCommissionReport } from "_services/userDetails.services";
import moment from "moment";

import ReactPaginate from 'react-paginate';
import InnerPageHeader from 'components/common/InnerPageHeader';
import DateRangePickerComponent from 'components/common/DateRangePickerComponent';
import { resetCommission } from '_services';

const UserCommission = () => {

    const [fromDate, setFromDate] = useState(null);
    const [toDate, setToDate] = useState(null);
    const [changePage, setChangePage] = useState(1);

    const [userData, setUserData] = useState([]);
    const [pageCount, setPageCount] = useState(0)
    const [perPage] = useState(50);
    const [total, setTotal] = useState({
        total_my_m_comm: 0,
        total_my_s_comm: 0,
        total_my_ms_comm: 0,
        total_dl_m_comm: 0,
        total_dl_s_comm: 0,
        total_dl_ms_comm: 0,
    });


    useEffect(() => {
        fetchUserLedger();
    }, [changePage || fromDate])



    const fetchUserLedger = () => {

        const from_date = fromDate ? (moment(moment.utc(fromDate).local().format("YYYY-MM-DD 00:00:00"), "YYYY-MM-DD HH:mm:ss").utc().format("YYYY-MM-DD HH:mm:ss")) : "";
        const to_date = toDate ? (moment(moment.utc(toDate).local().format("YYYY-MM-DD 23:59:00"), "YYYY-MM-DD HH:mm:ss").utc().format("YYYY-MM-DD HH:mm:ss")) : '';
        let sendObj = {};
        sendObj.from_date = from_date;
        sendObj.to_date = to_date;

        userCommissionReport(sendObj)
            .then((response) => {
                if (response && response.data && response.data.data) {
                    setUserData(response?.data?.data);

                    let total_my_m_comm = 0
                    let total_my_s_comm = 0
                    let total_my_ms_comm = 0
                    let total_dl_m_comm = 0
                    let total_dl_s_comm = 0
                    let total_dl_ms_comm = 0

                    if (response.data.data.comm_report.length > 0) {
                        {
                            response.data.data.comm_report?.map((item, index) => (
                                total_my_m_comm = Number(total_my_m_comm + item.my_m_comm),
                                total_my_s_comm = Number(total_my_s_comm + item.my_s_comm),
                                total_my_ms_comm = Number(total_my_ms_comm + item.my_m_comm + item.my_s_comm),
                                total_dl_m_comm = Number(total_dl_m_comm + item.dl_m_comm),
                                total_dl_s_comm = Number(total_dl_s_comm + item.dl_s_comm),
                                total_dl_ms_comm = Number(total_dl_ms_comm + item.dl_m_comm + item.dl_s_comm)
                            )
                            )
                        }
                    }

                    setTotal({
                        total_my_m_comm,
                        total_my_s_comm,
                        total_my_ms_comm,
                        total_dl_m_comm,
                        total_dl_s_comm,
                        total_dl_ms_comm,
                    })


                    // setPageCount(Math.ceil(response?.data.data.total_records / perPage))
                }
            })
            .catch((error) => {
                console.log(error, "error")
            });

    };

    const handleEvent = (from_date, to_date) => {
        if (from_date) {
            let fromDate = from_date._d;
            let toDate = to_date._d;
            setToDate(toDate);
            setFromDate(fromDate);
        }
    }


    const handlePageClick = (e) => {
        const selectedPage = e.selected;
        setChangePage(selectedPage + 1)
    };

    const resetData = (item) => {
        const userConfirmed = window.confirm("Are you sure you want to reset the data?");

        if (userConfirmed) {
            let sendObj = {
                user_id: item._id
            };

            resetCommission(sendObj)
                .then((response) => {
                    if (response && response.data && response.data.data) {
                        fetchUserLedger();
                    }
                })
                .catch((error) => {
                    console.log(error, "error");
                });
        }
    }

    return (
        <>
            <InnerPageHeader
                pageTitle={"User Commission Lenden"}
                breadcrumbs={[
                    { title: "Dashboard", url: "/" },
                    { title: "Commission Lenden", url: `/user-commission` },
                ]}
                currenPageTitle="List"
            />

            <div className="row">
                <DateRangePickerComponent handleEvent={(from_date, to_date) => handleEvent(from_date, to_date)} fDate={fromDate} tDate={toDate} />
                <div
                    className="col-lg-3 col-md-4 col-sm-6 col-xs-12"
                    style={{ margin: "11px 0px" }}
                >
                    <a className="btn btn-primary btn-xs search_date_btn">Search</a>
                </div>
            </div>

            <div className="wrapper wrapper-content animated fadeInRight">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="ibox float-e-margins">
                            <div className="ibox-title">
                                <h5>User Commission Lenden</h5>

                            </div>
                            <div className="ibox-content">
                                <div className="table-responsive">
                                    <table className="table table-bordered  dataTables-example">
                                        <thead>
                                            <tr>
                                                <th>Name</th>
                                                <th colSpan="3" className="text-center">
                                                    MILA HAI
                                                </th>
                                                <th colSpan="3" className="text-center">
                                                    DE DIYA HAI
                                                </th>
                                                <th>ACTION</th>
                                            </tr>
                                            <tr className="custom-table-header ">
                                                <th>Username</th>
                                                <th>M.Comm</th>
                                                <th>S.Comm</th>
                                                <th>T.Comm</th>

                                                <th>M.Comm</th>
                                                <th>S.Comm</th>
                                                <th>T.Comm</th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                userData?.comm_report?.map((item, index) => {
                                                    return (
                                                        <tr key={index}>
                                                            <td id="getdate_2794093"> {item.username} </td>
                                                            <td style={{ color: Number(item.my_m_comm) > 0 ? "green" : 'green' }}>{item.my_m_comm}</td>
                                                            <td style={{ color: Number(item.my_s_comm) > 0 ? "green" : 'green' }}> {item.my_s_comm} </td>
                                                            <td style={{ color: Number(item.my_m_comm + item.my_s_comm) > 0 ? "green" : 'green' }}> {Number(item.my_m_comm + item.my_s_comm)} </td>
                                                            <td style={{ color: Number(item.dl_m_comm) > 0 ? "red" : 'red' }}>{item.dl_m_comm}</td>
                                                            <td style={{ color: Number(item.dl_s_comm) > 0 ? "red" : 'red' }}>{item.dl_s_comm}</td>
                                                            <td style={{ color: Number(item.dl_m_comm + item.dl_s_comm) > 0 ? "red" : 'red' }}>{Number(item.dl_m_comm + item.dl_s_comm)}</td>
                                                            <td><button className='btn btn-primary btn-sm' onClick={() => { resetData(item) }}>Reset</button></td>
                                                        </tr>
                                                    )
                                                })
                                            }

                                        </tbody>
                                        <tfoot>
                                            <tr>
                                                <th>Total</th>
                                                <th style={{ color: Number(total.total_my_m_comm) > 0 ? "green" : 'green' }}>{total.total_my_m_comm}</th>
                                                <th style={{ color: Number(total.total_my_s_comm) > 0 ? "green" : 'green' }}>{total.total_my_s_comm}</th>
                                                <th style={{ color: Number(total.total_my_ms_comm) > 0 ? "green" : 'green' }}>{total.total_my_ms_comm}</th>

                                                <th style={{ color: Number(total.total_dl_m_comm) > 0 ? "red" : 'red' }}>{total.total_dl_m_comm}</th>
                                                <th style={{ color: Number(total.total_dl_s_comm) > 0 ? "red" : 'red' }}>{total.total_dl_s_comm}</th>
                                                <th style={{ color: Number(total.total_dl_ms_comm) > 0 ? "red" : 'red' }}>{total.total_dl_ms_comm}</th>
                                            </tr>
                                        </tfoot>
                                    </table>
                                </div>
                                <div className="paginate_class">
                                    <ReactPaginate
                                        previousLabel={"prev"}
                                        nextLabel={"next"}
                                        breakLabel={"..."}
                                        breakClassName={"break-me"}
                                        pageCount={pageCount}
                                        marginPagesDisplayed={2}
                                        pageRangeDisplayed={5}
                                        onPageChange={handlePageClick}
                                        containerClassName={"pagination"}
                                        subContainerClassName={"pages pagination"}
                                        activeClassName={"active"} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default UserCommission