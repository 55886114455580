import React, { useState, useEffect, useMemo } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import {
  getMatchLiveReport,
  getSubCompanayLiveReport,
  getDeclaredMarketSCLive,
  getSCSessionPosition,
  getDeclaredFancy,
  getLiveMatches,
} from "_services";
import { useSelector } from "react-redux";
import SocketHelper from "components/layouts/SocketHelper";
import LiveMatchesList from "components/LiveMatchesList/LiveMatchesList";
import InnerPageHeader from "components/common/InnerPageHeader";
import { convertParseFloat } from "_helpers";
import {
  awaitApiCallFuncation,
  detectMobView,
  differentFancyList,
  getFilterTypeMarketList,
} from "components/layouts/Helpers";
import { Accordion, Card } from "react-bootstrap";
import MarketView from "./MarketView";
import SessionView from "./SessionView";
import { ViewScoreWidget } from "components/LiveReport/ViewScoreWidget";

const marketInterval = Math.floor(Math.random() * (60 - 50)) + 50;
const fancyInterval = Math.floor(Math.random() * (50 - 40)) + 40;

let liveDataFancy = "";
let liveDataMarket = "";

function SubCompanyLiveReport() {
  const { event_id } = useParams();
  const location = useLocation();
  const eventName = location?.state?.matchData?.event_name;
  const [subCompanyliveData, setSubCompanyLiveData] = useState({});
  const [subCompanyAllData, setSubCompanyAllData] = useState([]);
  const [subCompData, setSubCompData] = useState([]);
  const sdata = useSelector((state) => state.socketDataReducer);
  const socketData = sdata.socketData;
  const [fancyData, setFancyData] = useState(null);
  const [liveMatches, setLiveMatches] = useState([]);
  const [declaredFancyData, setDeclaredFancyData] = useState([]);
  const [userRole, updateRole] = useState(null);
  const [declaredMarketData, updateDeclaredMarketData] = useState([]);
  const [closeOpenRoom, updateCloseOpenRoom] = useState("");
  const navigate = useNavigate();
  const [showDeclared, setDeclaredColumn] = useState("");
  const [isLoading, setLoading] = useState(false);
  const [APIcall, setAPICall] = useState({ market: true, fancy: true });

  useEffect(() => {
    if (event_id) {
      fetchSubCompanyLiveReport(event_id);
      fetchLiveMatches(event_id);
      fetchDeclaredMarket(event_id);
      fetchDeclaredFancy(event_id);
    }
    let role = localStorage.getItem("role");
    if (role && role != null) {
      updateRole(role);
    }
    // window.scrollTo({ top: 0, behavior: "smooth" });
    updateCloseOpenRoom(!closeOpenRoom);
    return () => {
      liveDataFancy = [];
      liveDataMarket = [];
      setSubCompanyLiveData({});
      console.clear();
    };
  }, [event_id]);

  useEffect(() => {
    if (subCompanyliveData) {
      if (subCompanyliveData?.market?.length) {
        APIcall?.market && getSubComapanyMarketData(subCompanyliveData.market);
        setAPICall((s) => ({ ...s, market: false }));
      }
      if (subCompanyliveData?.fancy?.length) {
        APIcall?.fancy && getFancyPositionData(subCompanyliveData.fancy);
        setAPICall((s) => ({ ...s, fancy: false }));
      }
    }
  }, [subCompanyliveData]);

  useEffect(() => {
    let data = subCompanyliveData;
    if (socketData && Object.keys(data).length) {
      switch (true) {
        case socketData?.updateMarketOdds?.odds.length > 0 &&
          event_id == socketData?.updateMarketOdds?.event_id:
          const updatedData = socketData.updateMarketOdds;
          let marketData = data?.market ? data?.market : [];
          data.market.map((market, markey) => {
            if (updatedData.market_id == market.market_id) {
              market.runners.map((run, key) => {
                updatedData.odds.find((odd) => {
                  if (run.selectionId === odd.selectionId) {
                    run["runner_odd"] = odd;
                    marketData[markey].runners[key] = run;
                  }
                });
              });
            }
          });
          data.market = marketData;
          setSubCompanyLiveData(data);
          break;
        case Boolean(socketData?.updateFancyOdds):
          if (event_id == socketData?.updateFancyOdds?.event_id) {
            let fancyData = data.fancy;
            data.fancy.map((fancyItem, fancyKey) => {
              if (
                "" + fancyItem.session_id ===
                "" + socketData.updateFancyOdds.market_id
              ) {
                fancyItem.lay_price1 = socketData.updateFancyOdds.lay_price1;
                fancyItem.lay_size1 = socketData.updateFancyOdds.lay_size1;
                fancyItem.back_price1 = socketData.updateFancyOdds.back_price1;
                fancyItem.back_size1 = socketData.updateFancyOdds.back_size1;

                fancyData[fancyKey] = fancyItem;
              }
            });
            data.fancy = fancyData;
            setSubCompanyLiveData(data);
          }
          break;
        case Boolean(socketData?.addUpdateFancy):
          if (event_id == socketData.addUpdateFancy.event_id) {
            fetchSubCompanyLiveReport(event_id);
          }
          break;
        case Boolean(socketData?.updateScore?.event_id):
          if (
            subCompanyliveData?.event?.event_id ===
            socketData.updateScore.event_id
          ) {
            let matchData = subCompanyliveData;
            const lData = socketData.updateScore.score
              ? JSON.parse(socketData.updateScore.score)
              : "";
            const parseData = parseLiveScore(lData, matchData);
            matchData.live_msg = parseData.liveScoreMsg;
            matchData.live_score1 = parseData.live_score1;
            matchData.live_score2 = parseData.live_score2;
            matchData.updateScore = lData?.data;
            setSubCompanyLiveData(matchData);
          }
          break;
        case Boolean(socketData?.bet_placed?.event_id):
          if (
            subCompanyliveData.event.event_id === socketData.bet_placed.event_id
          ) {
            if (socketData.bet_placed.bookmaker_type == 2) {
              // getMarketPositionData(socketData.bet_placed.market_id);
            }
            if (socketData.bet_placed.bookmaker_type == 3) {
              // getFancyPositionData(socketData.bet_placed.market_id);
            }
          }
          break;
        case socketData?.updateCommentary &&
          socketData.updateCommentary.event_id == event_id:
          subCompanyliveData.event.button_label =
            socketData.updateCommentary.button_label;
          subCompanyliveData.event.commentary_button_id =
            socketData.updateCommentary.commentary_button_id;
          subCompanyliveData.event.btn_color =
            socketData.updateCommentary.btn_color;
          setSubCompanyLiveData(subCompanyliveData);
          break;
        case Boolean(socketData?.reloadPage):
          updateCloseOpenRoom(!closeOpenRoom);
          break;
        case Boolean(socketData?.settlementDone):
          if (socketData.settlementDone.event_id == event_id)
            fetchSubCompanyLiveReport(socketData.settlementDone.event_id);
          break;
      }
    }
  }, [socketData]);

  useEffect(() => {
    if (event_id) {
      if (showDeclared === "session") {
        setLoading(true);
        fetchDeclaredFancy(event_id);
      }
      if (showDeclared === "market") {
        setLoading(true);
        fetchDeclaredMarket(event_id);
      }
    }
  }, [event_id, showDeclared]);

  const fetchLiveMatches = (event_id) => {
    let sendData = {};
    sendData.event_id = event_id;
    sendData.current_page = 1;
    sendData.items_perpage = 100;
    getLiveMatches(sendData)
      .then((response) => {
        if (
          response &&
          response.data &&
          response.data.data &&
          response.data.data.records &&
          response.data.data.records.length
        ) {
          let filterdData = response.data.data.records.filter(
            (el) => el.event_id != event_id
          );
          setLiveMatches(filterdData);

          // response.data.data.filter((el) => {
          //   if (el.event_id == event_id) {
          //     setEventName(el.event_name)
          //   }
          // })
        } else {
          setLiveMatches([]);
        }
      })
      .catch(() => {
        setLiveMatches([]);
      });
  };

  const getSubComapanyMarketData = async (liveDataMarket1) => {
    liveDataMarket = liveDataMarket1;
    try {
      let arrObj = [];
      awaitApiCallFuncation(
        liveDataMarket,
        (market, ind) => {
          let sendData = {};
          sendData.event_id = market.event_id;
          sendData.market_id = market.market_id;
          getSubCompanayLiveReport(sendData) // view_comp_position
            .then((response) => {
              if (response && response.data && response.data.data) {
                response.data.data.map((subComp) => {
                  setSubCompData(subComp.sub_comp);
                });

                let runnersData = JSON.parse(
                  JSON.stringify(response.data.data)
                );

                runnersData.forEach(
                  (val) => (val.market_id = market.market_id)
                );

                arrObj = [...arrObj, ...runnersData];
                setSubCompanyAllData(arrObj);
              } else {
                arrObj = [];
              }
              if (liveDataMarket?.length === ind + 1) {
                setTimeout(() => {
                  getSubComapanyMarketData(liveDataMarket);
                }, [1000 * marketInterval]);
              }
            })
            .catch(() => {});
        },
        !liveDataMarket
      );
    } catch (error) {}
  };

  function fetchMarketData(market) {
    let sendData = {};
    sendData.event_id = market.event_id;
    sendData.market_id = market.market_id;
    return getSubCompanayLiveReport(sendData)
      .then((response) => {
        if (response && response.data && response.data.data) {
          return response.data.data;
        } else {
          return null;
        }
      })
      .catch(() => {});
  }

  const getMarketPositionData = async (marketId) => {
    if (marketId) {
      let marketData = null;
      let marketIndex = -1;
      for (let i = 0; i < subCompanyliveData.market.length; i++) {
        if (subCompanyliveData.market[i].market_id == marketId) {
          marketData = subCompanyliveData.market[i];
          marketIndex = i;
        }
      }

      if (marketData) {
        const result = await fetchMarketData(marketData);
        for (let i = 0; i < result.length; i++) {
          setSubCompData(result[i].sub_comp);
        }
        let runnersData = JSON.parse(JSON.stringify(result));

        for (let i = 0; i < subCompanyAllData.length; i++) {
          for (let j = 0; j < runnersData.length; j++) {
            if (
              subCompanyAllData[i].market_id == marketId &&
              subCompanyAllData[i].selectionId == runnersData[j].selectionId
            ) {
              subCompanyAllData[i].comp = runnersData[j].comp;
              subCompanyAllData[i].sub_comp = runnersData[j].sub_comp;
            }
          }
        }
        //  setRenderView(false);
        setSubCompanyAllData(subCompanyAllData);
        setSubCompData(runnersData[0].sub_comp);
        //    setRenderView(true);
      }
    }
  };

  const fetchSubMarket = (market_id, selectionId) => {
    let valData;
    if (subCompanyAllData.length > 0) {
      subCompanyAllData.forEach((val, index) => {
        if (val.selectionId == selectionId && val.market_id == market_id) {
          valData = val.comp;
        }
      });
      return valData;
    }
  };

  const fetchSubCompanyLiveReport = (event_id) => {
    let sendData = {};
    sendData.event_id = event_id;
    getMatchLiveReport(sendData) // match_detail
      .then((response) => {
        if (response && response.data && response.data.data) {
          let matchData = response.data.data;

          const liveData = matchData.event.score
            ? JSON.parse(matchData.event.score)
            : "";
          const parseData = parseLiveScore(liveData, matchData);
          matchData.live_msg = parseData.liveScoreMsg;
          matchData.live_score1 = parseData.live_score1;
          matchData.live_score2 = parseData.live_score2;
          matchData.market.map((market, markey) => {
            market.runners.map((run, key) => {
              market.runner_odds.find((odd) => {
                if (run.selectionId === odd.selectionId) {
                  run["runner_odd"] = odd;
                  matchData.market[markey].runners[key] = run;
                }
              });
            });
          });
          if (subCompanyliveData?.fancy?.length > 0) {
            matchData.fancy = matchData.fancy.map((d) => {
              let existData = subCompanyliveData?.fancy.find(
                (s) => d.session_id === s.session_id
              );
              return existData ? { ...existData, ...d } : d;
            });
          }

          setSubCompanyLiveData(matchData);
        } else {
          setSubCompanyLiveData("");
        }
      })
      .catch((error) => {});
  };

  const getFancySCData = (fancyItem) => {
    let sendData = {};
    sendData.event_id = fancyItem.event_id;
    sendData.session_id = fancyItem.session_id;
    return getSCSessionPosition(sendData).then((response) => {
      if (response && response.data && response.data.data) {
        return response.data.data;
      } else {
        return null;
      }
    });
  };

  liveDataFancy = subCompanyliveData?.fancy;
  const getFancyPositionData = async () => {
    // if (sessionId) {
    //   let sessionData = null;
    //   let sessionIndex = -1;
    //   for (let i = 0; i < subCompanyliveData.fancy.length; i++) {
    //     if (subCompanyliveData.fancy[i].session_id == sessionId) {
    //       sessionData = fancy
    //       sessionIndex = ind;
    //     }
    //   }
    //   if (sessionData) {
    //     const result = await getFancySCData(sessionData);
    //     subCompanyliveData.fancy[sessionIndex].scdata = result;
    //     setSubCompanyLiveData(subCompanyliveData);
    //     setFancyData(result);
    //   }
    // } else {
    liveDataFancy = liveDataFancy;

    awaitApiCallFuncation(
      liveDataFancy,
      async (fancyItem, index) => {
        const result = await getFancySCData(fancyItem);
        if (liveDataFancy[index]) {
          liveDataFancy[index].scdata = result;
        }
        // if (index == liveDataFancy.length - 1) {
        setSubCompanyLiveData({ ...subCompanyliveData, fancy: liveDataFancy });
        setFancyData(result);
        // }
        if (liveDataFancy?.length === index + 1) {
          setTimeout(() => {
            getFancyPositionData(liveDataFancy);
          }, [1000 * fancyInterval]);
        }
      },
      !liveDataFancy
    );
    // }
  };

  function parseLiveScore(liveData, data) {
    let finalData = { live_score1: null, live_score2: null, liveScoreMsg: "" };

    if (liveData) {
      let liveScore = liveData.data;
      let liveScore1 = "";
      let liveScore2 = "";
      let liveScoreMsg = "";
      let team1 = "";
      let team2 = "";
      try {
        const runners = data.market[0].runners;
        team1 = runners[0].runnerName;
        team2 = runners[1].runnerName;
      } catch (error) {}
      liveScore1 = team1 + " (" + liveScore.spnnation1 + ") ";
      liveScore2 = team2 + " (" + liveScore.spnnation2 + ") ";

      if (liveScore.score1 && liveScore.score1 != "") {
        liveScore1 += liveScore.score1 + " Ovs";
      } else {
        liveScore1 += " 0-0 (0.0) Ovs";
      }

      if (liveScore.score2 && liveScore.score2 != "") {
        liveScore2 += liveScore.score2 + " Ovs";
      } else {
        liveScore2 += " 0-0 (0.0) Ovs";
      }
      let rrr = "";
      if (liveScore.spnreqrate1 != "" || liveScore.spnreqrate2 != "") {
        rrr = liveScore.spnreqrate1
          ? liveScore.spnreqrate1
          : liveScore.spnreqrate2;
        if (rrr != "") {
          rrr = " RRR: " + rrr;
        }
      }
      liveScoreMsg = liveScore.spnmessage + rrr;
      finalData = {
        live_score1: liveScore.activenation1 == "1" ? liveScore1 : liveScore2,
        live_score2: liveScore.activenation2 == "1" ? liveScore1 : liveScore2,
        liveScoreMsg: liveScoreMsg,
      };
    }
    return finalData;
  }

  const getDeclaredSatatusSum = (data) => {
    try {
      let val = data.reduce((total, value) => {
        return parseFloat(total) + parseFloat(value.status);
      }, 0);

      return Math.round(val * 100) / 100;

      // return val;
    } catch (error) {
      return 0;
    }
  };

  const getWinner = (data) => {
    const settlement_result = data.settlement_result;
    const runnerData = JSON.parse(data.runners);
    const winnerData = runnerData.filter((item) => {
      return item.selectionId == settlement_result;
    });
    const winnerName =
      winnerData && winnerData.length > 0 ? winnerData[0].runnerName : "";
    return winnerName;
  };

  const getDeclaredMyStatusSum = (data, scIndex) => {
    let total = 0;
    for (let i = 0; i < data.length; i++) {
      const subItem = data[i].sc[scIndex].my_status;
      total += subItem;
    }
    return total.toFixed(2);

    // try {
    //   let val = data.reduce((total, value) => {
    //     return parseFloat(total) + parseFloat(value.my_status);
    //   }, 0);

    //   return Math.round((val) * 100) / 100

    //   // return val;

    // } catch (error) {
    //   return 0;
    // }
  };

  const fetchDeclaredFancy = (event_id) => {
    let sendData = {};
    sendData.event_id = event_id;
    getDeclaredFancy(sendData)
      .then((response) => {
        if (response?.data?.data?.length) {
          setLoading(false);
          setDeclaredFancyData(response.data.data);
        } else {
          setLoading(false);
          setDeclaredFancyData([]);
        }
      })
      .catch((error) => {
        setLoading(false);
        setDeclaredFancyData([]);
      });
  };

  const fetchDeclaredMarket = (event_id) => {
    let sendData = {};
    sendData.event_id = event_id;
    getDeclaredMarketSCLive(sendData)
      .then((response) => {
        if (response?.data?.data?.length) {
          updateDeclaredMarketData(response.data.data);
          setLoading(false);
        } else {
          updateDeclaredMarketData([]);
          setLoading(false);
        }
      })
      .catch((error) => {
        updateDeclaredMarketData([]);
        setLoading(false);
      });
  };

  function getValueForLagai(market, run, index, matchDetail) {
    let bhav = "";
    if (
      matchDetail?.event?.sport_id == 1 ||
      (matchDetail?.event?.sport_id == 4 && market.runner_odds.length == 3)
    ) {
      bhav = getDisplayValue(
        run?.runner_odd?.ex?.availableToBack[0]?.price,
        market.market_type,
        true
      );
      return bhav;
    } else {
      if (matchDetail?.event?.sport_id == 1) {
        bhav = getDisplayValue(
          run?.runner_odd?.ex?.availableToBack[0]?.price,
          market.market_type,
          true
        );
        return bhav;
      } else {
        if (market.showLagaiOnly && index == 0) {
          bhav = market.lagaiBhav1;
        } else if (market.showLagaiOnly && index == 1) {
          bhav = market.lagaiBhav2;
        } else if (market.market_type == 5 && index == 0) {
          bhav = market.lagaiBhav1;
        } else if (market.market_type == 5 && index == 1) {
          bhav = market.lagaiBhav2;
        } else if (market.market_type == 6 && index == 0) {
          bhav = market.lagaiBhav1;
        } else if (market.market_type == 6 && index == 1) {
          bhav = market.lagaiBhav2;
        } else if (market.market_type == 7) {
          let val = 0;
          if (market.runner_odds && market.runner_odds.length > index) {
            val = getDisplayValue(
              market.runner_odds[index]?.ex?.availableToBack[0]?.price,
              market.market_type,
              true
            );
          }
          bhav = val;
        } else if (run.isFav) {
          bhav = getDisplayValue(
            run?.runner_odd?.ex?.availableToBack[0]?.price,
            market.market_type,
            true
          );
        }
        if (
          bhav != "" &&
          matchDetail &&
          matchDetail.event &&
          matchDetail.event.btn_color &&
          ((matchDetail.event.btn_color == "Red" &&
            matchDetail.event.commentary_button_id != 5) ||
            matchDetail.event.btn_color == "Yellow")
        ) {
          bhav = 0;
          if (matchDetail.event.btn_color == "Yellow") {
            // reloadView();
          }
        }
        return bhav;
      }
    }
  }

  function getValueForKhai(market, run, matchDetail) {
    let bhav = "";
    if (
      matchDetail?.event?.sport_id == 1 ||
      (matchDetail?.event?.sport_id == 4 && market.runner_odds.length == 3)
    ) {
      if (!market.showLagaiOnly) {
        bhav = getDisplayValue(
          run?.runner_odd?.ex?.availableToLay[0]?.price,
          market.market_type,
          false
        );
      }
      return bhav;
    } else {
      if (run.isFav && !market.showLagaiOnly) {
        bhav = getDisplayValue(
          run?.runner_odd?.ex?.availableToLay[0]?.price,
          market.market_type,
          false
        );
      }
      if (
        bhav != "" &&
        matchDetail &&
        matchDetail.event &&
        matchDetail.event.btn_color &&
        ((matchDetail.event.btn_color == "Red" &&
          matchDetail.event.commentary_button_id != 5) ||
          matchDetail.event.btn_color == "Yellow")
      ) {
        bhav = 0;
      }
      return bhav;
    }
  }

  function getDisplayValue(value, market_type, isLagai) {
    try {
      if (value) {
        if (market_type) {
          if (
            market_type === 1 ||
            market_type === 4 ||
            market_type === 5 ||
            market_type === 6 ||
            market_type === 7
          ) {
            if (market_type == 1 && isLagai && value > 1.2) {
              value = value - 0.01;
            }

            let val = value * 100;
            let calValue = ((parseFloat(val) - 1 * 100) / 100).toFixed(2);

            return calValue;
          } else if (market_type === 2) {
            let val = value * 100;
            return parseFloat(val.toFixed(2)) - 1 * 100;
          } else {
            return value;
          }
        } else {
          return value;
        }
      } else {
        return 0;
      }
    } catch (error) {
      return 0;
    }
  }

  function isFavTeam(market, runners, index) {
    const minBhavLimit = 0.96;
    const maxBhavLimit = 1.0;
    let showLagaiOnly = false;
    let lagaiBhav1 = 0;
    let lagaiBhav2 = 0;
    let lagai1 = getDisplayValue(
      runners[0]?.runner_odd?.ex?.availableToBack[0]?.price,
      market.market_type,
      true
    );
    let lagai2 = getDisplayValue(
      runners[1]?.runner_odd?.ex?.availableToBack[0]?.price,
      market.market_type,
      true
    );

    let isFav = false;
    if (runners.length == 3) {
      //TEST MATCH

      let lagai3 = getDisplayValue(
        runners[2]?.runner_odd?.ex?.availableToBack[0]?.price,
        market.market_type,
        true
      );
      let khai3 = getDisplayValue(
        runners[2]?.runner_odd?.ex?.availableToLay[0]?.price,
        market.market_type,
        false
      );
      if (index == 0) {
        if (lagai1 < lagai2) {
          isFav = true;
        }

        if (lagai1 < maxBhavLimit && lagai1 > minBhavLimit) {
          // showLagaiOnly = true;
          if (lagai1 < lagai2) {
            lagaiBhav1 = lagai1;
            lagaiBhav2 = lagai2;
          }
        }
      } else if (index == 1) {
        if (lagai2 < lagai1) {
          isFav = true;
        }
        if (lagai2 < maxBhavLimit && lagai2 > minBhavLimit) {
          //  showLagaiOnly = true;
          if (lagai2 < lagai1) {
            lagaiBhav1 = lagai1;
            lagaiBhav2 = lagai2;
          }
        }
      } else if (index == 2) {
        if (khai3 > 9.99 && lagai3 > 9.99) {
          isFav = false;
        } else {
          isFav = true;
        }
      }
    } else if (runners.length == 2) {
      //Other MATCH
      if (index == 0) {
        if (lagai1 < lagai2) {
          isFav = true;
        }

        if (lagai1 < maxBhavLimit && lagai1 > minBhavLimit) {
          if (lagai1 < lagai2) {
            lagaiBhav1 = lagai1;
            lagaiBhav2 = lagai2;
          }
        }
      } else if (index == 1) {
        if (lagai2 < lagai1) {
          isFav = true;
        }
        if (lagai2 < maxBhavLimit && lagai2 > minBhavLimit) {
          if (lagai2 < lagai1) {
            lagaiBhav1 = lagai1;
            lagaiBhav2 = lagai2;
          }
        }
      }
      if (market.market_type == 2 && lagai1 >= 94) {
        showLagaiOnly = true;
        lagaiBhav1 = lagai1;
        lagaiBhav2 = lagai2;
      }
    }
    if (market.market_type == 2) {
      // if (market.runner_odds[index]?.status == 'ACTIVE') {
      if (
        runners[index]?.selectionId == market.runner_odds[0]?.selectionId &&
        market.runner_odds[0]?.status == "ACTIVE"
      ) {
        showLagaiOnly = false;
        lagaiBhav1 = lagai1;
        lagaiBhav2 = lagai2;
        isFav = true;
      } else {
        isFav = false;
      }
    }

    if (market.market_type == 5 || market.market_type == 6) {
      showLagaiOnly = true;
      lagaiBhav1 = lagai1;
      lagaiBhav2 = lagai2;
    }
    // if (market.market_type == 6) {
    //   showLagaiOnly = false;
    //   lagaiBhav1 = lagai1;
    //   lagaiBhav2 = lagai2;
    // }

    if (+subCompanyliveData?.event?.cup_event === 1) {
      isFav = true;
    }

    let resultData = {
      isFav: isFav,
      showLagaiOnly: showLagaiOnly,
      lagaiBhav1: lagaiBhav1,
      lagaiBhav2: lagaiBhav2,
    };
    return resultData;
  }

  const getAllMarketIds = ({ market = [], fancy = [] }) => {
    const marketIds = market?.map((mrk) => mrk.market_id);
    const sessions = fancy?.map((fancy) => "" + fancy.session_id);
    return [...marketIds, ...sessions];
  };

  function getMarketList(list) {
    for (let i = 0; i < list.length; i++) {
      let runners = list[i].runners;
      let showLagaiOnly = false;
      let lagaiBhav1 = 0;
      let lagaiBhav2 = 0;
      for (let j = 0; j < runners.length; j++) {
        let resData = isFavTeam(list[i], runners, j);
        runners[j].isFav = resData.isFav;
        if (!showLagaiOnly) {
          showLagaiOnly = resData.showLagaiOnly;
          lagaiBhav1 = resData.lagaiBhav1;
          lagaiBhav2 = resData.lagaiBhav2;
        }
      }
      if (showLagaiOnly) {
        list[i].showLagaiOnly = showLagaiOnly;
        list[i].lagaiBhav1 = lagaiBhav1;
        list[i].lagaiBhav2 = lagaiBhav2;
      }
      // else if(list[i].market_type==6){
      //   list[i].lagaiBhav1 = lagaiBhav1;
      //   list[i].lagaiBhav2 = lagaiBhav2;
      // }
      list[i].runners = runners;
    }
    return list;
  }

  function getSCTotal(index, data) {
    let total = 0;
    for (let i = 0; i < data.length; i++) {
      total += data[i].sc[index].my_status;
    }
    return convertParseFloat(total);
  }

  function getCompanyTotal() {
    let total = 0;
    for (let i = 0; i < declaredMarketData.length; i++) {
      total += parseInt(declaredMarketData[i].status);
    }
    return convertParseFloat(total);
  }

  function redirectToFixture(match) {
    navigate(`/subcompany_live_report/${match.event_id}`);
    window.location.reload();
  }

  const showSubCompanyButton = (
    <button
      type="button"
      className="btn btn-primary"
      onClick={() => navigate(`/live_report/${event_id}`, { replace: true })}
    >
      Hide SubCompany
    </button>
  );

  let marketList =
    subCompanyliveData &&
    subCompanyliveData.market &&
    subCompanyliveData.market.length > 0
      ? getMarketList(subCompanyliveData.market)
      : [];
  const memorizeMarketList = useMemo(
    () => getAllMarketIds({ ...subCompanyliveData }),
    [subCompanyliveData, closeOpenRoom]
  );
  const memorizeCloseOpenRoom = useMemo(() => closeOpenRoom, [closeOpenRoom]);

  return (
    <>
      {memorizeMarketList && memorizeMarketList.length > 0 && (
        <SocketHelper
          event_id={event_id}
          marketList={memorizeMarketList}
          closeAllRoom={memorizeCloseOpenRoom}
        />
      )}

      <InnerPageHeader
        pageTitle="Sub Company Live Report"
        breadcrumbs={[
          { title: "Dashboard", url: "/" },
          { title: "Matches", url: "/matches_list" },
          {
            title: subCompanyliveData?.event?.event_name
              ? subCompanyliveData?.event?.event_name
              : eventName,
            url: {
              pathname: `/match_dashboard/${event_id}`,
              state: { matchData: subCompanyliveData?.event },
            },
          },
        ]}
        currenPageTitle={"Sub Company Live Report"}
      />
      <div id="render-sub-company-live-report">
        <div className="wrapper wrapper-content">
          <div className="row">
            <div className="col-lg-12">
              <div className="justify-end d-flex flex-center1 pb-05">
                {!detectMobView() && userRole == 1 && showSubCompanyButton}
              </div>
              <div className="ibox float-e-margins">
                {subCompanyliveData &&
                subCompanyliveData.event &&
                subCompanyliveData.event.sport_id == 1 &&
                subCompanyliveData.event.score_widget &&
                subCompanyliveData.event.score_widget != "" ? (
                  <iframe
                    id="frame"
                    src={
                      subCompanyliveData.event.score_widget
                        ? subCompanyliveData.event.score_widget
                        : ""
                    }
                    width="100%"
                    height="222"
                    frameBorder="0"
                  ></iframe>
                ) : (
                  <div className="container-fluid">
                    <div className="row">
                      <div className="flex_div_class rowGaper">
                        {subCompanyliveData?.event?.sport_id == 4 &&
                        subCompanyliveData?.event.score_widget &&
                        subCompanyliveData?.event.score_widget != "" ? (
                          <iframe
                            className="score_widget_iframe"
                            id="frame"
                            src={
                              subCompanyliveData?.event?.score_widget
                                ? subCompanyliveData?.event?.score_widget
                                : ""
                            }
                            width="100%"
                            height={"135px"}
                            frameBorder="0"
                            style={{
                              backgroundColor: "rgb(58, 97, 162)",
                            }}
                          ></iframe>
                        ) : (
                          <ViewScoreWidget
                            updateData={subCompanyliveData?.updateScore}
                          />
                        )}
                        <div
                          className="ldg-tbl-col col-lg-3 col-xs-3 col-md-3 col-sm-3 current-event-box bet_status_box stausBx_ht"
                          style={{
                            paddingRight: "5px",
                            paddingLeft: "3px !important",
                          }}
                        >
                          <div
                            className="inplay-show-place-bet"
                            style={{
                              backgroundColor: "rgb(58, 97, 162)",
                              color: "rgb(255, 255, 255)",
                              fontSize: "30px",
                              minHeight:
                                subCompanyliveData?.event?.sport_id == 4 &&
                                subCompanyliveData?.event.score_widget &&
                                subCompanyliveData?.event.score_widget != ""
                                  ? "150px"
                                  : "100%",
                            }}
                            id="event_table_403636"
                          >
                            {subCompanyliveData?.event?.button_label
                              ? subCompanyliveData.event.button_label
                              : subCompanyliveData?.event && (
                                  <>
                                    BET
                                    <br></br>
                                    OPEN
                                  </>
                                )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                <div className="row border-bottom">
                  <ul className="marquee_score_widget">
                    <marquee>{subCompanyliveData?.event?.announcement}</marquee>
                  </ul>
                </div>
                <div className="d-flex flex-center1 p1-05 pt-1">
                  {detectMobView() && userRole == 1 && showSubCompanyButton}
                </div>
                {getFilterTypeMarketList(1, marketList).length > 0
                  ? getFilterTypeMarketList(1, marketList).map(
                      (data, pindex) => (
                        <MarketView
                          key={pindex}
                          data={data}
                          pindex={pindex}
                          subCompData={subCompData}
                          subCompanyAllData={subCompanyAllData}
                          subCompanyliveData={subCompanyliveData}
                          getValueForLagai={getValueForLagai}
                          fetchSubMarket={fetchSubMarket}
                          getValueForKhai={getValueForKhai}
                        />
                      )
                    )
                  : null}

                {subCompanyliveData &&
                subCompanyliveData.fancy &&
                subCompanyliveData.fancy.length ? (
                  <div className="row session-resp-tbl">
                    {differentFancyList(subCompanyliveData?.fancy).map(
                      (fancy) => (
                        <SessionView
                          key={fancy?.session_type}
                          fancyData={fancyData}
                          fancy={fancy}
                        />
                      )
                    )}
                  </div>
                ) : null}

                {getFilterTypeMarketList(2, marketList).length > 0
                  ? getFilterTypeMarketList(2, marketList).map(
                      (data, pindex) => (
                        <MarketView
                          key={pindex}
                          data={data}
                          pindex={pindex}
                          subCompData={subCompData}
                          subCompanyAllData={subCompanyAllData}
                          subCompanyliveData={subCompanyliveData}
                          getValueForLagai={getValueForLagai}
                          fetchSubMarket={fetchSubMarket}
                          getValueForKhai={getValueForKhai}
                        />
                      )
                    )
                  : null}
                <div className="row ">
                  <Accordion activeKey={showDeclared}>
                    <Card>
                      <Card.Header>
                        <div
                          className="bet-place-tbl-th session-th-strip pointer justify-between d-flex align-center"
                          onClick={() =>
                            setDeclaredColumn(
                              showDeclared === "session" ? "" : "session"
                            )
                          }
                          style={{ padding: "15px 6px" }}
                        >
                          <p className="align-left m-0">DECLARED SESSIONS</p>
                          <span
                            className={
                              (showDeclared === "session" ? " active " : "") +
                              " fa arrow"
                            }
                          ></span>
                        </div>
                      </Card.Header>
                      <Accordion.Collapse eventKey="session">
                        {declaredFancyData && declaredFancyData.length ? (
                          <div className="table-responsive">
                            <table className="table table-bordered">
                              <thead>
                                <tr>
                                  <th
                                    className="bet-place-tbl-th"
                                    style={{ backgroundColor: "#49494A" }}
                                  >
                                    SESSION
                                  </th>
                                  <th className="bet-odds-type bet-place-tbl-th">
                                    Settled at Runs
                                  </th>
                                  <th className="bet-odds-type bet-place-tbl-th">
                                    {userRole == 1 ? "Company" : "Status"}
                                  </th>
                                  {declaredFancyData[0].sc &&
                                    declaredFancyData[0].sc.map(
                                      (subCompany, index) => (
                                        <th
                                          className="bet-odds-type bet-place-tbl-th"
                                          key={index}
                                        >
                                          {subCompany.username}
                                        </th>
                                      )
                                    )}
                                </tr>
                              </thead>
                              <tbody>
                                {declaredFancyData.map((declared, index) => (
                                  <tr key={index}>
                                    <td
                                      data-column="session_title"
                                      className="bet-place-tbl-td"
                                      style={{ textAlign: "left" }}
                                    >
                                      {declared.runner_name}{" "}
                                    </td>
                                    <td className="bet-place-tbl-td bet-val-cent">
                                      {declared.settlement_result}
                                    </td>
                                    <td
                                      className="bet-place-tbl-td bet-val-cent"
                                      style={{
                                        color:
                                          declared.status >= 0
                                            ? "#228C22"
                                            : "#FF0000",
                                      }}
                                    >
                                      {declared.status}
                                    </td>
                                    {declared.sc &&
                                      declared.sc.map((subCompany, index) => (
                                        <td
                                          key={index}
                                          className="bet-place-tbl-td bet-val-cent"
                                          style={{
                                            color:
                                              subCompany.my_status >= 0
                                                ? "#228C22"
                                                : "#FF0000",
                                          }}
                                        >
                                          {subCompany.my_status}
                                        </td>
                                      ))}
                                  </tr>
                                ))}

                                <tr>
                                  <td className="bet-place-tbl-td bet-val-cent">
                                    Declare Session Total
                                  </td>
                                  <td className="bet-place-tbl-td bet-val-cent"></td>
                                  <td
                                    className="bet-place-tbl-td bet-val-cent"
                                    style={{
                                      color:
                                        getDeclaredSatatusSum(
                                          declaredFancyData
                                        ) >= 0
                                          ? "#228C22"
                                          : "#FF0000",
                                    }}
                                  >
                                    {getDeclaredSatatusSum(declaredFancyData)}
                                  </td>
                                  {declaredFancyData[0].sc &&
                                    declaredFancyData[0].sc.map(
                                      (subCompany, scIndex) => (
                                        <td
                                          key={scIndex}
                                          className="bet-place-tbl-td bet-val-cent"
                                          style={{
                                            color:
                                              getDeclaredMyStatusSum(
                                                declaredFancyData,
                                                scIndex
                                              ) >= 0
                                                ? "#228C22"
                                                : "#FF0000",
                                          }}
                                        >
                                          {getDeclaredMyStatusSum(
                                            declaredFancyData,
                                            scIndex
                                          )}
                                        </td>
                                      )
                                    )}
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        ) : isLoading ? (
                          <p>Loading...</p>
                        ) : (
                          <p> No Data Found</p>
                        )}
                      </Accordion.Collapse>
                    </Card>
                    <Card>
                      <Card.Header>
                        <div
                          className="bet-place-tbl-th session-th-strip pointer justify-between d-flex align-center"
                          onClick={() =>
                            setDeclaredColumn(
                              showDeclared === "market" ? "" : "market"
                            )
                          }
                          style={{ padding: "15px 6px" }}
                        >
                          <p className="align-left m-0">DECLARED MARKET</p>
                          <span
                            className={
                              (showDeclared === "market" ? " active " : "") +
                              " fa arrow"
                            }
                          ></span>
                        </div>
                      </Card.Header>
                      <Accordion.Collapse eventKey="market">
                        {declaredMarketData && declaredMarketData.length ? (
                          <div className="table-responsive">
                            <table className="table table-bordered">
                              <thead>
                                <tr>
                                  <th
                                    className="bet-place-tbl-th"
                                    style={{ backgroundColor: "#49494A" }}
                                  >
                                    MARKET
                                  </th>
                                  <th className="bet-odds-type bet-place-tbl-th">
                                    WINNER
                                  </th>
                                  <th className="bet-odds-type bet-place-tbl-th">
                                    COMPANY
                                  </th>
                                  {/* <th className="bet-odds-type bet-place-tbl-th">
                                Status
                              </th> */}
                                  {declaredMarketData &&
                                    declaredMarketData.length > 0 &&
                                    declaredMarketData[0].sc &&
                                    declaredMarketData[0].sc.map(
                                      (item, index) => {
                                        return (
                                          <th
                                            key={index + "_no"}
                                            className="bet-place-tbl-th"
                                            style={{
                                              backgroundColor: "#49494A",
                                            }}
                                          >
                                            {item.username}
                                          </th>
                                        );
                                      }
                                    )}
                                </tr>
                              </thead>
                              <tbody>
                                {declaredMarketData.map((declared, index) => (
                                  <tr key={index}>
                                    <td
                                      data-column="session_title"
                                      className="bet-place-tbl-td"
                                      style={{ textAlign: "left" }}
                                    >
                                      {declared.market_name}{" "}
                                    </td>
                                    <td className="bet-place-tbl-td bet-val-cent">
                                      {getWinner(declared)}
                                    </td>
                                    <td
                                      className="bet-place-tbl-td bet-val-cent"
                                      style={{
                                        color:
                                          declared.status >= 0
                                            ? "#228C22"
                                            : "#FF0000",
                                      }}
                                    >
                                      {declared.status}
                                    </td>
                                    {declared.sc &&
                                      declared.sc.map((item, index) => {
                                        return (
                                          <th
                                            key={index + "_no"}
                                            className="bet-place-tbl-td bet-val-cent"
                                            style={{
                                              color:
                                                item.my_status >= 0
                                                  ? "#228C22"
                                                  : "#FF0000",
                                            }}
                                          >
                                            {isNaN(Number(item.my_status))
                                              ? item.my_status
                                              : convertParseFloat(
                                                  item.my_status
                                                )}
                                          </th>
                                        );
                                      })}
                                  </tr>
                                ))}

                                <tr>
                                  <td className="bet-place-tbl-td bet-val-cent">
                                    Declare Market Total
                                  </td>
                                  <td className="bet-place-tbl-td bet-val-cent"></td>
                                  <td
                                    className="bet-place-tbl-td bet-val-cent"
                                    style={{
                                      color:
                                        getCompanyTotal() >= 0
                                          ? "#228C22"
                                          : "#FF0000",
                                    }}
                                  >
                                    {getCompanyTotal()}
                                  </td>

                                  {declaredMarketData &&
                                    declaredMarketData.length > 0 &&
                                    declaredMarketData[0].sc &&
                                    declaredMarketData[0].sc.map(
                                      (item, index) => {
                                        return (
                                          <th
                                            key={index + "_no"}
                                            className="bet-place-tbl-td bet-val-cent"
                                            style={{
                                              color:
                                                getSCTotal(
                                                  index,
                                                  declaredMarketData
                                                ) >= 0
                                                  ? "#228C22"
                                                  : "#FF0000",
                                            }}
                                          >
                                            {getSCTotal(
                                              index,
                                              declaredMarketData
                                            )}
                                          </th>
                                        );
                                      }
                                    )}
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        ) : isLoading ? (
                          <p>Loading...</p>
                        ) : (
                          <p> No Data Found</p>
                        )}
                      </Accordion.Collapse>
                    </Card>
                  </Accordion>
                </div>
              </div>
            </div>
          </div>

          {liveMatches?.length
            ? liveMatches.map((match) => (
                <LiveMatchesList
                  key={match.event_id}
                  match={match}
                  redirectToFixture={(e) => redirectToFixture(e)}
                />
              ))
            : null}

          <div className="row" style={{ margin: "0 !important" }}>
            <div className="match-index-row header-top-spacing">
              <div
                className="row"
                style={{ paddingBottom: "30px", margin: "auto auto" }}
              ></div>
            </div>
          </div>

          <div className="wrapper wrapper-content">
            <div className="row">
              <div className="col-lg-12"></div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SubCompanyLiveReport;
