import React, { useState, useEffect } from 'react'
import InnerPageHeader from "../../components/common/InnerPageHeader";
import { UpdateLoginSetting, getOtherAppCharges } from "_services/othercharges.services"
import { toast } from "react-toastify";

function AgentLoginSettings() {

    const [otherChargesData, setotherChargesData] = useState({
        id: '',
        agent_multi_login: '',
    })

    useEffect(() => {
        getOtherChargesFunction();
    }, [])

    const getOtherChargesFunction = () => {
        let sendData = {}
        getOtherAppCharges(sendData)
            .then((res) => {
                setotherChargesData(res?.data?.data)
            }).catch((err) => {
                console.log(err)
            })
    }

    const handleInput = (e) => {
        setotherChargesData({
            ...otherChargesData,
            [e.target.name]: e.target.value == 0 ? 1 : 0
        })
    }

    var onFormSubmit = (e) => {
        e.preventDefault();

        let sendData = {
            id: otherChargesData.id,
            agent_multi_login: otherChargesData.agent_multi_login
        }
        UpdateLoginSetting(sendData)
            .then((res) => {
                // toast("Login Setting Data Updated");
                toast.success(
                    <div>
                        <i
                            className="fa fa-check"
                            style={{ fontWeight: "bold", marginRight: "1rem" }}
                            aria-hidden="true"
                        ></i>
                        <span>Login Setting Data Updated</span>
                    </div>,
                    {
                        position: "top-center",
                        autoClose: 3000,
                        hideProgressBar: true,
                        closeOnClick: false,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    }
                );
            }).catch((err) => {
                toast.error("Not Working , Login Setting Data Not Updated");
            })
    }

    return (
        <>
            <InnerPageHeader
                pageTitle="Login Setting"
                breadcrumbs={[
                    { title: "Dashboard", url: "/" },
                ]}
                currenPageTitle="Login Setting"
            />

            <div>
                <div className="row">
                    <div className="col-lg-12">
                        <div className="ibox float-e-margins">
                            <div className="ibox-content">
                                <form className="form-horizontal">
                                    <div className="form-group">
                                        <label className="col-sm-4 control-label"> Update Multiple Login </label>
                                        <div className="col-sm-1">
                                            <input
                                                type="checkbox"
                                                className="form-control"
                                                name="agent_multi_login"
                                                checked={otherChargesData.agent_multi_login == 1 ? true : false}
                                                value={otherChargesData.agent_multi_login}
                                                onChange={(e) => handleInput(e)}
                                            />
                                        </div>
                                    </div>

                                    <div className="hr-line-dashed"></div>

                                    <div className="form-group">
                                        <div className="col-sm-12" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                            <button className="btn btn-primary" onClick={(e) => onFormSubmit(e)}>
                                                Update Login Setting
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AgentLoginSettings


// getOtherAppCharges

// updateOtherAppCharges