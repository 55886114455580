import React from "react";

import FixtureMarketComponent from "components/FixtureMarket/FixtureMarketComponent";
import { connect } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";

function FixtureMarket(props) {
  const navigate = useNavigate();
  const params = useParams();
  const location = useLocation();

  return (
    <FixtureMarketComponent
      {...props}
      navigate={navigate}
      params={params}
      location={location}
    />
  );
}

const mapstatetop = (state) => ({
  ...state,
});

export default FixtureMarket = connect(mapstatetop)(FixtureMarket);
