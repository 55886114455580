import React, { Component } from "react";
import { connect } from "react-redux";
import MatchesListIdComponent from "../../../components/MatchesList/MatchesListIdComponent.jsx";
class MatchesListId extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return <MatchesListIdComponent {...this.props} />;
  }
}
const mapstatetop = (state) => ({
  ...state,
});

export default connect(mapstatetop)(MatchesListId);
