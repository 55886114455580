import React from "react";
import { Col, Row } from "react-bootstrap";

export default function MarketView(props) {
  const {
    data,
    pindex,
    userRole,
    marketStatusChange,
    marketShowHideStatusChange,
    liveData,
    getValueForKhai,
    getValueForLagai,
    fetchMarketPosition,
    setToggleBook
  } = props;
  return (
    <>
      <div key={pindex} className="market-container">
        <div className="market-header">
          <div className="col-sm-8 market" style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div style={{ display: 'flex' }}>
              <div className="switch-container" style={{ marginRight: '5px' }} title="on/off for clients" >
                <label className="switch">
                  <input
                    type="checkbox"
                    checked={data.hide_market == 1 ? false : true}
                    onChange={(e) =>
                      marketShowHideStatusChange(
                        e,
                        data.market_id,
                        data.hide_market,

                      )
                    }
                  />
                  <span className="slider round"></span>
                </label>
              </div>
              <p className="market-name">{data.market_name}</p>
            </div>
            {/* <p className="stack">Min:100 Max:100000</p> */}

            {/* <div>
              <button
                className="book-btn"
                onClick={() =>
                  setToggleBook(data)
                }
              >
                Book
              </button>
            </div> */}

            {userRole?.role != 10 &&
              <div className="book-btn">
                <a
                  style={{ fontSize: '10px' }}
                  className="btn btn-primary-book"
                  data-method="get"
                  data-remote="true"
                  onClick={() => setToggleBook(data)}
                >
                  Book
                </a>
              </div>
            }


          </div>
          <div className="col-sm-2 lagai">Lagai</div>
          <div className="col-sm-2 khai">Khai</div>
        </div>
        {data.runners && data.runners.length &&
          <>
            {data.runners.map((val, index) => (
              <div className="market-body" key={index}>
                <div className="col-lg-8 runner">

                  {/* {
                    // role && role == "1"
                    (userRole?.role == 1 || userRole?.role == 9)  &&
                    <div className="switch-container">
                      <label className="switch">
                        <input
                          type="checkbox"
                          checked={true}

                        />
                        <span className="slider round"></span>
                      </label>
                    </div>} */}

                  {
                    // role && role == "1"
                    (userRole?.role == 1 || userRole?.role == 10) &&
                    // data.market_type < 5 ? (
                    <div className="switch-container">
                      <label className="switch">
                        <input
                          type="checkbox"
                          checked={!val.lock_team_betting}
                          onChange={(e) =>
                            marketStatusChange(
                              e,
                              data.market_id,
                              val.selectionId,
                              val.lock_team_betting
                            )
                          }
                        />
                        <span className="slider round"></span>
                      </label>
                    </div>
                    // ) : null
                  }


                  <div className="runner-block">
                    <p className="runner-name">

                      {val?.runnerName}
                    </p>
                    <p className="win-loss" style={{
                      color:
                        fetchMarketPosition(
                          data.market_id,
                          val.selectionId
                        ) >= 0
                          ? "#228C22"
                          : "#FF0000",
                    }}> {fetchMarketPosition(data.market_id, val.selectionId)}</p>
                  </div>
                </div>
                <div className="col-lg-4 odds">
                  <div className="col-lg-6 lagai">
                    <div className="lagai-block">
                      <p className="price">
                        {/* {getValueForLagai(data, val, index, liveData)} */}
                        {data.market_type == 2 ? getValueForLagai(data, val, index, liveData) :
                          Math.abs((+(getValueForLagai(data, val, index, liveData))) + 1).toFixed(2)}
                      </p>
                      <p className="size">{val && val.runner_odd && val.runner_odd.ex && val.runner_odd.ex.availableToBack[0] && val.runner_odd.ex.availableToBack[0].size ? val.runner_odd?.ex?.availableToBack[0]?.size : '--'}</p>
                    </div>
                  </div>
                  <div className="col-lg-6 khai">
                    <div className="khai-block">
                      <p className="price">
                        {/* {getValueForKhai(data, val, index, liveData)} */}
                        {data.market_type == 2 ? getValueForKhai(data, val, index, liveData) :
                          Math.abs((+(getValueForKhai(data, val, index, liveData))) + 1).toFixed(2)}
                      </p>
                      <p className="size">{val && val.runner_odd && val.runner_odd.ex && val.runner_odd.ex.availableToLay[0] && val.runner_odd.ex.availableToLay[0].size ? val.runner_odd?.ex?.availableToLay[0]?.size : '--'}</p>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </>}

      </div>


      {/* <div className="row scroll-form" key={pindex}>
        {(data.market_type == 6 || data.market_type == 7) && (
          <div
            className="bet-place-tbl-th align-left"
            style={{ backgroundColor: "#6E8FC9" }}
          >
            <div className="d-flex justify-between flex-center">
              <p className="p-0">Market : {data.market_name}</p>
              {(userRole?.role == 1 || userRole?.role == 9) &&
                data.market_type >= 5 ? (
                <label className="switch">
                  <input
                    type="checkbox"
                    checked={data.betLockStatus}
                    onChange={(e) =>
                      marketStatusChange(
                        e,
                        data.market_id,
                        "", // no need to pass selection id
                        e.target.checked
                      )
                    }
                  />
                  <span className="slider round"></span>
                </label>
              ) : (
                <></>
              )}
            </div>
          </div>
        )}
        <div
          className={
            data.market_type == 6 || data.market_type == 7 ? "overflow-table" : ""
          }
        >
          <table className="table table-bordered table-responsive">

            <thead>
              {!(data.market_type == 6 || data.market_type == 7) && (
                <tr disabled>
                  <th
                    colSpan="4"
                    className="bet-place-tbl-th align-left"
                    style={{ backgroundColor: "#6E8FC9" }}
                  >
                    <div className="d-flex justify-between flex-center">
                      <p className="p-0">Market : {data.market_name}</p>
                      {(userRole?.role == 1 || userRole?.role == 9) &&
                        data.market_type >= 5 ? (
                        <label className="switch">
                          <input
                            type="checkbox"
                            checked={data.betLockStatus}
                            onChange={(e) =>
                              marketStatusChange(
                                e,
                                data.market_id,
                                "", // no need to pass selection id
                                e.target.checked
                              )
                            }
                          />
                          <span className="slider round"></span>
                        </label>
                      ) : (
                        <></>
                      )}
                    </div>
                  </th>
                </tr>
              )}
              <tr>
                <th
                  className="bet-place-tbl-th"
                  style={{ backgroundColor: "#49494A" }}
                >
                  RUNNER
                </th>
                {data.market_type == 6 || data.market_type == 7 ? (
                  data?.runners?.length &&
                  data?.runners.map((val, index) => (
                    <th className="bet-odds-type bet-place-tbl-th" key={index}>
                      {val?.runnerName}
                    </th>
                  ))
                ) : (
                  <>
                    <th className="bet-odds-type bet-place-tbl-th">LAGAI</th>
                    <th className="bet-odds-type bet-place-tbl-th">KHAI</th>
                  </>
                )}
                {data.market_type == 6 || data.market_type == 7 ? (
                  data?.runners?.length &&
                  data?.runners.map((val, index) => (
                    <th
                      className="bet-place-tbl-th"
                      style={{ backgroundColor: "#49494A" }}
                      key={index}
                    >
                      {val?.runnerName} POSITION
                    </th>
                  ))
                ) : (
                  <th
                    className="bet-place-tbl-th"
                    style={{ backgroundColor: "#49494A" }}
                  >
                    POSITION
                  </th>
                )}
              </tr>
            </thead>


            <tbody>
              {data.runners && data.runners.length ? (
                data.market_type == 6 || data.market_type == 7 ? (
                  <tr>
                    <td
                      data-runner-sid="Essex _403636"
                      data-column="runner_name"
                      className="bet-place-tbl-td"
                      style={{
                        textAlign: "left",
                      }}
                    >
                      {" "}
                      {data.market_name}
                    </td>
                    {data.runners && data.runners.length
                      ? data.runners.map((val, index) => (
                        <td
                          style={{ minWidth: "30px" }}
                          key={index}
                          data-runner-sid="Essex _403636"
                          data-column="back"
                          className="MarketTable bet-place-tbl-td first-runner-bet-odds-yes-value cursor-pointer "
                          odd-val="1.01"
                          market-id="480666"
                          runner-id="946451"
                          match-id="403636"
                          data-id="LAGAI"
                          id="Match Winner_403636_back_Essex _403636"
                        >
                          {getValueForLagai(data, val, index, liveData)}
                        </td>
                      ))
                      : null}
                    {data.runners && data.runners.length
                      ? data.runners.map((val, index) => (
                        <td
                          key={index}
                          data-runner-sid="Essex _403636"
                          data-column="position"
                          className="bet-place-tbl-td  bet-val-cent"
                          data-position="33595.26"
                          style={{
                            color:
                              fetchMarketPosition(
                                data.market_id,
                                val.selectionId
                              ) >= 0
                                ? "#228C22"
                                : "#FF0000",
                          }}
                        >
                          {fetchMarketPosition(data.market_id, val.selectionId)}
                        </td>
                      ))
                      : null}
                  </tr>
                ) : (
                  data.runners.map((val, index) => (
                    <tr key={index}>
                      <td
                        data-runner-sid="Essex _403636"
                        data-column="runner_name"
                        className="bet-place-tbl-td"
                        style={{
                          textAlign: "left",
                        }}
                      >
                        {
                          // role && role == "1"
                          (userRole?.role == 1 || userRole?.role == 9) &&
                            data.market_type < 5 ? (
                            <label className="switch">
                              <input
                                type="checkbox"
                                checked={!val.lock_team_betting}
                                onChange={(e) =>
                                  marketStatusChange(
                                    e,
                                    data.market_id,
                                    val.selectionId,
                                    val.lock_team_betting
                                  )
                                }
                              />
                              <span className="slider round"></span>
                            </label>
                          ) : null
                        }{" "}
                        {val.runnerName}
                      </td>
                      {data.market_type !== 6 && data.market_type !== 7 ? (
                        <>
                          {data?.market_type == 2 &&
                            data?.match_status != 0 &&
                            data?.match_status != "ACTIVE" ? (
                            <td
                              style={{
                                backgroundColor: "rgba(51, 51, 51, 0.5)",
                              }}
                              colSpan={2}
                              data-runner-sid="Essex _403636"
                              data-column="lay"
                              className="MarketTable bet-place-tbl-td first-runner-bet-odds-no-value cursor-pointer "
                              odd-val="1.02"
                              market-id="480666"
                              runner-id="946451"
                              match-id="403636"
                              data-id="KHAI"
                              id="Match Winner_403636_lay_Essex _403636"
                            >
                              {data?.match_status}
                            </td>
                          ) : (
                            <>
                              <td
                                data-runner-sid="Essex _403636"
                                data-column="back"
                                className="MarketTable bet-place-tbl-td first-runner-bet-odds-yes-value cursor-pointer "
                                odd-val="1.01"
                                market-id="480666"
                                runner-id="946451"
                                match-id="403636"
                                data-id="LAGAI"
                                id="Match Winner_403636_back_Essex _403636"
                              >
                                {getValueForLagai(data, val, index, liveData)}
                              </td>
                              <td
                                data-runner-sid="Essex _403636"
                                data-column="lay"
                                className="MarketTable bet-place-tbl-td first-runner-bet-odds-no-value cursor-pointer "
                                odd-val="1.02"
                                market-id="480666"
                                runner-id="946451"
                                match-id="403636"
                                data-id="KHAI"
                                id="Match Winner_403636_lay_Essex _403636"
                              >
                                {getValueForKhai(data, val, liveData)}
                              </td>
                            </>
                          )}
                        </>
                      ) : (
                        <td
                          data-runner-sid="Essex _403636"
                          data-column="back"
                          className="MarketTable bet-place-tbl-td first-runner-bet-odds-yes-value cursor-pointer "
                          odd-val="1.01"
                          market-id="480666"
                          runner-id="946451"
                          match-id="403636"
                          data-id="LAGAI"
                          id="Match Winner_403636_back_Essex _403636"
                        >
                          {getValueForLagai(data, val, index, liveData)}
                        </td>
                      )}
                      <td
                        data-runner-sid="Essex _403636"
                        data-column="position"
                        className="bet-place-tbl-td  bet-val-cent"
                        data-position="33595.26"
                        style={{
                          color:
                            fetchMarketPosition(
                              data.market_id,
                              val.selectionId
                            ) >= 0
                              ? "#228C22"
                              : "#FF0000",
                        }}
                      >
                        {fetchMarketPosition(data.market_id, val.selectionId)}
                      </td>
                    </tr>
                  ))
                )
              ) : null}
            </tbody>
          </table>
        </div>
      </div> */}
    </>
  );
}
