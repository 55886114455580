import React, { Component } from "react";

import { connect } from "react-redux";
import ClientReportComponent from "../../components/ClientReport/ClientReport";
class ClientReport extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      
        <ClientReportComponent />
      
    );
  }
}
const mapstatetop = (state) => ({
  ...state,
});

export default connect(mapstatetop)(ClientReport);
